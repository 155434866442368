import React, {Component, Fragment} from 'react';
import Config from '../../Config';
import TestIco from '../../core/images/test-tube.png'

export default class BSW extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    render(){
      const colorCodes = ['#368FFE,#27DFC5','#6188ED,#7E4AAD','#D1FCAB,#1AEAEB','#F42E78,#C17AFC','#F4E5FA,#61A2E7','#FF8993,#FEC180'];
      const {data} = this.props;
      let counter = 0;
        return(
        <Fragment>
          <div className="row">
            <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
              <h2 className="product-title">GG Labs</h2>
            </div>
            <div className="col-xl-5 col-xl-5 col-sm-5 col-4">
              <p className='mt-2 pull-right link text-primary'>{data.length} result</p>
            </div>
          </div>
          <div className="row" >
            {data.map((labs,key)=>{
              labs=labs._source;
              let color_code =  colorCodes[(key % 6)];
              let colorCode = color_code ? color_code.split(',') : [];
              return(
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 my-4" key={key}>
                  <div className={"brandsDiv"} style={{'backgroundImage': 'linear-gradient(-135deg, ' + colorCode[0] + ' 0%, ' + colorCode[1] + ' 100%)'}}>
                    <a href={Config.LABS_URL+"profile-detail/"+labs.code}>
                      <div className="brandsImg">
                        <img alt={labs.name} src={TestIco} className="img-fluid"
                          style={{width: "65%"}}
                          onError={(ev)=>{
                            ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                          }}/>
                      </div>
                      <h4 className="name mb-3">{labs.name}</h4>
                      <h5 className="name mt-3 mb-3">₹{labs.price}</h5>
                      <div className="visit-website">
                          {labs.testCount} Test Included
                      </div>
                    </a>
                  </div>
                </div>
              )

            })}
          </div>
        </Fragment>
        );

    }

}
