import React, {Component} from 'react';

import {PageTitle} from 'gg-react-utilities';
import FAQSegment from "./container/faqContainer";

export default class FAQ extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }
    
    render(){
        return(
            <React.Fragment>
              <PageTitle title='FAQ | Global Garner' />
                <div className="container-fluid header-below">
        					<div className="container p-s-0">
        						<div className="career header-below-content">
        							<div className="row text-center mb-5">
        								<div className="col-12 col-xl-12 text-center">
        									<h1 className="bp3-text-xlarge heading">Questions? Look here.</h1>
        									<span className="heading-desc">If you can’t find the answer? Call us on
                            <a href={'tel:+91 79 68221502'} data-rel="external" style={{color:'#B1D3FF'}}>+91 79 68221502</a> Or email <a href={'mailto:info@globalgarner.com'} data-rel="external" style={{color:'#B1D3FF'}}>info@globalgarner.com</a>.</span>
        								</div>
        							</div>
        						</div>
        					</div>
                </div>
                <div className="container-fluid">
                  <FAQSegment/>
                </div>
            </React.Fragment>
        );
    }
}
