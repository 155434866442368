import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";
import CallUs from '../container/callusContainer';
import RequestCall from './modal/requestCall';
import {Auth} from "gg-react-utilities";

const support = [  
  {
    title:'Call us',
    img:require('../images/svg/call.svg')
  },  
  {
    title:'FAQ',
    img:require('../images/svg/faq.svg')
  },
  {
    title:'Raise your tickets',
    img:require('../images/svg/question.svg')
  },
  {
    title:'Media',
    img:require('../images/svg/media.svg')
  },
  {
    title:'How to begin',
    img:require('../images/svg/smartphone.svg')
  },
  {
    title:'Watch video',
    img:require('../images/svg/mobile-phone.svg')
  }
]

export default class Support extends Component{
    constructor(props){
        super(props);

        this.state = {
            callUsModal: false,
            raiseQueryModal: false,
            requestCallModal: false,
        };
    }

    handleTabChange = (TabId) => {
        this.setState({activeTab: TabId});
    };

    componentDidMount(){

    }

    componentWillReceiveProps(nextprops){

    }

    render(){
        return(
    			<div className="container support-container">
    				<CallUs
    					size="lg"
    					isOpen={this.state.callUsModal}

    					className="bp3-dialog bp3-dialog-large p-0 rounded"
    				/>
            <RequestCall
    					size="lg"
    					isOpen={this.state.requestCallModal}
    					className="bp3-dialog bp3-dialog-large p-0 rounded"
    				/>
    				<Card className='bp3-elevation-1 row p-0'>
    					{support.map((res,i)=>{
    						return(
    						<div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 support' key={i}
								onClick={()=>{

								  if(res.title === 'Call us'){
									this.setState({callUsModal:!this.state.callUsModal})
								  } else if(res.title === 'Request call'){
									this.setState({requestCallModal:!this.state.requestCallModal})
								  } else if(res.title === 'Raise your tickets'){
									// this.setState({raiseQueryModal:!this.state.raiseQueryModal})
                                      if (!Auth.isLoggedIn()){

                                          Auth.login(true);
                                      }else {
                                          window.location.href = 'https://support.globalgarner.com/'
                                      }
								  } else if(res.title === 'FAQ'){
									this.props.nextPage('/faq');
								  } else if(res.title === 'Cashback/Reward'){
									this.props.nextPage('/systematic-cashback');
								  } else if(res.title === 'How to begin'){
									this.props.nextPage('/how-to-begin');
                  } else if(res.title === 'Media'){
                  this.props.nextPage('/media');
								  } else if(res.title === 'Watch video'){
									this.props.nextPage('/video');
								  }else if(res.title === 'Chat with us'){
									window.location.href = 'https://api.whatsapp.com/send?phone=916354917501&text=Hello%20Global%20Garner%20Team';
								  }
								}}>
    							<center>
    								<img src={res.img} alt='support-img'/>
    								<p>{res.title}</p>
    							</center>
    						</div>
    						)
    					})}
    				</Card>
    			</div>
        );
    }
}
