import React, {Component, Fragment} from 'react';
import {Classes, Dialog, Card } from "@blueprintjs/core";


export default class CallUs extends Component{

    constructor(props){
        super(props);

        this.state={
          autoFocus: true,
          canEscapeKeyClose: true,
          canOutsideClickClose: true,
          enforceFocus: true,
          isOpen: props.isOpen,
          usePortal: true,
          selectedOption:null
        }
        // this.handleClose = this.handleClose.bind(this);
    }



    componentDidMount(){
      this.setState({
        isOpen: this.props.isOpen
      });
    }

    componentWillReceiveProps(nextprops){
      this.setState({
        isOpen: nextprops.isOpen
      });

    }

    render(){
      const {data} = this.props;
        return(
            <React.Fragment>
              {this.props.data !== null ?
              <Dialog
                isOpen={this.state.isOpen}
                title={this.props.title}
                canOutsideClickClose={false}
                canEscapeKeyClose={true}
                onClose={() => {
                    this.setState({
                        isOpen:false
                    })
                }}
                className={this.props.className?this.props.className:''}>
                 <div className={Classes.DIALOG_BODY+' p-1 m-0 member-detail'} >
                   <Card className='text-center bp3-elevation-1'>
                     <center>
                       <img src={process.env.REACT_APP_IMAGE_URL+data.image} alt='team-member-img'
                         onError={(ev)=>{
                           ev.target.src = process.env.REACT_APP_USER_DEFAULT_IMAGE;
                       }}/>
                     </center>
                     <h3 className='name mb-2'>{data.name}</h3>
                     <p className='position'>{data.position}</p>
                     <p className='description'>
                       {data.description}
                     </p>
                     {this.props.socialIcon?
                       <Fragment>
                         <div className='border-bottom pt-3'/>
                         <div className='social-icon row no-gutters'>
                           <div className='col-xl-2 col-2'>
                             <a href={'mailto:'+data.email} data-rel="external">
                               <img src={require('../../images/svg/mail.svg')} alt='team-member-img'/>
                             </a>
                           </div>
                           <div className='col-xl-2 col-2'>
                             <a href={data.facebook}>
                               <img src={require('../../images/svg/fb.svg')} alt='team-member-img'/>
                             </a>
                           </div>
                           <div className='col-xl-2 col-2'>
                             <a href={'tel:'+data.mobile} data-rel="external">
                               <img src={require('../../images/svg/call.svg')} alt='team-member-img'/>
                             </a>
                           </div>
                           <div className='col-xl-2 col-2'>
                             <a href={data.linkedin}>
                               <img src={require('../../images/svg/linkedIn.svg')} alt='team-member-img'/>
                             </a>
                           </div>
                           <div className='col-xl-2 col-2'>
                             <a href={data.skype}>
                               <img src={require('../../images/svg/skype.svg')} alt='team-member-img'/>
                             </a>
                           </div>
                           <div className='col-xl-2 col-2'>
                             <a href={data.relations}>
                               <img src={require('../../images/svg/relation.svg')} alt='team-member-img'/>
                             </a>
                           </div>
                         </div>
                       </Fragment>

                       :null}
                   </Card>
                 </div>
             </Dialog>
             :null}
            </React.Fragment>
        );
    }

}
