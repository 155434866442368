import React, {Component, Fragment} from 'react';
import Config from '../../Config';
import {Helper} from 'gg-react-utilities';

export default class Product extends Component{
  constructor(props){
      super(props);
      this.state = {

      };
  }

  componentDidMount(){

  }

  componentWillReceiveProps(nextProps){

  }

  render(){
    const {data} = this.props;
    return(
      <Fragment>
        <div className="row">
          <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
            <h2 className="product-title">GG Products</h2>
          </div>
          <div className="col-xl-5 col-xl-5 col-sm-5 col-4">
            <p className='mt-2 pull-right link text-primary'>{data.length} result</p>
          </div>
        </div>
        <div className='product-list row'>
          {data.map((product)=>{
            product = product._source
            return(
              <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2' key={product.product_id} >
                <div className="polaroid">
                  <a href={Config.STORE_URL+'product/'+product.slug}>
                    <img src={product.image} alt="5 Terre" className="img-fluid"
                      onError={(ev)=>{
                        ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                      }}/>
                    </a>
                    <div className="topleft">
                      {product.coin_applicable ?
                        <div className=''>
                          <img src={process.env.REACT_APP_GG_COIN_IMAGE} className='gg-coin-img' alt='gg coin'
                            onError={(ev)=>{
                              ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                            }}/>
                        </div>
                        :null}
                      </div>
                      <div className="container">
                        <div className='row text-left'>
                          <div className="col-xl-12">
                            <a href={Config.STORE_URL+'product/'+product.slug}>
                              <p className="activity-product">{product.name}</p>
                            </a>
                          </div>
                          <h5 className="text-muted col-xl-12 mt-2 mb-2">Brand: {product.brand_name} </h5>
                          <div className='col-xl-12 d-flex justify-content-between'>
                            <p className="text-primary font-weight-bold text-nowrap mb-2">{Helper.formatMoney(product.price)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
      </Fragment>
        );

    }

}
