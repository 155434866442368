import React, {Component, Fragment} from 'react';
import {Button, Card, Classes, Dialog} from "@blueprintjs/core";
// import Recharge from "../../../home/container/RetailStrip/RechargeContainer";

export default class Director extends Component{
    constructor(props){
        super(props);
        this.state = {
            isCallModal: false,
            modalIsOpen: false,
            modalData:null,
            socialIcon:true
        };

    }

    componentDidMount(){
      //
    }

    render(){
      const {data} = this.props;

        return(
            <React.Fragment>
            <div className="row cb-tab-data text-center directer-member-list">
                {data.map((res)=>{
                return(
                  <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 directer-member-detail' key={res.team_id} >
                    <Card className='pt-4 pb-4 bp3-elevation-1'>
                      <center>
                        <img src={process.env.REACT_APP_IMAGE_URL+res.image} alt='team-member-img'
                          onError={(ev)=>{
                            ev.target.src = process.env.REACT_APP_USER_DEFAULT_IMAGE;
                        }}
                        />
                      </center>
                      <p className='employee-name'>{res.name}</p>
                      <p className='employee-position'>{res.position}</p>
                      <p className='employee-description'>{res.description}</p>
                      <Button className="btn-link" onClick={()=>{
                        this.setState({isCallModal:true,modalData:res})
                        }}>Read more</Button>
                      <div className='border-bottom pt-3'/>
                      <div className='employee-social-icon row no-gutters'>
                        <div className='col-xl-2 col-2'>
                          <a href={'mailto:'+res.email} data-rel="external">
                            <img src={require('../../images/svg/mail.svg')} alt='team-member-img'/>
                          </a>
                        </div>
                        <div className='col-xl-2 col-2'>
                          <a href={res.facebook}>
                            <img src={require('../../images/svg/fb.svg')} alt='team-member-img'/>
                          </a>
                        </div>
                        <div className='col-xl-2 col-2'>
                          <a href={'tel:'+res.mobile} data-rel="external">
                            <img src={require('../../images/svg/call.svg')} alt='team-member-img'/>
                          </a>
                        </div>
                        <div className='col-xl-2 col-2'>
                          <a href={res.linkedin}>
                            <img src={require('../../images/svg/linkedIn.svg')} alt='team-member-img'/>
                          </a>
                        </div>
                        <div className='col-xl-2 col-2'>
                          <a href={res.skype}>
                            <img src={require('../../images/svg/skype.svg')} alt='team-member-img'/>
                          </a>
                        </div>

                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
                {this.state.modalData !== null ?
                    <Dialog
                        isOpen={this.state.isCallModal}
                        title={this.props.title}
                        canOutsideClickClose={false}
                        canEscapeKeyClose={true}
                        className={'bp3-dialog p-0 rounded memberDetail'}>
                        <div className="bp3-dialog-header">
                            <h4 className="bp3-heading"> </h4>
                            <button aria-label="Close" className="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross" onClick={() =>{
                                this.setState({
                                    isCallModal:false
                                })
                            }}> </button>
                        </div>
                        <div className={Classes.DIALOG_BODY+' p-1 m-0 member-detail'} >
                            <Card className='text-center'>
                                <center>
                                    <img src={process.env.REACT_APP_IMAGE_URL+this.state.modalData.image} alt='team-member-img'
                                         onError={(ev)=>{
                                             ev.target.src = process.env.REACT_APP_USER_DEFAULT_IMAGE;
                                         }}/>
                                </center>
                                <h3 className='name mb-2'>{this.state.modalData.name}</h3>
                                <p className='position'>{this.state.modalData.position}</p>
                                <p className='description'>
                                    {this.state.modalData.description}
                                </p>
                                {this.state.socialIcon?
                                    <Fragment>
                                        <div className='social-icon row no-gutters'>
                                            <div className='col-xl-2 col-2'>
                                                <a href={'mailto:'+this.state.modalData.email} data-rel="external">
                                                    <img src={require('../../images/svg/mail.svg')} alt='team-member-img'/>
                                                </a>
                                            </div>
                                            <div className='col-xl-2 col-2'>
                                                <a href={this.state.modalData.facebook}>
                                                    <img src={require('../../images/svg/fb.svg')} alt='team-member-img'/>
                                                </a>
                                            </div>
                                            <div className='col-xl-2 col-2'>
                                                <a href={'tel:'+this.state.modalData.mobile} data-rel="external">
                                                    <img src={require('../../images/svg/call.svg')} alt='team-member-img'/>
                                                </a>
                                            </div>
                                            <div className='col-xl-2 col-2'>
                                                <a href={this.state.modalData.linkedin}>
                                                    <img src={require('../../images/svg/linkedIn.svg')} alt='team-member-img'/>
                                                </a>
                                            </div>
                                            <div className='col-xl-2 col-2'>
                                                <a href={this.state.modalData.skype}>
                                                    <img src={require('../../images/svg/skype.svg')} alt='team-member-img'/>
                                                </a>
                                            </div>

                                        </div>
                                    </Fragment>

                                    :null}
                            </Card>
                        </div>
                    </Dialog>
                    :null}
            </React.Fragment>
        );
    }
}
