import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";

export default class Loading extends Component{

    render(){

        return(
          <div className="row mt-5">

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <div className='row no-gutters mt-2 mb-2'>
                    <div className='col-3 col-xl-3 mt-3'>
                      <div className="bp3-skeleton rounded-circle" style={{height: "80px"}} />
                    </div>
                    <div className='col-1 col-xl-1'/>
                    <div className='col-8 col-xl-8 text-left mt-3'>
                      <div className="bp3-skeleton mb-3" style={{height: "30px",width:'80%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'50%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'60%'}} />
                    </div>
                  </div>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <div className='row no-gutters mt-2 mb-2'>
                    <div className='col-3 col-xl-3 mt-3'>
                      <div className="bp3-skeleton rounded-circle" style={{height: "80px"}} />
                    </div>
                    <div className='col-1 col-xl-1'/>
                    <div className='col-8 col-xl-8 text-left mt-3'>
                      <div className="bp3-skeleton mb-3" style={{height: "30px",width:'80%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'50%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'60%'}} />
                    </div>
                  </div>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <div className='row no-gutters mt-2 mb-2'>
                    <div className='col-3 col-xl-3 mt-3'>
                      <div className="bp3-skeleton rounded-circle" style={{height: "80px"}} />
                    </div>
                    <div className='col-1 col-xl-1'/>
                    <div className='col-8 col-xl-8 text-left mt-3'>
                      <div className="bp3-skeleton mb-3" style={{height: "30px",width:'80%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'50%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'60%'}} />
                    </div>
                  </div>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <div className='row no-gutters mt-2 mb-2'>
                    <div className='col-3 col-xl-3 mt-3'>
                      <div className="bp3-skeleton rounded-circle" style={{height: "80px"}} />
                    </div>
                    <div className='col-1 col-xl-1'/>
                    <div className='col-8 col-xl-8 text-left mt-3'>
                      <div className="bp3-skeleton mb-3" style={{height: "30px",width:'80%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'50%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'60%'}} />
                    </div>
                  </div>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <div className='row no-gutters mt-2 mb-2'>
                    <div className='col-3 col-xl-3 mt-3'>
                      <div className="bp3-skeleton rounded-circle" style={{height: "80px"}} />
                    </div>
                    <div className='col-1 col-xl-1'/>
                    <div className='col-8 col-xl-8 text-left mt-3'>
                      <div className="bp3-skeleton mb-3" style={{height: "30px",width:'80%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'50%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'60%'}} />
                    </div>
                  </div>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <div className='row no-gutters mt-2 mb-2'>
                    <div className='col-3 col-xl-3 mt-3'>
                      <div className="bp3-skeleton rounded-circle" style={{height: "80px"}} />
                    </div>
                    <div className='col-1 col-xl-1'/>
                    <div className='col-8 col-xl-8 text-left mt-3'>
                      <div className="bp3-skeleton mb-3" style={{height: "30px",width:'80%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'50%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'60%'}} />
                    </div>
                  </div>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <div className='row no-gutters mt-2 mb-2'>
                    <div className='col-3 col-xl-3 mt-3'>
                      <div className="bp3-skeleton rounded-circle" style={{height: "80px"}} />
                    </div>
                    <div className='col-1 col-xl-1'/>
                    <div className='col-8 col-xl-8 text-left mt-3'>
                      <div className="bp3-skeleton mb-3" style={{height: "30px",width:'80%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'50%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'60%'}} />
                    </div>
                  </div>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <div className='row no-gutters mt-2 mb-2'>
                    <div className='col-3 col-xl-3 mt-3'>
                      <div className="bp3-skeleton rounded-circle" style={{height: "80px"}} />
                    </div>
                    <div className='col-1 col-xl-1'/>
                    <div className='col-8 col-xl-8 text-left mt-3'>
                      <div className="bp3-skeleton mb-3" style={{height: "30px",width:'80%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'50%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'60%'}} />
                    </div>
                  </div>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <div className='row no-gutters mt-2 mb-2'>
                    <div className='col-3 col-xl-3 mt-3'>
                      <div className="bp3-skeleton rounded-circle" style={{height: "80px"}} />
                    </div>
                    <div className='col-1 col-xl-1'/>
                    <div className='col-8 col-xl-8 text-left mt-3'>
                      <div className="bp3-skeleton mb-3" style={{height: "30px",width:'80%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'50%'}} />
                      <div className="bp3-skeleton mb-3" style={{height: "20px",width:'60%'}} />
                    </div>
                  </div>
              </Card>
            </div>

          </div>
        );

    }

}
