export const BSWDataListAction = (sources) => {

    return {
        type: 'BSW_DATA_LIST',
        sources: sources
    }
}

export const ModuleListAction = (sources) => {

    return {
        type: 'MODULE_LIST',
        sources: sources
    }
}

export const LinkAction = (link) => {

    return {
        type: 'VIDEO_LINK',
        link: link
    }
}

export const flightCityListAction = (sources) => {

    return {
        type: 'FLIGHT_CITY_LIST',
        sources: sources
    }
}

export const megabrandDataListAction = (sources) => {

    return {
        type: 'MEGABRAND_DATA_LIST',
        sources: sources
    }
}

export const holidayCityListAction = (sources) => {

    return {
        type: 'HOLIDAY_CITY_LIST',
        sources: sources
    }
}

export const vendorListAction = (sources) => {

    return {
        type: 'VENDOR_LIST',
        sources: sources
    }
}

export const uposPartnerListAction = (sources) => {

    return {
        type: 'UPOS_PARTNER_LIST',
        sources: sources
    }
}

export const uposVendorListAction = (sources) => {

    return {
        type: 'UPOS_VENDOR_LIST',
        sources: sources
    }
}

export const vendorWishAction = (sources) => {

    return {
        type: 'VENDOR_WISH_STATUS',
        sources: sources
    }
}

export const topDealListAction = (sources) => {

    return {
        type: 'TOP_DEAL_LIST',
        sources: sources
    }
}

export const teamListAction = (sources) => {

    return {
        type: 'TEAM_LIST',
        sources: sources
    }
}

export const faqCategoryListAction = (sources) => {

    return {
        type: 'CATEGORY_FAQ_LIST',
        sources: sources
    }
}

export const faqListAction = (sources) => {

    return {
        type: 'FAQ_LIST',
        sources: sources
    }
}

export const searchFaqAction = (sources) => {

    return {
        type: 'SEARCH_FAQ_LIST',
        sources: sources
    }
}

export const careerListAction = (sources) => {

    return {
        type: 'CAREER_LIST',
        sources: sources
    }
}

export const imageListAction = (sources) => {

    return {
        type: 'IMAGE_LIST',
        sources: sources
    }
}

export const videoListAction = (sources) => {

    return {
        type: 'VIDEO_LIST',
        sources: sources
    }
}

export const pdfListAction = (sources) => {

    return {
        type: 'PDF_LIST',
        sources: sources
    }
}

export const desktopWallpaperListAction = (sources) => {

    return {
        type: 'DESKTOP_WALLPAPER_LIST',
        sources: sources
    }
}

export const mobileWallpaperListAction = (sources) => {

    return {
        type: 'MOBILE_WALLPAPER_LIST',
        sources: sources
    }
}


export const ringtoneListAction = (sources) => {

    return {
        type: 'RINGTONE_LIST',
        sources: sources
    }
}

export const postResumeAction = (sources) => {
    return{
        type: 'POST_RESUME',
        sources:sources
    }
}

export const operatorListAction = (sources) => {
    return{
        type: 'OPERATOR_LIST',
        sources:sources
    }
}

export const plansListAction = (sources) => {
    return{
        type: 'PLAN_LIST',
        sources:sources
    }
}

export const fromListAction = (sources) => {
    return{
        type: 'BUS_FROM_LIST',
        sources:sources
    }
}

export const brandListAction = (sources) => {
    return{
        type: 'BRAND_LIST',
        sources:sources
    }
}

export const bswBrandListAction = (sources) => {
    return{
        type: 'BSW_BRAND_LIST',
        sources:sources
    }
}

export const testimonialListAction = (sources) => {
    return{
        type: 'TESTIMONIALS_LIST',
        sources:sources
    }
}

export const partnerListAction = (sources) => {
    return{
        type: 'PARTNER_LIST',
        sources:sources
    }
}

export const mcpLatLngListAction = (sources) => {
    return{
        type: 'MCP_LAT_LNG_LIST',
        sources:sources
    }
}

export const acpLatLngListAction = (sources) => {
    return{
        type: 'ACP_LAT_LNG_LIST',
        sources:sources
    }
}

export const cpLatLngListAction = (sources) => {
    return{
        type: 'CP_LAT_LNG_LIST',
        sources:sources
    }
}

export const centralSearchAction = (sources) => {
    return{
        type: 'CENTRAL_SEARCH',
        sources:sources
    }
}
