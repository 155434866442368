import React, {Component} from 'react';
import Rectangle from '../../images/png/Rectangle.png';

export default class Mission extends Component{
    constructor(props){
        super(props);
        this.state={
          activeIndex:0,
          mission:[
            {
              title:'Our Vision',
              contentClass:'visionContent',
              contentId:'vision',
              divId:'showVision',
              desc:'<p>EMPOWERING PEOPLE CREATING EARNING OPPORTUNITIES FOR ALL & PROVIDING BUSINESS SALES SERVICES TO ALL BUSINESSES WITH ZERO CAPITAL INVESTMENT</p>'
            },
            {
              title:'Our Mission',
              contentClass:'missionContent',
              contentId:'mission',
              divId:'showMission',
              desc:'<p>To Create <br><strong>21,000</strong> Entrepreneurs <br> <strong>36,00,000</strong> Digitally Empowered Vendors <br> <strong>72,00,000</strong> Sustainable Jobs</p>'
            },
            {
              title:'Oath',
              contentClass:'oathContent',
              contentId:'oath',
              divId:'showOath',
              desc:'<p>LET OTHERS LEAD SMALL LIVES, BUT NOT ME <br> LET OTHERS ARGUE OVER SMALL THINGS, BUT NOT ME<br>LET OTHERS CRY OVER SMALL HURTS, BUT NOT ME<br>LET OTHERS LEAVE THEIR FUTURE IN SOMEONE ELSES HANDS, BUT NOT ME</p><p>“I BELIEVE IN MY MISSION, GREET LIFE WITH A CHEER, THERE’S BIG WORK TO DO AND THAT’S WHY I AM HERE”</p>'
            },
            {
              title:'Values',
              contentClass:'valueContent',
              contentId:'value',
              divId:'showValue',
              desc:'<p>TRUST, RELATIONSHIP & GROWTH</p>'
            }
          ]
        }
    }

    createMarkup(data) {
       return {__html: data};
     }

    render(){
        return(
            <React.Fragment>
                <div className="missionWrapper hidden-xs hidden-sm visible-md visible-lg visible-xl">
                    <div className="container">
                        <div className="missionContainer">
                            <div className="row">
                                <div className="col-12">
                                    <div className="missionGrid">
                                        <ul>
                                          {this.state.mission.map((res,i)=>{
                                            return(
                                              <li id={res.contentId} className={this.state.activeIndex===i ?'active':""} key={i}
                                              onClick={()=>{
                                                this.setState({activeIndex:i})
                                              }}
                                              >{res.title}
                                                  <div id={res.divId} className={this.state.activeIndex===i ?'active':""}>
                                                      <div className="background">
                                                          <img src={Rectangle} alt="" className="img-fluid" />
                                                          <div className={res.contentClass} dangerouslySetInnerHTML={this.createMarkup(res.desc)} />
                                                      </div>
                                                  </div>
                                              </li>
                                            )
                                          })}
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
