import React, {Component} from 'react';
import {PageTitle} from 'gg-react-utilities';
import Config from '../Config';


export default class GetAppLink extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }
    
    render(){
        return(
            <React.Fragment>
				<PageTitle title='Get app link | Global Garner' />
                <div className="container-fluid">
					<div className='container py-5 getapp-link'>
						<h1 className='text-center title pb-4 pb-s-3'>Download User Application</h1>
						<div className='row my-5 my-s-4 pb-4'>
							<div className='col-xl-5 col-lg-5 col-md-4 col-sm-5 col-12 d-flex align-item-center justify-content-center'>
								<img src={require('./images/iPhone.png')} alt='' className='img-fluid jump' />
							</div>
							<div className='col-xl-7 col-lg-7 col-md-8 col-sm-7 col-12 text-left d-flex align-item-center flex-column'>
								<h2 className='text-primary'><b>Get 2% to 100% Guaranteed Cashback/Reward</b></h2>
								<h3>Shop through the largest “All in One“ Shopping portal to available Cashback!</h3><br/>
								<div className="d-flex align-items-center justify-content-between justify-content-sm-start">
									<a href='https://itunes.apple.com/in/app/global-garner/id1200716855'>
										<img src={require('./images/AppStoreButton.png')} alt='' className='img-fluid mr-4' />
									</a>
									<a href='https://play.google.com/store/apps/details?id=com.globalgarner'>
										<img src={require('./images/GooglePlayButton.png')} alt='' className='img-fluid ml-4' />
									</a>
								</div>
								<br/><br/><br/>
								<div className="text-center-s">
									<a href={Config.ACCOUNT_URL+'register'} className='bp3-button rounded bp3-intent-primary'>Register as user</a>
								</div>
							</div>
						</div>
					</div>
                </div>
            </React.Fragment>
        );
    }
}
