import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ArrowForwardRounded } from "@material-ui/icons";
import { PageTitle, Auth } from "gg-react-utilities";

import ContestComponent from "./components/contest";
import Config from "../Config";

export default class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoPlayed: false,
      isLoggedIn: null,
    };
  }

  componentDidMount() {
    this.checkAuth();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }

  async checkAuth() {
    setTimeout(() => {
      Auth.isLoggedInAsync().then((res) => {
        this.setState({ isLoggedIn: res }, () => {
          if (this.state.isLoggedIn !== true) {
            Auth.login(true);
          }
        });
      });
    }, 1);
  }

  render() {
    let metaData = document.getElementsByTagName("meta");
    metaData["description"].content =
      "It's Contest Time! Time to challenge yourself.Explore! Participate! Win! Enjoy! Hurray!";
    metaData["keywords"].content =
      "Contest Time, Contest, Online contest, Giveaway, Contests and giveaways, Enter to win, Summer Contest, Social Media Contest";
    const { isLoggedIn } = this.state;
    return (
      <React.Fragment>
        <PageTitle title="Contest | Global Garner" />
        <div className="container-fluid header-below">
          <div className="container p-s-0">
            <div className="career header-below-content contest">
              <div className="row mt-4 mt-s-0">
                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12 col-12">
                  <h1 className="bp3-text-xlarge heading">
                    It's Contest Time! Time to challenge yourself.
                  </h1>
                  <span className="heading-desc">
                    Explore! Participate! Win! Enjoy! Hurray!
                  </span>
                  <div className="row btn-area pt-5 mt-5 mt-xl-5 mt-lg-5 mt-md-5 mt-s-0 mt-sm-2">
                    {isLoggedIn !== true ? (
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <a
                          className="bp3-button bp3-fill bp3-large sign-up"
                          href={Config.ACCOUNT_URL + "register"}
                        >
                          Register
                        </a>
                      </div>
                    ) : null}
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-s-4">
                      <Link
                        className="bp3-button bp3-intent-primary bp3-fill bp3-large participate"
                        to="/contest-achievers"
                      >
                        {" "}
                        Past Contest and Results{" "}
                        <ArrowForwardRounded className="ml-2" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-5 col-sm-12 col-12 mt-s-5 mt-sm-5 mt-lg-1 mt-md-3">
                  <center>
                    <img
                      src="https://gg-statics.s3.ap-south-1.amazonaws.com/contests/contest_icon.png"
                      alt="gift"
                      className="img-fluid"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <ContestComponent />
        </div>
      </React.Fragment>
    );
  }
}
