import React from 'react';
import {Button, Intent, RadioGroup, Radio, FormGroup, Dialog} from "@blueprintjs/core";
import {Textbox} from 'react-inputs-validation';
import Select from "react-select";

export default class Recharge extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            rechargeType: 2,
            operator:'',
            mobile:"",
            amount:"",
            validate:false,
            hasMobileError:true,
            hasOperatorError:false,
            hasOperatorErrorMsg:'',
            hasAmountError:true,
            isVisible:false,
            service_name:false,
            planData:null
        };
    }

    changeRechargeType = (e) =>{
        this.setState({rechargeType:Number(e.target.value), operator:""}, () => {
            this.props.getOperatorList(this.state.rechargeType);
        });
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    goToRechargeModule(){
        this.toggleValidating(true);

        if(!this.state.hasOperatorError){
            this.setState({
                hasOperatorErrorMsg:"Service Provider cannot be empty"
            });
        }else{
            this.setState({
                hasOperatorErrorMsg:""
            });
        }

        if(!this.state.hasMobileError && this.state.hasOperatorError) {
            let type = '';
            if(this.state.rechargeType == 2){
                type = 'mobile';
            }else{
                type = this.state.rechargeType;
            }

            window.location.href = this.props.url+type+"?bill_no="+this.state.mobile+"&type="+this.state.rechargeType+"&operator="+this.state.operator.id
        }else{
            //console.error("Please resolve all errors before proceed");
        }
    }

    componentDidMount(){
        let type = this.state.rechargeType;
        this.props.getOperatorList(type);
    }

    browsePlans(event){
        let sname = this.state.operator.service_provider_name+" - Plans & Offers";
        this.setState({
            isVisible:true,
            service_name:sname
        });
        let obj = {
            "service_id":this.state.rechargeType,
            "service_provider_id":this.state.operator.id
        }
        this.props.getPlansList(obj);
        event.preventDefault();
    }

    createMarkup(data) {
        return {__html: data};
    }

    render(){

        return(
            <div className="strip-form">
                <RadioGroup
                    className="d-inline option-type"
                    onChange={this.changeRechargeType.bind(this)}
                    name="type"
                    selectedValue={this.state.rechargeType}
                >
                    <Radio label="Prepaid" value={2} />
                    <Radio label="Postpaid" value={1} />
                </RadioGroup>
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-12">
                        <Textbox
                            tabIndex="1"
                            id={'mobile'}
                            name="mobile"
                            type="text"
                            value={this.state.mobile}
                            placeholder="Mobile Number"
                            validate={this.state.validate}
                            validationCallback={res =>
                                this.setState({ hasMobileError: res, validate: false })}
                            onChange={(mobile) => {
                                this.setState({ mobile });
                            }}
                            onKeyUp={(event)=>{
                                if (event.which === 13){
                                    this.goToRechargeModule()
                                }
                            }}
                            onBlur={(e) => {}}
                            validationOption={{
                                name: 'Mobile Number',
                                check: true,
                                required: true,
                                type:'number'
                            }} />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-3 mt-sm-0">
                        <FormGroup
                            helperText={this.state.hasOperatorErrorMsg}>
                            <Select
                                value={this.state.operator}
                                className='strip-select'
                                classNamePrefix="strip-select"
                                onChange={(operator) => {
                                    this.setState({
                                        operator,
                                        hasOperatorError:true
                                    },() =>{
                                        let obj = {
                                            "service_id":this.state.rechargeType,
                                            "service_provider_id":this.state.operator.id
                                        }
                                        //this.props.getPlansList(obj);
                                    });
                                }}
                                clearable={false}
                                multi={false}
                                backspaceRemoves={true}
                                options={this.props.operatorList}
                                valueKey={'id'}
                                placeholder="Service Provider"
                                key='id'
                                labelKey='service_provider_name'
                            />
                        </FormGroup>
                    </div>
                    {/*<div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12 amount">
                        <Textbox
                            tabIndex="1"
                            id={'amount'}
                            name="amount"
                            type="text"
                            value={this.state.amount}
                            placeholder="Amount"
                            validate={this.state.validate}
                            validationCallback={res =>
                                this.setState({ hasAmountError: res, validate: false })}
                            onChange={(amount) => {
                                this.setState({ amount });
                            }}
                            onKeyUp={(event)=>{
                                if (event.which === 13){
                                    this.goToRechargeModule()
                                }
                            }}
                            onBlur={(e) => {}}
                            validationOption={{
                                name: 'Amount',
                                check: true,
                                required: true,
                                type:'number'
                            }} />
                          {1?
                          <Button text="Browse Plans" className='btn-link' onClick={this.browsePlans.bind(this)} />
                        :null}
                    </div>*/}
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-right mt-xl-0 mt-lg-0 mt-md-0 mt-s-3 mt-sm-2 text-center-s">
                        <Button text='Proceed' intent={Intent.PRIMARY} onClick={this.goToRechargeModule.bind(this)} className="proceed-btn" />
                    </div>
                </div>
                <Dialog isOpen={this.state.isVisible}
                        title={this.state.service_name}
                        className="planWrapper"
                        onClose={() => {
                            this.setState({
                                isVisible:!this.state.isVisible,
                            });
                        }}>
                    <div className="offersWrapper">
                        <ul>
                            {this.props.planList.length===0 ?
                              <li>
                                <div className="offersItem">
                                  No plans found!
                                </div>
                              </li>
                              :
                              this.props.planList.map((res) =>{
                                    return(
                                            <li key={res.id}>
                                                <div className="offersItem">
                                                    <div className="offerPrice">
                                                        <h3>{res.amount}</h3>
                                                    </div>
                                                    <div className="offerDetail">
                                                        <h3>{res.plan_name}</h3>
                                                        <p dangerouslySetInnerHTML={this.createMarkup(res.plan_desc)}></p>
                                                    </div>
                                                    <div className="offerCircle">
                                                        <span>All</span>
                                                    </div>
                                                    <div className="offerValidity">
                                                        <span>{res.plan_validity}</span>
                                                    </div>
                                                    <div className="offerGet">
                                                        <Button className="bp3-button bp3-small" intent={Intent.PRIMARY} onClick={() =>{
                                                            this.setState({
                                                                amount:res.amount,
                                                                isVisible:false
                                                            });
                                                        }}>
                                                           GET
                                                        </Button>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                })
                            }
                        </ul>
                    </div>
                </Dialog>
            </div>
        );
    }
}
