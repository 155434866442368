import {connect} from 'react-redux';

import Flight from '../../components/RetailStrip/Flight';
import { airportList } from '../../../core/actions/stripAction';

const mapStateToProps = state => {

    return{

    }
}

const mapDispatchToProps = dispatch => {

    return {
        airportList:(input)=>{
            return airportList(input)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Flight);
