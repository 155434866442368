import React, {Component} from 'react';

export default class Summary extends Component{
  

    render(){
        return(
            <React.Fragment>
                <div className="summaryWrapper">
                    <div className="container">
                        <div className="summaryHeader">
                            <h3>Summary</h3>
                        </div>
                        <div className="summaryContainer">
                            <ul>
                                <li>The idea behind Global Garner is to become a <strong>Middleman (Salesman)</strong> for
                                    all category of Vendors and business, be it a retailer, wholesaler,
                                    manufacturer, e-commerce platform, online shops, offline local/regional
                                     shops & services providers of all types. Also, we aim to create more
                                      middle man for distribution of all kinds of products & services
                                      through UPOS - The Digital Mall & On-Ground Sales People, where more than Artificial
                                       Intelligence, the real intelligence i.e. Real People promote, sell
                                       & earn through interexchange of all business around.</li>
                                <li>It directly influences <strong>Entrepreneurship's</strong> simultaneously creating
                                    <strong>Sustainable White Collar Jobs</strong> which will increase demand for Skilled Labour.</li>
                                <li>Now, these vendors/businesses can focus on their core operational job & let
                                    Global Garner take care of their sales at <strong>ZERO Upfront Cost</strong>.</li>
                                <li>Also, an extra add-on benefit for all their users of <strong>2% to 100%
                                    Guaranteed Cashback/ Reward</strong> against the Guaranteed Zero which they were
                                    getting till date, which adds as a booster for the increase in sales &
                                    retention of the existing customer base, also increasing the earning from
                                    these customers through UPOS - The Digital Mall.</li>
                                <li><strong>UPOS - The Digital Mall</strong> is our dynamic & unique product where every vendor/ business can
                                    participate in each other’s business growth by selling & earning from
                                    each others’ products & services. Thus, creating a healthy business
                                    environment.</li>
                                <li>Therefore, we cover every possible sales point & users through our
                                    Online Sales (Middleman) UPOS - The Digital Mall & our On-Ground sales force of Partners &
                                    Advisors.</li>
                            </ul>
                        </div>
						<hr/>
						<div className="summaryHeader mt-5">
                            <h3>Details</h3>
                        </div>
						<div className="detailsContainer mt-4 pt-2 mb-4 pb-2">
							<div className="d-flex justify-content-center">
								<div className="tax_details col-12 text-center px-s-0">
									<h4>PAN : AAGCG2859N</h4>
									<h4>TAN : AHMG06418G</h4>
									<h4>GSTIN : 24AAGCG2859N1Z6</h4>
								</div>
							</div>
						</div>
                    </div>

                </div>
            </React.Fragment>

        );
    }
}
