import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import ResumePost from '../components/resumePost';
import { postResume } from '../../core/actions/action';
import { postResumeAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        postResume:state.RetailReducer.postResume,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        postResume:(obj) => {
            return postResume(obj)
            .then((res)=>{
              const sources = res.data;
              if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    dispatch(postResumeAction(null));
                }else{
                    dispatch(postResumeAction(null));
                }
            }).catch((error)=>{
                const sources = error.response;

                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumePost);
