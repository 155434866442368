const constant = {

    /* Constants FOR QR CODE ADVERTISEMENT */
    PLATFORM_LIST : [
        { id:"", name:"Please Select platform.."},
        { id: "All", name: "All" },
        { id: "Web", name: "Web" },
        { id: "Android", name: "Android" },
        { id: "iOS", name: "iOS" }
    ],
    MODULE_LIST : [
        { id:"", name:"Please Select Module.."},
        { id: "Resale", name: "Resale" },
        { id: "Franchise", name: "Franchise" },
        { id: "Bill Pay", name: "Bill Pay" },
        { id: "BSW", name: "BSW" },
        { id: "Travel & Hotel", name: "Travel & Hotel" },
        { id: "GG Vendor", name: "GG Vendor" },
        { id: "Real State", name: "Real State" },
        { id: "News", name: "News" },
        { id: "Food", name: "Food" }
    ],    
    ADVERTISEMENT_TYPE_LIST : [
        { id: "Image", name: "Image" },
        { id: "Video", name: "Video" },
    ],    
    DURATION_LIST : [
        { id:"", name:"Please Select Duration.."},
        { id: "10", name: "10" },
        { id: "20", name: "20" }
    ],    
    EXPIRE_TYPE_LIST : [
        { id:"", name:"Please Select Expire Type.."},
        { id: "Datewise", name: "Datewise" },
        { id: "View Count", name: "View Count" }
    ],
    VISIBILITY_TYPE_LIST : [
        { id:"", name:"Please Select Visibility Type.."},
        { id: "All", name: "All" },
        { id: "Pincode", name: "Pincode" },
        { id: "Area", name:"Area"},
        { id: "City", name: "City" },
        { id: "State", name: "State" },
        { id: "Coordinate", name: "Coordinate" }
    ],

    /* Constants for DIGITAL ADVERTISEMENT */

    CREATIVE_LIST : [
        { id:"", name:"Please Select Creatives.."},
        { id: "Creative Banner", name: "Creative Banner" },
        { id: "Creative Video", name: "Creative Video" },
    ],
    CREATIVE_SIZE_LIST : [
        { id:"", name:"Please Select Creative Size.."},
        { id: "900X30", name: "900X30" },
        { id: "300X300", name: "300X300" },
    ],

    /* Constants for Vehicle Inquiry */

    VEHICLE_TYPE_LIST : [
        { id: "1", name: "Two Wheeler" },
        { id: "2", name: "Four Wheeler" },
        { id: "3", name: "Commercial Vehicle" }
    ],    
    FUEL_TYPE_LIST : [
        { id: "", name: "Select fuel type"},
        { id: "1", name: "Petrol" },
        { id: "2", name: "Diesel" }
    ],    
    CASH_TYPE_LIST : [
        { id: "", name: "Cash / Finance"},
        { id: "1", name: "Cash" },
        { id: "2", name: "Finance" }
    ],

    /* Insurance Constants */

    INSURANCE_TYPE_LIST : [
        { id: "1", name: "Life Insurance" },
        { id: "2", name: "Health Insurance" },
        { id: "3", name: "General Insurance" }
    ],

    SERVICE_LIST:[
        { id: '', name: 'Please select a service...' },
        { id: "SEO", name: "SEO" },
        { id: "Social Media Marketing", name: "Social Media Marketing" },
        { id: "PPC (Pay per Click)", name: "PPC (Pay per Click)" },
        { id: "Web Design & Development", name: "Web Design & Development" },
        { id: "Mobile Application Development", name: "Mobile Application Development" },
        { id: "Lead Generation", name: "Lead Generation" },
        { id: "Graphic Design", name: "Graphic Design" }
    ]

};

export default constant;