import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import Search from '../components/search';
import {getCentralSearchData} from '../../core/actions/action';
import {centralSearchAction} from '../../core/actions';


const mapStateToProps = state => {
    return{
      centralSearchData:state.RetailReducer.centralSearchData,
      locationData:JSON.parse(localStorage.getItem('locationData')),
    }
}

const mapDispatchToProps = dispatch => {
    return {
      getCentralSearchData:(lat,lng,param) => {
        dispatch(centralSearchAction(null));
          return getCentralSearchData(lat,lng,param)
          .then((res)=>{
              const sources = res.data;
              if(sources.status===true){
                  dispatch(centralSearchAction(sources.data));
              }else{
                  Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                  dispatch(centralSearchAction(null));
              }
          }).catch((error)=>{
              const sources = error.response;
              if(sources===undefined){
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
              }else if(sources.status!==401){

              // }else{
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
              }
          })
      },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
