import React, {Component, Fragment} from 'react';
import {PageTitle, ssoInstance} from 'gg-react-utilities';
import TopBar from "../core/components/TopBar";
import Config from '../Config';

export default class Knowus extends Component {
    constructor(props){
        super(props);
        this.state = {
          travelSegment:[
          ]
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }
    

    componentWillMount(){

      ssoInstance.get(Config.STATS_API_URL + 'travel').then((res)=>{

          const sources = res.data;
          if (sources.status === true) {
            this.setState({travelSegment:sources.data })
          }

      }).then(function(json) {

      }).catch(function(error) {


      });
    }

    render(){
        return(
            <Fragment>
      				<PageTitle title='Travel | Global Garner' />
              <div className='container'>
                <TopBar title="Travel"/>
                <div className="row mt-1 mb-5 min-height">
                {
                  this.state.travelSegment.length>0?
                  this.state.travelSegment.map((module, key) => {
                    return (
                      <div className="col-lg-3 col-xs-6 col-md-3 col-sm-3 col-6 module-link mt-5 mb-4 text-center mt-s-0 d-flex align-items-center justify-content-center" key={key}>
                          <a href={module.web_url}>
                            <img src={module.image} alt={module.name} className='img-fluid'/>
                            <span>{module.name}</span>
                          </a>
                      </div>
                    )
                  })
                  :
                  <Fragment >

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                        <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "140px", height: "140px"}}/>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                        <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "140px", height: "140px"}}/>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                        <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "140px", height: "140px"}}/>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                        <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "140px", height: "140px"}}/>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                        <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "140px", height: "140px"}}/>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                        <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "140px", height: "140px"}}/>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                        <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "140px", height: "140px"}}/>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                        <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "140px", height: "140px"}}/>
                    </div>

                  </Fragment>
                }
                </div>
              </div>
            </Fragment>
        );
    }
}
