import React, {Component, Fragment} from 'react';
import Config from '../../Config';

export default class Vendor extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    render(){
      const {data} = this.props;
        return(
          <Fragment>
            <div className="row">
              <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
                <h2 className="product-title">GG Vendors</h2>
              </div>
              <div className="col-xl-5 col-xl-5 col-sm-5 col-4">
                <p className='mt-2 pull-right link text-primary'>{data.length} result</p>
              </div>
            </div>
            <div className='row product-list'>
              {data.map((res) => {
                res = res._source
                return(
                  <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4' key={res.vendor_id}>
                    <div className="product-card">
                      <div className="image">
                        <a href={Config.STORE_URL+'vendor/'+res.vendor_slug}>
                          <img src={res.display_pic===null||res.display_pic===''?process.env.REACT_APP_DEFAULT_IMAGE:res.display_pic} alt="vendor" className="img-fluid"
                            onError={(ev)=>{
                              ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                            }}/>
                          </a>
                        </div>
                        <div className="product-sticker">
                          {res.coin_applicable?
                            <img src={process.env.REACT_APP_GG_COIN_IMAGE} className='gg-coin-img' alt='gg coin'/>
                            :null}
                          </div>
                          <div className="product-details">
                            <div className='row'>
                              <div className="col-xl-7 col-7 text-left pr-xl-1 pr-lg-1 pr-md-1">
                                <a href={Config.STORE_URL+'vendor-detail/'+res.vendor_slug}>
                                  <h3 className="product-name">{res.company_name}</h3>
                                </a>
                              </div>
                              <div className="col-xl-5 col-5 text-right">
                                <h4 className="product-distance">({res.distance.toFixed(2)} km)</h4>
                              </div>

                              <div className="col-xl-12 col-lg-12 col-xl-12 col-12">
                                <p className="product-address">{res.area}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
          </Fragment>
        );

    }

}
