import React, {Component} from 'react';
import Register from "./Register";

export default class segment extends Component{
    constructor(props){
        super(props);

        this.state = {
            activeTab: "rg"
        };
    }

    handleTabChange = (TabId) => {
        this.setState({activeTab: TabId});
    };

    render(){
        return(
    			<div className="container-fluid px-s-0 how_it_works-container">
            

            <Register/>
    			</div>
        );
    }
}
