import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";
import { SearchRounded} from '@material-ui/icons';

import VideoList from "./container/videoListContainer";
import {PageTitle} from 'gg-react-utilities';

export default class Aboutus extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }
    
    render(){
        return(
            <React.Fragment>
              <PageTitle title='Video | Global Garner' />
                <div className="container-fluid header-below">
        					<div className="container p-s-0">
        						<div className="career header-below-content">
        							<div className="row text-center mb-5">
        								<div className="col-12 col-xl-12">
        									<h1 className="bp3-text-xlarge heading">Watch Video</h1>
        									<span className="heading-desc">
        										User manual video is helpful to shopping for user<br/>
        										and they will be know how to get the cashback and proccess.
        									</span>
        								</div>
        							</div>
        						</div>
        					</div>
                </div>
                <div className="container-fluid">
        					<div className="container p-s-0">
        						<Card  className='col-xl-12 searchbar'>
    									<div className="bp3-input-group">
    										<input type="text" className="bp3-input border-0 input-searchjob" placeholder="Search Video" />
    										<button className="bp3-button bp3-secondary btn-searchojob">
    											<SearchRounded style={{'color':'#236AEE'}}/>
    										</button>
    									</div>
    								</Card>
        					</div>
        					<VideoList />
                </div>
            </React.Fragment>
        );
    }
}
