import config from "../../../src/Config";

export const base64_url_encode = (input) => {

    input = window.btoa(input);

    input = input.replace(/\+/g, ".");
    input = input.replace(/\//g, "_");
    input = input.replace(/=/g, "-");

    return input;
}
export const setDefaultMegaBrandImage = (ev) => {
    return ev.target.src = config.MEGABRAND_DEFAULT_IMAGE;
};
export const base64_url_decode = (input) => {
    input = input.replace(/\./g, "+");
    input = input.replace(/_/g, "/");
    input = input.replace(/-/g, "=");

    return window.atob(input);
}
