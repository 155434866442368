import React from 'react';
import { Button, Intent, RadioGroup, Radio, FormGroup } from "@blueprintjs/core";
import { ssoInstance } from 'gg-react-utilities';
import Config from '../../../Config'
import { Async } from 'react-select';

export default class Holidays extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            packageType: '0',
            city:'',
            hasCityError:false,
            hasCityErrorMsg:'',
        };
    }

    changeRechargeType = (e) =>{
        this.setState({packageType:e.target.value});
    }

    goTohotel(){

        if(this.state.city===''){
            this.setState({
                hasCityErrorMsg:"Please select preferred location first"
            });
            return false;
        }else{
            this.setState({
                hasCityErrorMsg:""
            },()=>{

            let url = this.props.url;
            if(this.state.city.Category==="Packages"){
              url = url + 'HotelDetails/'+ this.state.city.Name;
            }else{
              url = url + 'SearchResult';
            }
            window.location.href = url;
          });
        }
    }

    componentDidMount(){

    }


    async getOptions(inputValue) {

        if (!inputValue) {
            return [];
        }


                return ssoInstance.post(Config.HOLIDAYS_API_URL + 'user/packege/v1.0.3/getCity',{"searchText" : inputValue,"tripType" : '2' })
                        .then(
                            (res)=> {
                              if (res.status === 200) {
                                const response = res.data;
                                if (response.status === true) {
                                  let holiday = [];
                                  response.data.map((resp) => {
                                      holiday.push({
                                         Category: resp.Category,
                                         ItemName: resp.ItemName,
                                         CityID: resp.CityID,
                                         StateID: resp.StateID,
                                         CountryID: resp.CountryID,
                                         Name: resp.Name,
                                         value: resp.Category,
                                         label: resp.ItemName
                                     });
                                     return resp;
                                  })
                                  return holiday;
                                }
                              }
                            }
                        );


    }

    render(){
        return(
            <div className="strip-form">
                <RadioGroup
                    className="d-inline option-type"
                    onChange={this.changeRechargeType.bind(this)}
                    name="type"
                    selectedValue={this.state.packageType}
                >
                    <Radio label="All" value={'0'} />
                    <Radio label="Packages in India" value={'1'} />
                    <Radio label="Packages for Abroad" value={'2'} />
                </RadioGroup>
                <div className="row">
                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 mt-s-3 mt-sm-0">
                        <FormGroup
                            helperText={this.state.hasCityErrorMsg}>
                            <Async
                                        isClearable
                                        className='strip-select'
                                        classNamePrefix="strip-select"
                                        autoFocus={true}
                                        multi={false}
                                        value={this.state.city}
                                        isLoading={true}
                                        onChange={(value)=>
                                          {
                                            this.setState({
                                              city:value
                                            })
                                          }
                                        }
                                        valueKey="CityID"
                                        labelKey="ItemName"
                                        Key="CountryID"
                                        loadOptions={this.getOptions}
                                        placeholder={'Where do you want to go?'}
                                        searchPromptText={"Please enter 3 or More characters"}
                                        backspaceRemoves={true}
                                        defaultOptions
                                    />

                        </FormGroup>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 text-right mt-xl-0 mt-lg-0 mt-md-0 mt-s-3 mt-sm-2 text-center-s">
                        <Button text='Search' intent={Intent.PRIMARY} onClick={this.goTohotel.bind(this)} className="proceed-btn" />
                    </div>
                </div>

            </div>
        );
    }
}
