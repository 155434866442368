import React, {Component} from 'react';

export default class UPOS extends Component{

    render(){
        return(
            <React.Fragment>
                <div className="uposWrapper">
                    <div className="container">
                        <div className="uposHeader">
                            <h3>UPOS - The Digital Mall : Universal Point of Sale</h3>
                            <p>“AB SABKA BUSINESS HAI APNA BUSINESS”</p>
                        </div>
                        <div className="uposContainer">
                            <div className="uposImage">
                                <img src={require('../../images/png/UPOS.png')} className="img-fluid" alt=""/>
                            </div>
                            <div className="uposDesc">
                                <p>India’s best-earning opportunity and a Digital Mall to sell 20 crore+ products.</p>

                                <p>It is an Advanced digital powerhouse platform to benefit the vendors from their existing customers or for creating new entrepreneurs
                                  at a minimum cost with zero investment in inventory.</p>
                                <p>UPOS - The Digital Mall is for Increasing the earning of vendors from existing customer base </p>
                                  <div className="row">
                                      <div className="col-sm-6 col-12">
                                          <ul>
                                              <li>Enhancing their profits</li>
                                              <li>Expanding their existing business</li>
                                              <li>Generating new employment</li>
                                          </ul>
                                      </div>
                                      <div className="col-sm-6 col-12">
                                          <ul>
                                              <li>Creating new earning opportunities</li>
                                              <li>Making new Entrepreneurs and Setting up new businesses</li>
                                              <li>Maximum return with a lowest capital requirement</li>
                                          </ul>
                                      </div>
                                  </div>
                                <div className="uposBottomDesc">
                                    <p><strong>CONCEPT:</strong><br></br></p>
                                    <p>Till now small or big retailers were selling the products of their own shop only & competing with each other & to increase their
                                      range of product was a costly affair which involves various other cumbersome parameters like different tie-up, approval, risk,
                                      high investment, etc. But with the introduction of UPOS - The Digital Mall, they will be able to EARN by selling the full range of product and services of
                                      other vendors at ZERO expense.</p>
                                    <p>It is an inter-exchange of Business between different types of vendors, be it small or large, from different states & cities, with this
                                      concept, the seller will have a wide range of all kinds of online and offline products & services available in the market. UPOS - The Digital Mall will
                                      provide him access to various online & offline products of other vendors (those who have a tie-up or are registered with us) & he will
                                      earn commission by selling those products</p>
                                </div>
                            </div>
                            <br/>
                            <div className="uposLinks row">
                               <div className={"col-xl-6 col-lg-6 col-12"}>
                                    <a href="#!">Learn More about UPOS - The Digital Mall : Universal Point of Sale </a>
                               </div>
                                <div className={"col-xl-6 col-lg-6 col-12 text-xl-right text-lg-right text-sm-right text-center"}>
                                <a href="#!" className="bp3-button bp3-intent-primary bp3-button bp3-small mt-s-3 mt-sm-0">Watch Video</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
