import React, {Component} from 'react';
import VideoModal from '../../core/components/videoModal';
import Youtube from '../../core/components/Youtube';
import Loading from './loading';


export default class VideoList extends Component{
    constructor(props){
        super(props);

        this.state = {
            isModalOpen: false,
            data:null,

        };
    }

    componentDidMount(){
      this.props.getVideoList();
    }

    render(){
        const {videoList} = this.props;
        return(
    			<div className="container p-s-0">
    				<VideoModal
    					size="lg"
    					isOpen={this.state.isModalOpen}
              data={this.state.data}
    					className="bp3-dialog p-0 rounded"
    				/>
          {videoList.length===0?
            <Loading/>
          :
    				<div className='row video-container py-5'>
						{videoList.map((res,key)=>{
							return(
								<div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 video' key={key} onClick={()=>{
									this.setState({isModalOpen:true,data:res.video_code})
									}}>
									<div className="bp3-elevation-3">
										<div className="embed-responsive embed-responsive-16by9">
											<Youtube videoId={res.video_code} onEnd={() => this.setState({videoPlayed: false})}/>
										</div>
										<p className='text-center'>{res.title}</p>
										<div className="overlay">
											<p className='text text-center'>
												{res.title}<br/>
											</p>
										</div>
									</div>
								</div>
							);
						})}
    				</div>
          }
    			</div>
        );
    }
}
