import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import Brands from '../components/brandsComponent';
import { getModuleList, getNearVendorList, addVendorToWishlist } from '../../core/actions/action';
import { ModuleListAction, megabrandDataListAction, vendorListAction, vendorWishAction } from '../../core/actions';

const mapStateToProps = state => {
    return{
        bswDataList:state.RetailReducer.bswDataList,
        megabrandDataList:state.RetailReducer.megabrandDataList,
        topDealList:state.RetailReducer.topDealList,
        vendorList:state.RetailReducer.vendorList,
        uposVendorList:state.RetailReducer.uposVendorList,
        moduleList:state.RetailReducer.moduleList,
        testimonialList:state.RetailReducer.testimonialList,
        locationData:JSON.parse(localStorage.getItem('locationData')),
    }
}

const mapDispatchToProps = dispatch => {

    return {


        getModuleList :() => {
          dispatch(ModuleListAction([]));
            return getModuleList()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(ModuleListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(megabrandDataListAction([]));
                    }
                }).catch((error)=>{
                    // Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },



        getNearVendorList :(limit,lat,lng) => {
          dispatch(vendorListAction(null));
            return getNearVendorList(limit,lat,lng)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(vendorListAction(sources.data.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(vendorListAction(null));
                    }
                }).catch((error)=>{
                    // Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },

        addVendorToWishlist:(product_id) => {
          dispatch(vendorWishAction(false))
            return addVendorToWishlist(product_id)
            .then((res)=>{
                const sources = res.data;

                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    dispatch(vendorWishAction(true))
                  }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                    dispatch(vendorWishAction(false))
                  }
            }).catch((error)=>{
                const sources = error.response;
                sources.data.error.map((res)=>{
                  Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                  return res;
                })

            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
