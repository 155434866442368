import React, {Component, Fragment} from 'react';
import Config from '../../Config';

export default class BSW extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    render(){
      const {data} = this.props;
      let counter = 0;
        return(
        <Fragment>
          <div className="row">
            <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
              <h2 className="product-title">Best Shopping Websites</h2>
            </div>
            <div className="col-xl-5 col-xl-5 col-sm-5 col-4">
              <p className='mt-2 pull-right link text-primary'>{data.length} result</p>
            </div>
          </div>
          <div className="row" >
            {data.map((affiliate)=>{
              counter++;
              affiliate=affiliate._source;
              // affiliate.color_code = affiliate.color_code.split(',');
              let colorCode = affiliate.color_code ? affiliate.color_code.split(',') : [];
              if(counter > 6) counter =1;
              return(
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 my-4" key={affiliate.affiliate_id}>
                  <div className={"brandsDiv"} style={{'backgroundImage': 'linear-gradient(-135deg, ' + colorCode[0] + ' 0%, ' + colorCode[1] + ' 100%)'}}>
                    <a href={Config.BSW_URL+"brands/"+affiliate.slug+"/"+counter}>
                      <div className="brandsImg">
                        <img alt={affiliate.name} src={affiliate.logo} className="img-fluid"
                          onError={(ev)=>{
                            ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                          }}/>
                      </div>
                      <h4 className="name">{affiliate.name}</h4>
                      <div className="visit-website">
                        Visit Website
                      </div>
                    </a>
                  </div>
                </div>
              )

            })}
          </div>
        </Fragment>
        );

    }

}
