import React, {Component} from 'react';
import TeamComponent from "./container/teamListContainer";
import {PageTitle} from 'gg-react-utilities';

export default class Team extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    render(){
      let metaData = document.getElementsByTagName("meta");
      metaData['description'].content = "Global Garner is a bunch of wunderkinds. People on board have proved their mettle in their individual fields and have now come together to enrich this unique platform of empowering people.";
      metaData['keywords'].content = "Team, Management team, IT team, HR Team, Top Managememt ";
        return(
            <React.Fragment>
              <PageTitle title='Team | Global Garner' />
                <div className="container-fluid header-below">
        					<div className="container p-s-0">
        						<div className="career header-below-content">
        							<div className="row text-center mb-5">
        								<div className="col-12 col-xl-12">
        									<h1 className="bp3-text-xlarge heading">Our Team</h1>
        									<span className="heading-desc">Global Garner is a bunch of wunderkinds. People on board have proved their mettle in their individual fields and have now come together to enrich this unique platform of empowering people.</span>
        								</div>
        							</div>
        						</div>
        					</div>
                </div>
                <div className="container-fluid">
                    <TeamComponent />
                </div>
            </React.Fragment>
        );
    }
}
