import { ssoInstance } from 'gg-react-utilities';
import Config from '../../Config';

export const getBSWDataList = () => {

    return ssoInstance.get(Config.BSW_API_URL + 'sliders');

}

export const getBSWShoppingList = () => {

    return ssoInstance.get(Config.BSW_API_URL + 'affiliates?per_page=10000');

}

export const getTravelList = () => {

    return ssoInstance.get(Config.STATS_API_URL + 'travel')

}

export const getRechargeList = () => {

    return ssoInstance.get(Config.RECHARGE_API_URL + 'services')

}

export const getModuleList = () => {

    return ssoInstance.get(Config.ADMIN_API_URL + 'get-active-module');

}

export const getPopularBrandList = () => {
    return ssoInstance.get(Config.MEGABRAND_API_URL + "popular-brands");
}

export const getMegabrandDataList = () => {

    return ssoInstance.get(Config.MEGABRAND_API_URL + 'get-all-slider');

}

export const putBrands = (id) => {

    return ssoInstance.put(Config.MEGABRAND_API_URL + 'favBrand/' + id);

}


export const putShopping = (id) => {

    return ssoInstance.post(Config.BSW_API_URL + 'favourite?affiliate_id=' + id);

}

export const getTopDealsList = () => {

    return ssoInstance.get(Config.STORE_API_URL + 'sliders');

}

export const getNearVendorList = (limit, lat, lng) => {

    return ssoInstance.get(Config.STORE_API_URL + 'vendor?start=0&length=' + limit + '&latitude=' + lat + '&longitude=' + lng + '&page=1&radius=1-10');
}

export const getUposVendorList = (lat, lng) => {

    return ssoInstance.get(Config.UPOS_API_URL + 'nearby-upos-vendors?limit=8&latitude=' + lat + '&longitude=' + lng);
}

export const addVendorToWishlist = (obj) => {
    let endpoint = Config.STORE_API_URL + 'vendor/toggle/favorites'
    if (obj.agent_id) {
        endpoint = Config.UPOS_API_URL + 'toggle-favourite'
    }
    return ssoInstance.post(endpoint, obj);
}

export const getTeamList = () => {

    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'team');

}

export const getTestimonialList = () => {

    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'testimonial');

}

export const getUposPartnerList = (limit, name) => {

    return ssoInstance.get(Config.UPOS_API_URL + 'get-customer-agents?page=' + limit + '&agent-name=' + name);

}

export const getFaqCategoryList = () => {

    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'faq-categories');

}

export const getFaqList = (cid) => {

    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'faq?cid=' + cid);

}

export const searchFaq = (query) => {

    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'search-faq?query=' + query);

}

export const getCareerList = (query) => {

    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'career?query=' + query);

}

export const getImageList = () => {

    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'image_gallery');

}

export const getVideoList = (param) => {
    param = param !== undefined ? '?page=media' : ''
    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'video_gallery' + param);

}

export const getPdfList = () => {

    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'pdf');

}

export const getDesktopWallpaperList = (page) => {

    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'desktop-wallpaper?page=' + page);

}

export const getMobileWallpaperList = (page) => {

    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'mobile-wallpaper?page=' + page);

}

export const getRingtoneList = () => {

    return ssoInstance.get(Config.ADMIN_GG_API_URL + 'ringtone');

}

export const postResume = (obj) => {

    let formData = new FormData();
    formData.append("name", obj.name)
    formData.append("exp", obj.exp)
    formData.append("email", obj.email)
    formData.append("mobile", obj.mobile)
    formData.append("address", JSON.stringify(obj.address))
    formData.append("ctc", obj.ctc)
    formData.append("desc", obj.desc)
    formData.append("resume", obj.resume)

    return ssoInstance.post(Config.ADMIN_GG_API_URL + 'post-resume', formData);
}

export const getPincodeList = (pin) => {
    return ssoInstance.get(Config.PARTNER_URL + 'pincode-list?q=' + pin);
}

export const getPartnerList = (pin) => {
    return ssoInstance.get(Config.PARTNER_URL + 'contact-partner-by-pincode?pincode=' + pin);
}

export const getMCPLatLng = () => {

    return ssoInstance.post(Config.PARTNER_URL + 'mcp-lat-lng');

}

export const getACPLatLng = () => {

    return ssoInstance.post(Config.PARTNER_URL + 'acp-lat-lng');

}

export const getCPLatLng = () => {

    return ssoInstance.post(Config.PARTNER_URL + 'cp-lat-lng');

}

export const getCentralSearchData = (lat, lng, param) => {

    return ssoInstance.get(Config.CENTRAL_SEARCH_API_URL + 'search?lat=' + lat + '&lon=' + lng + '&services=*&q=' + param);

}
