import React, {Component} from 'react';
import {PageTitle} from 'gg-react-utilities';
import AcheiversContent from "./components/content";

export default class Acheivers extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    render(){
        return(
            <React.Fragment>
                <PageTitle title='Contest | Global Garner' />
                <div className="container-fluid header-below">
                    <div className="container p-s-0">
                        <div className="career header-below-content contest text-center">
                            <h1 className="bp3-text-xlarge heading">New User Generation Contest</h1>
                            <p>Last Updated On 19/02/2018</p>
                        </div>
                    </div>
                </div>
                <AcheiversContent />
            </React.Fragment>
        );
    }
}
