let initialState = {
    airportList: [],
    operatorList:[],
    flightCityList:[],
    planList:[],
    bswBrandList:[],
    holidayCityList:[]
};

const strip = (state = initialState, action) => {

    switch (action.type) {

        case 'AIRPORT_LIST':
            return {...state, airportList: action.sources}
        case 'OPERATOR_LIST':
            return {...state, operatorList: action.sources}
        case 'PLAN_LIST':
            return {...state, planList: action.sources}
        case 'BSW_BRAND_LIST':
            return {...state, bswBrandList: action.sources}
        case 'HOLIDAY_CITY_LIST':
            return {...state, holidayCityList: action.sources}
        case 'FLIGHT_CITY_LIST':
            return {...state, flightCityList: action.sources}
        default:
            return state
    }
}

export default strip;
