import React, {Component} from 'react';

import Support from "./components/support";
import {PageTitle} from 'gg-react-utilities';

export default class Aboutus extends Component {

    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false
        };
    }

    nextPage(route){
      this.props.history.push(route);
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }
    
    render(){
        return(
            <React.Fragment>
              <PageTitle title='Support | Global Garner' />
                <div className="container-fluid header-below">
        					<div className="container p-s-0">
        						<div className="career header-below-content">
        							<div className="row text-center mb-5 pb-5">
        								<div className="col-12 col-xl-12 text-center py-5 mb-5 pt-s-0 pt-sm-0">
        									<h1 className="bp3-text-xlarge heading">We are at your service to assist you!</h1>
        									<span className="heading-desc mb-5">
        									  By accepting these Terms and Condition, you are deemed to accept our Privacy Policy.
        									</span>
        								</div>
        							</div>
        						</div>
        					</div>
                </div>
                <div className="container-fluid pb-5 mb-5">
                    <Support
                      nextPage={(route)=>{
                        this.nextPage(route);
                      }}/>
                </div>

            </React.Fragment>
        );
    }
}
