import React, {Component} from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import { Card } from "@blueprintjs/core";
import MemberDetailModal from './memberDetailModal';

export default class OperationalTeam extends Component{

    constructor(props){
        super(props);

        this.state = {
          isCallModal: false,
          modalData:null
        };
    }



    render(){
        const {data} = this.props;
        return(
            <div className="cb-tab-data">
      				<center>
                <MemberDetailModal
                  title={''}
                  isOpen={this.state.isCallModal}
                  className="bp3-dialog p-0 rounded"
                  data={this.state.modalData}
                  />
      					<div className='ot_tab'>
      						<Tabs animate={false} id="TabsClassic" key="horizontal" renderActiveTabPanelOnly={true} vertical={false} className="bd-tabs shadow-none" >
      							<Tab className="bd-tab shadow-none" id="tab2" title="IT Team" panel={
      								<div className="row directer-member-list text-center">
    										{data[1].IT.map((res)=>{
    										  return(
    											<div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 directer-member-detail' key={res.team_id}>
    											  <Card className='bp3-elevation-1 py-5'>
    												<center>
    												  <img src={process.env.REACT_APP_IMAGE_URL+res.image} alt='team-member-img'
    													onError={(ev)=>{
    													  ev.target.src = process.env.REACT_APP_USER_DEFAULT_IMAGE;
    												  }}/>
    												</center>
    												<h3 className='employee-name mb-2'>{res.name}</h3>
    												<p className='employee-position'>{res.position}</p>
    											  </Card>
    											</div>
    										  )
    										})}
      								</div>
      							}/>

      							<Tab className="bd-tab" id="tab4" title="HR Admin" panel={
      								<div className="row directer-member-list text-center">
										{data[0].HR.map((res)=>{
										  return(
											<div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 directer-member-detail' key={res.team_id}>
											  <Card className='bp3-elevation-1 py-5'>
												<center>
												  <img src={process.env.REACT_APP_IMAGE_URL+res.image} alt='team-member-img'
													onError={(ev)=>{
													  ev.target.src = process.env.REACT_APP_USER_DEFAULT_IMAGE;
												  }}/>
												</center>
												<p className='employee-name'>{res.name}</p>
												<p className='employee-position'>{res.position}</p>
											  </Card>
											</div>
										  )
										})}
      								</div>
      							}/>
      						</Tabs>
      					</div>
      				</center>
            </div>
        );
    }
}
