import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import MediaList from '../components/media';
import { getImageList, getVideoList, getPdfList } from '../../core/actions/action';
import {imageListAction, videoListAction, pdfListAction} from '../../core/actions';


const mapStateToProps = state => {

    return{
        imageList:state.RetailReducer.imageList,
        videoList:state.RetailReducer.videoList,
        pdfList:state.RetailReducer.pdfList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getImageList:() => {
          dispatch(imageListAction(null));
            return getImageList()
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(imageListAction(sources.data));
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    dispatch(imageListAction([]));
                }
            }).catch((error)=>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
            })
        },
        getVideoList:(param) => {
          dispatch(videoListAction(null));
            return getVideoList(param)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(videoListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(videoListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        getPdfList:() => {
          dispatch(pdfListAction(null));
            return getPdfList()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(pdfListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(pdfListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaList);
