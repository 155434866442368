import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";
import {GetAppRounded} from '@material-ui/icons';

export default class Audio extends Component{
    constructor(props){
        super(props);
        this.state = {
          playStatus:false
        };
    }

    render(){
      const {data} = this.props;
        return(
          <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ringtone-list mb-4'>
            <Card className="bp3-elevation-3 text-center">
              <div className=''>
                <p className='ringtone-title'>Global Garner</p>
                <p className='ringtone-title'>{data.title}</p>
              </div>
              <div className="mt-4">
                <img src={require('../../images/svg/music-notes.svg')} className='img-fluid' alt='ringtone' />
              </div>
              {!this.state.playStatus?
              <div className='row text-center mt-4'>
                <div className="overlay col-sm-6 col-xl-6 col-md-6 col-6 d-flex justify-content-center align-items-center" onClick={()=>{this.setState({playStatus:true})}}>
                  <span className="bp3-icon bp3-icon-caret-right text-primary"></span> <span className='ml-2 text-primary'>Play</span>
                </div>
                <div className="overlay col-sm-6 col-xl-6 col-md-6 col-6 border-left">
                  <a className="d-flex justify-content-center align-items-center" download={process.env.REACT_APP_IMAGE_URL+data.ringtone} href={process.env.REACT_APP_IMAGE_URL+data.ringtone}><GetAppRounded/> <span className='ml-2'>Download</span></a>
                </div>
              </div>
              :
              <div className='row mt-4'>
                <audio controls autoPlay>
                  <source src={process.env.REACT_APP_IMAGE_URL+data.ringtone} type="audio/mp3"/>
                </audio>
              </div>
            }
            </Card>
          </div>
        );
    }
}
