import React, {Component} from 'react';

export default class AwesomeBswDeals extends Component{
    constructor(props){
        super(props);
        this.state = {
            loadingData: true,
            data: []
        };
    }

    componentDidMount(){

    }

    render(){
        return(
            <section className="py-5">
                <div className="container awesome-deals">
                  <div className="row mb-3 investor">
                    <div className="col-xl-12 col-12 pr-s-0">
                      <h2 className="custom-heading left">Our Investors</h2>
                    </div>
                    <div className="col-xl-12 col-12 pr-s-0 text-center">
                      <center>
                        <img src={require('../images/png/The_Times_of_India_Crest.png')} alt='investor' className='img-fluid mt-5 mb-3'/>
                      </center>
                      <h1>BENNETT COLEMAN & CO LTD<br/><b>(THE TIMES GROUP)</b></h1>
                      <p className='mt-4'>India's largest 180 year old media conglomerate - The Times of India and Economic Times, has invested in us showing trust in the
                        management and business model.</p>
                    </div>
                </div>
              </div>
            <div className="clearfix"></div>
          </section>
        );
    }
}
