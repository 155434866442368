const config = {
    APP_URL:process.env.REACT_APP_BASE_URL+'globalgarner.globalgarner'+process.env.REACT_APP_Extension+'/',
    MEGABRAND_DEFAULT_IMAGE: 'https://s3.ap-south-1.amazonaws.com/gg-mega-brands/brand_images/test/default.png',
    RECHARGE_URL :process.env.REACT_APP_BASE_URL+'recharge.globalgarner'+process.env.REACT_APP_Extension+'/',
    ADMIN_GG_API_URL:process.env.REACT_APP_BASE_URL+'gg-admin.globalgarner'+process.env.REACT_APP_Extension+'/api/',
    FLIGHT_API_URL : process.env.REACT_APP_BASE_URL+'flight-api.globalgarner'+process.env.REACT_APP_Extension+'/user/flight/v1.0.3/',
    RECHARGE_API_URL : process.env.REACT_APP_BASE_URL+'recharge-api-new.globalgarner'+process.env.REACT_APP_Extension+'/user/',
    HOTEL_API_URL : process.env.REACT_APP_BASE_URL+'hotel-api.globalgarner'+process.env.REACT_APP_Extension+'/api/',
    HOLIDAYS_API_URL : process.env.REACT_APP_BASE_URL+'holiday-api.globalgarner'+process.env.REACT_APP_Extension+'/',
    ADMIN_API_URL:process.env.REACT_APP_BASE_URL+'admin.globalgarner'+process.env.REACT_APP_Extension+'/api/',
    STATS_API_URL:process.env.REACT_APP_BASE_URL+'stats.globalgarner'+process.env.REACT_APP_Extension+'/api/',
    RAILBHOJ_API_URL:process.env.REACT_APP_BASE_URL+'railbhoj-api.globalgarner'+process.env.REACT_APP_Extension+'/api/',
    BUS_API_URL:process.env.REACT_APP_BASE_URL+'bus-api.globalgarner'+process.env.REACT_APP_Extension+'/api/v3/',
    BSW_API_URL:process.env.REACT_APP_BASE_URL+'best-shopping-websites-api.globalgarner'+process.env.REACT_APP_Extension+'/user/v3/',
    MEGABRAND_API_URL:process.env.REACT_APP_BASE_URL+'mega-brands-api.globalgarner'+process.env.REACT_APP_Extension+'/user/v1.0.3/',
    STORE_API_URL:process.env.REACT_APP_BASE_URL+'store-api.globalgarner'+process.env.REACT_APP_Extension+'/v1.0.3/',
    STORE_URL:process.env.REACT_APP_BASE_URL+'store.globalgarner'+process.env.REACT_APP_Extension+'/',
    UPOS_API_URL:process.env.REACT_APP_BASE_URL+'upos-api.globalgarner'+process.env.REACT_APP_Extension+'/v3/',
    BSW_URL:process.env.REACT_APP_BASE_URL+'best-shopping-websites.globalgarner'+process.env.REACT_APP_Extension+'/',
    LABS_URL:process.env.REACT_APP_BASE_URL+'gglabs.globalgarner'+process.env.REACT_APP_Extension+'/',
    GRANGE_URL:process.env.REACT_APP_BASE_URL+'grange.globalgarner'+process.env.REACT_APP_Extension+'/',
    MEGABRAND_URL:process.env.REACT_APP_BASE_URL+'mega-brands.globalgarner'+process.env.REACT_APP_Extension+'/',
    PARTNER_URL:process.env.REACT_APP_BASE_URL+"partner.globalgarner"+process.env.REACT_APP_Extension+"/api/",
    BUISNESS_URL:process.env.REACT_APP_BASE_URL+'business.globalgarner'+process.env.REACT_APP_Extension+'/',
    IMAGE_URL:'https://s3.ap-south-1.amazonaws.com/gg-statics/',
    RECHARGE_IMAGE_URL:"https://s3.ap-south-1.amazonaws.com/gg-recharge/home_recharge/",
    CENTRAL_SEARCH_API_URL:process.env.REACT_APP_BASE_URL+'search.globalgarner'+process.env.REACT_APP_Extension+'/api/',
    INQUIRY_API_URL: process.env.REACT_APP_BASE_URL+'inquiry-api.globalgarner'+process.env.REACT_APP_Extension+'/api/',
    EMAIL_REGEX : /[^@]+@[^@]+\.[^@]+/,

    ACCOUNT_URL:process.env.REACT_APP_BASE_URL+'accounts.globalgarner'+process.env.REACT_APP_Extension+'/',
    BASE_URL:process.env.REACT_APP_BASE_URL,
    Extension:process.env.REACT_APP_Extension,
};


export default config;

export const packageConfigure={


}
