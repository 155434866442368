import React, {Component} from 'react';
import Config from '../../Config';
import { Intent, Position, Toaster } from "@blueprintjs/core";
import { NavigateNext, NavigateBefore, FavoriteBorderRounded, FavoriteRounded} from '@material-ui/icons';
import { getBSWShoppingList, putShopping } from '../../core/actions/action';
import Carousel from 'react-grid-carousel'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
    slidesToSlide: 6
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 6
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const BSW = ({ isActive }) => (
  <span
    style={{
      display: 'inline-block',
      borderRadius: '7px',
      height: isActive ? '10px' : '10px',
      width: isActive ? '19px' : '10px',
      background: isActive ? '#236AEE' : '#CBCBCB'
    }}
  ></span>
)
export default class AwesomeBswDeals extends Component{
    constructor(props){
        super(props);
        this.state = {
            slider:[1],
            data:[],
            cardColor:[],
            previous:false,
            toggle:null
        };
    }

    componentDidMount(){
      this.props.getBSWDataList();
      getBSWShoppingList()
        .then((res) => {
        
          this.setState({
            data: res.data.data.data
          })

        }).catch((error) => {
          const sources = error.response;
        })

      const Color = [
       1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18
      ];
      this.setState({
        cardColor: Color
      })
     
    }
  handleFavoriteToggle = (e, affiliate_id, key) => {
    e.preventDefault();
    putShopping(affiliate_id)
      .then((res) => {
        let sources = res.data;
        let message = res.data.message;
        let alertColor = Intent.DANGER;


        if (sources.status === true) {
          alertColor = Intent.SUCCESS;
          this.state.data[key].fav_status = sources.data.is_favorite
          this.setState({ toggle: sources.data.is_favorite });
        }

        Toaster.create({
          position: Position.TOP,
        }).show({ message: message, intent: alertColor })

      })
      .catch((errors) => {
        if (errors.response.status !== 401) {
          let message = "";
          if (errors.response) {
            message = errors.response.message ? errors.response.message : "Something went wrong!";
          } else {
            message = "Something went wrong!";
          }
          Toaster.create({
            position: Position.TOP,
          }).show({ message: message, intent: Intent.DANGER });
        }
      });
  };


    render(){
      let counter = 0;
      let data=this.state.data
      var i=0
      var cardColor = this.state.cardColor;

        return(
            <section className="pb-5 pt-5 bg-home-basic">
              <div className="container awesome-deals">
                <div className="row py-3">
                  <div className="col-xl-8 col-8 pr-s-0">
                    <h2 className="custom-heading left">Best Shopping Websites</h2>
                  </div>
                  <div className="col-xl-4 col-4 text-right">
                    <a className="view-all-link mt-2" href={Config.BSW_URL+'search'}>View All</a>
                  </div>
                  </div>
              <div className="row mt-4">
                {
                  this.state.slider.length === 0 ?
                    <React.Fragment>
                      <div className="bp3-skeleton w-100" style={{ height: "335px" }}>&nbsp;</div>
                    </React.Fragment>
                    :
                     <div className="col-sm-12 col-md-12 col-xs-12 mt-3 awesome-dealsbsw-img mb-2">
                          
                      <Carousel dot={BSW} cols={6} hideArrow={false} showDots responsiveLayout={[
                        { breakpoint: 1200, cols: 5, rows: 3, gap: 5 },
                        { breakpoint: 768, cols: 4, rows: 3, gap: 2 },
                        { breakpoint: 468, cols: 1, rows: 1, gap:1}
                      ]}
                        mobileBreakpoint={300}  rows={3} gap={10} loop className="mb-5 position-relative" >
                          
                          {data.length !=0 && data.map((data, key) => {
                         
                           return(   
                             <Carousel.Item>
                             <div>
                               <div style={{ display: 'none' }}>
                                 {cardColor[i] == undefined ? i=0 :""}
                               </div>
                            
                                 <div className={cardColor[i] == 1 || cardColor[i] == 12 || cardColor[i] == 16 ? "brandsDiv red-skin" : cardColor[i] == 2 || cardColor[i] == 11 || cardColor[i] == 17 ? "brandsDiv blue-skin" : cardColor[i] == 3 || cardColor[i] == 10 || cardColor[i] == 18 ? "brandsDiv orange-skin" : cardColor[i] == 4 || cardColor[i] == 9 || cardColor[i] == 13 ? "brandsDiv green-skin" : cardColor[i] == 5 || cardColor[i] == 8 || cardColor[i] == 14 ? "brandsDiv darkblue-skin" : cardColor[i] === 6 || cardColor[i] == 7 || cardColor[i] == 15 ? "brandsDiv lightdark-skin" :"brandsDiv red-skin"}>
                                 {data.fav_status === 0 ? <FavoriteBorderRounded className="float-right heart" onClick={(e) => {
                                   this.handleFavoriteToggle(e, data.affiliate_id, key)
                                 }} /> : <FavoriteRounded className="float-right heart" onClick={(e) => {
                                     this.handleFavoriteToggle(e, data.affiliate_id, key)
                                 }} />}

                                <a href={Config.BSW_URL + 'brands/' + data.slug}>
                                <div class="brandsImg">
                                  <img alt="#" src={data.logo} class="img-fluid" />
                                </div>
                                <h4 class="name">{data.name}</h4>
                                    <div class="visit-website">Visit Website</div></a>
                               <div style={{display:'none'}}>                               
                                 {i++
                                }
                                </div>
                                </div>
                              </div>
                              </Carousel.Item>
                            )   
                          })}

                      </Carousel>
                    

                      </div>
                    
                }
              </div>
              {data.length !=0?
                    <div className="border-bottom mb-4 mt-4"></div>:""}
                  <div className="row">
                      {
                          this.props.bswDataList.length===0 ?
                              <React.Fragment>
                                  <div className="bp3-skeleton w-100" style={{height: "335px"}}>&nbsp;</div>
                              </React.Fragment>
                              :
                              this.props.bswDataList.map((single, key) => {
                                counter++;
                                if(counter > 6) counter =1;
                                  return <a href={Config.BSW_URL+'brands/'+single.slider_link+'/'+counter} key={single.slider_id} className="col-sm-4 col-md-4 col-xs-12 mt-3 awesome-dealsbsw-img mb-2">
                                      <div className="awesome-img">
                                        <img src={single.image_url} alt="Deals" className="img-fluid" onError={(ev)=>{
                                          ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                        }}/>
                                    </div>
                                  </a>
                              })
                      }
                  </div>
              </div>
          		<div className="clearfix"></div>
            </section>
        );
    }
}
