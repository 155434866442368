import React, {Component} from 'react';
import {Button} from "@blueprintjs/core";

import VideoModal from '../../core/components/videoModal';

const data = [
  {
    title:'Become our User',
    image:require('../images/png/register_yourself.png'),
    content:'<h3>A PENNY SAVED WITH US IS A PENNY EARNED<br/>PAY FOR YOUR DAILY NEEDS & GET UPTO 100% CASHBACK/REWARD (WITH NO UPPER LIMIT)</h3>'
  },
  {
    title:'What is Global garner?',
    image:require('../images/png/What-is-GG.png'),
    content:'<h3>A universal App to save what you Spend. All Products & Services are Packed in One Website and App to cater all your needs and<br/> requirements and giving extra benefit of cashback. For all Businesses, it is the World’s 1st & Only Postpaid Sales Service Provider.</h3>'
  },
  {
    title:'Why to Choose Global garner ?',
    image:require('../images/png/Why_to_choose_GG_before_GG.png'),
    image1:require('../images/png/Why_to_choose_GG_after_GG.png'),
    content:"<h3>NO STOPPING ON CRAZY SHOPPING!<br/>YES, YOU READ IT RIGHT.<br/>BUT WHY?<br/>CAUSE IT'S FOREVER OFFER OF 100% CASHBACK/REWARD WITH NO UPPER LIMIT ON YOUR PURCHASES OF THE SAME PRODUCTS AND SERVICES, YOU ARE USING SINCE YEARS.<br/>SO... SHOP, SHOP & SHOP, AND BE A SHOPPING SPREE!!</h3>"
  },
  {
    title:'How it works?',
    image:require('../images/png/how_it_works.png'),
    image1:require('../images/png/how_it_works_GG.png'),
    content:'<h3>TILL DATE BUYERS USE TO EXCHANGE MONEY WITH SELLER FOR PRODUCTS & SERVICES FOR WHICH BUYER USE TO GET PRODUCT OR SERVICES BUT THEREAFTER EXPECTS NOTHING ALSO THIS MOVEMENT OF ECONOMY IS UNSTOPPABLE.</h3>',
    content1:'<h3>NOW WITH GLOBAL GARNER AS YOUR BUYING PARTNER YOU GET DUAL 100% CASHBACK/REWARD FIRST INSTANTLY THROUGH GG COINS AND SECOND THOUGH SCB FIFO PROCESS.</h3>'
  },
  {
    title:'Best shopping website',
    image:require('../images/png/bsw.png'),
    content:'<h3>SHOP FROM THE BEST SHOPPING WEBSITES & GET 100% CASHBACK/REWARD FROM GLOBAL GARNER</h3><h5>*AMAZON, HOTELLOOK, JETRADAR, DIVYAPHARMA & REST WILL BE AUTO-TRACKED</h5><h5>*UPV GENERATION MAY TAKES AROUND 45 TO 120 DAYS IN CASE OF BEST SHOPPING WEBSITES</h5>',
    proccess:['VISIT RETAILER’S WEBSITE','MAKE A PURCHASE','PAY THROUGH DIFFERENT MODES AVAILABLE','UPLOAD BILL (ONLY IN CASE OF FEW WEBSITES*)','AFTER APPROVAL','GET upto 100% CASHBACK/REWARD ']
  },
  {
    title:'Mega Brands',
    image:require('../images/png/megabrand.png'),
    content:"<h3>RETAIL THERAPY IS ALWAYS A GOOD IDEA WITH MEGABRANDS LIKE SHOPPER'S STOP, BIG BAZAAR, PANTALOON AND MANY MORE BRANDS</h3>",
    proccess:['CHOOSE BRAND VOUCHER (DIGITAL/PHYSICAL)','SELECT QUANTITY & VALUE (DENOMINATION)','MAKE ONLINE PAYMENT','PURCHASE WILL BE AUTO-TRACKED','GET upto 100% CASHBACK/REWARD ']
  },
  {
    title:'Travel',
    image:require('../images/png/travel.png'),
    content:'<h3>DELICIOUS FOOD NEARBY IS JUST A CLICK AWAY<br/>FIND RESTAURANTS, FOOD JUNCTION, FOOD HUBS NEARBY YOUR LOCALITY & GET 100% CASHBACK/REWARD ON ORDERING YOUR MEAL FROM REGISTERED VENDORS.</h3>',
  },
  {
    title:'Bus',
    image:require('../images/png/bus.png'),
    content:'<h3>Make your Journey adventurous by exploring the roads by Bus</h3>',
    proccess:['select destination and date','search and select your bus ticket','enter your details','MAKE PAYMENT','booking IS AUTO TRACKED','After journey your booking IS SENT TO UPV CYCLE','GET upto 100% CASHBACK/REWARD ']
  },
  /*{
    title:'Railbhoj',
    image:require('../images/png/railbhoj.png'),
    content:'<h3>LIFE IS A COMBINATION OF TRAVEL AND FOOD, NOW ORDER MEALS ON TRAIN WHEELS</h3>',
    proccess:['enter train name/no or PNR number','search food','select station and your food','enjoy your meal on wheels','order is auto-tracked','After food delivery your order IS SENT TO UPV CYCLE','GET upto 100% CASHBACK/REWARD ']
  },*/
  {
    title:'Flight',
    image:require('../images/png/flight.png'),
    content:'<h3>YOUR WINGS ALREADY EXISTS, ALL YOU HAVE TO DO IS TO FLY</h3>',
    proccess:['select destination ,date and class','search and select your flight','enter your details','MAKE PAYMENT',' booking IS AUTO TRACKED','After journey your booking IS SENT TO UPV CYCLE','GET upto 100% CASHBACK/REWARD ']
  },
  {
    title:'Hotel',
    image:require('../images/png/Hotel.png'),
    content:'<h3>Luxury is a matter of choice, choose the best Hotels at the best price. Enjoy the comfort , cherish the moments</h3>',
    proccess:['select your city and date','choose the best Hotel with best Tariff','Complete your booking','Booking WILL BE AUTO-TRACKED','GET upto 100% CASHBACK/REWARD ']
  },
  {
    title:'Holiday Packages',
    image:require('../images/png/Holiday.png'),
    content:'<h3>PLAN A  HOLIDAY TRIP TO YOUR DREAM PARADISE WITHOUT BURNING A HOLE IN YOUR POCKET AND GET REWARDED TOO</h3>',
    proccess:['plan your holiday','select your destination','choose the best packages with best rates','MAKE ONLINE PAYMENT','PURCHASE WILL BE AUTO-TRACKED','GET UPTO 100% CASHBACK/REWARD']
  },
  {
    title:'Local Vendor',
    image:require('../images/png/local_vendor.png'),
    content:'<h3>SHOPPING IS FUN FROM YOUR FAVORITE LOCAL SHOP.<br/>WE HAVE A WIDE RANGE OF LOCAL/REGIONAL VENDORS REGISTERED WITH US</h3>',
    proccess:['CHOOSE YOUR LOCAL GG REGISTERED VENDOR','PURCHASE YOUR DESIRED PRODUCT','PAY THROUGH DIFFERENT MODES AVAILABLE','GET GG QR BILL','UPLOAD BILL','AFTER APPROVAL','GET upto 100% CASHBACK/REWARD ']
  },
  // {
  //   title:'Make my cart',
  //   image:require('../images/png/make_my_cart.png'),
  //   content:'<h3>NOW, OFFLINE SHOPPING USERS CAN ALSO SHOP ONLINE THROUGH OUR NEWEST FEATURE<br/>MAKE MY CART</h3>'
  // },
  {
    title:'IT & HR Services',
    image:require('../images/png/it&hr.png'),
    content:'<h3>ONE STOP SOLUTION FOR ALL YOUR HR & IT REQUIREMENTS</h3>',
    proccess:['Enter your details and requirement','We will fulfill your needs']
  },
  {
    image:require('../images/png/Buy-Car.png'),
    title:'Buy Car',
    content:'<h3>START DRIVING YOUR HAPPINESS IN YOUR DREAM VEHICLE</h3>',
    proccess:['Enter your details and requirement','We will fulfill your needs']
  },
  {
    title:'GG-Food',
    image:require('../images/png/food.png'),
    content:'<h3>DELICIOUS FOOD NEARBY IS JUST A CLICK AWAY<br/>FIND RESTAURANTS, FOOD JUNCTION, FOOD HUBS NEARBY YOUR LOCALITY & GET 100% CASHBACK/REWARD ON ORDERING YOUR MEAL FROM REGISTERED VENDORS.</h3>',
    proccess:['FIND GG REGISTERED RESTAURANTS/FOOD JUNCTION','ORDER ONLINE/OFFLINE','GET GG QR BILL','UPLOAD BILL','AFTER APPROVAL','GET upto 100% CASHBACK/REWARD ']
  },
  {
    title:'Insurance',
    image:require('../images/png/insurance.png'),
    content:'<h3>life is a risk! Generate your enquiry to protect yourself, your family and your belongings</h3>',
    proccess:['Enter your details and requirement','We will fulfill your needs']
  },
  {
    title:'GG-Franchise',
    image:require('../images/png/GG-Franchise.png'),
    content:'<h3>WORLD’S BEST FRANCHISE OPTIONS UNDER ONE ROOF<br/>YOU CAN START YOUR BUSINESS BY CHOOSING YOUR FAVORITE FRANCHISE OR;<br/>YOU CAN CREATE YOUR OWN BRANDS & FRANCHISE HERE</h3>',
    proccess:['select the franchise you want to invest in','apply','earn']
  },
  {
    title:'Daily utility bills',
    image:require('../images/png/bill_pay&recharge.png'),
    content:'<h3>MAKE HASSLE-FREE PAYMENTS FROM ANYWHERE, FOR YOUR EACH AND EVERY NEED<br/>YOU CAN PAY YOUR UTILITY BILLS & GET 100% CASHBACK/REWARD FROM BILL PAY & RECHARGE MODULE</h3>',
    proccess:['SELECT THE UTILITY FOR PAYMENT','SELECT YOUR OPERATOR','ENTER DETAILS','MAKE FULL BILL PAYMENT','PAYMENT IS AUTO TRACKED','YOUR PAYMENT IS SENT TO UPV CYCLE','GET upto 100% CASHBACK/REWARD ']
  },
  {
    title:'Loan',
    image:require('../images/png/Loan.png'),
    content:'<h3>Need any kind of loan? Yes, then we are here to assist you in fulfilling your loan requirements. Apply loan and we will help you to fulfill your requirements</h3>',
    proccess:['select type of loan','ENTER YOUR personal DETAILS AND LOAN REQUIREMENTS','WAIT FOR APPROVAL AND SANCTION','AFTER verification and assessment','Loan is disbursed']
  },
  {
    title:'What is UPV (User purchase value)?',
    image:require('../images/png/what_is_UPV.png'),
    content:'<h3>UPV IS USER PURCHASE VALUE. UPV IS GENERATED WHEN A USER DOES A PURCHASE OF MINIMUM AMOUNT ₹ 10,000 VIA DIFFERENT MODULES OF GLOBAL GARNER</h3><br/><h5>*UPV GENERATION TAKES AROUND 15 TO 90 DAYS, IT MAY TAKE LONGER IN CASE OF BEST SHOPPING WEBSITES.</h5>'
  },
  {
    title:'Various Cashback Options',
    image:require('../images/png/Various-Cashback-Options.png'),
    content:'<h3>CHOOSE ANYONE CASHBACK OPTION:SCB FIFO, SCB RPP, IC OR MY CIRCLE AND ENJOY CASHBACK.</h3>'
  },
  {
    title:'Instant Cashback',
    image:require('../images/png/IC.png'),
    content:'<h3>IT IS A CASHBACK/REWARD IN WHICH USER CAN SHOP AND GET GUARANTEED 2% TO 10% INSTANT CASHBACK/REWARD WITHOUT WAITING TO <br/>FORM AN UPV OR WITHOUT NEW UPVS TAGGING UNDER THE PURCHASES.</h3>'
  },
  {
    title:'SCB Random Pick Proccess',
    image:require('../images/png/SCB-RPP.png'),
    content:'<h3>THE SYSTEM WILL RANDOMLY PICK THE USER FOR ANY GIVEN SLAB/AMOUNT OF CASHBACK BUT THERE WILL BE A GUARANTEE OF EVERYONE GETTING THE <br/>CASHBACK/REWARD FROM MINIMUM 2% TO MAXIMUM 100%.</h3>'
  },
  {
    title:'Systematic cashback (FIFO)',
    image:require('../images/png/SCB-FIFO.png'),
    content:'<h3>IT IS A SYSTEMATIC CASHBACK PROCESS IN WHICH CASHBACK/REWARD IS GIVEN IN 3 UNEQUAL INSTALMENTS OF &#8377; 500, &#8377; 1000, &#8377; 8500 EACH AS PER UNIQUE AUTO TAGGING SYSTEM, WHERE USER HAS TO DO NOTHING EXCEPT FOR GENERATING HIS OWN UPVS.ALSO GET ADDITIONAL &#8377; 10000 BY GG COIN CONVERSION AS A FOURTH PAY OUT.</h3>'
  },
  {
    title:'My Circle',
    image:require('../images/png/my_circle.png'),
    content:'<h3>It is a cashback in which the User will be rewarded by referring new users to generate new UPVs under him from his own social circle. It is unlike other cashback options in which UPVs will be tagged globally. In this cashback, UPVs will be tagged within a circle formed by a user by generating his UPV at the beginning, thereafter referring 6 UPVs under his UPV , thus creating his own circle. Further these 6 UPVs will refer other 6 UPVs under each UPV of their previous referral Circle and so on till it reaches the maximum level of 9 circles. Then this will keep continuing so on and so forth and other UPVs will keep referring new UPVs and further creating 9 referral Circles under them to get rewards. Therefore, several circles will keep forming in a single circle.</h3>'
  },
  {
    title:'Buddy Referral',
    image:require('../images/png/buddy_refferal.png'),
    content: '<h3>IT IS CASHBACK/REWARD WHERE USER GETS GG Gift Voucher WORTH ₹ 200 AS CASHBACK/REWARD WHEN THE REFERRED USER GENERATES AN UPV.</h3>'
  },
  {
    title:'SCB Process',
    image:require('../images/png/scb_process.png'),
    content:'<h3>AS ECONOMY MOVEMENT CANNOT BE STOPPED THEREFORE OUR CASHBACK/REWARD ALSO CANNOT BE STOPPED, WITH EVERY NEW RS 10000 SPENT BY ANY USER IN THE GG’S SYSTEM A NEW UPV IS GENERATED AND GOES INTO OUR CASHBACK/REWARD SYSTEM AND HENCE USER GETS HIS 100% CASHBACK/REWARD.</h3>'
  },
  {
    title:'What is SCB (Systematic cashback)?',
    image:require('../images/png/What-is-SCB.png'),
    content:"<h3>CASHBACK/REWARD WE PROCESS IS A COPYRIGHTED UNIQUE COMBINATION OF REVENUES WE GENERATE. YOU GET CASHBACK/REWARD IN<br/>4 INSTALLMENTS ON THE GENERATION OF INDEPENDENT & GLOBALLY GENERATED UPV’S WHEN OTHER NEW UPV'S ARE TAGGED UNDER YOU</h3>"
  },
  {
    title:'How you get your cashback/rewards?',
    image:require('../images/png/How-to-get-your-CB-R.png'),
    content:'<h3>You get cashback in 2 forms:  GG Coins and GG Money. GG Coins can be used upto the extent of 5% in your next purchase* . GG Money can be transferred directly into Bank account</h3>'
  },
  {
    title:'What is GG-Coin?',
    image:require('../images/png/GG-Coins.png'),
    content:'<h3>DO SOME FINE COIN WITH OUR GG COINS & REWARD YOURSELF<br/>GG COINS ARE THE REWARD POINTS YOU GET THROUGH VARIOUS REFER & EARN PROGRAMS</h3>OCCASIONS ON WHICH YOU EARN GG COINS –<br/>'+
        '<ul><li>INSTANTLY ON UPV GENERATION</li><li>MAKE A FRIEND</li><li>LIKE/SHARE/POST</li><li>MAKE NEARBY FRIEND</li><li>PRODUCT REFERRAL – 5% OF BILL AMOUNT</li>'+
        '<li>USER/APP REFERRAL – 50 GG COINS</li><li>GET 100% INSTANT GG COIN ON UPV & FPV GENRATION</li><li>USE GG COINS TO THE EXTEND OF 5% THE BILL AMOUNT</li><li>COMPULSORILY CONVERTED TO GG MONEY ON 4TH PAYOUT MAX UPTO &#8377; 10000</li></ul>'
  },
  {
    title:'What is GG-Money?',
    image:require('../images/png/GG-Money.png'),
    content:'<h3>YOU HAVE PAID OVER THE ODDS, NOW IT’S TIME TO GET REWARDS<br/>CASHBACK/REWARD IN THE FORM OF GG MONEY ON REQUEST TRANSFER IN YOUR BANK ACCOUNT AFTER DEDUCTION OF APPLICABLE SERVICE CHARGES & GOVERNMENT CHARGES</h3>'
  },
  {
    title:'GG-Rewards',
    image:require('../images/png/GG-Rewards.png'),
    content:'<h3>YOUR OWN PIGGY BANK TO COLLECT GG COINS & GG MONEY<br/>USE GG REWARDS TO PAY, REQUEST AND SEND GG MONEY AND GG COINS TO GG USERS AND GG VENDORS</h3>'
  },
  {
    title:'What is FPV?',
    image:require('../images/png/What-is-FPV.png'),
    content:'<h3>2 NECESSITIES OF LIFE CLUBBED TOGETHER TO REWARD ON PURCHASE OF GG WATER BY GIVING CASHBACK/REWARD ON FUEL</h3>'
  },
  {
    title:'FPV Instant cashback',
    image:require('../images/png/FPV-Instant.png'),
    content: '<h3>PURCHASE A GG OR GG COBRANDED WATER BOTTLE AND GET INSTANT CASHBACK/ REAWARD NO NEED TO WAIT TO GENERATE FPV AS SOON AS YOU MAKE A PURCHASE U GET CASHBACK /REWARD INSTANTLY IN THE FORM Shopping<br/>DISCOUNT VOUCHERS WORTH &#8377; 100</h3>'
  },
  {
    title:'FPV Random pick proccess',
    image:require('../images/png/FPV-RPP.png'),
    content:'<h3>SYSTEM WILL RANDOMLY SELECT THE USER AND CASHBACK PERCENTAGE GENERATED IN A MONTH. QUALIFIERS ARE ARRANGED IN A POOL AND SYSTEM WILL RANDOMLY SELECT THE FPV AND CASHBACK PERCENTAGE, UNLIKE THE ABOVE PROCESS FIFO USERS WILL NOT HAVE TO WAIT FOR THEIR TURN THEY ARE RANDOMLY SELECTED. USERS WILL GET 2% TO 100% CASHBACK.</h3>'
  },
  {
    title:'FPV firt in first out',
    image:require('../images/png/FPV-FIFO.png'),
    content:'<h3>USER WHO GENERATES THE FPV FIRST WILL GET THE CASHBACK FIRST WHEN FPVS ARE TAGGED AS PER SCB TAGGING SYSTEM. ALSO GET 2% OF THE FUEL AMOUNT AS INSTANT CASHBACK.</h3>'
  },
  {
    title:'How will you get fuel cashback?',
    image:require('../images/png/How-will-you-get-Fuel-Cashback.png'),
    content:''
  },

  {
    title:'How to get your FPV cashback/rewards?',
    image:require('../images/png/How-to-get-your-FPV-Cashback.png'),
    content:''
  },
  {
    title:'Summary of User benefits',
    image:require('../images/png/Summary-of-user-beneifts.png'),
    content:''
  },
  {
    title:'UPOS - The Digital Mall',
    image:require('../images/png/UPOS.png'),
    content:'<h3>India’s best-earning opportunity! It is an Advanced digital powerhouse platform to benefit the vendors from their existing customers or for creating new entrepreneurs by selling 20 crores + products with zero investment in inventory and zero risk.</h3>',
    proccess:['REGISTER','SELL','EARN']
  },
]

export default class Register extends Component{

    constructor(props){
        super(props);

        this.state = {
            isModalOpen: false,

        };
    }

    createMarkup(data) {
        return {__html: data};
    }

    render(){
        return(
            <div className="p-s-0 how_it_works-container">
              <VideoModal
      					size="lg"
      					isOpen={this.state.isModalOpen}
                data={'FIyZASXdU2I'}
      					className="bp3-dialog p-0 rounded"
      				/>
            <section className="mt-3 how_it_works py-4 px-s-3 text-center">
                  <br/>
                  <center>
                    <img className="tab-img img-fluid" src={require('../images/png/how-to-get-cb.png')} alt="Fuel Cashback" />
                  </center>
                  <h3 className="">
                      Choose Products and services from the widest range of 300+ brands, 34000 brand and local shops and <br/>earn while you spend through incredible
                      cashback/reward options</h3>

                    <Button text="How to get Cashback Video" className='bp3-button btn-link' onClick={()=>{
                        this.setState({isModalOpen:true})
                      }}>
                      &nbsp;&nbsp;&nbsp;<span className="bp3-icon bp3-icon-chevron-right"></span>
                    </Button>
                  <br/>
                  <br/>
              </section>
              {data.map((res,i)=>{
                return(
                  <section className="text-center mb-4 mt-4 how_it_works py-5 py-s-4 px-s-3" key={i}>
                    <h2 className='title text-center'>{res.title}</h2>
                    {res.title==='What is Global garner?' || res.title==='Summary of User benefits' || res.title==='How to get your FPV cashback/rewards?' || res.title==='How will you get fuel cashback?'?
                        <div className='col-xl-12 col-md-12 col-sm-12 col-12 text-center'>
                          <img className="tab-img img-fluid" src={res.image} alt={res.title} />
                          <div className='' dangerouslySetInnerHTML={this.createMarkup(res.content)}/>
                        </div>
                      :res.title==='Why to Choose Global garner ?'?
                              <div className='row text-center justify-content-center'>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                  <img className="tab-img img-fluid" src={res.image} alt={res.title} />
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                  <img className="tab-img img-fluid" src={res.image1} alt={res.title} />
                                </div>
                                <div className="text-center col-12"  dangerouslySetInnerHTML={this.createMarkup(res.content)}/>
                              </div>
                            :res.title==='How it works?'?
                                <div className='row text-center justify-content-center'>
                                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12'>
                                    <img className="tab-img img-fluid" src={res.image} alt={res.title} />
                                    <div className='' dangerouslySetInnerHTML={this.createMarkup(res.content)}/>
                                  </div>
                                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12'>
                                    {res.content1?
                                        <div className='text-center justify-content-center'>
                                          {res.image1?<img className="tab-img img-fluid" src={res.image1} alt={res.title} />:null}
                                          <div className='' dangerouslySetInnerHTML={this.createMarkup(res.content1)}/>
                                        </div>
                                        :
                                        <div className="text-center"  dangerouslySetInnerHTML={this.createMarkup(res.content)}/>
                                    }
                                  </div>
                                </div>
                              :i%2===0?
                      <div className='row d-flex flex-md-row flex-row-reverse justify-content-center'>
                        <div className='col-xl-6 col-lg-6 col-md-10 col-sm-10 col-12 align-self-center'>
                          <br/>
                            <center>
                              <img className="tab-img img-fluid" src={res.image} alt={res.title} />
                            </center>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-10 col-sm-10 col-12 align-self-center'>
                              <h3 className="text-center"  dangerouslySetInnerHTML={this.createMarkup(res.content)}/>
                              {res.proccess ?
                                <ul className='process-segment'>
                                  {res.proccess.map((data,key)=>{
                                    return(
                                      <li className='mb-4 mb-s-3' key={key}>
                                        <p className='process bp3-elevation-1'>
                                          {data}
                                        </p>
                                      </li>
                                    )
                                  })}
                                </ul>
                                :
                                null
                              }
                        </div>
                      </div>
                      :
                      <div className='row d-flex flex-lg-row flex-column-reverse justify-content-center'>
                        <div className='col-xl-6 col-lg-6 col-md-10 col-sm-10 col-12 align-self-center'>
                            <div className="text-center"  dangerouslySetInnerHTML={this.createMarkup(res.content)}/>
                            {res.proccess ?
                              <ul className='process-segment'>
                                {res.proccess.map((data,key)=>{
                                  return(
                                    <li className='mb-4 mb-s-3' key={key}>
                                      <p className='process bp3-elevation-1'>
                                        {data}
                                      </p>
                                    </li>
                                  )
                                })}
                              </ul>
                              :
                              null
                            }
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-10 col-sm-10 col-12 align-self-center'>
                          <br/>
                            <center>
                              <img className="tab-img img-fluid" src={res.image} alt={res.title} />
                            </center>
                        </div>

                      </div>
                    }
                  </section>
                )
              })}
                <section className='how_it_works py-5 py-s-4'>
                  <br/>
                  <div className="container text-center mt-3 mb-3">
                    <h1 className='title'>So, what are you waiting for ?</h1>
                    <h1 className='title'>Begin your journey ……</h1>
                    <h1 className='title'>Go Shopping, Now with Global Garner!</h1>
                  </div>
                  <br/>
                </section>
            </div>
        );
    }
}
