import React, { Component } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import { getRechargeList } from '../../core/actions/action';
import config from '../../Config';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

export default class BillPayRecharge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previous: false,
            Travel: []
        };
    }
    componentDidMount() {
        this.props.getMegabrandDataList();
        getRechargeList()
            .then((res) => {

                this.setState({
                    Travel: res.data.data
                })

            }).catch((error) => {
                const sources = error.response;
            })



        this.Carousel.state.currentSlide == 0 && this.setState({ previous: true })
    }
   
   render() {
       let travel = this.state.Travel
       return (
           <div>
               <section className="pt-5 pb-5 recharge-bg-blue position-relative">
                    <div className="container">
                       <div class="row">
                           <div class="col-xl-12 col-12 pr-s-0">
                               <h2 class="billpay-heading left">Bill Pay and Recharge</h2>
                            </div>
                        </div>
                        <div className="row">
                           <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 awesome-deals-img mb-2">
                               <Carousel responsive={responsive} ref={(el) => (this.Carousel = el)} arrows={false}>
                                   {travel && travel.map(res => {
                                       return (
                                           <div>
                                               <a href={res.service_slug == "mobile-postpaid" || res.service_slug == "mobile-prepaid" ? config.RECHARGE_URL:config.RECHARGE_URL + res.service_slug}>
                                               <div className="oval">
                                                       <img
                                                           className="bill_recharge_img"
                                                           src={res.service_img}
                                                           alt={res.service_type}
                                                       />
                                                   </div></a>
                                               <p className="product-name">{res.service_type}</p>
                                           </div>
                                       )
                                   })}
                               </Carousel>
                               {this.state.previous === false ?
                               <button className="billrecharge-pre" onClick={() => {
                                   const previousSlide = this.Carousel.state.currentSlide - 1;
                                   this.Carousel.previous()
                                   this.Carousel.goToSlide(previousSlide)
                                   }}><NavigateBefore /></button> : ""}
                               <button className="billrecharge-next" onClick={() => {
                                   const nextSlide = this.Carousel.state.currentSlide + 1;
                                   this.Carousel.next()
                                   this.setState({ previous: false })
                                   this.Carousel.goToSlide(nextSlide)
                               }}><NavigateNext /></button>
                            </div>
                        </div>
                    </div>
               </section>
           </div>
       );

   }

}