import React, {Component} from 'react';

import HowItWork from "./components/howItWork";
import {PageTitle} from 'gg-react-utilities';

export default class HowItWorkComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }
    
    render(){
        return(
            <React.Fragment>
              <PageTitle title='How it works | Global Garner' />
                <div className="container-fluid header-below">
        					<div className="container p-s-0">
        						<div className="career header-below-content">
        							<div className="col-12 col-xl-12 text-center mb-5">
        								<h1 className="bp3-text-xlarge heading">How it works</h1>
        								<span className="heading-desc">
        									Money Talks: Fortunately, It Will Never Say Goodbye With Global Garner. You Have Paid Over The Odds In These Years Now Shop Around And Give
        									Yourself A Shopping Therapy Without Worry!!!!<br/>Now Step In The World Of Global Garner …!!!!
        								</span>
        							</div>
        						</div>
        					</div>
                </div>
                <HowItWork />
            </React.Fragment>
        );
    }
}
