import React, {Component} from 'react';
import {PageTitle} from 'gg-react-utilities';
import Search from "./Container/searchContainer";
import TopBar from "../core/components/TopBar";


export default class GlobalSearch extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    render(){
        return(
            <React.Fragment>
                <div className="container mb-5">
                    <PageTitle title='Search Result | Global Garner' />
                    <TopBar title="Search Result"/>
                    <div className="container-fluid">
                        <Search
                          param={this.props.match.params.param}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
