import React, {Component} from 'react';

export default class GGModule extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    render(){
      const {data} = this.props;
      let counter = 0;
      return(
        <section>
          <div className="row">
            <div className="col-xl-7 col-xl-7 col-sm-7 col-8">

            </div>
            <div className="col-xl-5 col-xl-5 col-sm-5 col-4">
              <p className='mt-2 pull-right link text-primary'>{data.length} result</p>
            </div>
          </div>
          <div className="row mt-3 mb-3">
            {data.map((module, key) => {
            counter++;
            module = module._source
            if(counter > 8) counter = 1;
              return (
                  <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2" key={module.id}>
                      <a href={module.url}>
                        <div className={""}>
                          <img src={module.image==='' || module.image===null ? process.env.REACT_APP_DEFAULT_IMAGE : module.image}
                            alt={module.name} className='img-fluid rounded-circle'
                          onError={(ev)=>{
                            ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                          }}/></div>
                        <span>{module.title}</span>
                      </a>
                  </div>
                )
              })
            }
            </div>
            <div className="clearfix"></div>
          </section>
        );

    }

}
