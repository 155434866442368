import React, {Component} from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import FPV from "./CashbackType/FPV";
import UPV from "./CashbackType/UPV";

export default class CashbackTypeTabs extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeTab: props.activeTab!==null?props.activeTab:'scb'
        };
    }

    componentDidMount(){

    }

    handleTabChange = (TabId) => {
        this.setState({activeTab: TabId});
    };

    render(){
      let metaData = document.getElementsByTagName("meta");
      metaData['description'].content = 'Global Garner is a unique all in one platform which gives big cashback that can be transferred to bank account,through 3 exclusive cashback options.';
      metaData['keywords'].content = "cashback offer, cashback, best cash back, topcashback, cashback app";
        return(
    			<div className="p-s-0">
    				<div className="various-cashback-types py-5 my-4 pb-s-0">
    					<Tabs id="cashback-type" animate={false} onChange={this.handleTabChange} selectedTabId={this.state.activeTab}>
    						<Tab id="scb" title="UPV Cashback" panel={<UPV/>} />
                <Tab id="fpv" title="FPV Cashback" panel={<FPV/>} />
    					</Tabs>
    				</div>
    			</div>
        );
    }
}
