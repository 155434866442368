import React, { Component } from 'react';
import { Card } from "@blueprintjs/core";

import ButtonData from '../buttonSegment';
import ButtonDetails from '../buttonDetails';
import Segment from './segment';
import { Helper } from 'gg-react-utilities';

export default class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActiveBtnID: 0,
            segmentID: null,
            isOpen: false,
            isActive: null
        };
    }

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <div className="container term_condition p-s-0">
                <div className="segment text-center py-5">
                    <h2 className="bp3-text-xlarge heading tc-prohead">Our Segments</h2>
                    <span className="tc-subprohead">How to use our Various Segments and get cashback*</span>
                    <div className='row my-5'>
                        <div className='col-xl-2 col-lg-2 col-md-3 col-sm-4 button-group tc-sidebar'>
                            {
                                ButtonData.buttonText.map((res, key) => {
                                    return (
                                        <a href={"#" + res.id} className={this.state.isActiveBtnID === key ? 'bp3-button bp3-fill bp3-active tc-type' : 'bp3-button bp3-fill tc-type'}
                                            key={key}
                                            onClick={() => {
                                                this.setState({
                                                    isActiveBtnID: key,
                                                    segmentID: key
                                                })
                                            }}>{res.text}</a>
                                    )
                                })
                            }
                        </div>
                        <div className='col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12 offset-xl-1 offset-lg-1 offset-md-1'>
                            {ButtonDetails.data.map((res, key) => {
                                return (

                                    <Segment res={res} key={key} />

                                );
                            })}
                        </div>
                    </div>
                    <div className="terms text-center">
                        <h1 className="bp3-text-xlarge heading ">Terms of use</h1>
                        <span className="heading-desc">LAST UPDATED ON 11-03-2022</span>
                        <Card className='col-12 col-xl-12 mt-4 p-5 p-s-0'>
                            <p>
                                These Website Terms Of Use (<b>“Terms Of Use”</b> or <b>“Terms”</b>) Is An Electronic Record In The Form Of An Electronic Contract Formed Under Information Technology
                      Act, 2000 And Rules Made Thereunder And The Amended Provisions Pertaining To Electronic Documents /Records In Various Statutes As Amended By
                      The Information Technology Act, 2000. These Terms Of Use Do Not Require Any Physical, Electronic Or Digital Signature.<br /><br />

                      These Terms Of Use Is A Legally Binding Document Between The User And Global Garner Sales Services Limited (Both Terms Defined Below).
                      These Terms Of Use Will Be Effective Upon Your Acceptance Of The Same (Directly Or Indirectly In Electronic Form Or By Means Of An Electronic Record)
                      And Will Govern The Relationship Between User And Global Garner (Defined Below) For The Use Of The Website To Purchase Product/S Service/S Available
                      Therein.<br /><br />

                      This Document Is Published And Shall Be Construed In Accordance With The Provisions Of Rule 3 (1) Of The Information Technology (Intermediaries
                      Guidelines) Rules, 2011 Under Information Technology Act, 2000 That Require Publishing The Rules And Regulations, Privacy Policy And User Terms For
                      Access Or Usage Of The Website.<br /><br />

                    These Terms Of Use Of The Website Located At The Url <a href="https://www.globalgarner.com" target="_blank" rel="noopener noreferrer">www.globalgarner.com</a> (https://www.globalgarner.com) And mobile application under the Name And Style “Global Garner” (The “Website” App) Is Between Global Garner Sales Services Limited (Hereinafter Referred To As <b>"Global Garner"</b> or <b>“We”</b> or <b>“Us”</b> or <b>“Our”</b> Or <b>“GG”</b>) And The Guest User Or Registered User Of The Website Hereinafter Referred To As <b>“You”</b> or <b>“Your”</b> or <b>“Yourself”</b> or <b>“Guest User”</b> Describe The Terms On Which Global Garner Offers You Access To The Website And Such Other Services As Are Incidental And Ancillary Thereto (<b>“Services”</b>).<br /><br />

                      Global Garner And You Are Hereinafter Individually Referred To As <b>“Party”</b> And Collectively As <b>“Parties”</b>, As The Context May Require.<br /><br /></p>

                            <p style={{ 'color': '#1A1A1A' }} className="font-weight-normal">
                                Please Read These Terms Of Use Carefully Before Using Or Registering On The Website Or Accessing Any Material, Information Or Services Through The
                      Website. If You Do Not Agree With These Terms Of Use, Please Do Not Use The Website/Mobile Application</p>

                            <p>
                                If You Are Accessing The Global Garner Mobile Application Or Accessing The Services Through The Global Garner Website Then While These Terms Of Use
                                Will Apply To Such Use, There May Be Additional Terms (Such As The Terms Imposed By Mobile Application Stores Like Apple’s App Store, Android’s Play
                                Store, Microsoft’s Store From Time To Time) Which Will Govern The Use Of The Mobile Application. These Additional Terms To The Extent Applicable Are
                      Hereby Deemed To Be Incorporated In These Terms Of Use By Way Of Reference.<br /><br />

                      This Agreement Sets Forth The Terms And Conditions That Apply To The Use Of The Site By The User. By Using This Site, The User Agrees To Comply With All
                      Of The T&C Hereof. The Right To Use The Site Is Personal To The User And Is Not Transferable To Any Other Person Or Entity. The User Shall Be Responsible
                      For Protecting The Confidentiality Of Their Password(S), If Any. The User Acknowledges That, Although The Internet Is Often A Secure Environment,
                      Sometimes There Are Interruptions In Service Or Events That Are Beyond The Control Of The Company, And The Company Shall Not Be Responsible For Any Data
                      Lost While Transmitting Information On The Internet. While It Is The Company’s Objective To Make The Site Accessible 24 Hours Per Day, 7 Days Per Week,
                      The Site May Be Unavailable From Time To Time For Any Reason Including, Without Limitation, Routine Maintenance, Due To Overload.<br /><br />

                      Cyber Attack, Bug In Code Etc. You Understand And Acknowledge That Due To Unavoidable Circumstances Both Within And Outside Of The Control Of The Company
                      , Access To The Site May Be Interrupted, Suspended Or Terminated From Time To Time. Data Loss May Occur Due To Various Reasons. On The Manifestation Of
                      The Data Loss Issue, We Might Put The Service Down In Order To Reconstruct The Data From The Back Up. The Company Shall Have The Right At Any Time To
                      Change Or Discontinue Any Aspect Or Feature Of The Site, Including, But Not Limited To, Content, Hours Of Availability And Equipment Needed For Access
                      Or Use. Further, The Company May Discontinue Disseminating Any Portion Of Information Or Category Of Information May Change Or Eliminate Any
                      Transmission Method And May Change Transmission Speeds Or Other Signal Characteristics.<br /><br />

                      Global Garner Mobile Application And Website Keeps A Track Of User Location To Give Better Location Based Services To Its Users. User Location Is Not
                      Stored Without The Consent Of The User And Is Never Shared With Any Third Party. We May Share, Use Some User Info For Better User Experience.<br /><br /></p>

                            <p><span style={{ 'color': '#1A1A1A' }} className="font-weight-normal">Website Segments:</span> Website Is Divided Into Various Major Segments<br /><br />

                      Global Garner Is Not Liable For Any Product Or Service You Buy From Our Platform, As Global Garner Is Providing Third Party Support To Its Vendor To
                      Reach Its Customer. We Are Aggregator Site Providing Sales Services And Marketing Support To The Vendors<br /><br />

                      UPV: User Purchase Value :Cumulative Purchases Of &#8377; 10000/From All The Segments Is Termed As UPV<br /><br />

                                <b>Abbreviations:</b>
                                <b>SCB: Systematic Cashback Process:</b><br /><br />
                                <b>SCB FIFO : Systematic Cashback Process-First In First Out</b><br /><br />
                                <b>SCB RPP: Systematic Cashback Process-Random Pick Process:</b><br /><br />
                                <b>IC: Instant Cashback</b>
                            </p>
                        </Card>
                    </div>

                    <div className="cashback-detail">
                        <br />
                        <br />
                        <br />
                        <h1 className="bp3-text-xlarge heading text-center">Various Segments</h1>
                        <Card className='col-12 col-xl-12 mt-4'>
                            <div className="row justify-content-center">
                                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 cashback">
                                    <p className="para-text">
                                        <b>DEFINITIONS
                                        AND INTERPRETATION</b></p>
                                    <p className="para-text">
                                        <b>a) UPV:User Purchase Value</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            The
                                            value of each UPV allotted is equivalent to &#8377; 10,000. Every
                                            purchase of any value made by the User will be allotted a number of
                                        UPVs in multiples of &#8377; 10,000.&nbsp;<br></br>Each multiple of &#8377;
                                        10,000 is considered to be an individual UPV and hence, for each&#8377;
                                        10,000 spent by the Purchaser, one UPV shall be allotted to them.</p></li>
                                        <li><p className="para-text">
                                            The
                                            Cash-back /reward will be given on the basis of each individual UPV
                                            as per the Cash-back Table through SCB FIFO. E.g. If the User makes
                                            a purchase of &#8377; 27,000 then the UPV allotted to him would be 2 of
                                            value&#8377; 10,000 each and the cash-back process on these two UPV
                                            shall commence as per the cash back structure. The excess amount of
                                            &#8377; 7,000 spent will remain in the account till the User makes
                                            further purchases of &#8377; 3,000 upon which, the amount spent would be
                                            sufficient to allot another UPV of&#8377; 10000 to the User to initiate
                                        cash-back.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>b) FPV : Fuel Purchase Value</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Global
                                            Garner will provide its users 100% Cashback/reward equivalent to
                                            Fuel amount on the purchase of GG water or GG co-branded water
                                            bottle. One FPV is generated on purchasing 1 litre packaged drinking
                                            bottle and scanning the QR available with it. The cashback/reward
                                            will be given in 3 installments as per Systematic Cashback (SCB
                                            FIFO/ FPV FIFO) Process or cashback/reward will be processed as per
                                        FPV RPP or IC as opted by the User</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <i><b>Note:</b></i><i>&nbsp;User
                                        is only concerned with generating his own UPV and FPV. Other UPVs or
                                        FPVs will be generated by the other users and arranged by the company
                                        as per Systematic cashback process(SCB FIFO). FPV Generation might
                                        take 24hrs to 48hrs.UPV generation depends upon other parameters like
                                    approval, commission, successful transaction etc.</i></p>
                                    <p className="para-text">
                                        Cashback
                                        We Provide is a Unique Combination of Vendor Commission and
                                        Advertisements Revenue We Source from Various Partners. GG  is solely
                                        responsible for the 100% cashback through SCB on the receipt of full
                                    commission from vendor/ mediator</p>
                                    <p className="para-text">
                                        Calculation
                                        of UPV and FPV will run simultaneously, it will not be merged
                                        together. Users don’t have to wait for FPV conversion , as soon as
                                        the user scans the QR code within 72 hours FPV will be allotted to
                                    the user and Cash back process will start as per SCB as given above.</p>
                                    <p className="para-text">
                                        <b>c)
                                        Our on-ground sales partner</b></p>
                                    <p className="para-text">
                                        Global
                                        Garner offers the opportunity to everyone on first cum first basis
                                    for long Term Business Association opportunity as a <span><b>Business
Partner </b></span><b>–</b><span><b>
                                            MCP </b></span><b>–</b><span><b>
                                            CP - ACP </b></span><b>–</b><span> <b>
                                            Partner </b></span>and
                                    as a Vendor.</p>
                                    <p className="para-text">
                                        <b>MCP:
                                        Master Channel Partner</b></p>
                                    <p >Master
                                    Channel Partner is a non-exclusive State Head and holds the entire
                                    state responsibility for creating, developing the Global Garner
                                    business opportunities. The MCP is the representative of the company
                                    in the State/ Region/ Territory/ City / Locality as head of the state
                                    and can recommend and Appoint CP and ACP and vendors after seeking
                                    approval from the company. MCP can sign the association agreement
                                    with CP,ACP and Vendors on company’s behalf after seeking written
                                    approval for Authorisation but cannot amend or edit any terms without
                                    company’s knowledge and approval. MCP can grow to become <b>Business
                                        Partner</b>
                                    and with same Job role but he can recruit his team PAN INIDA.</p>

                                    <p className="para-text">
                                        <b>CP
                                        : Channel Partner</b></p>
                                    <p >Channel
                                    Partner further is responsible for taking 10 Pin codes of his
                                    district and works as a non-exclusive head of sales in assigned pin
                                    codes. He is responsible for developing the business on all opted Pin
                                    codes through his ACP’s.
                                    The
                                    CP will be representative of the company in the State/ Region/
                                    Territory/ City / Locality and can recommend and Appoint ACP and
                                    vendors after seeking approval from MCP and company. CP can sign the
                                    association agreement with ACP and Vendors on company’s behalf
                                    after seeking written approval for Authorisation but cannot amend or
                                    edit any terms without company’s knowledge and approval.</p>

                                    <p className="para-text">
                                        <b>ACP
                                        : Assistant Channel Partner</b></p>
                                    <p className="para-text">
                                        <span>ACP
                                        will be the Exclusive Head of the particular pin code of Global
Garner business. </span>ACP
                                    can recommend/recruit/enrol  Vendors in his Pincode after
                                    consideration with CP and  after approval from Company as per
                                    Company’s discretion. ACP can also sell UPOS - The Digital Mall and other products of
                                    the Company. <span> LANG="en-US">ACP
can recruit unlimited Partners under him across India.</span></p>
                                    <p className="para-text">
                                        <span><b>PARTNER:</b></span>
                                        <span>Partner
                                        is going to be the appointed by ACP and Will report into ACP. Partner
                                        can be any person who is willing to sell UPOS - The Digital Mall and other Products &amp;
                                        Services of Global Garner.</span></p>

                                    <p className="para-text">
                                        <b>d)
                                    </b><b> VARIOUS
                                    CASHBACK OPTIONS</b><b>:</b></p>
                                    <p className="para-text">
                                        <b>i) Instant Cashback</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            It
                                            is a cashback/reward in which you can Shop and get guaranteed 1% to
                                            10% instant cashback without waiting to form an UPV or without
                                            worrying about new UPVs tagging under your purchases. Cashback will
                                            be processed instantly as soon as you make a purchase of any amount
                                            and this purchase will not be counted under SCB process. In this
                                            case, cashback /reward will be processed only after receipt of
                                            approval and commission from the vendor/mediator or any third party
                                            if involved. The duration of instant reward will vary from segment
                                            to segment . This may take 15 to 120 days.&nbsp;</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>You
                                        can choose from any of the four options (1,2,3.4). You are always
                                        allowed to change your cashback option anytime or during any
                                        purchase. But once chosen for any particular purchase, it cannot be
                                        changed. It is the responsibility of the user to verify his cashback
                                            option .</b></p>
                                    <p className="para-text">
                                        <b>ii)
                                                    SCB RPP: Random Pick Process</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            It
                                            is a Cashback/reward process in which cashback/reward will be
                                            processed through random selection of UPVs generated in a month.
                                            Cashback/reward will be processed monthly where qualifiers (users
                                            whose UPV has been generated) are arranged in a pool and then
                                            number of qualifiers and cashback percentage are decided as per
                                            above process called SCB but unlike SCB process of FIFO (First in
                                            and First Out), where the UPVs are given cashback as per tagging
                                            system as and when new UPVs are generated, in this process of SCB
                                            RPP the system will randomly pick the User for any given
                                            slab/amount of cashback but there will be a guarantee of everyone
                                            getting the cashback/reward from minimum 2% to maximum 100%. Amount
                                            of cashback /reward given to any user will be randomly decided by
                                            the system but the eligibility for the number of qualifiers under
                                            any given cashback slab will solely depends upon SCB process only.
                                            The user which comes first or which comes last into the system
                                            under this cashback has equal probably of getting any amount of
                                            cashback due to the shopping done by others. Then these UPVs will
                                            be out of the system of SCB tagging. The value of which can be
                                            changed anytime but cashback will depend upon new purchase done by
                                            the users one after the other. For eg. If 259 UPVs are generated in
                                            a prescribed time frame. Then as per SCB process, eligibility will
                                                    be as below:</p></li>
                                    </ul>
                                    <div className="table-responsive">
                                        <table className="bp3-html-table bp3-html-table-striped bp3-html-table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>100%</td>
                                                    <td>cashback to</td>
                                                    <td>1 User</td>
                                                </tr>
                                                <tr>
                                                    <td>15%</td>
                                                    <td>cashback to</td>
                                                    <td>6 Users</td>
                                                </tr>
                                                <tr>
                                                    <td>5%</td>
                                                    <td>cashback to</td>
                                                    <td>36 Users</td>
                                                </tr>
                                                <tr>
                                                    <td>2%</td>
                                                    <td>cashback to</td>
                                                    <td>216 users</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br></br>
                                    <p className="para-text">
                                        Note:
                                        2% reward is in the form of GG Gift Vouchers or any other vouchers
                                                only, no GG-money is given for 2% slab.</p>
                                    <p className="para-text">
                                        But
                                        this cashback/reward will be processed on random pick basis depending
                                        upon eligibility as above, where cash back amount will not depend
                                        upon actual new UPVs generated under any UPV. Amount will be randomly
                                        decided as per SCB criteria. Then these UPVs will be out of the
                                        system of SCB tagging. 100% cashback in the form of GG Coins is also
                                        given when UPVs are generated under RPP. However , GG coins will not
                                        be converted into GG Money in case of SCB Random Pick Process[RPP]
                                                (for any slab), though GG coins can be used for shopping.</p>
                                    <p className="para-text">
                                        <i>Note
                                        :For any cashback option: We can change value of UPV, cash back or
                                        number of users/purchases on which cash back is been given from time
                                        to time but cash back will always depend on new users joining the
                                        company and generating UPV, along with the marketing income and
                                        commission earned from the vendors, advertisements, enrolment fee
                                        etc. But cashback will always depend upon aggregated commission from
                                                    various segments. </i><i>Reward
                                        can be in the form of GG Money/GG Coins or any kind of reward or
                                                vouchers on the discretion and final decision of the company.</i></p>
                                    <p className="para-text">
                                        <b>iii)
                                        SCB FIFO: Systematic Cash Back First in First out</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            <b>SCB
                                            FIFO IS A SYSTEMATIC CASHBACK PROCESS IN WHICH CASHBACK /REWARD IS
                                            GIVEN IN 3 UNEQUAL INSTALLMENTS OF &#8377; 500, &#8377; 1000, &#8377; 8500 EACH AS
                                            PER UNIQUE AUTO TAGGING SYSTEM, WHERE USER HAS TO DO NOTHING EXCEPT
                                            FOR GENERATING HIS OWN UPVs BY TRANSACTING THROUGH US FOR HIS DAILY
                                            NEEDS. THIS MONEY IS A REAL CASH WHICH CAN BE TRANSFERRED INTO YOUR
                                                BANK ACCOUNT.</b></p></li>
                                    </ul>
                                    <p className="para-text">
                                        Cash
                                        back /reward will start after user has made the cumulative purchase
                                        of &#8377; 10000/- through our app or website from different modules and
                                        uploaded his bills (in few cases auto tracked by the system ) and
                                        Vendor approves the purchase and pays the commission and then other
                                        new user (counted globally under his UPV) doing the same is tagged
                                        under the user’s UPV as per our copyrighted process, explained
                                    below:</p>
                                    <p className="para-text">
                                        <b>SCB
                                        FIFO Explained: Systematic Cash Back First In First Out</b><br></br>Cash
                                    Back will start only after cumulative purchase of &#8377; 10,000/- and
                                    all other approvals. 100% Cashback (Through Systematic Cash Back
                                    process) will be given in 3 Installments to all users/customers as
                                    below. Under your UPV,</p>

                                    <ul>
                                        <li><p className="para-text">
                                            When
                                            6 New UPVs are created, you will get 5% of &#8377; 10000 i.e. &#8377; 500</p></li>
                                        <li><p className="para-text">
                                            When
                                            36 New UPVs are created, you will get 10% of &#8377; 10000 i.e.&#8377; 1000</p></li>
                                        <li><p className="para-text">
                                            When
                                            216 New UPVs are created, you will get 85% of &#8377; 10000 i.e. &#8377;
                                            8500</p></li>
                                        <li><p className="para-text">
                                            When
                                            1296 New UPVs are created, you will get additional 100% of &#8377;
                                            10000 by conversion of GG coins to GG Money</p></li>
                                    </ul>

                                    <p className="para-text">
                                        <b>Total
                                        &#8377; 20000/-</b><br></br><br></br>Cash
                                    back/reward will start after an UPV is generated and thereafter new
                                    user (counted globally under your UPV) doing the same and after
                                    receipt of commission and approval Cash Back/reward will start
                                    through Systematic cashback tagging process. Please note that the
                                    user is only concerned with generating his own UPV. Other UPVs will
                                    be generated by the other users all over the world and arranged by
                                    the system/company as per SCB process. (FIFO or RPP as opted by the
                                    user)</p>
                                    <p className="para-text">
                                        <i><b>Note:
                                        Cashback/Reward will only be given on generation of new UPVs if no
                                        new UPVs are generated then no cashback/Reward will be given. GG coin
                                        conversion will be maximum &#8377; 10000 with each UPV depending upon GG
                                        coins available in the GG Rewards. You hereby accept that you have
                                        read the contents carefully and understand that 100% cashback/reward
                                        will only be given on generation of new UPVs. No timeline for the
                                        cashback has been promised by the Company or any of its vendor or
                                        representative..</b></i>
                                    </p>
                                    <p className="para-text">
                                        AS
                                        A PROMOTIONAL OFFER INSTANTLY GET GG Gift VoucherS OR ANY OTHER VOUCHER
                                    WORTH &#8377; 200 AND &#8377; 10000/- AS GG COINS ON UPV GENERATION</p>


                                    <p className="para-text">
                                        <b>iv)
                                        My Circle</b></p>
                                    <ul>
                                        <li>

                                            <p className="para-text">
                                                It is a cashback in which the User will be rewarded by referring new users to generate new UPVs under him from his own social
                                                circle. It is unlike other cashback options in which UPVs will be tagged globally. In this cashback, UPVs will be tagged within
                                                a circle formed by a user by generating his UPV at the beginning, thereafter referring 6 UPVs under his UPV , thus  creating his
                                      own circle and completing the first Referral Circle.<br /><br />

                                      Then these 6 UPVs thus formed will further refer 6 UPVs to his family and friends under each UPV of first Referral circle creating
                                       further 36 UPVs, thereby creating the second level of Referral Circle.<br /><br />

                                      Further these 6 UPVs referring other 6 UPVs under each UPV of their previous referral Circle and so on till it reaches the maximum
                                      level of 9 circles , totalling 12093235 UPVs in a particular circle and giving &#8377; 2100000/- as a GG Money cashback and
                                      &#8377; 50 lakh GG coin conversion to the first UPV. Then this will keep continuing so on and so forth and other UPVs will keep
                                      forming or referring new UPVs and further creating 9 referral Circle under them to get the above rewards.<br /><br />

                                       In this, My circle cashback user has to refer his family and friends to further refer 6 more people / UPV in that circle and as when these
                                       referred people creates a UPV, it will be tagged under UPV, who has referred it. Each referred UPV has to refer 6 new UPVs and so on till 9
                                       levels are reached for each and every UPV and this Circle which keep on continuing separately like other circles in the system. Therefore, several
                                       circles are formed in a single circle and the creator is benefitted till 9 referral circles for his initial UPV.<br /><br />

                                      My circle cashback entirely depends upon new UPVs referred, not new Users in specific. New Users can also be referred but calculations are entirely based
                                      on UPVs. There can be as many numbers of Circle in the system. One user can create any number of circles and refer in any Circle<br /><br />
                                                <div className="table-responsive">
                                                    <table className="bp3-html-table bp3-html-table-bordered">
                                                        <thead>
                                                            <th className='text-center' width='150'>My Circle</th>
                                                            <th className='text-center'>UPVs</th>
                                                            <th className='text-center' width='250'>GG Money Cashback</th>
                                                            <th width='500'></th>
                                                        </thead>
                                                        <tbody className='text-center'>
                                                            <tr>
                                                                <td>Referral Circle 1</td>
                                                                <td>6</td>
                                                                <td>{Helper.formatMoney(500, true, false)}</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Referral Circle 2</td>
                                                                <td>36</td>
                                                                <td>{Helper.formatMoney(1000, true, false)}</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Referral Circle 3</td>
                                                                <td>216</td>
                                                                <td>{Helper.formatMoney(3500, true, false)}</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Referral Circle 4</td>
                                                                <td>1296</td>
                                                                <td>{Helper.formatMoney(5000, true, false)}</td>
                                                                <td className='text-left'>Plus 10K GG Coins Conversion to GG Money</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Referral Circle 5</td>
                                                                <td>7776</td>
                                                                <td>{Helper.formatMoney(18000, true, false)}</td>
                                                                <td className='text-left'>Plus 36K GG Coins Conversion to GG Money</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Referral Circle 6</td>
                                                                <td>46656</td>
                                                                <td>{Helper.formatMoney(50000, true, false)}</td>
                                                                <td className='text-left'>Plus 1 Lakh GG Coins Conversion to GG Money</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Referral Circle 7</td>
                                                                <td>279936</td>
                                                                <td>{Helper.formatMoney(150000, true, false)}</td>
                                                                <td className='text-left'>Plus 3 Lakh GG Coins Conversion to GG Money</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Referral Circle 8</td>
                                                                <td>1679616</td>
                                                                <td>{Helper.formatMoney(672000, true, false)}</td>
                                                                <td className='text-left'>Plus 15 Lakh GG Coins Conversion to GG Money</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Referral Circle 9</td>
                                                                <td>10077696</td>
                                                                <td>{Helper.formatMoney(2100000, true, false)}</td>
                                                                <td className='text-left'>Plus 50 Lakh GG Coins Conversion to GG Money</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br />
                                                <br />
                                      Cashback will be given after the completion of each referral circle, i.e after 6 referrals are completed under each UPV of the previous circle. GG Coin
                                      conversion will be only after Referral Circle 3 , ie from 4th circle not before that. Subject to the availability of GG Coins in your GG Rewards.<br /><br />

                                      Referral circle 1 completes on 6 new referrals under 1st UPVs of the initial creator of the circle<br />
                                      Referral circle 2 completes on 6 new referrals under each  6   (6*6=36)UPVs of Circle 1<br />
                                      Referral circle 3 completes on 6 new referrals under each  36  (36*6=216)UPVs of Circle 2<br />
                                      Referral circle 4 completes on 6 new referrals under each  216 (216*6=1296) UPVs of Circle 3<br />
                                      Referral circle 5 completes on 6 new referrals under each  1296  (1296*6=7776) UPVs of Circle 4<br />
                                      Referral circle 6 completes on 6 new referrals under each  7776  (7776*6=46656) UPVs of Circle 5<br />
                                      Referral circle 7 completes on 6 new referrals under each  46656 (46656* 6=279936)  UPVs of Circle 6<br />
                                      Referral circle 8 completes on 6 new referrals under each  279936  (279936*6 =1679616) UPVs of Circle 7<br />
                                      Referral circle 9 completes on 6 new referrals under each  1679616 (1679616 *6= 10077696)  UPVs of Circle 8<br />

                                      After completion of each Referral Circle GG Money will be credited in GG Rewards starting from &#8377; 500 to &#8377; 2100000/-
                                      <br />  <br />
                                      Once a user generates his parent UPV in My Circle he can create his own Circle by adding UPVs under his UPV.
                                        To add UPV, user has to ask his referrals to add their UPVs in his circle, under his parent UPV by selecting ADD IN CIRCLE option in front of the generated UPV from their App and  enter the  parent UPV no. under which referred user wishes to tag.
                                        <br /> <br />
                                                <b>It also means any UPV can select its parent UPV but for the child UPV, the child UPV has to add himself under a parent UPV.
                                                </b><br /> <br />
                                                <b>A UPVs can only be tagged if it is not tagged under any Parent UPV or no UPV is tagged below it.
                                                </b><br /> <br />
                                                Once the referral adds his UPV under the parent UPV of the User who has referred him, User can see his whole circle by clicking on the UPV number. <b>Referred UPV can never change its parent UPV i.e UPV under which it is tagged.</b>
                                                <br /> <br />
                                            If any UPV under the user is not performing ie it has not tagged any UPV under him, then parent UPV can REPLACE such UPV by clicking on that UPV id and selecting REPLACE UPV.
                                            <b>Such UPVs can only be replaced if no UPV is tagged under it, otherwise it cannot be replaced.
                                            UPVs can only be Replaced but not Removed</b>
                                                <br /> <br />
                                                User can also convert My circle UPV in FIFO, if he has not started his Circle yet or he has not been tagged under any other circle or any parent UPV by selecting Convert your UPV tab in front of the UPV.
                                                <b> Once any UPV is tagged under any UPV, it cannot be converted.</b>

                                            </p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>v)
                                        Buddy UPV Cashback</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            It
                                            is cashback/reward where you can get Shopping discount voucher worth
                                            &#8377; 200 as cashback/reward when your referred User generates an UPV.
                                            You don’t have to wait for referral cashback initiation process.
                                            You will get cashback instantly as soon as UPV is generated by your
                                            friend. So, don’t wait just refer your friends. It is a
                                            promotional offer, Company can change it any time Global Garner has
                                            full right to amend any process or guidelines mentioned here
                                        without necessarily informing.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <i>Important
                                        Note: Cashback/reward We Provide is a Unique Combination of Vendor
                                        Commission and Advertisements Revenue We Source from Various
                                        Partners. GG is solely responsible for the 100% cashback through SCB
                                        on the receipt of full commission and approval from vendor/mediator
                                        (This SCB process entirely depends upon new purchases done by the
                                        users and tagged under the previous purchases done by the other users
                                        as per SCB tagging algorithm. The company is liable to give cashback
                                        as per this algorithm only. Company will not be responsible if new
                                        purchases are not done or made under the existing/previous purchases
                                        as cashback is processed when new purchases are tagged under the
                                        existing purchases as per defined SCB algorithm.) Company will not be
                                        liable for any delay in cashback/reward. As a user you hereby confirm
                                        that the cashback/ reward timeline has not been committed by the
                                        Company and it may take longer time also.</i></p>
                                    <p className="para-text">FOR
                                    THESE CASHBACK/REWARDS USER DOES NOTHING EXCEPT TO OWN THROUGH US FOR
                                    HIS DAILY NEED ITEMS, BUT IF USER WISH TO EARN MORE CASHBACK THEN
                                    READ BELOW GIVEN POINTS:</p>
                                    <p className="para-text">
                                        <b>e)
                                        MAKE MY CART</b></p>
                                    <p className="para-text">
                                        If
                                        you don’t know how to place an order online, then send your request
                                        through our app or call our local representative. He will help you in
                                        placing the order. This feature enables you to place your order by
                                        just uploading your wish list written on a piece of paper, we will
                                        then forward this list to our local representative. User has to
                                        maintain the confidentiality of his passwords, credentials and other
                                        details, Global Garner is not liable for any misuse of user’s
                                        information by our local representative (MCP, CP, ACP). Global Garner
                                    is just providing support to its Users to make there online shopping.</p>
                                    <p className="para-text">By
                                    accepting these terms and conditions, you hereby confirm that you
                                    will not share any information or your personal details like password
                                    etc with them and you also confirm that you will not engage in any
                                    kind of activity or financial transaction with our local
                                    representative (MCP, CP, ACP) apart from help sought for order
                                    placement. Any transactions done between the User and the local
                                    representative will be dealt between them</p>
                                    <p className="para-text">
                                        <b>f)
                                        MISCELLANEOUS</b></p>
                                    <p className="para-text">
                                        <b>GG
                                        Relations</b>&nbsp;:
                                    Social Network</p>
                                    <p className="para-text">
                                        <b>Add
                                        Friends</b>&nbsp;:
                                    You and Your Friend both will get 25 GG-Coins Each. Send Friend
                                    request, once your friend request is accepted, both the users get 25
                                    GG-Coins Each</p>
                                    <p className="para-text">
                                        <b>Like
                                        and Share</b>&nbsp;:
                                    You and Your Friend both will get 1 GG-Coins Each When you like or
                                    share any content, you get 1 GG-Coin on each like and share and 1
                                    GG-Coin each to the person whose content is liked or shared.</p>
                                    <p className="para-text">
                                        <b>GG
                                        Nearby</b>&nbsp;:You
                                    and Your Friend both will get 50 GG- Coins Each Send friend request
                                    to other nearby “GG - Relations” users through their GPS
                                    location, once accepted both the users get 50 GG-Coins each</p>
                                    <p className="para-text">
                                        Max
                                        Extra Cashback Allowed per UPV is &#8377; 5000 from This Segment. Maximum
                                        Extra Cashback that can be converted into GG Money Allowed per UPV is
                                        &#8377; 5000 from This Segment. This will be credited as per company’s
                                        payment structure, subject to change on companies discretion time to
                                        time. All payments will be made after deducting the applicable taxes
                                        and charges as per company’s policy and government directive which
                                        may change from time to time .</p>
                                    <p className="para-text">
                                        <b>REFER
                                        AND EARN</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Refer
                                            a Friends &amp; Earn 50 GG - Coins Per Download &amp; Registration.
                                            The friend who downloads the app through your recommendation, also
                                            gets 50 GG - Coins . Now whenever referred user does a purchase and
                                            gets a cashback, 10% of the cash back received by him will go to
                                        referring user with no waiting and no upper limit.</p></li>
                                        <li><p className="para-text">
                                            Refer
                                            a Vendor and once the vendor is registered with GG, you get 2.75% of
                                        the commission received by the Company from the referred vendor.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>Max
                                        Extra Cashback Allowed Per UPV is &#8377; 5000 from This Segment. Maximum
                                        Extra Cashback that can be converted into GG Money Allowed per UPV is
                                        &#8377; 5000 from This Segment. This will be credited as per company’s
                                        payment structure, subject to change on companies discretion time to
                                        time. All payments will be made after deducting the applicable taxes
                                        and charges as per company’s policy and government directive which
                                        may change from time to time .</b></p>
                                    <p className="para-text">
                                        <i><b>IMPORTANT	NOTE:</b></i><i><br></br>Total
                                    Fix Cashback and Extra Cashback Allowed per UPV is &#8377; 20000 + 10% of
                                    the Cashback of Referred User’s Cashback + 2.75% of the referred
                                    Vendor commission received by the Company .</i></p>

                                    <p className="para-text">
                                        <b>g)
                                        WHAT IS GG COIN ?</b></p>
                                    <p className="para-text">
                                        <b>i)
                                        GG - Coin = &#8377; 1/-</b></p>
                                    <p className="para-text">
                                        <b>GG
                                        - Coins</b>&nbsp;can
                                    be redeemed with new purchases to the extent of 5% of the bill amount
                                    while paying to selected GG Vendors and GG- Coins which will remain
                                    in GG Rewards till your 4th Payout will be compulsorily converted
                                    into GG-Money and then user can encash it to his Bank account,  Up to
                                    the maximum limit of &#8377; 10000 in case of SCB FIFO. GG coins will not
                                    be converted into GG Money in case of SCB Random Pick Process(for any
                                    slab), though GG coins can be used for shopping. User gets 100%
                                    cashback as GG Coins instantly on generation on each and every UPV as
                                    a promotional offer. The redemption of these coins can be made while
                                    paying to selected GG vendors by redeeming up to the extent of 5% of
                                    the bill amount. Company has full right to end this offer any time or
                                    change the terms or conditions therein. Company has full right to
                                    select the vendor on which GG coins can be redeemed.<br></br>GG coins can
                                    also be redeemed up to 80% (promotional campaign)on GG local brands.
                                    (No cashback is applicable on GG local brands. These GG local brands are the platform to make users utilise their GG Coins to the
                                    fullest.)</p>
                                    <p className="para-text">
                                        <b>ii)
                                    GG –Money</b></p>
                                    <p className="para-text">
                                        GG
                                        –Money is the real cashback , which can be transferred to the bank
                                        account. Cashback in the form of GG-money gets credited in GG
                                        rewards. User has to initiate withdrawal request by entering correct
                                        account details. It may take 15-20 days to get GG-Money credited in
                                        account. It is responsibility of the user to give correct account
                                    details in order to enable us to process the request.</p>
                                    <p className="para-text">
                                        Note:
                                        Company has full rights to give cashback/ reward in any form GG Coin
                                        or GG Money or vouchers, which may subject to vary from time to time
                                    as per Company’s discretion.</p>
                                    <p className="para-text">
                                        <b>OUR CONCEPT</b><b>:</b></p>
                                    <p className="para-text">
                                        As
                                        economy is moving but not giving any benefit to common people but
                                        with this concept every user who will pay or buy through us will be
                                        benefitted as we give 100% Cash Back /reward (Through Copyrighted
                                        Systematic Cash Back Process) through the movement of the economy
                                        (SCB FIFO). 2% to 100% in case SCB RPP is chosen or  1% to 10%
                                        instant cashback if Instant cashback option is chosen by the User.</p>
                                    <p className="para-text">
                                        This
                                        is very unique concept of getting 100% cashback/reward through SCB
                                        process where user is only concerned with his shopping of mandatory
                                        product and services, which till today was giving him nothing but
                                        today with us he gets 100% cashback /reward&nbsp;through
                                    Systematic Cash Back process by just <b>referring
                                        and buying product and services from the vendors listed with this
                                    concept</b>.</p>
                                    <p className="para-text">
                                        <b>CUSTOMERS/
                                        USERS BENEFIT</b>:
                                    Choice of All Best Brands and Services -&nbsp;<b>Global,
                                    National &amp; Regional</b>&nbsp;at
                                    Best Discounted Price and then&nbsp;up to <b>100%
                                    Cashback</b>&nbsp;(Through
                                    Systematic Cash Back process) on their spending’s with no upper
                                    limit from best sellers and service providers&nbsp;<b>both
                                    ONLINE AND OFFLINE/REGIONAL STORE.</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Our
                                            platform also helps consumers to bring their cost of living down, we
                                            do it by sharing our revenues (Sales Commission &amp; Advertisement
                                        Income Etc.) Through SCB with them to the extent of&nbsp;<b>100%
                                        cashback</b>&nbsp;of
                                        their spending’s done with the businesses associated with us.</p></li>
                                        <li><p className="para-text">
                                            All
                                        consumers get&nbsp; up to <b>100%
                                        Cashback</b>&nbsp;irrespective
                                        of the product/services they purchase and all cashback are processed
                                        through&nbsp;<b>SCB
                                        (Systematic Cash Back)or SCB RPP. 1% to 10% instant cashback&nbsp;</b>if
                                        Instant cashback option is selected by the User.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>VENDORS/
                                        BUSINESS/SELLER BENEFIT</b>:
                                    It’s a concept where we intend to provide free sales services to
                                    all types of businesses Small, Medium &amp; Large for their any type
                                    of sales requirements like Products sales, Franchising, Marketing
                                    Advertisement etc. <b>ALL
                                    AT ZERO UPFRONT COST</b></p>

                                    <ul>
                                        <li><p className="para-text">
                                            Vendor
                                            Only has to pay pre decided commission once we give them business
                                            else they need not to pay anything.</p></li>
                                        <li><p className="para-text">
                                            As
                                            we see today sometimes businesses hire sales force to increase
                                            their sale but if things are not going as per their plan they
                                            again sack the sales staff but now with our platform they will
                                            only have to pay when they gain business through us totally on
                                            Variable Cost so win-win situation for everyone.</p></li>
                                        <li><p className="para-text">
                                            Anyone
                                            can promote his business at&nbsp;<b>ZERO
                                            Capital Investment on Marketing, Advertisements and Sales
                                            Services</b>&nbsp;through
                                            the best in class sales representatives with Online and Offline
                                            Sales Support all present at local geographies around the world
                                            and has to pay commission only when we help him to gain business
                                            through us.</p></li>
                                        <li><p className="para-text">
                                            Gets&nbsp;<b>100%
                                            Free Marketing, Advertisements and Sales Services</b>&nbsp;to
                                            source New Customers and Retain them as minimum offer that they
                                            can give to their customers would be up to 100% Cashback from
                                            Global Garner&nbsp;<b>through
                                            SCB process or SCB RPP. 1% to 10% instant cashback.</b>&nbsp;if
                                            Instant cashback option is selected by the User.</p></li>
                                        <li><p className="para-text">
                                            We
                                            provide free advanced digital platform to them.
                                        </p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>ON-GROUND
                                        SALE FORCE BENEFIT:</b>&nbsp;Gets&nbsp;<b>Great
                                    Earnings</b>&nbsp;by
                                    promoting &amp; Selling Regional Vendors and Brands Associated with
                                    us.</p>
                                    <ul>
                                        <li><p className="para-text">
                                            It
                                            will give them a platform to create a Great Earning and freedom to
                                            work at their own allotted time and location of their choice, now
                                            they can help small business to grow without charging them any fee
                                        yet earn whenever anyone in their area spends.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        Though
                                        we accumulate different commission charges from different vendors yet
                                        our sales concept provides equal refund to all its user and vendors
                                        with no pre-cost. Given below are the segments of expenses where our
                                    users can spend and can get  up to 100% cashback/reward.</p>
                                    <p className="para-text">
                                        <b>Mandatory
                                        Monthly Expenses:</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Schools/Day
                                        Care/Tuition Fees</p></li>
                                        <li><p className="para-text">
                                            Medical<br></br>a.
                                        Doctor Fee<br></br>b. Monthly Medicines<br></br>c. Hospitalization<br></br>d.
                                        Laboratory Expenses</p></li>
                                        <li><p className="para-text">
                                            Groceries
                                        &amp; Vegetables</p></li>
                                        <li><p className="para-text">
                                            Fuel
                                        Expenses*</p></li>
                                        <li><p className="para-text">
                                            Clothing
                                        etc.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>Mandatory
                                        but occasional expenses</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Outdoor
                                        catering and banquets</p></li>
                                        <li><p className="para-text">
                                            Professional
                                        services<br></br>a. Lawyers<br></br>b. Doctors<br></br>c. Carpenter<br></br>d.
                                        Architect<br></br>e. Painting<br></br>f. Car Service<br></br>g. Any type of repair
                                        works<br></br>h. Broker Services<br></br>i. Investment Services<br></br>j. Advisory
                                        Services<br></br>k. Security Services<br></br>l. Packers and Movers<br></br>m.
                                        Repair and Maintenance Services<br></br>n. All other expenses listed on
                                        the website which has our license</p></li>
                                        <li><p className="para-text">
                                            Real
                                        estate</p></li>
                                        <li><p className="para-text">
                                            Mobile</p></li>
                                        <li><p className="para-text">
                                            Electronics</p></li>
                                        <li><p className="para-text">
                                            Kitchen
                                        Articles &amp; Furniture</p></li>
                                        <li><p className="para-text">
                                            Automobiles
                                        and Accessories etc.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>Occasional
                                        yet lifestyle expenses</b></p>
                                    <ol>
                                        <li><p className="para-text">
                                            Franchising
                                        Fee</p></li>
                                        <li><p className="para-text">
                                            Marketing</p></li>
                                        <li><p className="para-text">
                                            Training
                                        Fee</p></li>
                                        <li><p className="para-text">
                                            Rent</p></li>
                                        <li><p className="para-text">
                                            Salary</p></li>
                                        <li><p className="para-text">
                                            Infrastructure</p></li>
                                        <li><p className="para-text">
                                            Job
                                        Portals Enrolment Fee</p></li>
                                        <li><p className="para-text">
                                            Advisory
                                        Expenses etc.</p></li>
                                    </ol>
                                    <p className="para-text">
                                        Accumulation
                                        of all the above expenses user has to spend minimum &#8377; 10000/- in a
                                        year for getting eligible for 100% cash back (Through Systematic Cash
                                    Back process).{/*<strike>Even
                                    brands which will advertise themselves with us will be eligible for
                                    guaranteed number of customer or 100% cash back (Through Systematic
                                    Cash Back process). </strike>*/}Cash
                                    back will start after user has made the cumulative purchase of &#8377;
                                    10000/- (termed as UPV) through our app or website from different
                                    modules and uploaded his bills (in few cases auto tracked by the
                                    system ) and Vendor approves the purchase and pays the commission and
                                    then other new user (counted globally under his UPV) doing the same
                                    is tagged under the user’s UPV as per our copyrighted process, as
                                    explained below:</p>
                                    <p className="para-text">
                                        Processed
                                    as given below:</p>
                                    <div className="table-responsive">
                                        <table className="bp3-html-table bp3-html-table-striped bp3-html-table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>AFTER 6</td>
                                                    <td>NEW UPV</td>
                                                    <td>5% CASH BACK WILL BE PAID</td>
                                                </tr>
                                                <tr>
                                                    <td>AFTER 36</td>
                                                    <td>NEW UPV</td>
                                                    <td>10% CASH BACK WILL BE PAID</td>
                                                </tr>
                                                <tr>
                                                    <td>AFTER 216</td>
                                                    <td>NEW UPV</td>
                                                    <td>85% CASH BACK WILL BE PAID</td>
                                                </tr>
                                                <tr>
                                                    <td>AFTER 1296</td>
                                                    <td>NEW UPV</td>
                                                    <td>ADDITIONAL 100% CASH BACK WILL BE PAID</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br></br>
                                    <p className="para-text">
                                        <b>THEREFORE,
                                        100% CASHBACK (Through Systematic Cash Back process ) will be paid.
                                        In Random Pick Process UPVs will be randomly picked for different
                                        slabs and in case if IC, 1% to 10% instant cashback as per option
                                        selected by the User</b></p>
                                    <p className="para-text">
                                        Note:
                                        We can change value of cashback/reward or value of UPV or number of
                                        users on which cash back is been given from time to time but cash
                                        back will always depend on new users joining the company and
                                        generating UPV, along with aggregation of marketing income and
                                        commission earned from the vendors, advertisements, enrolment fee
                                        etc. Cashback/reward is given by aggregation the commission received
                                        by the Company , so any value can be changed but cashback will always
                                        depend upon new users coming and aggregation of commission received
                                    from the vendors.</p>
                                    <p className="para-text">
                                        <b>HOW
                                        CONCEPT WORKS</b><b>?</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            User
                                            will download the mobile application (app) or register on website,
                                            and start using it, they can either purchase or refer their purchase
                                            through our mobile app or website from the above mentioned
                                        segments.&nbsp;<br></br>It doesn’t matter what purchase user is doing
                                        to claim cash back i.e. If he is doing his grocery purchase from us
                                        and the next user coming after him is doing purchase of a car or a
                                        real estate or any of the other services or product stated above he
                                        will still get cash back on his purchase and all purchase value code
                                        would be calculated in multiple of &#8377; 10000/- or any other
                                        denomination as decided by the company.</p></li>
                                        <li><p className="para-text">
                                            E.g.
                                            1st user has purchased &#8377; 10000/- groceries and the next user
                                            purchases a car worth &#8377; 1000000/- then the purchase value code
                                            created by the next user would be 100 and the 1st UPV (User) will
                                        get his cash back accordingly.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        As
                                        monthly mandatory expenses cannot be hold and everyone has to incur
                                        it therefore everyone is keen to have some type of rebate or discount
                                        or cashback on it. It will surely penetrate the market very soon and
                                        as number of users on app will increase we will start giving cashback
                                        which will immediately boost our business, as now more and more user
                                        will come on the platform and simultaneously when more user will come
                                        on board , shopping through the portal will increase and then cash
                                    back will start following with a great pace.</p>
                                    <p className="para-text">
                                        <b>USP
                                        OF THE CONCEPT</b><br></br>Major
                                    commercial transaction would be between the vendor and the user and
                                    app/website would be used by the user only to refer the user’s
                                    purchases and get the cash back/reward.</p>
                                    <p className="para-text">
                                        <b>Cash
                                        back /reward would be managed through our aggregated commission
                                        generated from vendors on new user joining and generating the UPV +
                                        advertisement sourced + enrolment fee.</b></p>
                                    <p className="para-text">
                                        <b>Our
                                        charges:</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            <b>Registration
                                            fee</b>&nbsp;:
                                        Free for All Users</p></li>
                                        <li><p className="para-text">
                                            <b>Bank
                                            Transfer Charge</b>&nbsp;:
                                        10% of the conversion amount. Company can change this percentage
                                        from time to time.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>Key
                                        feature:</b>&nbsp;Best
                                    part of getting cashback is that we count the purchase amount only,
                                    not the product. I.e. When you buy grocery for your home but if
                                    someone buys a car for himself still you get eligible for cash back.
                                    Only purchases in multiples of &#8377; 10000 will be considered for cash
                                    back</p>
                                    <p className="para-text">
                                        <b>Process</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Once
                                            user will upload his purchase, the same purchase will be forwarded
                                            to the vendor and after his approval the eligible cash back amount
                                        and timeline  would start reflecting on his dashboard</p></li>
                                        <li><p className="para-text">
                                            In
                                            few modules purchased will be auto tracked by the system eg, best
                                            shopping website (except few), mega brands, bill pay and recharge
                                        etc</p></li>
                                        <li><p className="para-text">
                                            We
                                            will appoint channel partners in each city and these channel
                                            partners will be responsible for the new vendor tie up and all other
                                            activities as asked by the company. They will act as a local
                                        representative of the particular pincode</p></li>
                                        <li><p className="para-text">
                                            The
                                            Cash Back/reward which we process is a unique combination of revenue
                                        we generate from vendors and advertisement sourced on our platform.</p></li>
                                        <li><p className="para-text">
                                            All
                                            purchases are converted into UPVs i.e. User Purchase Value and Cash
                                            Back is provided on UPVs generated on our Platform (in multiples of
                                        &#8377; 10000/-)</p></li>
                                        <li><p className="para-text">
                                            Example
                                            : You Purchased a Grocery worth &#8377; 6000 and Medicines worth &#8377; 4000
                                            then the UPV generated by you is 1 same way if someone purchases a
                                            Flat worth &#8377; 50,00,000/- Then UPV Generated by him is 500 on which
                                            each UPV will Give him 100% Cash Back (through systematic cash back
                                        process).</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <i><b>Important
                                        Point to Note:</b></i><i>&nbsp;Though
                                        we generate different revenue for different vendors on different
                                        products yet we give all our users a unique and equal cash back of
                                        100% (Through Systematic Cash Back process).Now Cashback will follow
                                        as per SCB when new UPVs are assigned under your UPV in case of SCB
                                        FIFO .or 2% to 100% cashback in a month through SCB RPP or 2% to 10%
                                    through IC. Depending upon the cashback option chosen by the user</i></p>
                                    <p className="para-text">
                                        <b>NO
                                        UPPER LIMIT OF CASHBACK</b></p>
                                    <p className="para-text">
                                        One
                                        can get Cash Back of any amount ranging from 10000 to 1 Crore or
                                        Above. User has nothing to do and the platform is totally FREE for
                                        all its USER to enrol. All Users/UPVs are automatically arranged by
                                    the company.</p>
                                    <p className="para-text">
                                        <i>Important
                                        Note: All UPVs are generated automatically and Users have nothing to
                                        do for UPV as One User’s UPV might be from Mumbai and other can be
                                        from Kolkata and next can be from Bangalore etc. Every time any user
                                        spends &#8377; 10000 anywhere in the world a new UPV is added to the
                                        Cashback System i.e. SCB All UPV will get eligible for 100% Cash back
                                        (Through Systematic Cash Back process) on First Come First Serve
                                        Basis only.(Sometimes this sequence may be hindered due to technical
                                        glitches beyond our control or any other error beyond our control)</i></p>
                                    <p className="para-text">
                                        <b>Buy
                                        Anything From Global Garner REGISTERED VENDOR And Get 2% to 100%
                                        Guaranteed Cashback/ reward through SCB RPP. (If the user can wait
                                        more get 100% cashback/reward Through Systematic Cash Back process)
                                        With No Upper Limit* as per SCB FIFO explained above.</b></p>
                                    <p className="para-text">
                                        <b>Please
                                        note that, user is only concerned for generating his own UPV. Other
                                        UPVs will be generated by the other users all over the world and
                                        arranged by the company as per SCB process. Or through SCB RPP, if
                                        opted by User. 1% to 10% instant cashback if Instant cashback option
                                        is selected by the User</b></p>
                                    <p className="para-text">
                                        <b>Cashback/reward
                                        through SCB is credited as GG Money in GG Rewards which can be
                                        transferred in the bank account. While 2% reward in case of RPP is in
                                        the form of some vouchers , not GG Money. User is solely responsible
                                        for initiating the withdrawal request and for providing the correct
                                        banking details in order us to facilitate the payment.</b></p>
                                    <p className="para-text">
                                        <b>GG
                                        FUEL CASHBACK: PANI PE PETROL KA CASHBACK</b></p>
                                    <p className="para-text">
                                        One
                                    FPV = One Litre of GG /GG Co-branded Packaged drinking water<br></br>1
                                    Litre of GG/GG Co-branded water Bottle will give you 2% to 100%
                                    cashback (Through Systematic Cash Back process) of 1 Litre Fuel for
                                    your Vehicle depending upon the category of fuel chosen.</p>
                                    <p className="para-text">
                                        <b>Process:</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Buy
                                            1 Litre of GG /GG Co-branded packaged drinking water available at
                                        the nearby store  or on the water trolley</p></li>
                                        <li><p className="para-text">
                                            Register
                                        your vehicle details</p></li>
                                        <li><p className="para-text">
                                            Scan
                                        the barcode given on/with the bottle by GG app</p></li>
                                        <li><p className="para-text">
                                            FPV
                                        generated</p></li>
                                        <li><p className="para-text">
                                            Now
                                        you are eligible for 1 Litre of fuel cashback</p></li>
                                        <li><p className="para-text">
                                            You
                                            will receive Cashback of the fuel price, for the category of fuel
                                        you have registered with us.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        FPV
                                        is termed as Fuel Purchase Value. Every Litre of packaged drinking
                                        water purchased would be allotted a FPV.. Barcode will be on the
                                        water bottle or may be separately given. One FPV is generated on the
                                        purchase of a 1-litre water bottle, there is no accumulation of any
                                    further purchases&nbsp;</p>
                                    <p className="para-text">
                                        Fuel
                                    cashback is processed through 3 types of cashback</p>
                                    <p className="para-text">
                                        <span>FPV
                                        Instant Cashback/ Reward :Get Instant Cashback of ₹5/- or Shopping
                                        discount vouchers worth &#8377; 100/- or any other reward as soon as you
buy a water bottle and generate FPV.</span></p>
                                    <p className="para-text">
                                        <span>FPV
                                        Random Pick Process : System will randomly select the user and
                                        cashback percentage generated in a month. Qualifiers are arranged in
                                        a pool and system will randomly select the FPV and cashback
                                        percentage. Unlike the above process of FIFO, RPP users will not have
                                        to wait for their turn, they are randomly selected &amp; Paid in
months’ time. </span>
                                    </p>
                                    <p className="para-text">
                                        <span>FPV
                                        First In First Out: A User who generates the FPV first will get the
                                        Cashback in 3 installments when FPVs are tagged as per SCB tagging
                                        system along with conversion of GG Coins into GG Money. Get 2% of the
                                        fuel amount as Instant Cashback along with 100% Cashback in next 3
installments as per FPV FIFO process.</span></p>
                                    <p className="para-text">
                                        E.g.:
                                        you have registered a petrol vehicle with us, then your cashback will
                                    follow as given below (Assuming Petrol Price: &#8377; 80)</p>
                                    <ul>
                                        <li><p className="para-text">
                                            Under
                                        your FPV,</p></li>
                                        <li><p className="para-text">
                                            6
                                        New FPVs are created, you will get 5% of petrol price i.e. &#8377; 4</p></li>
                                        <li><p className="para-text">
                                            When
                                        36 New FPVs are created, you will get 10% of petrol price i.e. &#8377; 8</p></li>
                                        <li><p className="para-text">
                                            When
                                            216 New FPVs are created, you will get 85% of petrol price i.e. &#8377;
                                        68</p></li>
                                        <li><p className="para-text">
                                            When
                                            1296 New FPVs are created, you will again get 100% of petrol price
                                        i.e. &#8377; 80</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>Please
                                        note that, user is only concerned for generating his own FPV. Other
                                        FPVs will be generated by the other users and arranged by the company
                                        as per SCB FPV Generation might take 24hrs to 72 hrs. FPV value will
                                        depend on the basis of which fuel you are using in your registered
                                        vehicle viz petrol, diesel, CNG. Petrol / Diesel/ CNG price subject
                                        to vary from time to time. FPV FIFO Cashback will depend upon the
                                        fuel type selected by the users.</b></p>
                                    <p className="para-text">
                                        <b>Cashback/reward
                                        through FPV is credited as GG Money in GG Rewards which can be
                                        transferred in the bank account, except for Instant cashback, if
                                        GG Gift Voucher is chosen. `User is solely responsible for initiating
                                        the withdrawal request and for providing the correct banking details
                                        in order us to facilitate the payment.</b></p>

                                    <p className="para-text">
                                        <b>Benefits
                                        of Vendor/Seller: (100% Free Platform)</b></p>
                                    <p className="para-text">
                                        Register
                                        yourself with Global Garner and become Authorized Vendor to give 2%
                                        to 100% guaranteed cashback/reward through SCB RPP or 2% to 10%
                                        instant cashback through IC OR 100% Cashback (Through Systematic Cash
                                    Back process.</p>
                                    <p className="para-text">
                                        s)
                                    with No Upper Limit* to all your customers, IF users can wait more.</p>
                                    <ul>
                                        <li><p className="para-text">
                                            100%
                                        Free Marketing &amp; Free Advertisement by Global Garner.</p></li>
                                        <li><p className="para-text">
                                            100%
                                        Free Shop Listing by Global Garner.</p></li>
                                        <li><p className="para-text">
                                            Only
                                        have to pay sales commission once the customer does the purchase.</p></li>
                                        <li><p className="para-text">
                                            Free
                                        Payment Gateway (If required by Vendor)</p></li>
                                        <li><p className="para-text">
                                            Delivery
                                        Services (If required by vendor)</p></li>
                                        <li><p className="para-text">
                                            COD
                                        (If required by vendor)</p></li>
                                        <li><p className="para-text">
                                            Full
                                            training support from our Local Representative (*Terms &amp;
                                        Conditions Apply)</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <i><b>Important
                                            Points:</b></i><i><br></br>@The
                                        vendor gets exclusivity right of 1 Kilometre radius or any other
                                        area as per the decision of the Company, only when 50% business of
                                        the vendor is routed through Global Garner platform. If the vendor
                                        fails to do so, Exclusivity will be cancelled automatically. These
                                        customers will come to you because they will get 2 % to 100%
                                        Guaranteed Cashback from Global Garner or 100% cashback (through SCB
                                        FIFO) if they can wait, which is the best offer available on the
                                        planet. (as per vendor agreement terms and conditions of exclusivity
                                        clause)</i></p>

                                    <p className="para-text">
                                        <b>Example
                                        to consider:</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Let
                                            us assume only 1000 customers live in the 1 km area which we have
                                            allotted to you, now initially there was no guarantee that these
                                            customers will come to you, but now there is much better chance of
                                        getting maximum customers in your area.</p></li>
                                        <li><p className="para-text">
                                            Let
                                            us assume you are having a Grocery Shop. Till date you were doing a
                                            business of 2 lacs but now only 1000 customers will buy &#8377; 10000/-
                                            inventory from you, then your turnover would be &#8377; 10000000/-, so if
                                            your total margin is 10% and you are sharing 5% with us then you
                                        will be earning &#8377; 5 lacs in a month.</p></li>
                                        <li><p className="para-text">
                                            Your
                                        distributor will increase your margins due to your business volume.</p></li>
                                        <li><p className="para-text">
                                            Still
                                        your commission to us will remain the same.</p></li>
                                        <li><p className="para-text">
                                            Also
                                            please note above said is only for illustration purpose actual
                                        figures may vary.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <i><b>Important
                                        Note:</b></i><i>&nbsp;We
                                        reserve all the rights to change any term as per our discretion.
                                        Above mentioned example is only for illustration purpose, actual
                                    figures may vary.</i></p>
                                    <p className="para-text">
                                        <b>Terms
                                        of becoming a Vendor:</b><br></br>Register
                                    yourself with Global Garner and become an authorized Vendor
                                    Requirements, also guided by separate terms:</p>
                                    <ul>
                                        <li><p className="para-text">
                                            Bank
                                        A/C mandatory</p></li>
                                        <li><p className="para-text">
                                            One
                                        dedicated staff with basic computer knowledge for GG accounting</p></li>
                                        <li><p className="para-text">
                                            Shop
                                        and establishment registration mandatory for shop owners.</p></li>
                                        <li><p className="para-text">
                                            Service
                                        providers should mandatorily have PAN</p></li>
                                        <li><p className="para-text">
                                            Willing
                                            to expand, if business sourced by GG is adequate for expansion, to
                                        provide best services to the customers</p></li>
                                        <li><p className="para-text">
                                            Should
                                        be ready to place small marketing board of GG at his business</p></li>
                                        <li><p className="para-text">
                                            Should
                                        give bills with GG barcode to all GG users<br></br>List of required
                                        documents for company/firm/shop/individual given below:</p></li>
                                        <li><p className="para-text">
                                            Incorporation
                                        certificate (for company/firms)</p></li>
                                        <li><p className="para-text">
                                            Shop
                                        &amp; establishment certificate (for shops)</p></li>
                                        <li><p className="para-text">
                                            Pan
                                        card (of company/firm/shop/individual)</p></li>
                                        <li><p className="para-text">
                                            Pan
                                        card of authorized signatory (for company /firm/shop)</p></li>
                                        <li><p className="para-text">
                                            Letter
                                        or board resolution for authorized signatory (for company/firm)</p></li>
                                        <li><p className="para-text">
                                            Id
                                        proof (for company /firm/shop/individual)</p></li>
                                        <li><p className="para-text">
                                            Address
                                        proof (for company /firm/shop)</p></li>
                                        <li><p className="para-text">
                                            Cancel
                                        cheque</p></li>
                                        <li><p className="para-text">
                                            NACH
                                        form dully signed (for commission)</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>Benefits
                                        of MCP/CP/ACP: As per Company’s policy, which may change from time
                                        to time., guided by the separate agreement</b></p>
                                    <p className="para-text">
                                        <i><b>Important
                                        Note: We reserve all the rights to change any term as per our
                                        discretion. Above mentioned example is only for illustration purpose,
                                        actual figures may vary.</b></i></p>
                                    <p className="para-text">
                                        <b>GENERAL</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            GLOBAL
                                            GARNER SALES SERVICES LIMITED is a company incorporated
                                            under the Indian Companies Act, 2013, having its registered office
                                            at 5th floor, Grand Emporio, Motera Stadium Road, Ahmedabad, Gujarat 380005,
                                            and is an intermediary in the form
                                            of an online marketplace and is limited to managing the Website to
                                            enable Vendors to exhibit, advertise, display, make available and
                                            offer to sell their Product/s and to enable You to purchase the
                                            Product/s so offered (“Product/s”), and other incidental
                                            Services thereto (“Services”) including use of the Website by
                                            You. The Company further has developed a unique concept where it
                                            offers cash-back options to You and its Vendors, Channel Partners
                                            and Assistant Channel Partner in accordance with the provisions of
                                        Cash-back Annexure herein attached.</p></li>
                                        <li><p className="para-text">
                                            These
                                            Terms of Use are subject to revision by GLOBAL GARNER at any time
                                            without notice and hence You are requested to carefully read these
                                            Terms of Use from time to time before using the Website. The revised
                                            Terms of Use shall be made available on the Website. You are
                                            requested to regularly visit the Website to view the most current
                                            Terms of Use. In the event such a facility is provided on the
                                            Website, You can determine when GLOBAL GARNER last modified any part
                                            of the Terms by referring to the “Last Updated” legend provided
                                            in that document. It shall be Your responsibility to check these
                                            Terms of Use periodically for changes. GLOBAL GARNER may require You
                                            to provide Your direct or indirect consent to any update in a
                                            specified manner before further use of the Website and the Services.
                                            If no such separate consent is sought, Your continued use of the
                                            Website and/or Services, following such changes, will constitute
                                            Your acceptance of those changes. The whole process is copyrighted
                                            property of Global Garner Sales Services Limited and copy of
                                            the concept in any form will deemed as illegal and will initiate
                                        legal action</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>SERVICES</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            The
                                            Website is an electronic platform in the form of an electronic
                                            marketplace and an intermediary that (a) provides a platform for
                                            Sellers (who are Vendors) to advertise, exhibit, make available and
                                            offer to sell various Product/s to other Users (You/the Buyers), and
                                            (b) provides a platform for such other Users to accept the offer of
                                            sale of the Product/s made by the Vendors on the Website and/or in
                                            their physical stores from a unique login-id provided by the Company
                                            and to make payments to the Vendors for purchase of the Product/s
                                            approved by them, and © provides services to facilitate your
                                            engagement with Vendors to under commerce on the Website, and (d)
                                            provides such other services as are incidental and ancillary
                                            thereto. The Services are offered to the Users through various modes
                                            which may include issue of coupons and vouchers that can be redeemed
                                        for various Product/s.</p></li>
                                        <li><p className="para-text">
                                            For
                                            the entire Duration, the Website shall be maintained by the Company.
                                            The ownership of the Website shall vest with the Company and the
                                            Company shall make its best efforts to deal with any technical
                                            issues affecting the Website (such as, for instance, the Website
                                        becoming inoperative).</p></li>
                                        <li><p className="para-text">
                                            The
                                            Company does not warrant that You will be able to use the Website
                                            and purchase Product/s for sale at all times or locations on the
                                            Website or that the Website and the services provided through the
                                            Website will be uninterrupted or error-free or that the defects will
                                        be corrected by The Company.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>ELIGIBILITY
                                            TO USE</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            The
                                            Services are not available to minors under the age of eighteen (18)
                                            or to any Users suspended or removed from the GLOBAL GARNER system
                                            by GLOBAL GARNER for any reason whatsoever. If You are disqualified
                                            as per the preceding sentence, You shall not be permitted to avail
                                            the Services or use the Website. You represent that You are of legal
                                            age to form a binding contract and are not a person barred from
                                            receiving the Services under the laws as applicable in India.
                                            Notwithstanding the foregoing, if You are below the age of eighteen
                                            (18) years, please read through these Terms of Use with Your parent
                                            or legal guardian, and in such a case these Terms of Use shall be
                                            deemed to be a contract between GLOBAL GARNER and Your legal
                                            guardian or parent and to the extent permissible under applicable
                                        laws, enforceable against You.</p></li>
                                        <li><p className="para-text">
                                            GLOBAL
                                            GARNER reserves the right to refuse access to use the Services
                                            offered at the Website to new Users or to terminate access granted
                                            to existing Users at any time without according any reasons for
                                        doing so.</p></li>
                                        <li><p className="para-text">
                                            You
                                            shall not have more than one active Account (defined hereunder) on
                                            the Website. Additionally, You are prohibited from selling, trading,
                                        or otherwise transferring Your unique login-id to another person.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>USER
                                            ACCOUNT AND REGISTRATION</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            You
                                            can access and use the Website and the Services only as a registered
                                        user.</p></li>
                                        <li><p className="para-text">
                                            To
                                            become a registered User ,You need to create Your Account on the
                                            Website using your true credentials, including your name, mobile
                                            number, address ,bank details and other required information. You
                                        can register using your&nbsp;<b>Aadhar
                                        number or PAN number.</b>&nbsp;By
                                        registering on website, you undertake that the information provided
                                        by you is true and correct to the best of your knowledge. These
                                        information and credentials are individually and collectively
                                        referred to the&nbsp;<b>“Account
                                        Information”)</b>.
                                        If you provide any information that is untrue or inaccurate or
                                        obsolete , Global garner has the right to suspend or terminate your
                                        registration and refuse to give you user benefits.</p></li>
                                        <li><p className="para-text">
                                            In
                                            the event You register as a User by creating an Account in order to
                                            avail the Services provided by the Company through its website, You
                                            will be responsible for maintaining the confidentiality and security
                                            of the Account Information, and are fully responsible for all
                                            activities that occur under Your Account. You agree to (a)
                                            immediately notify GLOBAL GARNER of any unauthorized use of Your
                                            Account Information or any other breach of security, and (b) ensure
                                            that You exit from Your Account at the end of each session. GLOBAL
                                            GARNER cannot and will not be liable for any loss or damage arising
                                            from Your failure to comply with this section. You may be held
                                            liable for losses incurred by GLOBAL GARNER or any other user of or
                                            visitor to the Website due to authorized or unauthorized use of Your
                                            Account as a result of Your failure in keeping Your Account
                                        Information secure and confidential.</p></li>
                                        <li><p className="para-text">
                                            You
                                            shall ensure that the Account Information provided by You in the
                                            Website’s registration form is complete, accurate and up-to-date.
                                            You also represent that You shall provide to the Company information
                                            about Yourself such as name, contact details, email address, bank
                                            account details, PAN No., Service Tax Registration, and other
                                            compliance related details through the User Registration Form and
                                            that such information is true and correct as on date. Use of another
                                            User’s Account Information for availing the Services is expressly
                                        prohibited.</p></li>
                                        <li><p className="para-text">
                                            If
                                            You provide any information that is untrue, inaccurate, not current
                                            or incomplete (or becomes untrue, inaccurate, not current or
                                            incomplete), or GLOBAL GARNER has reasonable grounds to suspect that
                                            such information is untrue, inaccurate, not current or incomplete,
                                            GLOBAL GARNER has the right to suspend or terminate Your Account and
                                            refuse any and all current or future use of the Website /Services
                                        (or any portion thereof).</p></li>
                                        <li><p className="para-text">
                                            Depending
                                            upon the nature of Services You wish to avail from the Website, You
                                            may be required to provide certain personal information and GLOBAL
                                            GARNER may collect certain personal information. Your provision of,
                                            and GLOBAL GARNER’s collection, storage, use, disclosure and
                                            otherwise dealing of such personal information shall be governed by
                                        GLOBAL GARNER 's privacy policy</p></li>
                                        <li><p className="para-text">
                                            As
                                            a part of the registration process, You hereby state that You have
                                            completed the User Registration Form and have provided other
                                            relevant details as required by the Company. Both Users, guest and
                                            registered shall have the option to accept or decline to accept
                                            these Terms. Please provide acceptance to these Terms if and only if
                                        You are agreeable to the provisions provided herein.</p></li>
                                        <li><p className="para-text">
                                            If
                                            You are not agreeable to the provisions of these Terms, please
                                            decline by clicking on the appropriate box. By declining these
                                            Terms, you agree to continue as a Guest User with limited access to
                                        the website and this will stop the registration process.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>PAYMENTS
                                            RELATED INFORMATION</b></p>

                                    <ul>
                                        <li><p className="para-text">
                                            Presently,
                                            GLOBAL GARNER does not levy any fee for browsing the Website or
                                            buying the Product/s on the Website. GLOBAL GARNER may charge from
                                            users certain fees for the use of the Website and Services as a
                                            whole, or certain features of the Website / Services. You agree to
                                            pay any such fees, as may be applicable to the Services that You
                                            use. GLOBAL GARNER will try to ensure that You are made aware of the
                                            applicability of any fees for a particular use of the Website
                                            /Services, as well as the amount of fees payable by You for any such
                                            use of the Website /Service. You agree that GLOBAL GARNER may, at
                                            any time, charge, modify or waive fees required to use the Website.
                                            Your continued use of the Website /Services after such change in the
                                            fees will be considered to be Your acceptance of such changes, and
                                        the applicability of these Terms of Use to such changes.</p></li>
                                        <li><p className="para-text">
                                            You
                                            agree to provide correct and accurate financial information, such as
                                            credit/debit card details to the approved payment gateway or
                                            pre-paid payment instrument account details for availing Services on
                                            the Website. You shall not use the credit/debit card or pre-paid
                                            payment instrument which is not lawfully owned by You, i.e. In any
                                            transaction, You must use Your own credit/debit card or pre-paid
                                            instrument account. The information provided by You will not be
                                            utilized or shared with any third party unless required in relation
                                            to fraud verifications or by law, regulation or court order or in
                                            accordance with the terms of the Privacy Policy. You will be solely
                                            responsible for the security and confidentiality of Your
                                            credit/debit card details or pre-paid instrument account. GLOBAL
                                            GARNER expressly disclaims all liabilities that may arise as a
                                            consequence of any unauthorized use of Your credit/debit card or
                                        pre-paid instrument account.</p></li>
                                        <li><p className="para-text">
                                            For
                                            Your making payments for buying the Product/s on the Website, in
                                            addition to the Terms, the terms and conditions of Your bank,
                                            applicable financial institution and/or card issuing association may
                                            also be applicable to You. Your bank, financial institution or card
                                            issuing association may decline or prevent You from making
                                            electronic payments for buying the Product/s on Website and GLOBAL
                                        GARNER does not control the same and shall be liable for the same.</p></li>
                                        <li><p className="para-text">
                                            Payment
                                            Facility for Your orders: GLOBAL GARNER may from time to time
                                            contract with third party payment service providers including banks
                                            to open nodal bank account under applicable Indian laws, to
                                            facilitate the payment between Users i.e. You and Vendors and for
                                            collection of GLOBAL GARNER 's fees and other charges. These third
                                            party payment service providers may include third party banking or
                                            credit card payment gateways, payment aggregators, prepaid
                                            instruments, cash on delivery or demand draft /pay order on delivery
                                            service providers, mobile payment service providers or through any
                                            facility as may be authorized by the Reserve Bank of India for
                                            collection, refund and remittance, as the case may be of payment or
                                            supporting the same in any manner. If the online Payment Gateway is
                                            used, You must bear all additional applicable charges (payment
                                            gateway charges of 2.5% of the Selling Price) and any other charges
                                            like convenience charges etc. as would be applicable and payable to
                                            the Third Party. All the refunds etc for the failed transactions
                                        will be processed by them once we initiate them from our end</p></li>
                                        <li><p className="para-text">
                                            The
                                            Selling Price in respect of the Product/s purchased by You shall be
                                            received in full by the Vendor either online through the Payment
                                            Gateway offered on the Website, or online directly by way of spot
                                            payment or Cash-On-Delivery, as per the Vendor’s own payment
                                        collection policy.</p></li>
                                        <li><p className="para-text">
                                            You
                                            would be eligible for cash-back on Your Purchases on the website and
                                            in the physical stores of the Vendor/s of the website. The Vendor
                                            approves of the Purchases made by You and then only after the GLOBAL
                                            GARNER receives the Commission due to it as described in the online
                                            Commission Grid and other charges levied upon the Vendor, shall you
                                            be eligible for cash-back. Cash-back will be administered only as
                                            per the process described in the SYSTEMATIC CASHBACK(SCB) Annexure
                                            attached to these terms . It is further clarified that you shall be
                                            only liable for the cash back when Global Garner receives its
                                            commission from its vendor for purchase through website or mobile
                                            App of Global Garner. Also you can get cash back through Regional
                                            vendors once they approve you bill for cashback and give us our
                                            commission to process the cashback. You have to upload bills on App
                                            /Website that you have received from vendor after clearing your
                                            Bill. Thereafter upon receiving your uploaded bill, your bill is
                                            sent to vendor for his approval/verification and then finally after
                                            his approval and receiving our commission we will start your
                                        cashback process through SCB.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>USER
                                            OBLIGATIONS AND ROLE OF GLOBAL GARNER</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Subject
                                            to compliance with the Terms, GLOBAL GARNER grants You a
                                            non-exclusive, non-sub-licensable, non-transferable, revocable, and
                                            limited right to access and use this Website and the Services
                                            provided therein. All orders placed on the Website are covered under
                                            the “100% Cash-back” programme through SCB. GLOBAL GARNER shall
                                            ensure that the Vendor agrees to fulfil the commitments made under
                                            the above mentioned program. The said programme grants You cash-back
                                            rewards for regularly purchasing Product/s using the Website. Though
                                            till we don’t receive the approval and the commission which is
                                            agreed by the Seller/Vendor on the product which is purchased by you
                                            and submitted for Cashback we would be unable to process the
                                        cashback.</p></li>
                                        <li><p className="para-text">
                                            You
                                            agree to use the Services, Website/App and the Content (as de ned
                                            herein) provided therein only for purposes that are permitted by:
                                        (a) the Terms; and (b) any applicable law.</p></li>
                                        <li><p className="para-text">
                                            You
                                            agree to adhere to all limitations on dissemination, use and
                                            reproduction of any materials (such as the Product catalogues) that
                                            Your access on the Website in accordance with Article 8 (use of
                                        content).</p></li>
                                        <li><p className="para-text">
                                            You
                                            agree not to access (or attempt to access) the Website or Services
                                            by any means other than through the interfaces that are provided by
                                        GLOBAL GARNER</p></li>
                                        <li><p className="para-text">
                                            If
                                            You believe that any Content or User Content (as defined herein) or
                                            other content available on the Website does not comply with the
                                            terms of the Terms(s) or applicable law, You may report such content
                                        in the manner prescribed under Article 8 herein.</p></li>
                                        <li><p className="para-text">
                                            The
                                            Website may allow You to post certain content, data or information
                                            of Your own such as allowing You to rate Product/s, Vendor, post
                                            Your comments, and reviews in relation to Product/s on specified
                                            pages of the Website, as well as submit any suggestions, comments,
                                            questions or other information to GLOBAL GARNER using the Website
                                            /Services (collectively referred to “User Content”). You, being
                                            the originator of the User Content, are solely responsible for the
                                            User Content that You upload, post, publish, transmit or otherwise
                                            make available on the Website. You represent that You have obtained
                                            all relevant consents and approvals in order to post any User
                                            Content. You further represent that all such User Content will be in
                                            accordance with applicable law. You acknowledge that GLOBAL GARNER
                                            does not endorse any User Content on the Website and is not
                                            responsible or liable for any User Content. GLOBAL GARNER reserve
                                        the right to disable access to the User Content on the Website.</p></li>
                                        <li><p className="para-text">
                                            You
                                            hereby grant GLOBAL GARNER a perpetual, non-revocable, worldwide,
                                            royalty-free and sub-licensable right and license to use, copy,
                                            distribute, display, publish, transmit, make available, reproduce,
                                            modify, adapt the User Content and create derivate works of the User
                                            Content. You represent and warrant that You own or otherwise control
                                            all of the rights to the User Content that You post or that You
                                            otherwise provide on or through the Website; and that, as at the
                                            date that the User Content is posted or submitted on the Website:
                                            (i) the User Content is accurate; (ii) use of the User Content You
                                            supply does not breach these Terms of Use; and (iii) that such User
                                        Content is lawful.</p></li>
                                        <li><p className="para-text">
                                            You
                                            agree, understand and acknowledge that the Website is an electronic
                                            platform in the form of an electronic marketplace and an
                                            intermediary that provides an electronic venue where various Users
                                            may electronically meet and interact with each other to engage in
                                            commerce and transact. You further agree and acknowledge that GLOBAL
                                            GARNER is only a facilitator and is not and cannot be a party to or
                                            control in any manner any advertisement, exhibition, making
                                            available, offer to sell or transactions of sale or purchase of
                                            Product/s on the Website. GLOBAL GARNER is not the Vendor of the
                                            Product/s. accordingly, any contract for the sale /purchase of
                                            Product/s on the Website is a bipartite contract between You and the
                                            Vendors (if You are a You) or You and the You (if You are a Vendor).
                                            GLOBAL GARNER neither recommends You to buy or sell any Product/s on
                                            the Website nor does GLOBAL GARNER endorses any such Product/s and
                                            nor does GLOBAL GARNER provides any guarantee, warranties or
                                            assurance with respect to the advertisement, exhibition, making
                                            available, offer to sell or transactions of sale or purchase of
                                            Product/s on the Website. Further, GLOBAL GARNER does not guarantee,
                                            warranty or provide any assurance on the behaviour of any User of
                                            the Website including any guarantee, warranty or assurance that any
                                            User (whether You or Vendor) will complete any transaction or act in
                                        a prudent manner.</p></li>
                                        <li><p className="para-text">
                                            When
                                            You use the Website and/or the Services You specifically undertake
                                            not to host, display, upload, modify, publish, transmit, update or
                                        share any information or Content that:</p></li>
                                        <ul>
                                            <li><p className="para-text">
                                                Belongs
                                            to another person and to which the User does not have any right to;</p></li>
                                            <li><p className="para-text">
                                                Is
                                                grossly harmful, harassing, blasphemous, defamatory, obscene,
                                                pornographic, paedophilic, libellous, invasive of another’s
                                                privacy, hateful, or racially, ethnically objectionable,
                                                disparaging, relating or encouraging money laundering or gambling,
                                            or otherwise unlawful in any manner whatsoever;</p></li>
                                            <li><p className="para-text">
                                                Harms
                                            minors in any way;</p></li>
                                            <li><p className="para-text">
                                                Impersonate
                                                any person or entity, or falsely state or otherwise misrepresent
                                            Your affiliation with a person or entity;</p></li>
                                            <li><p className="para-text">
                                                Threatens
                                                the unity, integrity, defence, security or sovereignty of India,
                                                friendly relations with foreign states or public order or causes
                                                incitement to the commission of any cognizable offence or prevents
                                            investigation of any offence or is insulting of any other nation;</p></li>
                                            <li><p className="para-text">
                                                Infringes
                                            any patent, trademark, copyright or other proprietary rights;</p></li>
                                            <li><p className="para-text">
                                                Deceives
                                                or misleads the addressee about the origin of such messages or
                                                communicates any information which is grossly offensive or menacing
                                            in nature;</p></li>
                                            <li><p className="para-text">
                                                Engage
                                                in any activity that interferes with or disrupts access to the
                                                Website or the Services (or the servers and networks which are
                                            connected to the Website);</p></li>
                                            <li><p className="para-text">
                                                Attempt
                                                to gain unauthorized access to any portion or feature of the
                                                Website, any other systems or networks connected to the Website, to
                                                any GLOBAL GARNER server, or to any of the Services offered on or
                                                through the Website, by hacking, password mining or any other
                                            illegitimate means;</p></li>
                                            <li><p className="para-text">
                                                Probe,
                                                scan or test the vulnerability of the Website or any network
                                                connected to the Website, nor breach the security or authentication
                                                measures on the Website or any network connected to the Website.
                                                You may not reverse look-up, trace or seek to trace any information
                                                on any other user, of or visitor to, the Website, or any other
                                                customer of GLOBAL GARNER , including any GLOBAL GARNER Account not
                                                owned by You, to its source, or exploit the Website or Service or
                                                information made available or offered by or through the Website, in
                                                any way whether or not the purpose is to reveal any information,
                                                including but not limited to personal identification information,
                                            other than Your own information, as provided for by the Website;</p></li>
                                            <li><p className="para-text">
                                                Disrupt
                                                or interfere with the security of, or otherwise cause harm to, the
                                                Website, systems resources, accounts, passwords, servers or
                                                networks connected to or accessible through the Websites or any
                                            affiliated or linked sites;</p></li>
                                            <li><p className="para-text">
                                                Use
                                                the Website or Content for any purpose that is unlawful or
                                                prohibited by the Terms, or to solicit the performance of any
                                                illegal activity or other activity which infringes the rights of
                                            GLOBAL GARNER or other third parties;</p></li>
                                            <li><p className="para-text">
                                                Falsify
                                                or delete any author attributions, legal or other proper notices or
                                                proprietary designations or labels of the origin or source of
                                            software or other material contained in a file that is uploaded;</p></li>
                                            <li><p className="para-text">
                                                Violate
                                                any code of conduct or other guidelines, which may be applicable
                                            for or to any particular Service;</p></li>
                                            <li><p className="para-text">
                                                Violate
                                                any applicable laws or regulations for the time being in force
                                            within or outside India;</p></li>
                                            <li><p className="para-text">
                                                Violate
                                            the terms of the Terms.</p></li>

                                            <li><p className="para-text">
                                                You
                                                agree that You are solely responsible to GLOBAL GARNER and to any
                                                third party for any breach of Your obligations under these Terms of
                                                Use and for the consequences (including any loss or damage which
                                                GLOBAL GARNER or its affiliates or its vendors may suffer) for any
                                            such breach.</p></li>
                                            <li><p className="para-text">
                                                GLOBAL
                                                GARNER does not pre-screen User Content. GLOBAL GARNER has no
                                                obligation to monitor any User Content. However, GLOBAL GARNER at
                                                its discretion and/or in accordance with applicable law may monitor
                                                any User Content and may remove any User Content from the Website if
                                                GLOBAL GARNER determines in its sole discretion that such User
                                                Content is in violation of these Terms of Use or any applicable law.
                                                Where GLOBAL GARNER removes any User Content from the Website,
                                                GLOBAL GARNER will make reasonable efforts to inform the user who
                                                had posted such User Content. Such actions do not in manner negate
                                                or dilute GLOBAL GARNER 's position as an intermediary or impose any
                                            liability on GLOBAL GARNER with respect to User Content.</p></li>
                                            <li><p className="para-text">
                                                You
                                                shall solely be responsible for maintaining the necessary computer
                                                /mobile equipment, internet connections and other software and
                                                technologies that may be required to access, use and transact on the
                                                Website. You may incur access or data fees from third parties in
                                                connection with Your purchase and/or use of the services. You are
                                            responsible for all such fees.</p></li>
                                            <li><p className="para-text">
                                                You
                                                shall use this Website, and any voucher/coupons purchased through
                                                it, for personal, non-commercial use only and shall not re-sell the
                                            same to any other person.</p></li>
                                            <li><p className="para-text">
                                                You
                                                may need to install updates that we or any third party introduce
                                                from time to time to use the services, Product/s, Website including
                                                downloads and required functionality, such as bug fixes, patches,
                                                enhanced functions, missing plug-ins and new versions
                                            (collectively,&nbsp;<b>“Updates”</b>).
                                            Your use of the Product/s or Website You have installed requires
                                            that You have agreed to receive such automatically requested
                                            Updates.</p></li>
                                            <li><p className="para-text">
                                                Any
                                                purchase of the Product/s from the Website will be strictly for
                                                personal use of the User. The User hereby expressly agrees that any
                                                merchandize or Services purchased by the User will not be sold,
                                                resold, bartered or in any way used for any commercial purposes or
                                                for profit. The User hereby acknowledges that the Product/s
                                            purchased are not transferable to any third party for profit.</p></li>
                                            <li><p className="para-text">
                                                In
                                                case You purchases multiple Product/s in one transaction, the
                                                Vendor(s) may deliver all such Product/s together. However, this may
                                                not always be possible. If You purchases multiple Product/s in a
                                                single transaction, then all the Product/s would be dispatched to a
                                                single delivery address provided by You at the time of purchase. If
                                                You wishes to get delivery of the Product/s to different addresses,
                                                then You should purchase the Product/s under separate transactions
                                                and provide separate delivery addresses for each transaction, as may
                                                be required. You agree that the delivery of the Product/s can be
                                                made to the person who is present at the shipping address provided
                                            by You.</p></li>
                                        </ul>
                                    </ul>
                                    <p className="para-text">
                                        <b>TRANSFER
                                            OF PRODUCT/S OWNERSHIP AND CONSUMER RIGHTS</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            The
                                            ownership of the Product/s purchased will be transferred to You
                                            after successful delivery of the same at the destination provided by
                                            You, until which the ownership of the Product/s shall vest with the
                                            Vendor alone. Any damage in transit on account of
                                            inadequate/unsuitable packaging will be attributable only to the
                                            Vendor. The Company shall not be responsible for any unsatisfactory
                                            or delayed performance of the Vendor including delays as a result of
                                            the Product/s being out of stock. In case if delivery of goods
                                            delayed due to unavoidable circumstances, the company shall try to
                                        contact the vendor for urgent delivery.</p></li>
                                        <li><p className="para-text">
                                            The
                                            Vendor will offer standard manufacturer’s warranty actually
                                            associated with the Product/s and shall so issue a suitable, duly
                                            stamped, manufacturer’s warranty card to You with the Product/s at
                                            the time of dispatch of the Product/s. However, You agree that
                                            repair, replacement or refund of money (subject to necessary
                                            deductions) will be given to You against manufacturing defect or
                                            damage. You further agree and acknowledge that the primary and sole
                                            responsibility for the redressal of Your complaints will rest solely
                                            with the Vendor at all times. However, in the event, that You
                                            contact GLOBAL GARNER for the same, GLOBAL GARNER may inform the
                                            Vendor to provide or facilitate the provision of such services to
                                            the You. GLOBAL GARNER is not and shall not be obliged to provide
                                            any such additional services. GLOBAL GARNER’s role in relation to
                                            facilitating such services shall be limited to facilitating
                                            communication between You and the Vendor for the purpose of
                                        provision of such additional services.</p></li>
                                        <li><p className="para-text">
                                            That
                                            the address at which delivery of the product ordered by you, is to
                                        be provided correct and proper in all respects.</p></li>
                                        <li><p className="para-text">
                                            That
                                            in the event that a non-delivery occurs on account of a mistake by
                                            you (i.e. Wrong name or wrong address or any other wrong
                                            information) any extra cost incurred by Vendor for redelivery shall
                                        be claimed from you.</p></li>
                                        <li><p className="para-text">
                                            That
                                            before placing an order you are required to check the product
                                            description carefully at all times. By placing an order for a
                                            product you agree to be bound by the conditions of sale included in
                                            the item’s description, geographical limitation of warranty and
                                            notifications of voltage incompatibility in case of
                                        electronic/electrical products.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>USE
                                            OF CONTENT</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Except
                                            as expressly indicated to the contrary, GLOBAL GARNER hereby grants
                                            You a non-exclusive, revocable and non-transferable right to view,
                                            download and print product catalogues available on the Website,
                                        subject to the following conditions:</p></li>
                                        <ul>
                                            <li><p className="para-text">
                                                You
                                                may access and use the product catalogues solely for personal,
                                                informational, and internal purposes, in accordance with these
                                            Terms of Use;</p></li>
                                            <li><p className="para-text">
                                                You
                                                may not modify or alter product catalogues available on the
                                            Website;</p></li>
                                            <li><p className="para-text">
                                                You
                                                may not distribute or sell, rent, lease, license or otherwise make
                                                the product catalogues available on the Website available to
                                            others; and</p></li>
                                            <li><p className="para-text">
                                                You
                                                may not remove any text, copyright or other proprietary notices
                                            contained in the product catalogues available on the Website.</p></li>
                                        </ul>
                                        <li><p className="para-text">
                                            The
                                            rights granted to You in the product catalogues or any other
                                            materials as specified above are not applicable to the design,
                                            layout or look and feel of the Website. Such elements of the Website
                                            are protected by intellectual property rights and may not be copied
                                        or imitated in whole or in part.</p></li>
                                        <li><p className="para-text">
                                            Any
                                            software that is available on the Website is the property of GLOBAL
                                            GARNER or its vendors. You may not use, download or install any
                                            software available at the Website, unless otherwise expressly
                                            permitted by the Terms or by the express written permission of
                                        GLOBAL GARNER.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>INTELLECTUAL
                                            PROPERTY RIGHTS</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            The
                                            Website and the processes, and their selection and arrangement,
                                            including but not limited to all text, graphics, user interfaces,
                                            visual interfaces, sounds and music (if any), artwork and computer
                                            code (collectively, the “Content”) on the Website is owned and
                                            controlled by GLOBAL GARNER and the design, structure, selection,
                                            coordination, expression, look and feel and arrangement of such
                                            Content is protected by copyright, patent and trademark laws, and
                                            various other intellectual property rights. Through Your use of the
                                            Website, by no means are any rights impliedly or expressly granted
                                            to You in respect of such Content. GLOBAL GARNER reserves the right
                                            to change or modify the Content from time to time at its sole
                                        discretion.</p></li>
                                        <li><p className="para-text">
                                            The
                                            trademarks, logos and service marks displayed on the
                                        Website&nbsp;<b>(“Marks”)</b>&nbsp;are
                                        the property of GLOBAL GARNER or their Vendor/s or respective third
                                        parties. You are not permitted to use the Marks without the prior
                                        consent of GLOBAL GARNER, the Vendor/s or the third party that may
                                        own the Marks.</p></li>
                                        <li><p className="para-text">
                                            Unless
                                            otherwise indicated or anything contained to the contrary or any
                                            proprietary material owned by a third party and so expressly
                                            mentioned, GLOBAL GARNER owns all intellectual property rights to
                                            and into the trademark “Global Garner”, and the Website,
                                            including, without limitation, any and all rights, title and
                                            interest in and to copyright, related rights, patents, utility
                                            models, designs, know-how, trade secrets and inventions (patent
                                            pending), goodwill, source code, meta tags, databases, text,
                                        content, graphics, icons, and hyperlinks.</p></li>
                                        <li><p className="para-text">
                                            Except
                                            as expressly provided herein, You acknowledge and agree that You
                                            shall not copy, republish, post, display, translate, transmit,
                                            reproduce or distribute any Content through any medium without
                                            obtaining the necessary authorization from GLOBAL GARNER or thirty
                                        party owner of such Content.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>THIRD
                                            PARTY CONTENT</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            General
                                            third party information such as, product catalogues, product
                                            description and specifications, lists of dealers, reports on news,
                                            entertainment, technology and features, advertisements including
                                            videos, images and photographs of the Product/s, links to third
                                            party websites and other data from external sources is made
                                            available on the Website (“Third Party Content”). The provision
                                            of Third Party Content is for general informational purposes only.
                                            You acknowledge that the Third Party Content provided to You is
                                            obtained from sources believed to be reliable or provided by the
                                            Vendors in the process of advertising, exhibiting and offering to
                                            sell the Product/s on the Website. All Third Party Content is
                                            provided on an ‘As Is’ basis. GLOBAL GARNER may not own /have
                                            the rights and title to any such Third Party Content, or provide any
                                            guarantee with respect to the accuracy, title, merchantability,
                                            non-infringement or fitness for a particular purpose of any Third
                                            Party Content. GLOBAL GARNER shall not be held liable for any loss
                                            suffered by You based on Your reliance on or use of such Third Party
                                        Content.</p></li>
                                        <li><p className="para-text">
                                            In
                                            the event any Third Party Content contains links to third party
                                            websites, and You visit any such external link, You agree to do so
                                            at Your own risk, responsibility and liability. GLOBAL GARNER makes
                                            no warranty or representation regarding, and does not endorse, any
                                            website linked to the Website /Services or the information appearing
                                        thereon or any of the Product/s or services described thereon.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>DISCLAIMER
                                            OF WARRANTIES &amp; LIABILITY</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            The
                                            website, services, content, user content and any third party content
                                            are provided by GLOBALGARNER on an “As is” basis without
                                            warranty of any kind, express, implied, statutory or otherwise,
                                            including the implied warranties of title, non-infringement,
                                            merchantability or fitness for a particular purpose. Without
                                            limiting the foregoing, GLOBAL GARNER makes no warranty that (II)
                                            the results that may be obtained from the use of the website or
                                            services will be effective, accurate or reliable; (III) the quality
                                            of the website or services will meet your expectations; or that (IV)
                                            any errors or defects in the website or services will be corrected.
                                            No advice or information, whether oral or written obtained by you
                                            from GLOBAL GARNER or through the website from use of the services
                                            shall create any warranty not expressly stated in these terms of
                                        use.</p></li>
                                        <li><p className="para-text">
                                            To
                                            the maximum extent permitted by applicable law, GLOBAL GARNER will
                                            have no liability related to user content and/or third party content
                                            arising under Intellectual Property Rights, Label, Privacy,
                                            Publicity, Obscenity or other Laws. Global Garner Also disclaims all
                                            liability with respect to the misuse, loss, modification or
                                        unavailability of any user content and/or third party content.</p></li>
                                        <li><p className="para-text">
                                            You
                                            expressly understand and agree that, to the maximum extent permitted
                                            by applicable law, GLOBALGARNER will not be held liable for any loss
                                            that you may incur as a consequence of unauthorized use of your
                                            account or account information in connection with the website or any
                                            services, either with or without you knowledge, under any law. It
                                            shall be your duty to protect your account details. GLOBALGARNER has
                                            endeavoured to ensure that all the information on the website is
                                            correct, but GLOBALGARNER neither warrants nor makes any
                                            representations regarding the quality, accuracy or completeness of
                                            any data, information, product or service, GLOBAL GARNER shall not
                                            be responsible for the delay or inability to use the website or
                                            related functionalities, the provision of or failure to provide
                                            functionalities or for any information, software, products,
                                            functionalities and related graphics obtained through the website,
                                            or otherwise arising out of the use of the website, whether based on
                                            contract, tort, negligence, strict liability or otherwise. Further,
                                            GLOBAL GARNER shall not be held responsible non-availability of the
                                            website during periodic maintenance operations or any unplanned
                                            suspension of access to the website that may occur due to technical
                                            reasons or for any reason beyond GLOBAL GARNER's Control. The user
                                            understands and agrees that any material or data downloaded or
                                            otherwise obtained through the website is done entirely at their own
                                            discretion and risk and they will be solely responsible for any
                                            damage to their computer systems or loss of data. GLOBALGARNER is
                                            not responsible for any typographical error leading to any invalid
                                            coupon. GLOBALGARNER accepts no liability for any errors or
                                            omission, with respect to any information provided to you whether on
                                        behalf of itself or third parties.</p></li>
                                        <li><p className="para-text">
                                            In
                                            the event You have purchased/downloaded the Website from any online
                                            application stores, whether provided by Apple, Inc., Google, Inc. ,
                                            (or their respective affiliates) (collectively referred to as “Store
                                            Providers”), and there occurs any failure of the Website and /or
                                            Services, You may follow the process recommended by the Store
                                            Provider under the terms of the contract applicable between You and
                                            such Store Provider. The Store Providers shall not be liable for any
                                            (i) warranty obligations of GLOBAL GARNER and related claims,
                                            losses, liabilities, damages, costs or expenses; (ii) product
                                            liability /consumer protection claims, and claims related to the
                                            Website’s non-conformity with any applicable laws; and (iii) any
                                            third party claims of infringement of intellectual property rights
                                        by the Website /GLOBAL GARNER.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>INDEMNIFICATION
                                            AND LIMITATION OF LIABILITY</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            You
                                            agree to indemnify, defend and hold harmless GLOBAL GARNER, its
                                            subsidiaries, affiliates, vendors, agents and their respective
                                            directors, officers, employees, contractors and agents (herein after
                                        individually and collectively referred to as&nbsp;<b>“indemnified
                                        parties”</b>)
                                        from and against any and all losses, liabilities, claims, suits,
                                        proceedings, penalties, interests, damages, demands, costs and
                                        expenses (including legal and other statutory fees and disbursements
                                        in connection therewith and interest chargeable thereon) asserted
                                        against or incurred by the indemnified parties that arise out of,
                                        result from, or in connection with (i) Your breach of the Terms(s);
                                        or (ii) any claims made by any third party due to, or arising out
                                        of, or in connection with, Your use of the Website; or (iii) any
                                        claim that any Third Party Content /content, information or
                                        materials provided by You caused damage to a third party; or (iv)
                                        Your violation of any rights of another, including any intellectual
                                        property rights.</p></li>
                                        <li><p className="para-text">
                                            Notwithstanding
                                            anything to contrary in the Terms(s), GLOBALGARNER’s entire and
                                            aggregate liability to You under and in relation to these Terms of
                                            Use or otherwise shall not exceed the greater of Indian Rupees One
                                            Hundred (INR 100) or the amount of fees, if any, paid by You to
                                            GLOBAL GARNER under the relevant order to which the cause of action
                                        for the liability relates.</p></li>
                                        <li><p className="para-text">
                                            Notwithstanding
                                            anything to contrary in the Terms(s), in no event shall GLOBAL
                                            GARNER, its subsidiaries or affiliates and their respective
                                            officers, directors, employees, partners or suppliers be liable to
                                            You for any special, incidental, indirect, consequential, exemplary
                                            or punitive damages whatsoever, including those resulting from loss
                                            of use, data or profits, whether or not foreseeable or whether or
                                            not GLOBAL GARNER has been advised of the possibility of such
                                            damages, or based on any theory of liability, including breach of
                                            contract or warranty, negligence or other tortious action, or any
                                            other claim arising out of or in connection with Your use of or
                                        access to the Website, Services or Content.</p></li>
                                        <li><p className="para-text">
                                            Global
                                            garner sales services pvt ltd, and any of our group companies and
                                            the officers, directors, employees, shareholders or agents of any of
                                            them, exclude all liability and responsibility for any amount or
                                            kind of loss or damage that may result to you or a third party
                                            (including any direct, indirect, punitive or consequential loss or
                                            damages, or any loss of income, profits, goodwill, data, contracts,
                                            or loss or damages arising from or connected in any way to business
                                            interruption, loss of opportunity, loss of anticipated savings,
                                            wasted management or office time and whether in tort (including
                                            negligence), contract or otherwise, even if foreseeable) in
                                            connection with our services, this Website or in connection with the
                                            use, inability to use or the results of use of this Website, any
                                        websites linked to this Website or the material on these websites.</p></li>
                                    </ul>
                                    <ul>
                                        <li><p className="para-text">Nothing in these
                                        Website Terms excludes or limits our liability for death or personal
                                        injury arising from Railbhoj’s’s negligence, nor Railbhoj’s’s
                                        liability for fraudulent misrepresentation, nor any other liability
                                        which cannot be excluded or limited under applicable law. Nothing in
                                        these Website Terms affects your statutory rights.</p></li>
                                    </ul>
                                    <ul>
                                        <li><p className="para-text">With
                                        the exception of any liability referred to in paragraph above,
                                        Railbhoj’s total liability to you in relation to your use of the
                                        Website and the services that we provide including (but not limited)
                                        to liability for breach of these Website Terms and tort (including
                                        but not limited to negligence) is limited to an amount equivalent to
                                        thrice the value of your order or three thousand Indian rupees,
                                        whichever is the lower.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>TERMINATION</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            The
                                            Terms will continue to apply until terminated by either You or
                                            GLOBAL GARNER as set forth below. If You want to terminate Your
                                            Terms with GLOBAL GARNER, You may do so by (i) not accessing the
                                            Website; or (ii) closing Your Account for all of the Services that
                                        You use, where GLOBAL GARNER has made this option available to You.</p></li>
                                        <li><p className="para-text">
                                            You
                                            agree that GLOBAL GARNER may, in its sole discretion and without
                                            prior notice, terminate Your access to the Website and block Your
                                            future access to the Website if GLOBAL GARNER determines that You
                                        have violated the terms of these Terms of Use or any other Terms(s).</p></li>
                                        <li><p className="para-text">
                                            In
                                            addition to Article 13.2 above, GLOBAL GARNER may, at any time, with
                                            or without notice, terminate these Terms of Use (or portion thereof,
                                        such as any individual Additional Terms) with You if:</p>
                                            <ul>
                                                <li><p className="para-text">
                                                    GLOBAL
                                                    GARNER is required to do so by law (for example, where the
                                                    provision of the Services to You is, or becomes unlawful), or upon
                                                request by any law enforcement or other government agencies;</p></li>
                                                <li><p className="para-text">
                                                    The
                                                    provision of the Services to You by GLOBAL GARNER is, in GLOBAL
                                                GARNER 's opinion, no longer commercially viable;</p></li>
                                                <li><p className="para-text">
                                                    GLOBAL
                                                    GARNER has elected to discontinue, with or without reason, access
                                                to the Website, the Services (or any part thereof); or</p></li>
                                                <li><p className="para-text">
                                                    In
                                                    the event GLOBAL GARNER faces any unexpected technical issues or
                                                problems that prevent the Website and /or Services from working.</p></li>
                                            </ul>
                                        </li>
                                        <li><p className="para-text">
                                            GLOBAL
                                            GARNER may also terminate or suspend all or a portion of Your
                                            Account or access to the Services with or without reason. Except as
                                            may be set forth in any Additional Terms applicable to a particular
                                            Service, termination of Your Account may include: (i) removal of
                                            access to all offerings within the Website or with respect to the
                                            Services; (ii) disabling access to the Account Information,
                                            including Your personal information, log-in ID and password, and all
                                            related information, files and materials associated with or inside
                                            Your Account (or any part thereof), and any User Content uploaded by
                                        You; and (iii) prohibiting further use of the Services.</p></li>
                                        <li><p className="para-text">
                                            You
                                            agree that all terminations shall be made in GLOBAL GARNER’s sole
                                            discretion and that GLOBAL GARNER shall not be liable to You or any
                                            third party for any termination of Your Account (and accompanying
                                            deletion of Your Account Information), or Your access to the Website
                                        and Services.</p></li>
                                        <li><p className="para-text">
                                            Notwithstanding
                                            the foregoing, these such terms of this Terms as are meant to
                                            survive termination /expiry of this Terms, will survive indefinitely
                                            unless and until GLOBAL GARNER chooses to terminate them. GLOBAL
                                            GARNER may inform the Vendors who have accepted Your purchase orders
                                            on the Website that this Terms is terminated, and upon such
                                            information Vendors may cancel the purchase order. GLOBAL GARNER may
                                            also cancel all Your non-fulfilled orders on the Website without any
                                        obligation or liability towards You.</p></li>
                                    </ul>
                                    <ul>
                                        <li><p className="para-text">
                                            <b>REPORT
                                            ABUSE AND TAKE DOWN POLICY</b></p></li>
                                    </ul>
                                    <ul>
                                        <li><p className="para-text">
                                            In
                                            the event You come across any abuse or violation of these Terms of
                                            Sale or if You become aware of any objectionable content on the
                                            Website, or if You believe intellectual property rights of GLOBAL
                                            GARNER have been violated in any manner through the Website, please
                                            contact GLOBAL GARNER. Content that is considered obscene under
                                            Indian law is strictly forbidden on the globalgarner.com site, and
                                            users are prohibited from posting any material of an obscene nature
                                        on the site</p></li>
                                    </ul>
                                    <ul>
                                        <li><p className="para-text">
                                            <b>COMMUNICATIONS</b></p></li>
                                    </ul>
                                    <ul>
                                        <li><p className="para-text">
                                            When
                                            You use the Website or send emails or other data, information or
                                            communication to GLOBAL GARNER, You agree and understand that You
                                            are communicating with GLOBAL GARNER through electronic records and
                                            You consent to receive communications via electronic records from
                                            GLOBAL GARNER periodically and as and when required. GLOBAL GARNER
                                            will communicate with You by email or by notices on Website or
                                            electronic records on the Website or on Your mobile number which
                                            will be deemed adequate service of notice /electronic record to the
                                        maximum extent permitted under any applicable law.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>INTERNATIONAL
                                            USERS AND CHOICE OF LAW:</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            This
                                            Site is controlled, operated and administered by GLOBAL GARNER from
                                            its office within India. GLOBALGARNER makes no representation that
                                            Materials on this Website are appropriate or available for use at
                                            any other location(s) outside India. Any access to this Website from
                                            territories where their contents are illegal is prohibited. You may
                                            not use the Website or export the Materials in violation of any
                                            applicable export laws and regulations. If You access this Website
                                            from a location outside India, You are responsible for compliance
                                        with all local laws.</p></li>
                                        <li><p className="para-text">
                                            These
                                            Terms of Use shall be governed by the laws of India, without giving
                                            effect to its conflict of laws provisions. You agree that the
                                            appropriate court(s) as mentioned in para 19.1, will have the
                                            exclusive jurisdiction to resolve all disputes arising under these
                                            Terms of Use and You hereby consent to personal jurisdiction in such
                                        forum.</p></li>
                                        <li><p className="para-text">
                                            These
                                            Terms of Use constitutes the entire agreement between GLOBALGARNER
                                        and You with respect to Your use of the Website.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>MISCELLANEOUS
                                            PROVISIONS:</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Assignment:
                                            You cannot assign or otherwise transfer the Terms, or any rights
                                            granted hereunder or any obligations, to any third party and any
                                            such assignment or transfer or purported assignment or transfer
                                            shall be void ab initio. GLOBAL GARNER’s rights and/or obligations
                                            under the Terms are freely assignable or otherwise transferable by
                                            GLOBAL GARNER to any third parties without the requirement of
                                            seeking Your prior consent. GLOBAL GARNER may inform You of such
                                            assignment or transfer in accordance with the notice requirements
                                            under the Terms. GLOBAL GARNER shall have right to transfer Your
                                            Account and Account Information to a third party who purchases
                                        GLOBAL GARNER 's business as conducted under the Website.</p></li>
                                        <li><p className="para-text">
                                            Force
                                            Majeure: If performance of any service or obligation under these
                                            Terms of Use or other Terms by GLOBAL GARNER is, or other third
                                            parties in fulfilment of any purchase or sale transaction (for ex:
                                            logistics service provider, fulfilment centre, payment gateways
                                            etc.) are, prevented, restricted, delayed or interfered with by
                                            reason of labour disputes, strikes, acts of God, floods, lightning,
                                            severe weather, shortages of materials, rationing, utility or
                                            communication failures, earthquakes, war, revolution, acts of
                                            terrorism, civil commotion, acts of public enemies, blockade,
                                            embargo or any law, order, proclamation, regulation, ordinance,
                                            demand or requirement having legal effect of any government or any
                                            judicial authority or representative of any such government, or any
                                            other act whatsoever, whether similar or dissimilar to those
                                            referred to in this Article, which are beyond the reasonable control
                                            of GLOBAL GARNER or its third parties performing such services as
                                            sub-contractor to GLOBAL GARNER and could not have been prevented by
                                            reasonable precautions (each, a “Force Majeure Event”), then
                                            GLOBAL GARNER shall be excused from such performance to the extent
                                            of and during the period of such Force Majeure Event. GLOBAL GARNER
                                            shall exercise all reasonable commercial efforts to continue to
                                        perform its obligations hereunder.</p></li>
                                        <li><p className="para-text">
                                            Notice:
                                            All notices with respect to these Terms of Use from GLOBAL GARNER
                                            will be served to You by email or by general notification on the
                                            Website. Any notice provided to GLOBAL GARNER pursuant to these
                                        Terms of Use should be sent as set forth below.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>If
                                        to Global Garner then</b><br></br><b>Address
                                    : 5th floor, Grand Emporio, Motera Stadium Road, Ahmedabad, Gujarat 380005</b><br></br><b>Email
                                    :&nbsp;</b><a href="mailto:legal@globalgarner.com"><u><b>legal@globalgarner.com</b></u></a></p>
                                    <ul>
                                        <li><p className="para-text">
                                            Principal
                                            to Principal Relationship: You and GLOBAL GARNER are independent
                                            contractors, and nothing in these Terms of Use will be construed to
                                            create a partnership, joint venture, association of persons, agency
                                            (disclosed or undisclosed), franchise, sales representative, or
                                            employment relationship between You and GLOBAL GARNER. As an
                                            abundant caution, it is clarified that GLOBAL GARNER shall not have
                                            any right to conclude any contract for sale or purchase of Product/s
                                            for and /or on Your behalf and both You and GLOBAL GARNER have
                                        entered this Terms on principal to principal basis.</p></li>
                                        <li><p className="para-text">
                                            Severability:
                                            If, for any reason, a court of competent jurisdiction finds any
                                            provision of the Terms, or portion thereof, to be unenforceable,
                                            that provision shall be enforced to the maximum extent permissible
                                            so as to give effect to the intent of the parties as reflected by
                                            that provision, and the remainder of the Terms shall continue in
                                            full force and effect. GLOBAL GARNER may amend in a reasonable
                                            manner such provision to make it enforceable and such amendment will
                                            be given effect in accordance with the amendment terms of these
                                        Terms of Use.</p></li>
                                        <li><p className="para-text">
                                            Waiver:
                                            Any failure or delay by a party to enforce or exercise any provision
                                            of the Terms, or any related right, shall not constitute a waiver by
                                            such party of that provision or right. The exercise of one or more
                                            of a party’s rights hereunder shall not be a waiver of, or
                                            preclude the exercise of, any rights or remedies available to such
                                            party under these Terms of Use or in law or at equity. Any waiver by
                                            a party shall only be made in writing and executed by a duly
                                        authorized officer of such party.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>DISPUTE
                                            RESOLUTION:</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            In
                                            the event of any dispute arising out of or relating to these Terms,
                                            termination or invalidity thereof shall be referred to and finally
                                            resolved by an arbitration under the provisions of the Arbitration
                                            and Conciliation Act, 1996 and or its statutory modifications. The
                                            Arbitration proceedings shall be conducted in English. The
                                            principles of natural justice must be observed in the arbitration
                                        proceedings.</p></li>
                                        <li><p className="para-text">
                                            The
                                            Parties shall be treated with equality and impartiality and each of
                                            them should be given an opportunity to present its case. The
                                            arbitrator/s shall not be bound by the strict rules of law and
                                            evidence and the Arbitral Tribunal shall be free to decide the
                                            matter as to do substantial justice between the Parties. The seat of
                                            Arbitration shall be at Ahmedabad. The Arbitration award shall be
                                        final and binding.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        <b>GOVERNING
                                            LAW:</b></p>
                                    <ul>
                                        <li><p className="para-text">
                                            The
                                            said Terms shall be governed and construed in accordance with the
                                            laws of the India. Subject to provisions of Article 17, the Civil
                                            Courts at Ahmedabad shall have exclusive jurisdiction to try and
                                            entertain any dispute arising out of or in connection with these
                                        Terms.</p></li>
                                    </ul>
                                    <p className="para-text">
                                        GLOBAL
                                        GARNER SALES SERVICES PVT. LTD. SHALL IN NO EVENT BE RESPONSIBLE OR
                                        LIABLE FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL
                                        OR PUNITIVE DAMAGES ARISING ON ACCOUNT OF YOUR RELYING ON THE
                                        CONTENTS OF THE ADVERTISEMENT, WARRANTIES OR FITNESS FOR A PARTICULAR
                                        PURPOSE, OR MERCHANTABILITY OR AGAINST
                                    INFRINGEMENT.&nbsp;<a href="https://www.globalgarner.com/"><u>GLOBALGARNER.COM&nbsp;</u></a>EXPRESSLY
                                    DISCLAIMS ANY RESPONSIBILITY OR LIABILITY FOR ANY DAMAGE.</p>
                                    <p className="para-text">
                                        <b>Please
                                        Note: By accepting these Terms and Condition, you are deemed to
                                        accept our Privacy Policy.</b></p>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}