import React from 'react';
import {Button, Intent, FormGroup } from "@blueprintjs/core";
import { Async } from 'react-select';
import { ssoInstance } from 'gg-react-utilities';
import Config from '../../../Config';

export default class BSW extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            bsw:'',
            slug:null,
            hasBSWError:false,
            hasBSWErrorMsg:''
        };

    }

    async getOptions(inputValue) {

        if (!inputValue) {
            return [];
        }

        return ssoInstance.get(Config.BSW_API_URL + 'affiliates?name='+inputValue)
                        .then(
                            (res)=> {
                                const response = res.data;
                                if (response.status === true) {
                                  response.data.data.map((resp) => {
                                        resp.value = resp.name;
                                        resp.label = resp.name ;
                                        return resp;
                                  })
                                }
                                return response.data.data;
                              }

                        );

    }

    render(){

        return(
            <React.Fragment>
                <div className="strip-form">
                    <div className="row mt-3">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <FormGroup
                                helperText={this.state.hasBSWErrorMsg}>
                                <Async
                                  autoFocus={true}
                                  className='strip-select'
                                  classNamePrefix="strip-select"
                                            isClearable
                                            multi={false}
                                            value={this.state.bsw}
                                            isLoading={true}
                                            onChange={(value)=>
                                              {
                                                this.setState({
                                                  bsw:value,slug:value.slug, hasBSWError:true
                                                })
                                              }
                                            }
                                            valueKey="name"
                                            labelKey="name"
                                            Key="affiliate_id"
                                            loadOptions={this.getOptions}
                                            placeholder={'Search your favourite websites'}
                                            searchPromptText={"Please enter 3 or More characters"}
                                            backspaceRemoves={true}
                                            defaultOptions
                                        />

                            </FormGroup>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right mt-s-4 mt-sm-0 mt-xs-4 text-center-s">
                            <Button text='Search' className="search-btn" intent={Intent.PRIMARY}
                                    onClick={()=>{
                                        if(this.state.hasBSWError){
                                            window.location.href = this.props.url+'brands/'+this.state.slug+'/1';
                                        }else{
                                            this.setState({
                                                hasBSWErrorMsg: "BSW Brand cannot be empty"
                                            })
                                        }
                                    }}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
