import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from "@blueprintjs/core";
import { SearchRounded } from '@material-ui/icons';
import Loading from './loading';

import banner from '../images/home_vector.svg';
import '../style.scss';
import { PageTitle } from 'gg-react-utilities';

import salesexective from '../images/salesexective.svg';



export default class CareerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: ""
    };
  }


  componentDidMount() {
    this.props.getCareerList(this.state.searchText);
  }

  componentWillReceiveProps(nextProps) {

  }

  render() {

    return (
      <React.Fragment>
        <div className="joblist">
          <PageTitle title='Career | Global Garner' />
          <div className="container banner_section">
            <div className="bannerContent">
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 order-2 order-sm-2 order-md-2 order-md-1">
                  <h1 className="bannerTitle">Come work with us</h1>
                  <span className="bannerDisc">
                    Global Garner is a bunch of wunderkinds. People on board have proved their mettle in their individual fields and have now come together to enrich
                    this unique platform of empowering people.
                  </span>
                  <Card className='searchbar'>
                    <div className="bp3-input-group">

                      <input type="text" className="bp3-input border-0 input-searchjob"
                        placeholder="Search your dream job"
                        value={this.state.searchText}
                        onKeyUp={(e) => {
                          if (e.which === 13) {
                            this.props.getCareerList(this.state.searchText);
                          }
                        }}
                        onChange={(e) => {
                          this.setState({ searchText: e.target.value }, () => {
                            this.props.getCareerList(this.state.searchText);
                          })
                        }} />

                      <Button className="bp3-button bp3-secondary btn-searchojob"
                        onClick={() => {
                          this.props.getCareerList(this.state.searchText);
                        }}>
                        <SearchRounded style={{ 'color': '#236AEE' }} />
                      </Button>
                    </div>
                  </Card>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 order-1 order-sm-1 order-md-1 order-md-2">
                  <img className="banner_img" src={banner} />
                </div>
              </div>
            </div>
          </div>

          <div className="container landingcards mb-5">
            <div className="row mt-5 mb-2">
              <div className="col-12">
                <div className="landingcardsTitle">
                  <h2>Featured Jobs</h2>
                </div>
              </div>
            </div>

            <div className="row">

              {this.props.careerList.map((item, i) => (

                <div className='col-lg-4 col-md-6 col-sm-12 c_container'>
                  <div className="jobCard">

                    <Link to={{ pathname: '/job-description', state: { job: item, careerList: this.props.careerList } }}>
                      <Card>
                        <div className="row">
                          <div className='col-md-4 col-sm-12'>
                            <img src={process.env.REACT_APP_IMAGE_URL + item.logo} alt='job' className="img-fluid"
                              onError={(ev) => {
                                ev.target.src = process.env.REACT_APP_USER_DEFAULT_IMAGE;
                              }} />

                          </div>
                          <div className='col-md-8 col-sm-12'>
                            <h5>{item.title}</h5>
                            <p>Experience:<span>{item.experience}</span></p>
                            <p>Vacancy:<span>{item.no_positions === null ? "1" : item.no_positions}</span></p>
                            <p>Location:<span>{item.location}</span></p>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </div>
                </div>

              ))}

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
