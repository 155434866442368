import React, {Component} from 'react';
import {Classes, Dialog, FormGroup} from "@blueprintjs/core";
import { CloseRounded } from '@material-ui/icons';
import {Async} from 'react-select';
import { ssoInstance } from 'gg-react-utilities';
import Config from '../../../Config';
import _ from "lodash";

export default class CallUs extends Component{

    constructor(props){
        super(props);
        this.state={
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: props.isOpen,
            usePortal: true,
            selectedOption:'',
            hasPincodeError:false,
        }
    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      })
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextprops){
      if(nextprops.isOpen!==undefined){
          this.setState({
              isOpen: nextprops.isOpen
          });
      }
    }

    getPincode = (input, callback) => {
        if (input.length <= 2) {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        }

        this.props.getPincodeList(input).then((res) => {
            if (res.status === 200) {

                res.data.map((resp) => {
                  resp.value=resp.pincode
                  resp.label=resp.pincode

                    return resp;
                });
                console.log(res.data);
                return res.data;
            }

            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });

        }).then((json) => {
            callback(null, {
                options: json,
                complete: true
            })
        }).catch((error) => {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        });
    }

    async getOptions(inputValue) {

        if (!inputValue) {
            return [];
        }

        return ssoInstance.get(Config.PARTNER_URL+'pincode-list?q='+inputValue)
                        .then(
                            (response)=> {
                                if (response.status === 200) {
                                  response.data.map((resp) => {
                                        resp.value = resp.pincode;
                                        resp.label = resp.pincode ;
                                  })
                                }
                                return response.data;
                              }

                        );

    }

    render(){
        let pincodeSearch = _.debounce((input, callback) => { this.getPincode(input, callback) }, 100);
        const {partner} = this.props;
        return(
            <React.Fragment>
              <Dialog
                isOpen={this.state.isOpen}
                title={this.props.title}
                className={this.props.className?this.props.className:''}
                canOutsideClickClose={false}
                canEscapeKeyClose={true}
                >
                 <div className={Classes.DIALOG_BODY+' p-1 m-0'} >
                     <div className='col-12'>
                         <div className='row'>
                            <div className='col-xl-4 col-lg-4 col-md-4 vector-background d-flex align-items-center justify-content-center'>
                              <center>
                                <img src={require('../../images/support_page.png')} className='vector img-fluid' alt='vector'/>
                              </center>
                            </div>

                            <div className='col-xl-8 detail-area p-4'>
                                <span className='pull-right' style={{cursor: 'pointer'}}>
                                    <CloseRounded onClick={()=>{
                                        this.setState({
                                        isOpen: false
                                        })
                                    }}/>
                                </span>
                                <h3 className='title'>Call us for any query</h3>
                                <h5 className='title mb-5'>Enter your pincode and get number of your areas ACP and CP Contact number</h5>
                                <FormGroup
                                    helperText={this.state.hasFromErrorMsg}>
                                    <Async
                                        autoFocus={true}
                                        multi={false}
                                        value={this.state.selectedOption}
                                        isLoading={true}
                                        onChange={(value) => {
                                          this.setState({selectedOption:value, hasPincodeError:true},()=>{
                                            this.props.getPartnerList(value.pincode);
                                          });

                                        }}
                                        loadOptions={this.getOptions}
                                        valueKey="pincode"
                                        labelKey="pincode"
                                        Key="pincode"

                                        placeholder="Search Pincode"
                                        searchPromptText={"Please enter 3 or More characters"}
                                        backspaceRemoves={true}
                                    />
                                </FormGroup>
                                {this.props.partner!==null && this.state.selectedOption!==''?
                                  <div className='table-responsive'>
                                      <table className='bp3-html-table bp3-html-table-striped mt-4'>
                                          <tbody>
                                            <tr>
                                              <td>MCP Name</td>
                                            <td>MCP Contact</td>
                                            </tr>
                                                <tr>
                                                  <td>{partner.mcp_full_name}</td>
                                                <td><a href={'tel:'+partner.mcp_mobile} data-rel="external">{partner.mcp_mobile}</a></td>
                                            </tr>
                                              <tr>
                                                  <td>ACP Name</td>
                                                  <td>ACP Contact</td>
                                              </tr>
                                              <tr>
                                                  <td>{partner.acp_full_name}</td>
                                                  <td><a href={'tel:'+partner.acp_mobile} data-rel="external">{partner.acp_mobile}</a></td>
                                              </tr>
                                              <tr>
                                                  <td>CP Name</td>
                                                  <td>CP Contact</td>
                                              </tr>
                                              <tr>
                                                  <td>{partner.cp_full_name}</td>
                                                <td><a href={'tel:'+partner.cp_mobile} data-rel="external">{partner.cp_mobile}</a></td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>
                                  :
                                  <div className="error-block mt-4 text-center">
                                                    <img src={require('../../images/svg/Co_ACP_ATP_Found.svg')} alt='call-error' className="img-fluid" />
                                                    <center>
                                                        <h2 className='error-title'>No MCP, ACP and CP found in your area</h2>
                                                        <p className='error-muted'>For More Help Please Contact <a href={'mailto:info@globalgarner.com'} data-rel="external">info@globalgarner.com</a></p>
                                                    </center>
                                                </div>
                                }

                            </div>
                         </div>
                    </div>
                 </div>
             </Dialog>
            </React.Fragment>
        );
    }

}
