import React, { Component } from 'react';
import './style.scss';
import { PageTitle } from 'gg-react-utilities';
import banner from './images/home_vector.svg';
import applicationtester from './images/applicationtester.svg';
import fullstack from './images/fullstack.svg';
import laravel from './images/laravel.svg';
import reactjs from './images/reactjs.svg';
import relationshipmanager from './images/relationshipmanager.svg';
import salesece from './images/salesece.svg';
import uiux from './images/uiux.svg';
import { SearchRounded } from '@material-ui/icons';

import { Link } from 'react-router-dom';
import { Card, Button } from "@blueprintjs/core";
// import Loading from '../components/loading';

export default class CareerNew extends Component {

    joblistBox = [
        { icon: relationshipmanager, jobtitle: "Relationship Manager", exp: "2-4", vacancy: "5", location: "Ahmedabad" },
        { icon: salesece, jobtitle: "Sales Executive", exp: "0-2", vacancy: "5", location: "Ahmedabad" },
        { icon: applicationtester, jobtitle: "Manual and Automation Test Engineer", exp: "2-4", vacancy: "1", location: "Ahmedabad" },
        { icon: uiux, jobtitle: "UI/UX Designer", exp: "2-4", vacancy: "1", location: "Ahmedabad" },
        { icon: laravel, jobtitle: "Laravel Devloper", exp: "2-4", vacancy: "20", location: "Ahmedabad" },
        { icon: fullstack, jobtitle: "Full Stack Developer", exp: "2-4", vacancy: "5", location: "Ahmedabad" },
        { icon: salesece, jobtitle: "Executive Assistant", exp: "0-2", vacancy: "1", location: "Ahmedabad" },
        { icon: reactjs, jobtitle: "React JS Developer", exp: "2-4", vacancy: "5", location: "Ahmedabad" }
    ]

    render() {
        return (
            <React.Fragment>
                <div className="joblist">
                    <PageTitle title='Career | Global Garner' />
                    <div className="container banner_section">
                        <div className="bannerContent">
                            <div className="row">
                                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 order-2 order-sm-2 order-md-2 order-md-1">
                                    <h1 className="bannerTitle">Come work with us</h1>
                                    <span className="bannerDisc">
                                        Global Garner is a bunch of wunderkinds. People on board have proved their mettle in their individual fields and have now come together to enrich
                                        this unique platform of empowering people.
                                    </span>
                                    <Card className='searchbar'>
                                        <div className="bp3-input-group">
                                            <input type="text" className="bp3-input border-0 input-searchjob" placeholder="Search your dream job" onKeyUp="" onChange="" />
                                            <Button className="bp3-button bp3-secondary btn-searchojob">
                                                <SearchRounded style={{ 'color': '#236AEE' }} />
                                            </Button>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 order-1 order-sm-1 order-md-1 order-md-2">
                                    <img className="banner_img" src={banner} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container landingcards mb-5">
                        <div className="row mt-5 mb-2">
                            <div className="col-12">
                                <div className="landingcardsTitle">
                                    <h2>Featured Jobs</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row restextcenter">
                            {this.joblistBox.map((item, i) => (
                                <div className='col-lg-4 col-md-6 col-sm-12 c_container'>
                                    <div className="jobCard">
                                        <Link to={{ pathname: '/career/career/career-detail' }}>
                                            <Card>
                                                <div className="row">
                                                    <div className='col-md-4 col-sm-12'>
                                                        <img src={item.icon} />
                                                    </div>
                                                    <div className='col-md-8 col-sm-12'>
                                                        <h5>{item.jobtitle}</h5>
                                                        <p>Experience:<span>{item.exp}</span></p>
                                                        <p>Vacancy:<span>{item.vacancy}</span></p>
                                                        <p>Location:<span>{item.location}</span></p>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Link>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}