import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";

export default class Loading extends Component{

    render(){

        return(
          <div className="row">
            <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
              <div className="bp3-skeleton" style={{height: "50px"}}>&nbsp;</div>
            </div>
            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
                <Card className='bp3-elevation-1'>
                  <div className="bp3-skeleton" style={{height: "200px"}}/>
                </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                <div className="bp3-skeleton" style={{height: "200px"}}/>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                <div className="bp3-skeleton" style={{height: "200px"}}/>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                <div className="bp3-skeleton" style={{height: "200px"}}/>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                <div className="bp3-skeleton" style={{height: "200px"}}/>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                <div className="bp3-skeleton" style={{height: "200px"}}/>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                <div className="bp3-skeleton" style={{height: "200px"}}/>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                <div className="bp3-skeleton" style={{height: "200px"}}/>
              </Card>
            </div>

            <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                <div className="bp3-skeleton" style={{height: "200px"}}/>
              </Card>
            </div>
          </div>
        );

    }

}
