import React, {Component} from 'react';
import WallpaperComponent from "./container/WallpaperContainer";
import {PageTitle} from 'gg-react-utilities';

export default class Wallpaper extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }
    
    render(){
        return(
            <React.Fragment>
              <PageTitle title='Creatives & Ringtone | Global Garner' />
                <div className="container-fluid header-below">
        					<div className="container p-s-0">
        						<div className="career header-below-content">
        							<div className="row text-center mb-5">
        								<div className="col-12 col-xl-12">
        									<h1 className="bp3-text-xlarge heading">Creatives & Ringtone</h1>
        									<span className="heading-desc">
                            Adorn your gadgets with GG wallerpaper and ringtone.
                          </span>
        								</div>
        							</div>
        						</div>
        					</div>
                </div>
                <div className="container-fluid">
                    <WallpaperComponent />
                </div>
            </React.Fragment>
        );
    }
}
