import React, {Component} from 'react';
import Config from '../../Config';
import {ssoInstance} from 'gg-react-utilities';

export default class AwesomeBswDeals extends Component{
    constructor(props){
        super(props);
        this.state = {
          bswData:[]
        };
    }

    componentDidMount(){
      ssoInstance.get(Config.BSW_API_URL + 'affiliates?per_page=18').then((res)=>{

          const sources = res.data;
          if (sources.status === true) {
            this.setState({bswData:sources.data.data})
          }

      }).then(function(json) {

      }).catch(function(error) {


      });
    }

    render(){
      let counter = 0;
      const {bswData} = this.state;
        return(
            <section className="pb-5 bg-home-basic">
              <div className="container py-3">
                <div className='text-center'>
                  <h1 className="title">Best Shopping Website</h1>
                  <h3 className="title"></h3>
                </div>

                  <div className="row" >
                    {bswData.map((affiliate)=>{
                      counter++;
                      // affiliate.color_code = affiliate.color_code.split(',');
                      let colorCode = affiliate.color_code ? affiliate.color_code.split(',') : [];
                      if(counter > 6) counter =1;
                      return(
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12 my-4" key={affiliate.affiliate_id}>
                          <div className={"brandsDiv"} style={{'backgroundImage': 'linear-gradient(-135deg, ' + colorCode[0] + ' 0%, ' + colorCode[1] + ' 100%)', borderRadius: '7px'}}>
                            <a href={Config.BSW_URL+"brands/"+affiliate.slug+"/"+counter} className="pt-0">
                              <div className="brandsImg1" style={{top:'0'}}>
                                <img alt={affiliate.name} src={affiliate.logo} className="img-fluid"
                                  onError={(ev)=>{
                                    ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                  }}/>
                              </div>
                            </a>
                          </div>
                        </div>
                      )

                    })}
                  </div>
              </div>
              <center>
                <a className="view-all-link mt-3" href={Config.BSW_URL+'search'}>View All</a>
              </center>
          		<div className="clearfix"></div>
            </section>
        );
    }
}
