import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";
import {PageTitle} from 'gg-react-utilities';

export default class CreditsIOS extends Component {

    constructor(props){
        super(props);
        this.state = {
          data:[
            {
                name: "Alamofire",
                desc: "In order to keep Alamofire focused specifically on core networking implementations, additional component libraries have been created by the Alamofire Software Foundation to bring additional functionality to the Alamofire ecosystem. AlamofireImage - An image library including image response serializers, UIImage and UIImageView extensions, custom image filters, an auto-purging in-memory cache and a priority-based image downloading system. AlamofireNetworkActivityIndicator - Controls the visibility of the network activity indicator on iOS using Alamofire. It contains configurable delay timers to help mitigate flicker and can support URLSession instances not managed by Alamofire.",
                link: "https://github.com/Alamofire/Alamofire",
            },
            {
                name: "SDWebImage",
                desc: "This library provides an async image downloader with cache support. For convenience, we added categories for UI elements like UIImageView, UIButton, MKAnnotationView.",
                link: 'https://github.com/SDWebImage/SDWebImage',
            },
            {
                name: "GooglePlaces",
                desc: "GooglePlacesDemos contains a demo application showcasing various features of the Google Places API for iOS.",
                link: "https://github.com/googlemaps/maps-sdk-for-ios-samples/tree/master/GooglePlaces",
            },
            {
                name: "GooglePlacePicker",
                desc: "GooglePlacePickerDemos contains a demo application showcasing the Place Picker from the Google Places API for iOS.",
                link: 'https://github.com/googlemaps/maps-sdk-for-ios-samples/tree/master/GooglePlacePicker',
            },
            {
              name: "LBXScanView",
              desc: "A barcode and qr code scanner.",
              link: 'https://github.com/MxABC/swiftScan/blob/master/Source/LBXScanView.swift',
            },
            {
                name: "FIREBASE",
                desc: "A collection of quickstart samples demonstrating the Firebase APIs on iOS. Each sample contains targets for both Objective-C and Swift.",
                link: "https://github.com/firebase/quickstart-ios/blob/master/LICENSE",
            }
          ]
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    render(){
        return(
            <React.Fragment>
      				<PageTitle title='Credits IOS | Global Garner' />
                <div className="container-fluid header-below">
                  <div className="container p-s-0">
                    <div className="career header-below-content">
                      <div className="row text-center mb-5">
                        <div className="col-12 col-xl-12">
                          <h1 className="bp3-text-xlarge heading">Open source Credit for IOS</h1>
                          <span className="heading-desc">
                            This Application Uses Open Source Components.
                            You Can Find The Source Code Of Their Open Source Projects Along With License Information Below.
                            We Acknowledge And Are Grateful To These Developers For Their Contributions To Open Source.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='container-fluid'>
                  <div className='container credit-list-container'>
                    <div className='row my-5'>
                        {this.state.data.map((res,key)=>{
                          return(
                            <div className="col-xl-12 col-md-12 col-sm-12 col-12 mb-4 credit-list px-s-0" key={key}>
                              <Card className="bp3-elevation-3" key={key}>
                                <span className="project-title mt-5">{res.name}</span><br/>
                                <div className='project-link mt-3'>
                                  <a href={res.link}>{res.link}</a>
                                </div>
                                <p className="p-text mt-3">{res.desc}</p>
                              </Card>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
            </React.Fragment>
        );
    }
}
