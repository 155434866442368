import {connect} from 'react-redux';

import Megabrand from '../../components/RetailStrip/Megabrand';
import { getBrandList } from '../../../core/actions/stripAction';

const mapStateToProps = state => {

    return{
        brandList:state.RetailReducer.brandList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getBrandList :(obj) => {
            return getBrandList(obj)
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Megabrand);
