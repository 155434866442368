import React,{Component, Fragment} from 'react';

export default class Service extends Component{
    constructor(){
        super();

        this.state = {
          data:[
            {
              img:require('../images/custom.png'),
              title:'Customizable Designs',
              desc:'Easily build your Website & Online Ecommerce Store with drag & drop components. It takes less than five minutes to create your store with payment gateway & logistics.'
            },
            {
              img:require('../images/mobile_friendly.png'),
              title:'Mobile Friendly',
              desc:'Your Website & Online Ecommerce Store is automatically optimized for mobile use. Connect with Customers anytime, anywhere, on any device.'
            },
            {
              img:require('../images/discount.png'),
              title:'Coupons and Discounts',
              desc:'Offer coupons to your customers as incentives to buy your products. Boost sales on your online store.'
            },
            {
              img:require('../images/seo.png'),
              title:'SEO Friendly',
              desc:'Make your product more discoverable using page titles, Meta descriptions, H1 tag, Image ALT tags and much more. Helps your website to boost your search engine ranking.'
            },
            {
              img:require('../images/checkout.png'),
              title:'Single-Page Checkout',
              desc:'Single-page checkout will collect all the required information in a single page. Increase sales with one-click payments.'
            },
            {
              img:require('../images/payment.png'),
              title:'Multiple Payment Options',
              desc:'Integrate your favorite payment gateway and Collect online payments on your store via Credit cards, Debit cards, Wallets & Net banking.'
            },
            {
              img:require('../images/social_media.png'),
              title:'Social Media',
              desc:'Engaging with your existing and potential customers online has never been this easier. Sell your products via social media optimized links.'
            },
            {
              img:require('../images/single_dashboard.png'),
              title:'Single Dashboard',
              desc:'You get access to all your data with Reports of visitors, sales, trending products, orders, customers and reviews via your Website & Online Ecommerce Store.'
            }
          ],

        }
    }

    render(){
      const {data} = this.state
        return(
            <Fragment>
                <div className="col-12 mt-4 text-center">
                        <h2>All the Features you need to run your Website & Online Ecommerce Store</h2>
                        <h3>Perfect for beginners to create your online store and website.</h3>
                </div>

                <div className="row mt-5 service-list pt-5 pt-s-0
                ">
                    {data.map((res,i)=>{
                      return(
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-5 mb-s-3 pl-s-2 text-center" key={i}>
                              <div className="card-body">
                                <img src={res.img} alt="" className="img-fluid" />
                                <h4 className='title'>{res.title}</h4>
                                <h5>
                                  {res.desc}
                                </h5>
                              </div>


                        </div>
                      )
                    })}
                </div>
            </Fragment>
        )
    }
}
