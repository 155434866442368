import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import UposPartner from '../components/UposPartner';
import { getUposPartnerList } from '../../core/actions/action';
import { uposPartnerListAction } from '../../core/actions';

const mapStateToProps = state => {
    return{
        uposPartnerData:state.RetailReducer.uposPartnerData,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getUposPartnerList:(limit,name) => {
          dispatch(uposPartnerListAction(null));
            return getUposPartnerList(limit,name)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(uposPartnerListAction(sources.data));
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    dispatch(uposPartnerListAction(null));
                }
            }).catch((error)=>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UposPartner);
