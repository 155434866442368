import {connect} from 'react-redux';

import Railbhoj from '../../components/RetailStrip/Railbhoj';
import { getTrainList, getStationList } from '../../../core/actions/stripAction';


const mapDispatchToProps = dispatch => {

    return {
        getTrainList:(type)=>{
            return getTrainList(type)
        },
        getStationList:(trainCode)=>{
            return getStationList(trainCode)
        }
    }
}

export default connect(null, mapDispatchToProps)(Railbhoj);
