import React from 'react';
import AllModules from "./AllModules";
import AwesomeBswDeals from "./AwesomeBswDeals";
import AwesomeMBDeals from "./AwesomeMBDeals";
import VendorDeals from "./VendorDeals";


export default class Home extends React.Component {
    constructor(props){
        super(props);
        this.state ={

        };
    }

    componentDidMount(){

    }

    render(){
        return(
              <div className='Home-container'>
                <div className="container-fluid header-below">
        					<div className="container">
        						<div className="header-below-content career">
                      <div className="row text-center mb-5">
        								<div className="col-12 col-xl-12">
        									<h1 className="bp3-text-xlarge heading">Brands & Products</h1>
        									<span className="heading-desc"> </span>
        								</div>

        							</div>
        						</div>
        					</div>
        				</div>
                <AllModules {...this.props}/>
                <AwesomeMBDeals {...this.props}/>
                <AwesomeBswDeals {...this.props}/>
                <VendorDeals {...this.props}/>
              </div>
        );
    }
}
