import React, {Component, Fragment} from 'react';
import { FavoriteRounded, FavoriteBorderRounded } from '@material-ui/icons';
import {Auth} from 'gg-react-utilities';

export default class FavoriteIcon extends Component{
  constructor(props){
      super(props);

      this.state={
          show: this.props.show
      }

  }

  handleToggleClick=()=>{
    if(Auth.isLoggedIn()){
      this.props.addVendorToWishlist(this.props.vendor_slug)
      this.setState({
        show: !this.state.show
      });
    }else{
      Auth.login(true)
    }
  }

  render(){
      return(
        <Fragment>
          {this.state.show ?
            <FavoriteRounded className="favorite" onClick={()=>{ this.handleToggleClick() }}/>
            :
            <FavoriteBorderRounded style={{'color':'#727272'}} onClick={()=>{ this.handleToggleClick() }}/>
          }
        </Fragment>
      );

  }
}
