import React, {Component} from 'react';
import { Card, Button } from "@blueprintjs/core";


export default class Segment extends Component{
    constructor(props){
        super(props);
        this.state = {
            isActiveBtnID : 0,
            segmentID:null,
            isOpen:false,
            isActive:null
        };
    }

    createMarkup(data) {
        return {__html: data};
    }

    render(){
        const {res, key} = this.props;
        return(
              <Card className='row mb-5'  id={res.segID}>
                  <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-left'>
                      <img src={res.icon} className="img-fluid" alt='vendor'/>
                      <h3 className='tc-sidepartname'>{res.title}</h3>
                      <Button text={this.state.isOpen?'View Less':'View More'} className='btn-link tc-viewmore' onClick={() => {
                          this.setState({
                              isActive : key
                          },()=>{
                              if(this.state.isActive === key){
                                  this.setState({
                                      isOpen:!this.state.isOpen
                                  })
                              }else{
                                  this.setState({
                                      isOpen:false
                                  })
                              }
                          });
                      }}> &nbsp;&nbsp;&nbsp;
                      <span className="bp3-icon bp3-icon-chevron-right"/>

                      </Button>
                  </div>
                  <div className='col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-3 mt-sm-3 px-s-0 pr-sm-0 pr-md-3 pr-lg-3 pr-xl-3'>
                      <Card className={this.state.isOpen && this.state.isActive===key ? "show bg-tc border" : "bg-tc border"}>
                          <h5 className="tc-partname">{res.title}</h5>
                          <p dangerouslySetInnerHTML={this.createMarkup(res.description)}></p>
                      </Card>
                  </div>
              </Card>
        );

    }

}
