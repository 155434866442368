import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";
import StarRatings from 'react-star-ratings';
import {Auth} from 'gg-react-utilities';
import Config from '../../Config';
import Favorite from "../../core/components/favorite";

export default class VendorDeals extends Component{
    constructor(props){
        super(props);
        this.state = {
            loadingData: true,
            data: []
        };
    }

    componentDidMount(){
      if(this.props.locationData!==null){
        this.props.getNearVendorList(8,this.props.locationData.lat,this.props.locationData.lng)
      }else{
        this.setState({loadingData:false})
      };

    }

    render(){

        return(
            <section className="py-5 bg-home-basic">
              <div className="container awesome-deals">
                <div className="row mb-3">
                  <div className="col-xl-8 col-8 pr-s-0">
                    <h2 className="custom-heading left">Nearby GG Vendors</h2>
                  </div>
                  <div className="col-xl-4 col-4 text-right">
                    <a className="view-all-link mt-2" href={Config.STORE_URL+"vendor"}>View All</a>
                  </div>
                </div>

              {this.props.locationData===null?
                <Card className='bp3--3 col-12 col-xl-12 bp3-elevation-1 text-center mt-5'>
                  <p className='mt-5 mb-5'>Please choose location to see nearby vendors!</p>
                </Card>
                :this.props.vendorList===null ?
                <div className="row mt-4 product-list">
                  <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                    <Card className='bp3-elevation-1'>
                        <center>
                          <div className="bp3-skeleton" style={{height: "200px"}} />
                          <br/>
                          <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                          <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                          <br/>
                        </center>

                    </Card>
                  </div>
                  <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                    <Card className='bp3-elevation-1'>
                        <center>
                          <div className="bp3-skeleton" style={{height: "200px"}} />
                          <br/>
                          <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                          <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                          <br/>
                        </center>

                    </Card>
                  </div>
                  <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                    <Card className='bp3-elevation-1'>
                        <center>
                          <div className="bp3-skeleton" style={{height: "200px"}} />
                          <br/>
                          <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                          <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                          <br/>
                        </center>

                    </Card>
                  </div>
                  <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                    <Card className='bp3-elevation-1'>
                        <center>
                          <div className="bp3-skeleton" style={{height: "200px"}} />
                          <br/>
                          <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                          <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                          <br/>
                        </center>

                    </Card>
                  </div>
              </div>
              :
              <div className='row product-list'>
                {this.props.vendorList.length===0?
                  <Card className='bp3--3 col-12 col-xl-12 bp3-elevation-1'>
                    <center>
                      <img src={require("../images/svg/NoVendor.svg")} alt='vendor'/>
                    <p className='mt-3 mb-2'>{this.state.loadingData?'No vendor found!':'Please choose location to see nearby vendors!'}</p>
                    </center>
                  </Card>
                  :this.props.vendorList.map((res) => {
                    return(
                      <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4' key={res.vendor_id}>
                        <div className="product-card">
                          <div className="image">
                            <a href={Config.STORE_URL+'vendor/'+res.vendor_slug}>
                              <img src={res.display_pic===null||res.display_pic===''?process.env.REACT_APP_DEFAULT_IMAGE:res.display_pic} alt="vendor" className="img-fluid"
                                onError={(ev)=>{
                                  ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                }}/>
                            </a>
                            </div>
                            <div className="product-sticker">
                              {res.gg_coin_accept === 1 ?
                                <img src={process.env.REACT_APP_GG_COIN_IMAGE} className='gg-coin-img' alt='gg coin'/>
                              :null}
                              <div className='icon-area'>
                                {Auth.isLoggedIn()?
                                  <Favorite show={res.is_favorite===0?false:true} vendor_slug={{"vendor_slug":res.vendor_slug}} {...this.props}/>
                                  :
                                  <Favorite show={false} vendor_slug={res.vendor_slug} {...this.props}/>
                                }
                              </div>
                            </div>
                            <div className="product-details">
                              <div className='row'>
                                <div className="col-xl-8 col-8 text-left pr-xl-1 pr-lg-1 pr-md-1">
                                  <a href={Config.STORE_URL+'vendor-detail/'+res.vendor_slug}>
                                    <h3 className="product-name">{res.company_name}</h3>
                                  </a>
                                </div>
                                <div className="col-xl-4 col-4 text-right">
                                  <h4 className="product-distance">({res.distance} km)</h4>
                                </div>
                                <div className="col-4 col-lg-4 col-xl-4 d-flex align-items-center">
                                  <StarRatings
                                    rating={res.avg_rating===null?0:res.avg_rating}
                                    starRatedColor="#F5A623"
                                    starDimension='10px'
                                    numberOfStars={5}
                                    name='rating'
                                    />
                                </div>
                                <div className="col-xl-8 col-lg-8 col-xl-8 col-8 text-right">
                                  <p className="product-address">{res.area}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    )
                  })}
                  </div>
                }
              </div>
        			<div className="clearfix"></div>
            </section>
        );
    }
}
