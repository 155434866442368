import React from 'react';
import {Link} from "react-router-dom";
import RetailStrip from "./RetailStrip";
import AllModules from "./AllModules";
import NewestItemSlider from "./NewestItemSlider";
import AwesomeBswDeals from "./AwesomeBswDeals";
import AwesomeMBDeals from "./AwesomeMBDeals";
// import TopDeals from "./TopDeals";
import VendorDeals from "./VendorDeals";
import UposVendorDeals from "./UposVendorDeals";
import Testimonial from "./Testimonial";
import Investor from "./Investor";
import {GGDetailFooter} from 'gg-react-utilities';
import VideoAutoPlay from './VideoAutoPlay';
import Config from '../../Config';
import BillPayRecharge from "./BillPayRecharge"
import TravelHotelBooking from "./TravelHotelBooking"
import {Dialog} from "@blueprintjs/core";
import NewGGImage from "../../core/images/png/new_globalgarner.png";
export default class Home extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            isOpen:true
        };
    }

    componentDidMount(){

    }

    handleClose=()=>{
        this.setState({
            isOpen: false
        })
    }

    render(){
        return(
            <React.Fragment>
              <div className='Home-container'>
                <RetailStrip />
                 <AllModules {...this.props}/>
                <NewestItemSlider {...this.props} />
                <AwesomeBswDeals {...this.props}/>
                <BillPayRecharge {...this.props} /> 
                <AwesomeMBDeals {...this.props}/>
                <TravelHotelBooking {...this.props} />
                <VendorDeals {...this.props}/>
                <UposVendorDeals {...this.props}/>
                <Testimonial {...this.props} />
				        <Investor/>
                <GGDetailFooter/>
                <VideoAutoPlay link={this.props.link} />
              </div>
                <Dialog
                    isOpen={this.state.isOpen}
                    title=""
                    onClose={this.handleClose}
                    className="bp3-dialog-large">
                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="col-12 text-center mt-s-4">
                                {/*To access New launches like CashAqua, Pre Paid Card, Digital Gold etc, and for an improved user experience, please visit the latest version of our website
                                <br />
                                <br />*/}
                                <a href="https://beta.globalgarner.com" target="_blank"><img src={NewGGImage} style={{width:'60%'}}></img></a>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
    );
    }
}
