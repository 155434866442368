import React, { Component } from "react";
import { PageTitle } from "gg-react-utilities";

export default class Pitch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }

  render() {
    let metaData = document.getElementsByTagName("meta");
    metaData["description"].content = "Global Garner Pitch";
    metaData["keywords"].content =
      "global garner pitch, know everything about global garner, what is global garner, eveything about global garner, global garner, earn through global garner";
    return (
      <React.Fragment>
        <PageTitle title="Pitch | Global Garner" />
        <div className="container-fluid header-below">
          <div className="container p-s-0">
            <div className="career header-below-content">
              <div className="row text-center mb-5">
                <div className="col-12 col-xl-12">
                  <h1 className="bp3-text-xlarge heading">Pitch</h1>
                  <span className="heading-desc">
                    Global Garner Pitch - Everything you need to know about
                    Global Garner.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid"
          style={{ width: "100%" }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <video
            controlsList="nodownload"
            controls
            disablePictureInPicture
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen
            style={{ width: "100%" }}
          >
            <source src="https://d272mp8pusafu8.cloudfront.net/pitch-video.mp4"></source>
          </video>
        </div>
      </React.Fragment>
    );
  }
}
