import React from 'react';
import case_sudies from './images/case-studies.png';
import intro from './images/why.png';
import demo_img from './images/demo_img.png';
import Slider from './components/Slider';
import Service from './components/Service';
import Report from './components/Report';
import Testimonial from './components/Testimonial';


export default class Web extends React.Component{
    constructor(props){
        super(props);
        document.title = "Digital Marketing Certification Course in Ahmadabad | Global Garner";
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    render(){
        let metaData = document.getElementsByTagName("meta");
        metaData['description'].content = 'Global Garner is a well-known SEO company in India, specializing in SEO, content marketing, PPC, email marketing, social media marketing, lead generation, online reputation management and more. We offer reliable, sustainable and affordable online marketing service';
        metaData['keywords'].content = 'digital Marketing institute in India, digital marketing certificate programs in Ahmedabad, best institute for digital marketing, social media marketing courses, learn Digital Marketing, Social Media Marketing, SEO, YouTube, Email, PPC, digital marketing online training, digital marketing training in Ahmedabad, IT Services – Web Development, Mobile Application Development';
        return(
            <React.Fragment>
                <div className="app-wrapper">
                <div className="Inquiry_web">
                    <div className="Inq_slider">
                        <Slider/>
                    </div>

                    <div className="container">
                        <div className="col-12 py-5">
                            <div className="row d-sm-flex align-items-center inquiry_part py-sm-4">
                                <div className="col-md-6 col-12">
                                    <img src={demo_img} alt="" className="img-fluid" />
                                </div>
                                <div className="col-md-6 col-12 mt-s-4">
                                    <h2>We are Digital Marketing Agency & Work For Your Success</h2>
                                    <h4 className="mb-2">
                                        Global Garner was founded in 2011 as a Digital Marketing company in India. Global Garner is a professional digital
                                        marketing company that provides all kinds of marketing service to different types of business. Our SEO company offers lots
                                        of marketing services like SEO, paid marketing, PPC, social media marketing, etc. Also, design a Website and develop web
                                        applications for all kind of business.
                                    </h4>

                                    {/*<a href="https://globalwebguru.in/about/" className="pl-0 pr-0 view_more" target="_blank" rel="noopener noreferrer">View More</a>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-lightgrey">
                        <div className="container">
                            <div className="col-12 py-5 inq_service px-0">
                                <Service/>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="col-12 py-5">
                            <div className="row d-sm-flex align-items-center flex-column-reverse flex-md-row inquiry_part py-sm-4">
                                <div className="col-md-6 col-12 mt-s-4">
                                    <h2>Why Global Garner?</h2>
                                    <h4 className="m-0">
                                        We see a new horizon for our clients in blazing sky. Our winning solutions and skill helped several of our clients to act
                                        and have interaction with their customers.
                                    </h4>
                                    <br/>
                                    <h4 className="mb-3 mt-0">
                                        We provide Excellent customer services with strong employees attitude,
                                        highly efficient with affordable rates and having high integrity.
                                    </h4>

                                    {/*<a href="https://globalwebguru.in/about/" target="_blank"  rel="noopener noreferrer"className="pl-0 pr-0 view_more">View More</a>*/}
                                </div>
                                <div className="col-md-6 col-12">
                                    <img src={intro} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-report">
                        <div className="container">
                            <div className="col-12 py-5 inq_report px-0 px-s-3">
                                <Report/>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="col-12 py-5">
                            <div className="row d-sm-flex align-items-center inquiry_part py-sm-4">
                                <div className="col-md-6 col-12">
                                    <img src={case_sudies} alt="" className="img-fluid" />
                                </div>
                                <div className="col-md-6 col-12 mt-s-4">
                                    <h2>Recent Case Studies</h2>
                                    <br/>
                                    <h4 className="mb-2">
                                        Global Garner was founded in 2011 as a Digital Marketing company in India. Global Garner is a professional digital
                                        marketing company that provides all kinds of marketing service to different types of business. Our SEO company offers lots
                                        of marketing services like SEO, paid marketing, PPC, social media marketing, etc. Also, design a Website and develop web
                                        applications for all kind of business.
                                    </h4>

                                    {/*<a href="https://globalwebguru.in/case-studies/" target="_blank"  rel="noopener noreferrer"className="pl-0 pr-0 view_more">View More</a>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="bg-lightgrey">
                        <div className="container">
                            <div className="col-12 py-5 inq_testimonial px-0 pt-s-0">
                                <Testimonial/>
                            </div>
                        </div>
                    </div>*/}

                </div>
                </div>
            </React.Fragment>
        );
    }
}
