import React from 'react';
import {Intent, Position, Toaster} from "@blueprintjs/core";
import {Button} from "@blueprintjs/core";
import {ssoInstance} from "gg-react-utilities";
import config from "../../Config";
import {Textbox, Select} from "react-inputs-validation";
import validator from "validator";
import constant from "../../core/utils/constant";

export default class Report extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoggedIn :false,
            name:"",
            hasNameError:true,
            hasNameErrorMsg:"",
            mobile:"",
            hasMobileError:true,
            hasMobileErrorMsg:"",
            email:"",
            hasEmailError:true,
            hasEmailErrorMsg:"",
            website:"",
            hasWebsiteError:true,
            hasWebsiteErrorMsg:"",
            services:"",
            hasServiceError:true,
            hasServiceErrorMsg:"",
            validate:false,
            loader:false
        }
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    // async checkAuth(){
    //     setTimeout(()=>{
    //         Auth.isLoggedInAsync().then((res) => {
    //             this.setState({isLoggedIn:res},()=>{
    //                 if(res===false){
    //                     Auth.login(true);
    //                 }
    //             })
    //         });
    //     },1)
    // }

    onSubmitWebsite() {
        // Auth.isLoggedInAsync().then((res) => {
        //     this.setState({
        //         isLoggedIn: res,
        //     }, () => {
        this.toggleValidating(true);
        // if(this.state.isLoggedIn){
            if( !this.state.hasNameError &&
                !this.state.hasMobileError &&
                !this.state.hasEmailError &&
                !this.state.hasWebsiteError &&
                !this.state.hasServiceError
            ) {
                this.setState({
                    loader:true
                });
                ssoInstance.post(config.INQUIRY_API_URL + "web", {
                    userId: null,
                    name: this.state.name,
                    userName: null,
                    mobile: this.state.mobile,
                    email: this.state.email,
                    website: this.state.website,
                    service: this.state.services
                }).then((response) => {
                    this.setState({
                        loader:false
                    });
                    if (response.data.subject === "Error") {
                        Toaster.create({position: Position.TOP_RIGHT}).show({
                            message: response.data.message,
                            intent: Intent.DANGER
                        });
                    } else if (response.data.subject === "Success") {
                        Toaster.create({position: Position.TOP_RIGHT}).show({
                            message: response.data.message,
                            intent: Intent.SUCCESS
                        });
                        this.setState({
                            name:"",
                            mobile:"",
                            email:"",
                            website:"",
                            service:""
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        loader:false
                    });
                    Toaster.create({position: Position.TOP_RIGHT}).show({
                        message: "Something went wrong!",
                        intent: Intent.DANGER
                    });
                });
            }else{
                // console.error("Please enter all fields.");
            }
        // }else{
        //     Auth.login(true);
        // }
    }
        // });


    render(){
        return(
            <React.Fragment>
                <div className="col-12 mt-4">
                    <div className="row">
                        <h2>GET A FREE QUOTE</h2>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col mb-4">
                        <Textbox
                            tabIndex="1"
                            id={"Name"}
                            placeholder="Full Name"
                            value={this.state.name}
                            onBlur={e => {}}
                            onChange={(name, e) => {
                                this.setState({ name });
                            }}
                            validate={this.state.validate}
                            validationCallback={res =>
                                this.setState({ hasNameError:res, validate: false })
                            }
                            validationOption={{
                                name: 'Name',
                                check: true,
                                required: true
                            }}/>
                    </div>

                    <div className="col mb-4">
                        <Textbox
                            tabIndex="1"
                            id={"phone"}
                            name="phone"
                            type="text"
                            value={this.state.mobile}
                            placeholder="Mobile Number"
                            onChange={(mobile) => {
                                this.setState({ mobile });
                            }}
                            onBlur={e => {}}
                            validate={this.state.validate}
                            validationCallback={res =>
                                this.setState({ hasMobileError:res, validate: false })
                            }
                            validationOption={{
                                name: "Mobile Number",
                                check: true,
                                required: true,
                                customFunc: mobile => {
                                    if (validator.isMobilePhone(mobile, "en-IN")) {
                                        return true;
                                    } else {
                                        return "Is not a valid mobile number.";
                                    }
                                }
                            }}/>
                    </div>

                    <div className="col mb-4">
                        <Textbox
                            tabIndex="3"
                            id={"email"}
                            name="email"
                            type="text"
                            value={this.state.email}
                            placeholder="Email ID"
                            onChange={(email) => {
                                this.setState({ email });
                            }}
                            onBlur={e => {}}
                            validate={this.state.validate}
                            validationCallback={res =>
                                this.setState({ hasEmailError:res, validate: false })
                            }
                            validationOption={{
                                name: "Email ID",
                                check: true,
                                required: true,
                                customFunc: email => {
                                    if (config.EMAIL_REGEX.test(String(email).toLowerCase())) {
                                        return true;
                                    } else {
                                        return "Is not a valid email address";
                                    }
                                }
                            }}/>
                    </div>

                    <div className="col mb-4">
                        <Textbox
                            tabIndex="4"
                            id={"website"}
                            name="website"
                            type="text"
                            value={this.state.website}
                            placeholder="Website URL"
                            onChange={(website) => {
                                this.setState({ website });
                            }}
                            onBlur={e => {}}
                            validate={this.state.validate}
                            validationCallback={res =>
                                this.setState({hasWebsiteError:res, validate: false })
                            }
                            validationOption={{
                                name: "Website URL",
                                check: true,
                                required: true,
                                customFunc: website => {
                                    if (validator.isURL(website,{ protocols: ['http','https'], require_tld: true, require_protocol: false, require_host: true, require_valid_protocol: true, allow_underscores: false, host_whitelist: false, host_blacklist: false, allow_trailing_dot: false, allow_protocol_relative_urls: false, disallow_auth: false })) {
                                        return true;
                                    } else {
                                        return "Is not a valid Landing Page URL.";
                                    }
                                }
                            }}/>
                    </div>
                    <div className="col mb-4">
                        <Select
                            tabIndex="5" //Optional.[String or Number].Default: none.
                            id={'service'} //Optional.[String].Default: "". Input ID.
                            name={'service'} //Optional.[String].Default: "". Input name.
                            value={this.state.services} //Optional.[String].Default: "".
                            disabled={false} //Optional.[Bool].Default: false.
                            validate={this.state.validate} //Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at once, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                            validationCallback={res =>
                                this.setState({ hasServiceError: res, validate: false })} //Optional.[Func].Default: none. Return the validation result.
                            optionList={constant.SERVICE_LIST} //Required.[Array of Object(s)].Default: [].
                            onChange={(services, e) => {
                                this.setState({ services });
                            }}
                            onBlur={() => {}}
                            validationOption={{
                                name: 'Service', //Optional.[String].Default: "". To display in the Error message. i.e Please select a ${name}.
                                check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                                required: true,
                            }}
                        />
                    </div>

                    <div className="col-12 text-center">
                        <Button
                            className="btn_report mt-4"
                            text="SUBMIT"
                            intent={Intent.PRIMARY}
                            loading={this.state.loader}
                            onClick={() => {
                                this.onSubmitWebsite()
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
