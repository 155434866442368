import React, {Component} from 'react';

export default class FuelCashback extends Component{

    render(){
        return(
            <React.Fragment>
                <div className="fuelWrapper">
                    <div className="container">
                        <div className="fuelHeader">
                            <h3>FPV Cashback Options</h3>
                        </div>
                        <div className="fuelContainer">
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-12">
                                    <div className="fuelGrid">
                                        <div className="fuelImage">
                                          <center>
                                            <img src={require('../../images/png/FPV-Instant.png')} className="img-fluid" alt=""/>
                                          </center>
                                        </div>
                                        <div className="fuelGridDesc">
                                            <h4>FPV Instant Cashback</h4>
                                            <p className='mb-4'>₹5/- instant Cashback or GG Gift Voucher worth ₹100/-</p>
                                        </div>
                                        <div className="fuelKnow">
                                            <a href="/systematic-cashback?c=fpv">Know More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12 mt-s-3">
                                    <div className="fuelGrid">
                                        <div className="fuelImage">
                                          <center>
                                            <img src={require('../../images/png/FPV-RPP.png')} className="img-fluid" alt=""/>
                                          </center>
                                        </div>
                                        <div className="fuelGridDesc">
                                            <h4>FPV Random Pick Process</h4>
                                            <p className='mb-4'>2% to 100% Cashback of Fuel Value in a month</p>
                                        </div>
                                        <div className="fuelKnow">
                                            <a href="/systematic-cashback?c=fpv">Know More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12 mt-s-3 mt-sm-4 mt-md-0">
                                    <div className="fuelGrid">
                                        <div className="fuelImage">
                                          <center>
                                            <img src={require('../../images/png/FPV-FIFO.png')} className="img-fluid" alt=""/>
                                          </center>
                                        </div>
                                        <div className="fuelGridDesc">
                                            <h4>FPV First In First Out</h4>
                                            <p>2% of the Fuel amount as Instant Cashback along with 100% Cashback in 3 installments.</p>
                                        </div>
                                        <div className="fuelKnow">
                                            <a href="/systematic-cashback?c=fpv">Know More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
