import React, {Component, Fragment} from 'react';
import UposPartner from "./container/UposPartnerContainer";
import {PageTitle} from 'gg-react-utilities';

import TopBar from "../core/components/TopBar";

export default class Team extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    render(){
      // let metaData = document.getElementsByTagName("meta");
      // metaData['description'].content = "Global Garner is a bunch of wunderkinds. People on board have proved their mettle in their individual fields and have now come together to enrich this unique platform of empowering people.";
      // metaData['keywords'].content = "Team, Management team, IT team, HR Team, Top Managememt ";
        return(
            <Fragment>
                <PageTitle title='My UPOS Partner | Global Garner' />
        				<div className="container p-s-0">
                  <TopBar title="My UPOS Partner"/>
        				</div>
                <div className="container-fluid">
                    <UposPartner />
                </div>
            </Fragment>
        );
    }
}
