import React,{Component} from 'react';
import BrandsComponent from "./container/brandsContainer";
import {PageTitle} from 'gg-react-utilities';


export default class Home extends Component {

  componentDidMount(){
    setTimeout(()=>{
      window.scrollTo(0, 0);
    },1)
  }

    render(){
        return(
            <React.Fragment>
                <PageTitle title='Brands | Global Garner' />
                <BrandsComponent />
            </React.Fragment>
        );
    }
}
