import {ssoInstance} from 'gg-react-utilities';
import Config from '../../Config';

export const airportList = (input) => {
    let obj = {
        "searchAirportData" : input
    };
    return ssoInstance.post(Config.FLIGHT_API_URL + 'getAirport',obj);

}

export const getOperatorList = (type) => {
    let obj = {
        "service_id":type,
        "state_id":0,
        "city_id":0
    };
    return ssoInstance.post(Config.RECHARGE_API_URL + 'service_provider',obj);
}

export const getPlansList = (obj) => {
    return ssoInstance.post(Config.RECHARGE_API_URL + 'get_recharge_plan',obj);
}

export const getHolidaysCityList = (obj) => {

    return ssoInstance.post(Config.HOLIDAYS_API_URL + 'user/packege/v1.0.3/getCity',obj);
}


export const getTrainList = (type) => {

    return ssoInstance.get(Config.RAILBHOJ_API_URL + 'train/search/'+type);
}

export const getFromList = () => {

    return ssoInstance.get(Config.BUS_API_URL + 'sources');
}

export const getBrandList = (param) => {

    let obj = {
      "brand_name":param
    }
    return ssoInstance.post(Config.MEGABRAND_API_URL + 'all-brands',obj);
}

export const getBSWBrandList = (name) => {

    return ssoInstance.get(Config.BSW_API_URL + 'affiliates?name='+name);
}

export const getStationList = (trainCode) => {

    return ssoInstance.get(Config.RAILBHOJ_API_URL + 'train/route/'+trainCode);

}
