import React, {Component} from 'react';
import SliderComponent from './SliderComponent';
import Config from "../../../Config";
import {connect} from "react-redux";
import {getDesktopWallpaperList} from "../../../core/actions/action";
import {desktopWallpaperListAction} from "../../../core/actions";


class Desktop extends Component{
    constructor(props){
        super(props);

        this.state={
            page:1,
            desktopData:[],
            scrollLoader:true,
            isLoading:false,
            type:null,

        }
    }

    componentDidMount() {
        this.props.getDesktopWallpaperList(this.state.page);
        window.addEventListener('scroll', this.handleOnScroll);
    }

    componentWillReceiveProps(nextprops){
        let oldData = this.state.desktopData;
        let newDataFromApi = nextprops.desktopWallpaperList.data;
        let newData = oldData.concat(newDataFromApi);
        this.setState({
            desktopData:newData,
            last_page:nextprops.desktopWallpaperList.last_page
        })
    }

    handleOnScroll=()=> {
        let scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        let scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        let clientHeight = document.documentElement.clientHeight || window.innerHeight;
        let scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        if (scrolledToBottom) {
            this.loadMore();
        }
    }

    loadMore = () =>{
        this.setState({
            scrollLoader:false
        });
        setTimeout(() => {
            this.setState({
                page: this.state.page + 1,
                isLoading: true,
                scrollLoader:true
            }, () => {
                this.props.getDesktopWallpaperList(this.state.page);
            })
        },1000)
    }


    render(){
        const data = this.state.desktopData;
        let image =[];
        data.map((res)=>{
                image.push(res)
            return res;
        });


        return(
            <React.Fragment>

        			<div className="row cb-tab-data">
                        <SliderComponent
                            dataSource={image}
                        />
        			</div>
                {
                    this.state.last_page <= this.state.page ? null :
                        this.state.scrollLoader ?
                            null :
                            <React.Fragment>
                                <center>
                                    <div className="my-3">
                                        <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                    </div>
                                </center>
                            </React.Fragment>

                }
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {

    return{
        desktopWallpaperList:state.RetailReducer.desktopWallpaperList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getDesktopWallpaperList:(page) => {
            getDesktopWallpaperList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                      sources.data.data.map((image)=>{
                        image.src = Config.IMAGE_URL + image.image;
                        image.thumbnail = Config.IMAGE_URL + image.thumb_image;
                        image.thumbnailWidth = 320;
                        image.thumbnailHeight = 200;
                        return image;
                      })
                        dispatch(desktopWallpaperListAction(sources.data))
                    }else{
                        dispatch(desktopWallpaperListAction([]))
                    }
                });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Desktop);
