import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";

export default class Privacy_policy extends Component{
    constructor(props){
        super(props);

        this.state = {

        };
    }


    render(){
        return(
    			<div className='container p-s-0 mb-5'>
    			<Card className='privacy-content py-5 bp3-elevation-1'>
    				<div className="row">
    					<div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 offset-xl-1 offset-lg-1 offset-md-1 my-3">
    					  <p>THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC CONTRACT FORMED UNDER THE INFORMATION TECHNOLOGY ACT, 2000 AND THE RULES MADE THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THIS PRIVACY POLICY DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.</p>
    					  <p>THIS PRIVACY POLICY IS A LEGALLY BINDING DOCUMENT BETWEEN YOU AND THE COMPANY (BOTH TERMS DEFINED BELOW). THE TERMS OF THIS PRIVACY POLICY WILL BE EFFECTIVE UPON YOUR ACCEPTANCE OF THE SAME (DIRECTLY OR INDIRECTLY IN ELECTRONIC FORM, BY CLICKING ON THE “I ACCEPT” TAB OR BY USE OR THE WEBSITE OR BY OTHER MEANS) AND WILL GOVERN THE RELATIONSHIP BETWEEN YOU AND THE COMPANY FOR YOUR USE OF THE WEBSITE (DEFINED BELOW).</p>
    					  <p>THIS DOCUMENT IS PUBLISHED AND SHALL BE CONSTRUED IN ACCORDANCE WITH THE PROVISIONS OF THE INFORMATION TECHNOLOGY (REASONABLE SECURITY PRACTICES AND PROCEDURES AND SENSITIVE PERSONAL DATA OF INFORMATION) RULES, 2011 UNDER INFORMATION TECHNOLOGY ACT, 2000; THAT REQUIRE PUBLISHING OF THE PRIVACY POLICY FOR COLLECTION, USE, STORAGE AND TRANSFER OF SENSITIVE PERSONAL DATA OR INFORMATION.</p>
    					  <p>PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING THE WEBSITE, YOU INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT USE THIS WEBSITE. YOU HEREBY PROVIDE YOUR UNCONDITIONAL CONSENT OR AGREEMENTS TO THE COMPANY AS PROVIDED UNDER SECTION 43A AND SECTION 72A OF INFORMATION TECHNOLOGY ACT, 2000.</p>
    					  <p>By providing us your Information or by making use of the facilities provided by the Website, You hereby consent to the collection, storage, processing and transfer of any or all of Your Personal Information and Non-Personal Information by The Company as specified under this Privacy Policy. You further agree that such collection, use, storage and transfer of Your Information shall not cause any loss or wrongful gain to you or any other person.</p>
    					  <p>GLOBAL GARNER SALES SERVICES PRIVATE LIMITED (<strong>“GLOBAL GARNER”</strong> or <strong>“Herein referred to as the company , we ,us or our”</strong>) is concerned about the privacy of the data and information of Users (including Vendors and buyers/customers whether registered or non- registered) socializing on GG Relations, accessing, offering, selling or purchasing products or services on The Company’s Websites, mobile sites or mobile applications (<strong>“Website”</strong>) on the Website and otherwise doing business with The Company. The terms “You” or “Your” or “Yourself” shall be used to refer to the Users of the Website and mobile application . And The term <strong>“We”</strong> or <strong>“Us”</strong> or <strong>“Our”</strong> or <strong>“GG”</strong> shall be used to refer Global garner</p>
    					  <p>You can use our website and applications in many ways to interact with other users, to socialise with friends and others to buy different products through wide range of vendors , to sell your products and exhibit it among large user base, to interact with other, to create your own content etc. While using our site we may ask you to provide us with certain personally identifiable information, When you share such information with us, we can make your experience even better – to show you more relevant search results to meet your needs, to help you connect with different people. As you use our services, we want you to be clear how we are using information and the ways in which you can protect your privacy.</p>
    					  <p>This Privacy Policy is a contract between You and The Company, whether You use or access or You otherwise deal with. This Privacy Policy shall be read together with the respective Terms of Use or other terms and conditions of the Company and its respective Website or nature of business of the Website. As a user, when you use Global garner application and or website, you are sharing your information and you are trusting us with the same . This Privacy Policy is meant to help you understand what data we collect, why we collect it, and what we do with it…</p>
    					  <p>The Company has provided this Privacy Policy to familiarise You with.</p>
    					  <ul>
    						 <li>
    							<p>The type of data or information that You share with or provide to The Company and that The Company collects from You;</p>
    						 </li>
    						 <li>
    							<p>The purpose for collection of such data or information from You; The Company’s information security practices and policies; and</p>
    						 </li>
    						 <li>
    							<p>The Company’s policy on sharing or transferring Your data or information with third parties.</p>
    						 </li>
    					  </ul>
    					  <p>This Privacy Policy may be amended / updated from time to time. Upon amending / updating the Privacy Policy, the Company will accordingly amend the date above. We suggest that you regularly check this Privacy Policy to apprise yourself of any updates. Your continued use of Website or provision of data or information thereafter will imply Your unconditional acceptance of such updates to this Privacy Policy.</p>
    					  <h5><strong>1. Information Collected and Storage of Such Information</strong></h5>

    					  <p>The Information (which shall also include data) provided by You to The Company or collected from You by The Company may consist of “Personal Information” and “Non-Personal Information”.
    						 1.1 Personal Information
    						 Personal Information is Information collected that can be used to uniquely identify or contact You. Personal Information for the purposes of this Privacy Policy shall include, but not be limited to:
    					  </p>
    					  <ul>
    						 <li>
    							<p>Your address,</p>
    						 </li>
    						 <li>
    							<p>Your telephone number,</p>
    						 </li>
    						 <li>
    							<p>Your e-mail address or other contact information,</p>
    						 </li>
    						 <li>
    							<p>Your date of birth,</p>
    						 </li>
    						 <li>
    							<p>Your gender</p>
    						 </li>
    						 <li>
    							<p>Aadhar Card</p>
    						 </li>
    						 <li>
    							<p>Information regarding your transactions on the Website, (including sales or purchase history),</p>
    						 </li>
    						 <li>
    							<p>Your financial information such as bank account information or credit card or debit card or other payment instrument details,</p>
    						 </li>
    						 <li>
    							<p>Internet Protocol address, When you browse or use our website and application we use your device event information, system activity, hardware settings, browser type, browser language, the date and time of your request and referral URL.</p>
    						 </li>
    						 <li>
    							<p>Any other items of “sensitive personal data or information” as such term is defined under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data of Information) Rules, 2011 enacted under the Information Technology Act, 2000;</p>
    						 </li>
    						 <li>
    							<p>Identification code of your communication device which You use to access the Website or otherwise deal with the Company,</p>
    						 </li>
    						 <li>
    							<p>We may also collect data of your contact list and other information stored in your device on your permission if you allows us to do so</p>
    						 </li>
    						 <li>
    							<p>Any other Information that You provide during Your registration process, if any, on the Website</p>
    						 </li>
    						 <li>
    							<p>We also collect device-specific information (such as your hardware model, operating system version, unique device identifiers, and mobile network information including phone number). We link your device identifiers and phone number with your GG account</p>
    						 </li>
    					  </ul>
    					  <p>Such Personal Information may be collected in various ways including during the course of You:</p>
    					  <ul>
    						 <li>
    							<p>Registering on GG Relations to socialize and make friends and interact with them</p>
    						 </li>
    						 <li>
    							<p>Registering as a user on the Website,</p>
    						 </li>
    						 <li>
    							<p>Registering as a Vendor on the Website,</p>
    						 </li>
    						 <li>
    							<p>Registering as a partner by way of MCP, CP, ACP on the website</p>
    						 </li>
    						 <li>
    							<p>Availing certain services offered on the Website. Such instances include but are not limited to making an offer for sale, online purchase, participating in any online survey or contest, communicating with the company’s customer service by phone, email or otherwise or posting user reviews on the items available on the Website, or</p>
    						 </li>
    						 <li>
    							<p>Otherwise doing business on the Website or otherwise dealing with the Company.</p>
    						 </li>
    					  </ul>
    					  <p>We may receive Personal information about you from third parties, such as social media services, commercially available sources and business partners. If you access Website through a social media service or connect a service on Website to a social media service, the information We collect may include your user name associated with that social media service, any information or content the social media service has the right to share with us, such as your profile picture, email address or friends list, and any information you have made public in connection with that social media service. When you access the Website or otherwise deal with the Company through social media services or when you connect any Website to social media services, you are authorizing the Company to collect, store, and use and retain such information and content in accordance with this Privacy Policy.</p>
    					  <p>1.2 Non-Personal Information
    						 The Company may also collect information other than Personal Information from You through the Website when You visit and / or use the Website. Such information may be stored in server logs. This Non-Personal Information would not assist the Company to identify You personally.
    						 This Non-Personal Information may include:
    					  </p>
    					  <ul>
    						 <li>
    							<p>Your geographic location: (When you browse or use our website or application , we may collect and process information about your actual location)</p>
    						 </li>
    						 <li>
    							<p>details of Your telecom service provider or internet service provider,</p>
    						 </li>
    						 <li>
    							<p>the type of browser (Internet Explorer, Firefox, Opera, Google Chrome etc.),</p>
    						 </li>
    						 <li>
    							<p>the operating system of Your system, device and the Website You last visited before visiting the Website,</p>
    						 </li>
    						 <li>
    							<p>The duration of Your stay on the Website is also stored in the session along with the date and time of Your access,</p>
    						 </li>
    						 <li>
    							<p>Unique application numbers: When you browse or use our website and application, certain service include unique application number. This number and information about your installation (for example, the operating system type and application version number) may be sent to us when you install or uninstall a particular service or when that service periodically contacts our servers, such as for automatic update</p>
    						 </li>
    						 <li>
    							<p>Local storage: When you browse or use our website and application We may collect and store information (including personal information) locally on your device using mechanisms such as browser web storage (including HTML 5) and application data caches</p>
    							<h5><strong>Cookies</strong></h5>

    							<p>Non-Personal Information is collected through various ways such through the use of cookies as Company may store temporary or permanent <strong>“cookies”</strong> on Your computer. You can erase or choose to block these cookies from Your computer. You can configure Your computer’s browser to alert You when We attempt to send You a cookie with an option to accept or refuse the cookie. If You have turned cookies off, You may be prevented from using certain features of the Website. A “cookie” is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user. We place both permanent and temporary cookies in your computer’s hard drive. The cookies do not contain any of your personally identifiable information.</p>
    							<p>Ads: The Company may use third-party service providers to serve ads on the Company’s behalf across the internet and sometimes on the Website. They may collect Non- Personal Information about Your visits to the Website, and Your interaction with our products and services on the Website.</p>
    							<p>Please do note that Personal Information and Non Personal Information may be treated differently as per this Privacy Policy. You hereby represent to The Company that:</p>
    							<ul>
    							   <li>
    								  <p>The Information you provide to the Company from time to time is and shall be authentic, correct, current and updated and You have all the rights, permissions and consents as may be required to provide such Information to the Company.</p>
    							   </li>

    							   <li>
    								  <p>Your providing the Information to the Company and the Company’s consequent storage, collection, usage, transfer, access or processing of the same shall not be in violation of any third party agreement, laws, charter documents, judgments, orders and decrees.</p>
    							   </li>

    							   <li>
    								  <p>The Company and each of the Company entities officers, directors, contractors or agents shall not be responsible for the authenticity of the Information that You or any other user provide to the Company. You shall indemnify and hold harmless the Company and each of the Company entities officers, directors, contractors or agents and any third party relying on the Information provided by You in the event You are in breach of this Privacy Policy including this provision and the immediately preceding provision above.</p>
    							   </li>

    							   <li>
    								  <p>Your Information will primarily be stored in electronic form. However, certain data can also be stored in physical form. We may store, collect, process and use your data in countries other than Republic of India but under compliance with applicable laws. We may enter into agreements with third parties (in or outside of India) to store or process your information or data. These third parties may have their own security standards to safeguard your information or data and We will on commercial reasonable basis require from such third parties to adopt reasonable security standards to safeguard your information / data.</p>
    							   </li>

    							   <li>
    								  <p>You should be familiar with the terms IP addresses, pixel tags and browsers. Your privacy. While the cookies that we use may change from time to time as we improve and update our Global garner Services. Cookies are reliable mechanism to remember stateful information (such as items added in the shopping cart in an online store) or to record your browsing activity (including clicking particular buttons, logging in, or recording which pages were visited in the past).We use cookies to verify your account and determine your log in status so we can make it easier for you to access our Services and show you the appropriate experience and features. We also use cookies to help us keep your account, data and the Services safe and secure and to combat against spam and phishing attacks. We also use these technologies to collect and store information when you interact with services we offer to our associates</p>
    							   </li>

    							</ul>
    						 </li>
    					  </ul>
    					  <h5><strong>2. Purpose for Collecting, Using, Storing and Processing Your Information</strong></h5>

    					  <p>The Company collects, uses, stores and processes Your Information for any purpose as may be permissible under applicable laws (including where the applicable law provides for such collection, usage, storage or processes in accordance with the consent of the User) connected with a function or activity of each of the Company entities and shall include the following, individually and collectively referred to as “Purposes”:</p>
    					  <ul>
    						 <li>
    							<p>to facilitate Your use of the Website or other services of the Company; to provide better services to all of our users – from figuring out stuffS like which language you speak, to more complex things like which ads you’ll find most useful, the people who matter most to you online, or which videos you might like, Content you like most, your other areas of interest, your choices and preferences, to enable us to personalize your content, measure your choices , show you suitable advertisements and customize your browning experience</p>
    						 </li>
    						 <li>
    							<p>to respond to Your inquiries or fulfil Your requests for information about the various products and services offered on the Website;</p>
    						 </li>
    						 <li>
    							<p>to provide You with information about products and services available on the Website and to send You information, materials, and offers from The Company;</p>
    						 </li>
    						 <li>
    							<p>to send You important information regarding the Website, changes in terms and conditions, user agreements, and policies and/or other administrative information;</p>
    						 </li>
    						 <li>
    							<p>to send You surveys and marketing communications that The Company believes may be of interest to You;</p>
    						 </li>
    						 <li>
    							<p>to personalize Your experience on the Website by presenting advertisements, products and offers tailored to Your preferences;</p>
    						 </li>
    						 <li>
    							<p>to help You address Your problems incurred on the Website including addressing any technical problems;</p>
    						 </li>
    						 <li>
    							<p>if You purchase any product or avail of any service from the Website, to complete and fulfil Your purchase, for example, to have Your payments processed, communicate with You regarding Your purchase and provide You with related customer service;</p>
    						 </li>
    						 <li>
    							<p>for proper administering of the Website;</p>
    						 </li>
    						 <li>
    							<p>to conduct internal reviews and data analysis for the Website (e.g., to determine the number of visitors to specific pages within the Website);</p>
    						 </li>
    						 <li>
    							<p>to improve the services, content and advertising on the Website;</p>
    						 </li>
    						 <li>
    							<p>to provide value added services such as Single Sign-On. <strong>“Single Sign-On”</strong> shall mean a session/user authentication process that permits a user to enter his/her name or mobile number or e-mail address or any combination thereof and password in order to access multiple Websites and applications;</p>
    						 </li>
    						 <li>
    							<p>to facilitate various programmes and initiatives launched by the Company or third party service providers and business associates</p>
    						 </li>
    						 <li>
    							<p>to analyse how our services are used, to measure the effectiveness of advertisements, to facilitating payments</p>
    						 </li>
    						 <li>
    							<p>to conducting academic research and surveys</p>
    						 </li>
    						 <li>
    							<p>to protect the integrity of the Website;</p>
    						 </li>
    						 <li>
    							<p>to respond to legal, judicial, quasi-judicial process and provide information to law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law;</p>
    						 </li>
    						 <li>
    							<p>to conduct analytical studies on various aspects including user behaviour, user preferences etc.;</p>
    						 </li>
    						 <li>
    							<p>to permit third parties who may need to contact users who have bought products from the Website to facilitate installation, service and any other product related support;</p>
    						 </li>
    						 <li>
    							<p>to implement information security practices;</p>
    						 </li>
    						 <li>
    							<p>to determine any security breaches, computer contaminant or computer virus;</p>
    						 </li>
    						 <li>
    							<p>to investigate, prevent, or take action regarding illegal activities and suspected fraud,</p>
    						 </li>
    						 <li>
    							<p>to undertake forensics of the concerned computer resource as a part of investigation or internal audit;</p>
    						 </li>
    						 <li>
    							<p>to trace computer resources or any person who may have contravened, or is suspected of having or being likely to contravene, any provision of law including the Information Technology Act, 2000 that is likely to have an adverse impact on the services provided on any Website or by The Company;</p>
    						 </li>
    						 <li>
    							<p>to enable a potential buyer or investor to evaluate the business of The Company (as further described in paragraph 8 of the Privacy Policy).</p>
    						 </li>
    					  </ul>
    					  <p><strong>You hereby agree and acknowledge</strong> that the Information so collected is for lawful purpose connected with a function or activity of each of the Company or any person on their respective behalf, and the collection of Information is necessary for the Purposes.
    						 when you post anything on GG Relations you are sharing your content with others. In some cases, people you share and socialise with may download, save or re-share your contents with others on and off our portal. When you comment on another person’s post or like their content on GG Relations audience of such post is of that person. Few of your profile information is public in order to facilitate your interaction with new friends and individuals.
    					  </p>
    					  <p>You will not post messages, pictures or recordings or use the Website in any way which violates or infringes upon the rights of any third party, including but not limited to any copyright or trademark law, privacy or other personal or proprietary rights, or is fraudulent or otherwise unlawful or violates any law and also violates the rights of the others.</p>
    					  <p>You shall use the Website for your own personal use. You shall not authorize third party to use the Website or and you may not transfer your accounts with other users.</p>
    					  <p>It should be understood that when you register yourself with GG Relation, the contents which you post in your account of GG Relation, shall be public to all member of GG Relation and all members shall view the same.</p>
    					  <h5><strong>3. Sharing and Disclosure of Your Information</strong></h5>
    					  <ul>
    						 <li>
    							<p>The Services are not available to You hereby unless and until you agree unconditionally and permit that the Company may transfer, share, disclose or part with all or any of Your Information, within and outside of the Republic of India to various third party service providers / partners / banks and financial institutions for one or more of the Purposes or as may be required by applicable law. In such case We will contractually oblige the receiving parties of the Information to ensure the same level of data protection that is adhered to by the Company under applicable law.</p>
    						 </li>
    						 <li>
    							<p>You acknowledge and agree that, to the extent permissible under applicable laws, it is adequate that when the Company transfers Your Information to any other entity within or outside Your country of residence, the Company will place contractual obligations on the transferee which will oblige the transferee to adhere to the provisions of this Privacy Policy.</p>
    						 </li>
    						 <li>
    							<p>The Company may share statistical data and other details (other than Your Personal Information) without Your express or implied consent to facilitate various programmes or initiatives launched by the Company, its affiliates, agents, third party service providers, partners or banks &amp; financial institutions, from time to time. We may transfer/disclose/share Information (other than Your Personal Information) to those parties who support our business, such as providing technical infrastructure services, analysing how our services are used, measuring the effectiveness of advertisements, providing customer / buyer services, facilitating payments, or conducting academic research and surveys. These affiliates and third party service providers shall adhere to confidentiality obligations consistent with this Privacy Policy. Notwithstanding the above, We use other third parties such as a credit/debit card processing company, payment gateway, pre-paid cards etc. to enable You to make payments for buying products or availing services on The Company. When You Rgister for these services, You must have the ability to save Your card details for future reference and faster future payments. In such case, We may share Your relevant Personal Information as necessary for the third parties to provide such services, including your name, residence and email address. The processing of payments or authorization is solely in accordance with these third party’s policies, terms and conditions and We are not in any manner responsible or liable to You or any third party for any delay or failure at their end in processing the payments.</p>
    						 </li>
    						 <li>
    							<p>The Company may also share Personal Information if the Company believe it is necessary in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of various terms and conditions or our policies.</p>
    						 </li>
    						 <li>
    							<p>We reserve the right to disclose your information when required to do so by law or regulation, or under any legal obligation or order under law or in response to a request from a law enforcement or governmental agency or judicial, quasi-judicial or any other statutory or constitutional authority or to establish or exercise our legal rights or defend against legal claims.</p>
    						 </li>
    						 <li>
    							<p>You further agree that such disclosure, sharing and transfer of Your Personal Information and Non-Personal Information shall not cause any wrongful loss to You or to any third party, or any wrongful gain to us or to any third party.</p>
    						 </li>
    					  </ul>
    					  <h5><strong>4. Links to Third Party Websites</strong></h5>
    					  <ul>
    						 <li>
    							<p>Links to third-party advertisements, third-party Websites or any third party electronic communication service may be provided on the Website which are operated by third parties and are not controlled by, or affiliated to, or associated with, the Company unless expressly specified on the Website.</p>
    						 </li>
    						 <li>
    							<p>The Company is not responsible for any form of transmission, whatsoever, received by You from any third party Website. Accordingly, the Company does not make any representations concerning the privacy practices or policies of such third parties or terms of use of such third party Websites, nor does the Company control or guarantee the accuracy, integrity, or quality of the information, data, text, software, music, sound, photographs, graphics, videos, messages or other materials available on such third party Websites. The inclusion or exclusion does not imply any endorsement by the Company of the third party Websites, the Website’s provider, or the information on the Website. The information provided by You to such third party Websites shall be governed in accordance with the privacy policies of such third party Websites and it is recommended that You review the privacy policy of such third party Websites prior to using such Websites.</p>
    						 </li>
    					  </ul>
    					  <h5><strong>5. Security &amp; Retention</strong></h5>
    					  <ul>
    						 <li>
    							<p>The security of your Personal Information is important to us. The Company strives to ensure the security of Your Personal Information and to protect Your Personal Information against unauthorized access or unauthorized alteration, disclosure or destruction. For this purpose, the Company adopts internal reviews of the data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where the Company stores Your Personal Information. Each of the Company entities shall adopt reasonable security practices and procedures as mandated under applicable laws for the protection of Your Information. Provided that Your right to claim damages shall be limited to the right to claim only statutory damages under Information Technology Act, 2000 and You hereby waive and release the Company and its entities from any claim of damages under contract or under tort.</p>
    						 </li>
    						 <li>
    							<p>If you choose a payment gateway to complete any transaction on Website then Your credit card data may be stored in compliance with industry standards/ recommended data security standard for security of financial information such as the Payment Card Industry Data Security Standard (PCI-DSS).</p>
    						 </li>
    						 <li>
    							<p>The Company may share your Information with third parties under a confidentiality agreement which inter alia provides for that such third parties not disclosing the Information further unless such disclosure is for the Purpose. However, the Company is not responsible for any breach of security or for any actions of any third parties that receive Your Personal Information. The Company is not liable for any loss or injury caused to You as a result of You providing Your Personal Information to third party (including any third party Websites, even if links to such third party Websites are provided on the Website).</p>
    						 </li>
    						 <li>
    							<p>Notwithstanding anything contained in this Policy or elsewhere, the Company shall not be held responsible for any loss, damage or misuse of Your Personal Information, if such loss, damage or misuse is attributable to a Force Majeure Event (as defined below).</p>
    						 </li>
    						 <li>
    							<p>A <strong>“Force Majeure Event”</strong> shall mean any event that is beyond the reasonable control of The Company and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorised access to computer, computer system or computer network, computer crashes, breach of security and encryption (provided beyond reasonable control of the Company), power or electricity failure or unavailability of adequate power or electricity.</p>
    						 </li>
    						 <li>
    							<p>While We will endeavour to take all reasonable and appropriate steps to keep secure any Personal Information that We hold about You and prevent unauthorized access, You acknowledge that the internet or computer networks are not fully secure and that We cannot provide any absolute assurance regarding the security of Your Personal Information.</p>
    						 </li>
    						 <li>
    							<p>You agree that all Personal Information shall be retained till such time required for the Purpose or required under applicable law, whichever is later. Non-Personal Information will be retained indefinitely.</p>
    						 </li>
    					  </ul>
    					  <h5><strong>6. User Discretion and Opt-Out</strong></h5>
    					  <ul>
    						 <li>
    							<p>You agree and acknowledge that You are providing your Information out of your free will. You have an option not to provide or permit the Company to collect Your Personal Information or later on withdraw Your consent with respect to such Personal Information so provided herein by sending an email to the Grievance Officer or such other electronic address of the Company as may be notified to You. In such case, You should neither visit any Website nor use any services provided by the Company nor shall You contact any of the Company entities. Further, the Company may not deliver products to You, upon Your order, or the Company may deny you access from using certain services offered on the Website.</p>
    						 </li>
    						 <li>
    							<p>You can add or update Your Personal Information on regular basis. Kindly note that the Company would retain Your previous Personal Information in its records.</p>
    						 </li>
    					  </ul>
    					  <h5><strong>7. Business / Assets Sale or Transfers</strong></h5>
    					  <ul>
    						 <li>
    							<p>The Company may sell, transfer or otherwise share some or all of its assets, including Your Information in connection with a merger, acquisition, reorganization or sale of assets or business or in the event of bankruptcy. Should such a sale or transfer occur, the Company entities will reasonably ensure that the Information you have provided and which We have collected is stored and used by the transferee in a manner that is consistent with this Privacy Policy. Any third party to which the Company is transferred or sold as aforesaid will have the right to continue to use the Information that you provide to us or collected by us immediately prior to such transfer or sale.</p>
    						 </li>
    						 <li>
    							<p>You are also under an obligation to use this Website for reasonable and lawful purposes only, and shall not indulge in any activity that is not envisaged through the Website.</p>
    						 </li>
    					  </ul>
    					  <h5><strong>8. Further Acknowledgements</strong></h5>
    					  <ul>
    						 <li>
    							<p>This privacy policy is clear and easily accessible and provide statements of The Company policies and practices with respective to the Information;</p>
    						 </li>
    						 <li>
    							<p>The trademarks, logos and service marks displayed on the Website (“Marks”) are the property of the Company or its Vendors or respective third parties. You are not permitted to use the Marks without the prior consent of the Company, the Vendor or the third party that may own the Marks.</p>
    						 </li>
    						 <li>
    							<p>This privacy policy provides for the various types of personal or sensitive personal data of information to be collected;</p>
    						 </li>
    						 <li>
    							<p>This privacy policy provides for the purposes of collection and usage of the Information;</p>
    						 </li>
    						 <li>
    							<p>This privacy policy provides for disclosure of Information; and</p>
    						 </li>
    						 <li>
    							<p>This privacy policy provides for reasonable security practices and procedures.</p>
    						 </li>
    					  </ul>
    					  <h5><strong>9. Data Security</strong></h5>
    					  <ul>
    						 <li>
    							<p>We use a number of mechanisms (encryption, passwords, and physical security) to protect the security and integrity of your personal information against unauthorized access and disclosure. Unfortunately, no data transmission over the internet can be guaranteed to be completely secure. So while we strive to protect such information, we cannot ensure or warrant the security of any information you transmit to us and you do so at your own risk. Once any personal information comes into our possession, we will take reasonable steps to protect that information from misuse and loss and from unauthorised access, modification or disclosure. Further Global Garner has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access</p>
    						 </li>
    					  </ul>
    					  <h5><strong>10. Confidentiality of Information</strong></h5>
    					  <ul>
    						 <li>
    							<p>Users who use any of the features on “Global Garner” agree and accept that they have been fully
    							   informed by “Global Garner” that the use of features may lead to publication, to all users of “Global Garner”, of any personal information posted by them while using any specific feature on “Global Garner”. Users further agree that the authenticity of, and consequences from the posting by users of any personal information of themselves or any other person, are the sole responsibility of the user. Users further agree and accept that the terms of the Privacy Policy will be applicable to the use of all features, existing and new. However, the Users agree and accept that confidentiality of information posted on such features has been waived by the Users of such features themselves.
    							</p>
    						 </li>
    					  </ul>
    					  <h5><strong>11. Indemnity</strong></h5>
    					  <ul>
    						 <li>
    							<p>The User should agree to indemnify and hold “Global Garner”, its officers, subsidiaries, affiliates, successors, assigns, directors, officers, agents, service providers, suppliers and employees, harmless from any claim or demand, including reasonable attorney fees and court costs, made by any third party due to or arising out of content submitted by the user, users use of the service, breach by the user of any of the representations and warranties herein, or user’s violation of any rights of another.</p>
    						 </li>
    					  </ul>
    					  <h5><strong>12. Grievances</strong></h5>

    					  <p>Global Garner strongly believes in a sincere and transparent approach to its users. You trust and love us and we would never put growth before trust. This policy aims at minimizing instances of customer complaints, grievances and disappointments via channelized approach, review and redressal. The self-review will be of help in identifying shortcomings in the product features and service delivery. If you’re disappointed, then at some point in time we’ve not done a great enough job. Global Garner’s policy on grievance redressal follows the under noted principles.</p>
    					  <ul>
    						 <li>
    							<p>Our users are to be treated fairly at all times.</p>
    						 </li>
    						 <li>
    							<p>Complaints or concerns raised by users are dealt with an open mind, with courtesy and in a timely manner.</p>
    						 </li>
    						 <li>
    							<p>Users are informed about the channels to escalate their complaints, concerns and grievances within Global Garner and their rights if they are not satisfied with the resolution of their complaints.</p>
    						 </li>
    						 <li>
    							<p>Global Garner will take care of all complaints efficiently and fairly as they value each user.</p>
    						 </li>
    						 <li>
    							<p>All employees at Global Garner must work in good faith and without prejudice to the interests of the users.</p>
    						 </li>
    						 <li>
    							<p>We understand that customer grievances can happen due to multiple reasons. It can be because of the gap between the promised service levels and the service levels actually offered. Since we are dealing with humans, a lot of grievances can arise due to the attitudinal and the way communication gets done. It can also happen due to the actual errors across the system. Users have complete authority to file a complaint/ share feedback if they are disappointed by services rendered by Global Garner. They can give their complaint/ feedback in writing, email, or on <a href="mailto:info@globalgarner.com">info@globalgarner.com</a>. If user’s dilemma is not resolved within the given time or if they are not satisfied with the solution provided by Global Garner, they can approach our tiered redressal system with their complaint or other legal avenues available for grievance redressal. In order to make Global Garner redressal channels more meaningful and effective, a structured system has been put in place. This system will ensure that the complaints are redressed seamlessly and well within the stipulated timeframe.</p>
    						 </li>
    					  </ul>
    					  <h5><strong>13. General</strong></h5>
    					  <ul>
    						 <li>
    							<p>We may update, upgrade, modify (partially and/or fully) this policy at any time, with updates taking effect when you next post or after 30 days, whichever is sooner. If we or our corporate affiliates are involved in a merger or acquisition, we may share personal information with another company, but this policy will continue to apply.</p>
    						 </li>
    					  </ul>
    					  <h5><strong>14. Your Consent</strong></h5>

    					  <p>By using the Website and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this privacy policy.</p>
    					  <p>If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.</p>
    					  <p>Our Privacy Policy applies to all of the services offered by Global garner and its affiliates.</p>
    				   </div>
    				</div>
    			</Card>
        </div>
        );
    }
}
