import React,{Component} from 'react';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import { withRouter } from 'react-router-dom';


class TopBar extends Component {

    goBack(){
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="top-bar">
                <span className="back-icon" onClick={() => this.goBack()}><KeyboardArrowLeftRounded style={{'color':'#383838'}}/></span>
                <h1 className="title m-0">{this.props.title}</h1>
            </div>
        );
    }
}
export default withRouter(TopBar);
