import React, { Component, Fragment } from "react";
import { PageTitle } from "gg-react-utilities";
import Config from "../Config";

export default class Knowus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allModules: [
        {
          name: "User Benefits",
          img: require("./images/svg/userbenefits.svg"),
          to: "/how-to-begin",
        },
        {
          name: "Vendor Benefits",
          img: require("./images/svg/vendorbenefits.svg"),
          to: Config.BUISNESS_URL + "business",
        },
        {
          name: "Business Partner Benefits",
          img: require("./images/svg/businesspartner.svg"),
          to: Config.BUISNESS_URL + "channel-partner",
        },

        {
          name: "How Cashback Works",
          img: require("./images/svg/cashback.svg"),
          to: "/systematic-cashback",
        },
        {
          name: "Media",
          img: require("./images/svg/media.svg"),
          to: "/media",
        },
        {
          name: "Contest",
          img: require("./images/svg/contest.svg"),
          to: "/contest",
        },
        {
          name: "Team",
          img: require("./images/svg/team.svg"),
          to: "/team",
        },
        {
          name: "Pitch",
          img: require("./images/svg/Pitch-Logo.svg"),
          to: "/pitch_video_d272Yiz",
        },
      ],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }

  render() {
    let counter = 0;
    return (
      <Fragment>
        <PageTitle title="Know us | Global Garner" />
        <div className="container">
          <div className="row mt-3 mb-5 min-height">
            {this.state.allModules.map((module, key) => {
              counter++;
              if (counter > 6) counter = 1;
              return (
                <div
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 module-link mt-5 text-center mt-s-0 d-flex align-items-center justify-content-center"
                  key={key}
                >
                  <a href={module.to}>
                    <div className={"card-bg-" + counter}>
                      <img
                        src={module.img}
                        alt={module.name}
                        className="img-fluid"
                        style={{ width: "75%" }}
                      />
                    </div>
                    <span>{module.name}</span>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </Fragment>
    );
  }
}
