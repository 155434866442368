import React, { Component } from 'react';
import Slider from 'react-animated-slider';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NavigateNext, NavigateBefore, FavoriteBorder } from '@material-ui/icons';
import 'react-animated-slider/build/horizontal.css';
import image from '../../../src/core/images/png/mobile-home.png'


const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 1
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
};




export default class AwesomeBswDeals extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingData: true,
			data: ['', '', '', '']
		};
	}

	componentDidMount() {
		this.props.getTestimonialList();
	}

	render() {
		const { testimonialList } = this.props;

		return (
			<section className="py-5 bg-grey-test">
				<div className="container awesome-deals">
					<div className="row mb-3 testimonial">
						<div className="col-xl-12 col-12 pr-s-0 mb-5">
							<h2 className="custom-heading left">Testimonials</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 testimonial-icon awesome-deals-img testimonial mb-2">

							<Carousel responsive={responsive} ref={(el) => (this.Carousel = el)} arrows={false} showDots={true}>
								{testimonialList.map((article, index) =>
									<div key={index} className="bg-ts">
										<div className="row">
											<div className="col-md-10 col-sm-10 col-xs-12 ml-auto mr-auto">
												<div className="space-3rem">
													<div className="row">
														<div className="col-lg-3 col-sm-4 col-md-4 col-6 ml-auto mr-auto">
															<div className="position-relative">
																<img className="img-mobile position-relative" width={100} src={image} />
																<div className="testmonial-user">
																	<img src={process.env.REACT_APP_IMAGE_URL + article.image} draggable="false" />
																	<h5>{article.name}</h5>
																	<p>{article.designation}</p>
																	<p>{article.city}</p>
																</div>
															</div>
														</div>
														<div className=" col-lg-9 col-sm-12 col-md-12 col-10 ml-auto mr-auto testmonial-height">
															<p className="testimonial-list">{article.description}</p>
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>
								)}
							</Carousel>

						</div>
					</div>


				</div>

				<div className="clearfix"></div>
			</section>
		);
	}
}
