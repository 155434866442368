import React, {Component} from 'react';
import Config from '../../Config';
import {ssoInstance} from 'gg-react-utilities';

export default class AwesomeMBDeals extends Component{
    constructor(props){
        super(props);
        this.state = {
          megabrandData:[]
        };
    }

    componentDidMount(){
      ssoInstance.post(Config.MEGABRAND_API_URL + 'all-brands?per_page=18').then((res)=>{

          const sources = res.data;
          if (sources.status === true) {
            this.setState({megabrandData:sources.data.data})
          }

      }).then(function(json) {

      }).catch(function(error) {


      });

    }

    render(){
      const {megabrandData} = this.state;
        return(
            <section className="py-5">
                <div className="container awesome-deals">
                  <div className='text-center'>
                    <h1 className="title">Buy Best Vouchers</h1>
                  </div>

                  <div className="mt-5 mega-brand row">
                    {megabrandData.map((brand, key) => {
                      return(

                        <div className={" col-12 col-xl-2 col-sm-6 col-md-2 px-0  mb-3"} data-brandid={brand.brand_id} key={brand.brand_id}>
                          <a href={Config.MEGABRAND_URL+'brand/'+brand.slug}>

                            <div className="mega-brand-card mr-3 py-3">
                            <div className="text-center">
                                <img
                                    src={brand.image}
                                    className="img-fluid brand-img1"
                                    alt={brand.brand_name}
                                    onError={(ev)=>{
                                      ev.target.src = process.env.REACT_APP_MEGABRAND_DEFAULT_IMAGE;
                                    }}

                                />

                            </div>

                            </div>
                              </a>
                        </div>
                      );
                    })
                  }
                </div>
                <center>
                  <a className="view-all-link mt-3 bp3-text-medium" href={Config.MEGABRAND_URL+'brand'}>View All</a>
                </center>
              </div>
        			<div className="clearfix"></div>
            </section>
        );
    }
}
