import React from 'react';
import {Position, Button, Icon, Label, Toaster} from '@blueprintjs/core';
import { Async } from 'react-select';
import Config from "../../../Config";
import {ssoInstance} from 'gg-react-utilities';
import {base64_url_encode} from '../../../core/utils/helper';
import {Intent} from "@blueprintjs/core/lib/esm/index";
import {DateRangeInput} from "@blueprintjs/datetime";
import _ from "lodash";
import RoomSelection from './RoomSelection';

const customDropdownStyles = {

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: 'none',

    }),
    indicatorSeparator: base => ({
        ...base,
        display: 'none',

    }),
    menu: (provided, state) => ({
        ...provided,
        marginTop: '0px',
        borderRadius: '0 0 3px 3px '
    }),
    option: (provided, state) => ({
        ...provided,
        textAlign: 'left'
    }),
}


export default class SearchBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }


    defaultProps = () => {

        let showRecentSearches = (this.props.showRecentSearches !== undefined) ? ((this.props.showRecentSearches === true) ? true : false) : false;
        let startDate = new Date();
        let endDate = new Date(new Date(new Date().getFullYear(), (new Date().getMonth()), (new Date().getDate() + 1)));
        let rooms = [
            {
                adults: 2,
                children: 0
            }
        ];

        return {
            location: null,
            locationLabel: null,
            showRecentSearches,
            startDate,
            endDate,
            rooms,
        };
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.searchTerms !== undefined) {

            this.setState({
                location: nextProps.searchTerms.location,
                locationLabel: nextProps.searchTerms.locationLabel,
                startDate: nextProps.searchTerms.startDate,
                endDate: nextProps.searchTerms.endDate,
                rooms: nextProps.searchTerms.rooms
            });
        }
    }

    updateRoomGuests = (rooms) => {
        this.setState({
            rooms
        });
    }

    saveSearchTermToLocal = (totalGuests) => {

        let currentSearchedItem = {
            location: this.state.location,
            locationLabel: this.state.locationLabel,
            startDate: this.state.startDate.getTime(),
            endDate: this.state.endDate.getTime(),
            totalGuests: totalGuests,
            rooms: base64_url_encode(JSON.stringify(this.state.rooms)),
        };

        let recentViewed = localStorage.getItem('recentViewed');

        if (recentViewed === null) {

            recentViewed = [currentSearchedItem];

        } else {
            recentViewed = JSON.parse(recentViewed);

            if (Array.isArray(recentViewed)) {

                for (let i = 0; i < recentViewed.length; i++) {
                    if (recentViewed[i].location.toLowerCase() === currentSearchedItem.location.toLowerCase()) {
                        recentViewed.splice(i, 1);
                    }
                }

                recentViewed.push(currentSearchedItem)

                if (recentViewed.length > 6) {
                    recentViewed.splice(0, 1);
                }
            } else {
                recentViewed = [currentSearchedItem];
            }
        }

        localStorage.setItem('recentViewed', JSON.stringify(recentViewed));

    }


    getOptions = (searchTerm) => {
        return ssoInstance.get(Config.HOTEL_API_URL + 'autocomplete?search=' + searchTerm)
            .then(response => {
                // do you have to `parse`? I don't remember...
                if (response && response.data && response.data.data) {

                    return response.data.data; // this is the options array?
                }
                return [];
            })
            .catch(error => {
                return [];
                // whoops! what now?
            });

    };

    onChange = (location) => {
        this.setState({
            location: location.value,
            locationLabel: location.label,
        });

    }

    getOptionValue = (option) => option.value;

    getOptionLabel = (option) => option.label;

    validateSearchForm = () => {

        if (this.state.location === null || this.state.location === '') {
            Toaster.create({position: Position.TOP,}).show({
                message: "Please Select Destination",
                intent: Intent.DANGER
            });
            return false;
        }
        if (this.state.startDate === null || this.state.startDate === '') {
            Toaster.create({position: Position.TOP,}).show({
                message: "Please Select checkin date",
                intent: Intent.DANGER
            });
            return false;
        }
        if (this.state.endDate === null || this.state.endDate === '') {
            Toaster.create({position: Position.TOP,}).show({
                message: "Please Select checkout date",
                intent: Intent.DANGER
            });
            return false;
        }

        return true;
    }

      searchHotel = (isFromDetailPage = false) => {

      if (this.validateSearchForm() === false) {
          return false;
      }

      const formData = new FormData();

      formData.append('locationLabel', (this.state.locationLabel.length > 20) ? this.state.locationLabel.substring(0, 20) + "..." : this.state.locationLabel);
      formData.append('startDate', this.state.startDate.getTime());
      formData.append('endDate', this.state.endDate.getTime());
      formData.append('rooms', base64_url_encode(JSON.stringify(this.state.rooms)));
      formData.append('location', this.state.location);
      let url = this.props.url
      if (isFromDetailPage === true) {
          formData.append('hotel', base64_url_encode(this.state.hotel));
          formData.append('provider', this.state.provider);
          const queryString = new URLSearchParams(formData).toString();
          url = url + 'hotel?' + queryString;

      } else {
          const queryString = new URLSearchParams(formData).toString();

          let totalGuests = 0;

          this.state.rooms.map(function (room) {
              totalGuests += (room.adults + room.children);
              return true;
          });

          this.saveSearchTermToLocal(totalGuests);
          url = url + 'search?' + queryString;
      }

      window.location.href = url;
    }

    render() {
        var dateFormatOptions = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
        if (this.props.isVertical === true) {
            dateFormatOptions = {weekday: 'short', year: '2-digit', month: 'short', day: 'numeric'};
            return (
                <div className="strip-form">
                    <div className="col-12">
                        <div className="row d-flex align-items-center justify-content-between">
                            <div className="check date-range">
                                <label>Check In</label>
                                <DateRangeInput
                                    endInputProps={{placeholder: "To"}}
                                    startInputProps={{placeholder: "From"}}
                                    shortcuts={false}
                                    className="booknow-date-picker"
                                    closeOnSelection={true}
                                    minDate={new Date()}
                                    maxDate={new Date(new Date(new Date().getFullYear() + 1, (new Date().getMonth()), (new Date().getDate())))}
                                    formatDate={date => date.toLocaleDateString("en-IN", dateFormatOptions)}
                                    popoverProps={{position: Position.BOTTOM}}
                                    parseDate={str => new Date(str)}
                                    value={[this.state.startDate, this.state.endDate]}
                                    onChange={(date) => {
                                        this.setState({
                                            startDate: date[0],
                                            endDate: date[1]
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="col-12">
                        <div className="row">
                            <div className="bp3-form-group">
                                <div className="bp3-form-content">
                                    <Label>Booking Details</Label>
                                    <RoomSelection
                                        isVertical={this.props.isVertical}
                                        rooms={this.state.rooms}
                                        updateRoomGuests={(rooms) => this.updateRoomGuests(rooms)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
        return (
          <div className="strip-form">
                <div className="hotel-strip-box">
                    <div className="row mt-3 mt-s-0">
                        <div
                            className="col-md-12 col-12 pr-sm-0 bg-white rounded shadow-search px-s-2">
                            <div
                                className="col-lg-4 col-md-6 col-sm-12 col-12 mt-s-2 border-none-xs d-flex align-items-center pull-left">
                                {(this.state.locationLabel !== undefined && this.state.locationLabel !== null) ?
                                    <React.Fragment>
                                        {/*//DO NOT REMOVE THIS CONSOLE*/}
                                        {/*//DO NOT REMOVE THIS CONSOLE*/}
                                        <Async
                                            icon="map-marker"
                                            className='strip-select'
                                            classNamePrefix="strip-select"
                                            styles={customDropdownStyles}
                                            name="location"
                                            onChange={(value) => this.onChange(value)}
                                            defaultValue={{
                                                label: this.state.locationLabel,
                                                value: this.state.location
                                            }}
                                            loadOptions={_.debounce(this.getOptions, 50)}
                                            placeholder={'Search by City, Hotel, or Area'}
                                            getOptionValue={this.getOptionValue}
                                            getOptionLabel={this.getOptionLabel}
                                        />
                                    </React.Fragment>
                                    :
                                    <Async
                                        styles={customDropdownStyles}
                                        className='strip-select'
                                        classNamePrefix="strip-select"
                                        name="location"
                                        onChange={(value) => this.onChange(value)}
                                        loadOptions={_.debounce(this.getOptions, 50)}
                                        placeholder={'Search by City, Hotel, or Area'}
                                        getOptionValue={this.getOptionValue}
                                        getOptionLabel={this.getOptionLabel}
                                    />}
                                <Icon icon="map-marker" iconSize={20} className="inputaddon-icon pull-left text-muted"/>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-s-3 d-flex check date-range">
                                <DateRangeInput
                                    endInputProps={{placeholder: "To"}}
                                    startInputProps={{placeholder: "From"}}
                                    shortcuts={false}
                                    className="full-width-date-picker"
                                    closeOnSelection={true}
                                    minDate={new Date()}
                                    maxDate={new Date(new Date(new Date().getFullYear() + 1, (new Date().getMonth()), (new Date().getDate())))}
                                    formatDate={date => date.toLocaleDateString("en-IN", dateFormatOptions)}
                                    popoverProps={{position: Position.BOTTOM}}
                                    parseDate={str => new Date(str)}
                                    value={[this.state.startDate, this.state.endDate]}
                                    onChange={(date) => {
                                        this.setState({
                                            startDate: date[0],
                                            endDate: date[1]
                                        })
                                    }}
                                />
                                <Icon icon="calendar" iconSize={20} className="inputaddon-icon"/>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12 col-sm-3 mt-s-2 px-lg-0 pull-left mt-xl-2 pt-xl-1">
                                <div className="bg-white-xs">
                                    <RoomSelection
                                        rooms={this.state.rooms}
                                        updateRoomGuests={(rooms) => this.updateRoomGuests(rooms)}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt-s-4 pull-left">
                                <Button
                                    text="Search"
                                    intent={Intent.PRIMARY}
                                    className="search_btn text-center"
                                    onClick={() => this.searchHotel()}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
