import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import AwsSliderStyles from 'react-awesome-slider/src/styles';
import slider_1 from '../images/slider/slider_1.jpg';
import slider_2 from '../images/slider/slider_2.jpg';
import slider_3 from '../images/slider/slider_3.jpg';
import slider_5 from '../images/slider/slider_5.jpg';

export default class Slider extends React.Component{
    constructor(){
        super();
        this.state = {
            SelectedSlide: 0
        }
    }

    tick() {
        this.setState(prevState => ({
            SelectedSlide: (this.state.SelectedSlide === 4) ? 0 : this.state.SelectedSlide + 1
        }));
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render(){
        return(
            <React.Fragment>
                <div>
                    {/*SelectedSlide: {this.state.SelectedSlide}*/}
                </div>
                {
                    <AwesomeSlider
                        cssModule={AwsSliderStyles}
                        infinite={true}
                        autoplay={true}
                        selected={this.state.SelectedSlide}
                    >
                        <div data-src={slider_1} alt="" />
                        <div data-src={slider_2} alt="" />
                        <div data-src={slider_3} alt="" />
                        <div data-src={slider_5} alt="" />
                    </AwesomeSlider>
                }
            </React.Fragment>
        )
    }
}