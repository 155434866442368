import React, {Component} from 'react';
import ResumeComponent from "./container/resumePostContainer";
import {PageTitle} from 'gg-react-utilities';


export default class Resume extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }
    
    render(){

        return(
            <React.Fragment>
              <PageTitle title='Career | Global Garner' />
                <div className="container-fluid header-below">
                <div className="container">
                  <div className="career header-below-content">
                    <div className="col-12 col-xl-12 text-center py-5 py-s-0 py-sm-3 p-s-0">
                      <h1 className="bp3-text-xlarge heading">Candidate Information</h1>
                      <span className="heading-desc">
                        Please fill all mandatory fields
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                <div className="container-fluid">
                    <ResumeComponent />
                </div>
            </React.Fragment>
        );
    }
}
