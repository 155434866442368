import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { ArrowForwardRounded, PlayArrowRounded } from "@material-ui/icons";
import Youtube from "../core/components/Youtube";
// import YoutubeImg from '../core/images/png/Youtube.png';
import { PageTitle, Auth } from "gg-react-utilities";
import Introduction from "./components/Introduction";
import Cashback from "./components/cashback";
import MoneyCoin from "./components/MoneyCoin";
import Vendor from "./components/vendor";
import UPOS from "./components/upos";
import Trolley from "./components/trolley";
import FuelCashback from "./components/fuelCashback";
import Summary from "./components/summary";
import Mission from "./components/mission";
import Config from "../Config";

export default class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoPlayed: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }

  render() {
    let metaData = document.getElementsByTagName("meta");
    metaData["description"].content =
      "Get information about Global Garner company profile and meet the dedicated team members";
    metaData["keywords"].content =
      "Know us, About us, About us online shopping, Upto 100% cashback, Cashback";

    return (
      <Fragment>
        <PageTitle title="About Us | Global Garner" />
        <div className="container-fluid header-below">
          <div className="container">
            <div className="header-below-content about-us">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12 col-12 p-s-0">
                  <h1 className="bp3-text-xlarge heading">
                    Let’s Show the world What a Salesman can do!
                  </h1>
                  <span className="heading-desc">
                    Global Garner is a Universal app to save what you spend by
                    providing the best offer of Cashback/Reward with no upper
                    limit.
                    <br />
                    <br />
                    Global Garner is also the world's 1st and only postpaid
                    sales company with zero UPFRONT COST, which helps the vendor
                    to sell their products and services with its Advanced
                    Digital Platform and on-ground sales support.
                    <br />
                    <br />
                    So, Register for Free and enjoy the unlimited benefits of
                    Global Garner!
                  </span>
                  <div className="clearfix"></div>
                  <div className="row btn-area pt-5 text-center-xs">
                    {!Auth.isLoggedIn() ? (
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-5 col-12 mb-s-3">
                        <a
                          className="spl-btn"
                          href={Config.ACCOUNT_URL + "register"}
                        >
                          Register
                        </a>
                      </div>
                    ) : null}
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-7 col-12 mt-s-4">
                      <Link
                        className="spl-btn btn-bg-blur with-icon"
                        to="/video"
                      >
                        Watch Video{" "}
                        <span>
                          <ArrowForwardRounded />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-5 col-sm-12 col-12 p-s-0">
                  {this.state.videoPlayed ? (
                    <Youtube
                      videoId="gEUp7ITlJSg"
                      onEnd={() => {
                        this.setState({ videoPlayed: false });
                      }}
                      autoPlay
                    />
                  ) : (
                    <div className="youtube-video">
                      <center>
                        <img
                          src={require("../core/images/png/Youtube.png")}
                          alt="youtube"
                          className="youtub-img img-fluid"
                          onClick={() => {
                            this.setState({ videoPlayed: true });
                          }}
                        />
                      </center>
                      <span
                        className="box-shadow-2"
                        onClick={() => {
                          this.setState({ videoPlayed: true });
                        }}
                      >
                        <PlayArrowRounded style={{ fontSize: "75px" }} />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Introduction />
        <Cashback />
        <MoneyCoin />
        <Vendor />
        <UPOS />
        <Trolley />
        <FuelCashback />
        <Summary />
        <Mission />
      </Fragment>
    );
  }
}
