import React, {Component} from "react";
import ReactPlayer from "react-player";

export default class VideoAutoPlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true
        }
        this.closeFrame = this
            .closeFrame
            .bind(this);
    }
    closeFrame() {
        this.setState({open: false})
    }
    render() {

        return null; // Remove this for show video

        if (window.screen.width <= 576) 
            return null;
        let link = this.props.link;

        if (!this.state.open) {
            return null;
        }

        if (this.props.link == undefined || this.props.link == null || this.props.link == "") {
            return null;
        }

        console.log(this.props);
        return (
            <div
                style={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                width: 450,
                height: 300,
                background: '#b9b4b4',
                padding: 15,
                paddingTop: 5
            }}>
                <div
                    style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <a
                        href="javascript:;"
                        onClick={this.closeFrame}
                        style={{
                        background: '#020000',
                        padding: 5,
                        color: 'white',
                        fontWeight: 'bold',
                        fontFamily: 'arial'
                    }}>X</a>
                </div>
                <ReactPlayer url={link} playing width='100%' height='100%'/>
            </div>
        );
    }
}