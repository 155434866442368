import React, {Component} from 'react';

export default class Cashback extends Component{

    render(){
        return(
            <React.Fragment>
                <div className="cashbackWrapper">
                    <div className="container">
                        <div className="cashbackHeader">
                            <h3>Cashback Options</h3>
                        </div>
                        <div className="cashbackContainer">
                            <div className="row">
                                <div className="col-md-6 mb-5 col-sm-6 col-12">
                                    <div className="cashbackGrid">
                                        <div className="cashbackImage">
                                          <center>
                                            <img src={require('../../images/png/IC.png')} className="img-fluid" alt=""/>
                                          </center>
                                        </div>
                                        <div className="cashbackGridDesc">
                                            <h4>Instant Cashback</h4>
                                            <p>2% to 10% Cashback in the form of GG Money which User can
                                                transfer to Bank Account.</p>
                                              <br/>
                                        </div>
                                        <div className="cashbackKnow">
                                            <a href="/systematic-cashback">Know More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-5 col-sm-6 col-12 mt-s-3 mt-sm-0">
                                    <div className="cashbackGrid">
                                        <div className="cashbackImage">
                                          <center>
                                            <img src={require('../../images/png/SCB-RPP.png')} className="img-fluid" alt=""/>
                                          </center>
                                        </div>
                                        <div className="cashbackGridDesc">
                                            <h4>SCB Random Pick Process</h4>
                                            <p>100% GG Coin with 2% to 100% Cashback in a month’s Time.
                                                2% is GG Gift Voucher and 5%, 15%, 100% is GG Money which User
                                                can transfer to Bank Account.</p>
                                        </div>
                                        <div className="cashbackKnow">
                                            <a href="/systematic-cashback">Know More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-5 col-sm-6 col-12 mt-s-3 mt-sm-0">
                                    <div className="cashbackGrid">
                                        <div className="cashbackImage">
                                          <center>
                                            <img src={require('../../images/png/SCB-FIFO.png')} className="img-fluid" alt=""/>
                                          </center>
                                        </div>
                                        <div className="cashbackGridDesc">
                                            <h4>SCB First In First Out</h4>
                                            <p>100% GG Coin with ₹ 200 GG Gift Voucher +
                                            100% Real Money in 3 unequal installments which User can transfer
                                                to Bank Account.</p>
                                              <br/>
                                        </div>
                                        <div className="cashbackKnow">
                                            <a href="/systematic-cashback">Know More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-5 col-sm-6 col-12 mt-s-3 mt-sm-4 mt-md-0">
                                    <div className="cashbackGrid">
                                        <div className="cashbackImage">
                                          <center>
                                            <img src={require('../../images/png/my_circle.png')} className="img-fluid" alt=""/>
                                          </center>
                                        </div>
                                        <div className="cashbackGridDesc">
                                            <h4>My Circle</h4>
                                            <p>
                                              GG Money cashback upto ₹ 2100000/- just by referral and 100% instant GG Coin along with GG Coin conversion into GG
                                              Money upto ₹ 50 lakh which User can transfer to Bank Account
                                            </p>
                                        </div>
                                        <div className="cashbackKnow">
                                            <a href="/systematic-cashback">Know More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
