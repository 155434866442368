import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";

export default class LoadingComponent extends Component{

    render(){

        return(
          <div className="row py-5">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton rounded-circle px-3" style={{height: "120px",width:'50%'}} />
                    <br/>
                    <div className="bp3-skeleton mb-3 " style={{height: "25px",width:'50%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />

                  </center>

              </Card>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton rounded-circle px-3" style={{height: "120px",width:'50%'}} />
                    <br/>
                    <div className="bp3-skeleton mb-3 " style={{height: "25px",width:'50%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />

                  </center>

              </Card>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton rounded-circle px-3" style={{height: "120px",width:'50%'}} />
                    <br/>
                    <div className="bp3-skeleton mb-3 " style={{height: "25px",width:'50%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />

                  </center>

              </Card>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton rounded-circle px-3" style={{height: "120px",width:'50%'}} />
                    <br/>
                    <div className="bp3-skeleton mb-3 " style={{height: "25px",width:'50%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />

                  </center>

              </Card>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton rounded-circle px-3" style={{height: "120px",width:'50%'}} />
                    <br/>
                    <div className="bp3-skeleton mb-3 " style={{height: "25px",width:'50%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />

                  </center>

              </Card>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton rounded-circle px-3" style={{height: "120px",width:'50%'}} />
                    <br/>
                    <div className="bp3-skeleton mb-3 " style={{height: "25px",width:'50%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />

                  </center>

              </Card>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton rounded-circle px-3" style={{height: "120px",width:'50%'}} />
                    <br/>
                    <div className="bp3-skeleton mb-3 " style={{height: "25px",width:'50%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />

                  </center>

              </Card>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton rounded-circle px-3" style={{height: "120px",width:'50%'}} />
                    <br/>
                    <div className="bp3-skeleton mb-3 " style={{height: "25px",width:'50%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />

                  </center>

              </Card>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <Card className='bp3-elevation-1'>
                  <center>
                    <div className="bp3-skeleton rounded-circle px-3" style={{height: "120px",width:'50%'}} />
                    <br/>
                    <div className="bp3-skeleton mb-3 " style={{height: "25px",width:'50%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />
                    <div className="bp3-skeleton mb-3" style={{height: "20px",width:'90%'}} />

                  </center>

              </Card>
            </div>

          </div>
        );

    }

}
