let initialState = {
    teamList: null,
    moduleList:[],
    link:'',
    bswDataList: [],
    megabrandDataList: [],
    topDealList: [],
    vendorList: [],
    uposVendorList:null,
    faqList: [],
    faqCategoryList:[],
    searchFaqQuery:[],
    careerList: [],
    imageList: [],
    videoList: [],
    desktopWallpaperList: [],
    mobileWallpaperList: [],
    testimonialList:[],
    ringtoneList: [],
    pdfList: [],
    postResume:null,
    fromList:[],
    brandList: [],
    pincodeList: [],
    partner:null,
    mcpLatLngListData:[],
    acpLatLngListData:[],
    cpLatLngListData:[],
    centralSearchData:null,
    uposPartnerData:null
};
const team = (state = initialState, action) => {

    switch (action.type) {
        case 'VIDEO_LINK':
            return {...state, link:action.link}
        case 'MODULE_LIST':
            return {...state, moduleList: action.sources}
        case 'BSW_DATA_LIST':
          return {...state, bswDataList: action.sources}
        case 'TESTIMONIALS_LIST':
          return {...state, testimonialList: action.sources}
        case 'MEGABRAND_DATA_LIST':
          return {...state, megabrandDataList: action.sources}
        case 'TOP_DEAL_LIST':
          return {...state, topDealList: action.sources}
        case 'VENDOR_LIST':
          return {...state, vendorList: action.sources}
        case 'UPOS_VENDOR_LIST':
          return {...state, uposVendorList: action.sources}
        case 'TEAM_LIST':
            return {...state, teamList: action.sources}
        case 'FAQ_LIST':
            return {...state, faqList: action.sources}
        case 'SEARCH_FAQ_LIST':
            return {...state, searchFaqQuery: action.sources}
        case 'CATEGORY_FAQ_LIST':
            return {...state, faqCategoryList: action.sources}
        case 'CAREER_LIST':
            return {...state, careerList: action.sources}
        case 'IMAGE_LIST':
            return {...state, imageList: action.sources}
        case 'VIDEO_LIST':
            return {...state, videoList: action.sources}
        case 'PDF_LIST':
            return {...state, pdfList: action.sources}
        case 'DESKTOP_WALLPAPER_LIST':
            return {...state, desktopWallpaperList: action.sources}
        case 'MOBILE_WALLPAPER_LIST':
            return {...state, mobileWallpaperList: action.sources}
        case 'RINGTONE_LIST':
            return {...state, ringtoneList: action.sources}
        case 'POST_RESUME':
            return {...state, postResume: action.sources}
        case 'BUS_FROM_LIST':
            return {...state, fromList: action.sources}
        case 'BRAND_LIST':
            return {...state, brandList: action.sources}
        case 'PARTNER_LIST':
            return {...state, partner: action.sources}
        case 'MCP_LAT_LNG_LIST':
            return {...state, mcpLatLngListData: action.sources}
        case 'ACP_LAT_LNG_LIST':
            return {...state, acpLatLngListData: action.sources}
        case 'CP_LAT_LNG_LIST':
            return {...state, cpLatLngListData: action.sources}
        case 'UPOS_PARTNER_LIST':
            return {...state, uposPartnerData: action.sources}
        case 'CENTRAL_SEARCH':
            return {...state, centralSearchData: action.sources}
        default:
            return state
    }
}

export default team;
