import React, {Component, Fragment} from 'react';
import Loading from './loading';
import Module from './ggModule';
import BSW from './BSW';
import LABS from './LABS';
import GRANGE from './GRANGE';
import Megabrand from './Megabrand';
import Vendor from './Vendor';
import Product from './Product';
import Service from './Service';

export default class Search extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
      let searchText = new URLSearchParams(window.location.search);
      searchText=searchText.get('gg_search');
      if(this.props.locationData!==null){
        this.props.getCentralSearchData(this.props.locationData.lat,this.props.locationData.lng,searchText);
      }else{
        this.props.getCentralSearchData(0,0,searchText);
      }
    }

    componentWillReceiveProps(nextProps){
    }

    render(){
        const {centralSearchData} = this.props;
        return(
          <Fragment>

            <div className='container'>
                <section className="pt-3">
                  {centralSearchData===null?
                    <Fragment>
                      <div className="row">
                        <div className="col-xl-7 col-xl-7 col-sm-7 col-8">

                        </div>
                      </div>
                      <Loading loadingType='module'/>
                    </Fragment>
                    :
                    centralSearchData.gg_modules!==undefined?
                      <Module data={centralSearchData.gg_modules}/>
                    :null
                  }
                </section>
                <div className="clearfix"></div>
                <section className="pt-5">
                  {centralSearchData===null?
                    <Fragment>
                      <div className="row">
                        <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
                          <h2 className="product-title">Best Shopping Websites</h2>
                        </div>
                      </div>
                      <Loading />
                    </Fragment>
                    :
                    centralSearchData.bsw_affiliates!==undefined?
                      <BSW data={centralSearchData.bsw_affiliates}/>
                    :null
                  }
                </section>
                <div className="clearfix"></div>
                <section className="pt-5">
                  {centralSearchData===null?
                    <Fragment>
                      <div className="row">
                        <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
                          <h2 className="product-title">Megabrands</h2>
                        </div>
                      </div>
                      <Loading />
                    </Fragment>
                    :
                    centralSearchData.mb_brands!==undefined?
                      <Megabrand data={centralSearchData.mb_brands}/>
                    :null
                  }
                </section>
                <div className="clearfix"></div>
                <section className="pt-5">
                    {centralSearchData===null?
                        <Fragment>
                            <div className="row">
                                <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
                                    <h2 className="product-title">GG Labs</h2>
                                </div>
                            </div>
                            <Loading />
                        </Fragment>
                        :
                        centralSearchData.gg_labs!==undefined?
                            <LABS data={centralSearchData.gg_labs}/>
                            :null
                    }
                </section>
                <div className="clearfix"></div>
                <section className="pt-5">
                    {centralSearchData===null?
                        <Fragment>
                            <div className="row">
                                <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
                                    <h2 className="product-title">GG Grange</h2>
                                </div>
                            </div>
                            <Loading />
                        </Fragment>
                        :
                        centralSearchData.gg_labs!==undefined?
                            <GRANGE data={centralSearchData.gg_grange}/>
                            :null
                    }
                </section>
                <div className="clearfix"></div>
                <section className="pt-5">
                  {centralSearchData===null?
                    <Fragment>
                      <div className="row">
                        <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
                          <h2 className="product-title">GG Vendors</h2>
                        </div>
                      </div>
                      <Loading />
                    </Fragment>
                    :
                    centralSearchData.store_vendors!==undefined ?
                      <Vendor data={centralSearchData.store_vendors}/>
                    :null
                  }
                </section>
                <div className="clearfix"></div>
                <section className="pt-5">
                  {centralSearchData===null?
                    <Fragment>
                      <div className="row">
                        <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
                          <h2 className="product-title">GG Products</h2>
                        </div>
                      </div>
                      <Loading />
                    </Fragment>
                    :
                    centralSearchData.store_products!==undefined?
                      <Product data={centralSearchData.store_products}/>
                    :null
                  }
                </section>
                <div className="clearfix"></div>
                <section className="pt-5">
                  {centralSearchData===null?
                    <Fragment>
                      <div className="row">
                        <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
                          <h2 className="product-title">GG Services</h2>
                        </div>
                      </div>
                      <Loading />
                    </Fragment>
                    :
                    centralSearchData.store_services!==undefined?
                      <Service data={centralSearchData.store_services}/>
                    :null
                  }
                </section>
                <div className="clearfix"></div>
            </div>

          </Fragment>
        );
    }
}
