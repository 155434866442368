import React, {Component, Fragment} from 'react';
import {PageTitle, Loader, ssoInstance} from 'gg-react-utilities';
import TopBar from "../core/components/TopBar";
import Config from '../Config';

export default class Knowus extends Component {
    constructor(props){
        super(props);
        this.state = {
          sitemap:[


          ]
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    componentWillMount(){
      ssoInstance.get(Config.STATS_API_URL + 'get-sitemap-links').then((res)=>{

          const sources = res.data;
          if (sources.status === true) {
            this.setState({sitemap:sources.data.sitemapArr });
          }

      }).then(function(json) {

      }).catch(function(error) {


      });
    }

    render(){
        return(
            <Fragment>
      				<PageTitle title='Sitemap | Global Garner' />
              <div className='container'>
                <TopBar title="Sitemap"/>
                <div className="mb-5 min-height">
                  <ul className='sitemap'>
                    {this.state.sitemap.length===0?
                      <div className='col-xl-12 col-12 text-center py-5'>
                        <div className='py-5 mt-5'>
                          <center>
                            <Loader/>
                            <p className='mt-2'>Please wait ...</p>
                          </center>
                        </div>
                      </div>
                      :this.state.sitemap.map((res,id)=>{
                      return(
                        <div className='sitemap-list mb-5' key={id}>
                          <h2>{res.group_name}</h2>
                          {res.group_links.map((link,i)=>{
                            return(
                              <a href={link.link} title={link.name} key={i}>{link.name}</a>
                            )
                          })}
                        </div>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </Fragment>
        );
    }
}
