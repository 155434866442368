import React, {Component} from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Classes, Dialog} from "@blueprintjs/core";

const style = {
  width: '100%',
  height: '100%',
  left: '-15px'

}

export class MapContainer extends Component {
    constructor(props){
        super(props);
        this.state={
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: props.isOpen,
            usePortal: true,
            selectedOption:'',
            hasPincodeError:false,
        }
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      })
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextprops){
      if(nextprops.isOpen!==undefined){
          this.setState({
              isOpen: nextprops.isOpen
          });
      }
    }
  render() {

    return (
      <Dialog
        isOpen={this.state.isOpen}
        title={this.props.title}
        onClose={this.handleClose}
        className={this.props.className?this.props.className:''}>
         <div className={Classes.DIALOG_BODY+' p-1 m-0'} >
           <div className='col-12 ' style={{height:'600px'}}>
            <Map
              style={style}
              google={this.props.google}
              initialCenter={{lat:20.5937,lng:78.9629}}
               zoom={4.55}>
               {this.props.cpLatLngListData.map((res,i)=>{
                 return(
                   <Marker
                     key={i}
                     position={{lat:Number(res.bs_place_latitude),lng:Number(res.bs_place_longitude)}}
                     icon={{
                       url: "https://www.globalgarner.com/gg-assets/img/cp.png",
                     }} />
                 );
               })}
               {this.props.mcpLatLngListData.map((res,i)=>{
                 return(
                   <Marker
                     key={i}
                     position={{lat:Number(res.bs_place_latitude),lng:Number(res.bs_place_longitude)}}
                     icon={{
                       url: "https://www.globalgarner.com/gg-assets/img/mcp.png",
                     }} />
                 );
               })}
               {this.props.acpLatLngListData.map((res,i)=>{
                 return(
                   <Marker
                     key={i}
                     position={{lat:Number(res.bs_place_latitude),lng:Number(res.bs_place_longitude)}}
                     icon={{
                       url: "https://www.globalgarner.com/gg-assets/img/acp.png",
                     }} />
                 );
               })}
            </Map>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAPDJ53fvwujHOA8stIsINFb5wPMyklCjo')
})(MapContainer)
