import {connect} from 'react-redux';

import CallUs from '../components/modal/callUs';
import {getPincodeList, getPartnerList} from '../../core/actions/action';
import {partnerListAction} from "../../core/actions";
import {Intent, Position, Toaster} from "@blueprintjs/core";

const mapStateToProps = state => {

    return{
        partner:state.RetailReducer.partner,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getPincodeList:(input)=>{
            return getPincodeList(input)
        },
        getPartnerList:(pin) => {
          dispatch(partnerListAction(null));
            return getPartnerList(pin)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(partnerListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(partnerListAction(null));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallUs);
