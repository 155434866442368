import React, {Component} from 'react';

const data = [
  {
    title:'All in one universal App',
    image:require('../images/png/All_in_one_Universal_App.png'),
    content:'<h3>One mobile, one app for all your needs with the universe of all the products & services you can think of like, all packed into one app & website to save what you spend.<br/> Using this unique app, you can shop for your daily essentials, utilities, pay for your daily needs, spend on wide array of shopping options which you have been doing since decades but getting nothing but now with us you can do the same without any worry and can get real cashback along with <b>instant rewards in the form of GG Coins*.</b></h3>'
  },
  {
    title:'Best Online Shopping Websites like Amazon, Myntra, Big Basket etc',
    image:require('../images/png/bsw.png'),
    content:'<h3>Explore the largest conglomeration of shopping websites you have been shopping since long at the best price along with amazing cashback/Reward from us all in one app.</h3>'
  },
  {
    title:'Best Brand Stores /Mega Brand like Shoppers stop, Lifestyle, Big Bazar etc',
    image:require('../images/png/megabrand.png'),
    content:'<h3>Grab vouchers of your favourite Mega brand stores and enjoy the traditional way of shopping at the store with our splendid Cashback/Reward offers.</h3>'
  },
  {
    title:'Flight and bus booking',
    image:require('../images/png/bus.png'),
    image1:require('../images/png/flight.png'),
    content:'<h3>Life is a journey and Journey is adventurous, Explore the places  and Book your tickets at the cheapest rates with an incredible offers of cashback/rewards.</h3>'
  },
  {
    title:'Regional local vendors',
    image:require('../images/png/local_vendor.png'),
    content:'<h3>Shopping, a traditional way is always the first choice. Now you can shop from your local vendors and enjoy awesome cashback/ reward offers, like never before.</h3>'
  },
  {
    title:'Mobile Recharge & Online Bill Payments',
    image:require('../images/png/bill_pay&recharge.png'),
    content:'<h3>Make hassle-free utility bill payments/recharge from anywhere, with our safe & secure online recharge and bill pay portal and avail the benefits of cashback/Reward.</h3>'
  },
  {
    title:'Railbhoj',
    image:require('../images/png/railbhoj.png'),
    content:'<h3>Order yummylicious meals on wheels at your seat when you travel by train from your favourite restaurants and enjoy cashback/reward too.</h3>'
  },
  {
    title:'',
    image:'',
    content:'<h3><b>And many more collection of shopping options like Entertainment, Loan consultancy, Resale, Real Estate, Franchise, Food Ordering, Job Search, Matrimonial Search, News, Donation, Crowdfunding, Fuel Cashback, Chat, Make My Cart, GG rewards</b> etc along with best price and discounts offered by the respective brands and vendors.</h3>'
  },
  {
    title:'Guaranteed 2% to 100% cashback/ reward on all your purchases',
    image:require('../images/png/SCB-RPP.png'),
    content:'<h3>Never before opportunity to choose your very own from 3 exclusive cashback options : IC, SCB RPP, SCB FIFO.<br/>IC:In the case of IC, you get instant cashback up to 10% . <br/>SCP RPP:  In case of SCB RPP, you can get 2% to 100% cashback/ reward in a month, through the random selection of UPV*.<br/> SCB FIFO: If you can wait more get 100% cashback in 3 instalments through Systematic Cashback Process or SCB by capturing the movement of the economy with No Upper Limit as soon your User Purchase Value* (UPV) is generated in the system on cumulative purchase of ₹ 10000/-. So, get ready to earn & enjoy Cashback on everything you shop via Global Garner. <br/> <br/> <b>Note:</b> All this is made possible through our copyrighted business model</h3>'
  },
  {
    title:'Earn Real Cashback up to 100% that Can Be Transferred to Your Bank Account',
    image:require('../images/png/Transfer-to-bank-account.png'),
    content:'<h3>Get your cashback in your GG rewards and transfer it  to your bank account in just a few easy steps.</h3>'
  },
  {
    title:'Also Get Instant cashback on every purchase',
    image:require('../images/png/GG-Coins.png'),
    content:'<h3>Get instant rewards in the form of GG Coins on every shopping, which can be redeemed for a discount of 5%-50% of the bill amount on various big brands and selected vendors. <b/>Get 50 GG coins on every new referral generation.<br/><br/>GG coin is also converted to GG Money as an additional 4th payout of SCB FIFO</h3>'
  },
  {
    title:'Earn Referral income for the lifetime',
    image:require('../images/png/buddy_refferal.png'),
    content:'<h3>Refer your friends and family and earn 10 % of the cashback received by them for the lifetime.<br/>Refer a vendor and earn 2.75% of the commission paid by the referred vendor to us .</h3>'
  },
  {
    title:'Paani pe Petrol ka cashback',
    image:require('../images/png/What-is-FPV.png'),
    content:'<h3>Global garner water bottle gives paani pe petrol ka cashback, you have never heard of before. Buy our packaged drinking water bottle and get a chance to choose anyone fuel cashback option: FPV IC, FPV RPP, FPV FIFO</h3>'
  },
]

export default class Register extends Component{
    constructor(props){
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }

    createMarkup(data) {
        return {__html: data};
    }

    render(){
        return(
            <div className="p-s-0 how_it_works-container">
              <div className="container py-5 text-center process_steps">
                  <div className="timeline">
                      <ol>
                          <li>
                              <div>
                                  <time>Register</time>

                                  Explore the universal app to save what you spend.
                              </div>
                          </li>
                          <li>
                              <div>
                                  <time>Choose Product and Service</time>
                                  Choose your favourite Product and service from 20 crore + Products.
                              </div>
                          </li>
                          <li>
                            <div>
                              <time>Choose your cashback option</time>

                              Select from 4 exclusive cachback option and get upto 100% cashback  .
                            </div>
                          </li>
                          <li>
                            <div>
                              <time>Buy and upload Bill</time>
                                Some purchases are auto tracked & for some upload bill.
                            </div>
                          </li>
                          <li>
                              <div>
                                  <time>Get Cashback</time>
                                  Enjoy cashback and transfer it to your bank account.!
                              </div>
                          </li>
                          <li>
                              <div>
                                  <time>Happy You</time>
                                  Shop! Earn! Repeat!
                              </div>
                          </li>
                      </ol>
                  </div>
              </div>

              <div></div>

              {data.map((res,i)=>{
                return(
                  <section className="text-center mb-4 mt-4 how_it_works py-5 py-s-4 px-s-3" key={i}>
                    <h2 className='title text-center mb-0'>{res.title}</h2>
                    {res.title==='Flight and bus booking'?
                              <div className='row text-center justify-content-center'>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <img className="tab-img img-fluid" src={res.image} alt={res.title} />
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <img className="tab-img img-fluid" src={res.image1} alt={res.title} />
                                    </div>
                                    <div className="text-center col-12"  dangerouslySetInnerHTML={this.createMarkup(res.content)}/>
                              </div>
                              :res.title==='Guaranteed 2% to 100% cashback/ reward on all your purchases'?
                              <div className="cashbackWrapper">
                                  <div className="container">
                                      <p>Never before opportunity to choose your very own from 4 exclusive cashback options : IC, SCB RPP, SCB FIFO, My Circle. </p>                                         <br/>
                                      <div className="cashbackContainer">
									  <div className="row">
										<div className="col-md-6 mb-5 col-sm-6 col-12">
											<div className="cashbackGrid">
												<div className="cashbackImage">
												<center>
													<img src={require('../images/png/IC.png')} className="img-fluid" alt=""/>
												</center>
												</div>
												<div className="cashbackGridDesc">
													<h4>Instant Cashback</h4>
													<p>In the case of IC, you get instant cashback up to 10%.</p>
													<br/>
												</div>
												<div className="cashbackKnow">
													<a href="/systematic-cashback">Know More</a>
												</div>
											</div>
										</div>
										<div className="col-md-6 mb-5 col-sm-6 col-12 mt-s-3 mt-sm-0">
											<div className="cashbackGrid">
												<div className="cashbackImage">
												<center>
													<img src={require('../images/png/SCB-RPP.png')} className="img-fluid" alt=""/>
												</center>
												</div>
												<div className="cashbackGridDesc">
													<h4>SCB Random Pick Process</h4>
													<p>In case of SCB RPP, you can get 2% to 100% cashback/ reward in a month, through the random selection of UPV*.</p>
												</div>
												<div className="cashbackKnow">
													<a href="/systematic-cashback">Know More</a>
												</div>
											</div>
										</div>
										<div className="col-md-6 mb-5 col-sm-6 col-12 mt-s-3 mt-sm-0">
											<div className="cashbackGrid">
												<div className="cashbackImage">
												<center>
													<img src={require('../images/png/SCB-FIFO.png')} className="img-fluid" alt=""/>
												</center>
												</div>
												<div className="cashbackGridDesc">
													<h4>SCB First In First Out</h4>
													<p>If you can wait more get 100% cashback in 3 instalments through Systematic Cashback Process or SCB by capturing the movement of the economy with No Upper Limit as soon your User Purchase Value* (UPV) is generated in the system on cumulative purchase of ₹ 10000/-. So, get ready to earn & enjoy Cashback on everything you shop via Global Garner</p>
													<br/>
												</div>
												<div className="cashbackKnow">
													<a href="/systematic-cashback">Know More</a>
												</div>
											</div>
										</div>
										<div className="col-md-6 mb-5 col-sm-6 col-12 mt-s-3 mt-sm-4 mt-md-0">
											<div className="cashbackGrid">
												<div className="cashbackImage">
												<center>
													<img src={require('../images/png/my_circle.png')} className="img-fluid" alt=""/>
												</center>
												</div>
												<div className="cashbackGridDesc">
													<h4>My Circle</h4>
													<p>
													GG Money cashback upto Rs.2100000/- just by referral and 100% instant GG Coin along with GG Coin conversion into GG Money upto Rs.50 lakh which User can transfer to Bank Account
													</p>
												</div>
												<div className="cashbackKnow">
													<a href="/systematic-cashback">Know More</a>
												</div>
											</div>
										</div>
									</div>
                                          <br/>
                                        <h4 className='text-left'><b>Note:</b> All this is made possible through our copyrighted business model.</h4>
                                      </div>
                                  </div>
                              </div>
                              :res.title==='Paani pe Petrol ka cashback'?
                              <div className="cashbackWrapper">
                                  <div className="container">
                                    <p>Global garner water bottle gives paani pe petrol ka cashback, you have never heard of before. Buy our packaged drinking water bottle and get a chance to choose anyone fuel cashback option: FPV IC, FPV RPP, FPV FIFO</p>
                                  <br/><div className="cashbackContainer">
                                          <div className="row">
                                              <div className="col-md-4 col-sm-6 col-12">
                                                  <div className="cashbackGrid">
                                                      <div className="cashbackImage">
                                                        <center>
                                                          <img src={require('../images/png/FPV-Instant.png')} className="img-fluid" alt=""/>
                                                        </center>
                                                      </div>
                                                      <div className="cashbackGridDesc">
                                                          <h4>FPV IC</h4>
                                        <p>Get Instant Cashback of ₹5/- or Shopping discount vouchers worth ₹100/- or any other reward as soon as you buy a water bottle.</p>
                                                          <br/>
                                                          <br/>
                                                          <br/> <br/>
                                                      </div>
                                                      <div className="cashbackKnow">
                                                          <a href="/systematic-cashback?c=fpv">Know More</a>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-md-4 col-sm-6 col-12 mt-s-3 mt-sm-0">
                                                  <div className="cashbackGrid">
                                                      <div className="cashbackImage">
                                                        <center>
                                                          <img src={require('../images/png/FPV-RPP.png')} className="img-fluid" alt=""/>
                                                        </center>
                                                      </div>
                                                      <div className="cashbackGridDesc">
                                                          <h4>FPV RPP</h4>
                                                          <p>System will randomly select the user and cashback percentage generated in a month. Qualifiers are arranged in a pool and system will randomly select the FPV and cashback percentage. Unlike the above process of FIFO, RPP users will not have to wait for their turn, they are randomly selected & Paid in months’ time.</p>
                                                      </div>
                                                      <div className="cashbackKnow">
                                                          <a href="/systematic-cashback?c=fpv">Know More</a>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-md-4 col-sm-6 col-12 mt-s-3 mt-sm-4 mt-md-0">
                                                  <div className="cashbackGrid">
                                                      <div className="cashbackImage">
                                                        <center>
                                                          <img src={require('../images/png/FPV-FIFO.png')} className="img-fluid" alt=""/>
                                                        </center>
                                                      </div>
                                                      <div className="cashbackGridDesc">
                                                          <h4>FPV FIFO</h4>
                                                          <p>A User who generates the FPV first will get the Cashback in 3 installments when FPVs are tagged as per SCB tagging system along with conversion of GG Coins into GG Money.</p>
                                                          <br/>
                                                          <br/><br/>
                                                      </div>
                                                      <div className="cashbackKnow">
                                                          <a href="/systematic-cashback?c=fpv">Know More</a>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              :res.title===''?
                              <div className='row d-flex flex-md-row flex-row-reverse justify-content-center'>
                                <br/>
                                <h3 className="text-center"  dangerouslySetInnerHTML={this.createMarkup(res.content)}/>
                                <br/>
                              </div>
                              :i%2===0?
                      <div className='row d-flex flex-md-row flex-row-reverse justify-content-center'>
                        <div className='col-xl-6 col-lg-6 col-md-10 col-sm-10 col-12 align-self-center'>
                          <br/>
                            <center>
                              <img className="tab-img img-fluid" src={res.image} alt={res.title} />
                            </center>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-10 col-sm-10 col-12 align-self-center'>
                              <h3 className="text-center"  dangerouslySetInnerHTML={this.createMarkup(res.content)}/>

                        </div>
                      </div>
                      :
                      <div className='row d-flex flex-lg-row flex-column-reverse justify-content-center'>
                        <div className='col-xl-6 col-lg-6 col-md-10 col-sm-10 col-12 align-self-center'>
                            <div className="text-center"  dangerouslySetInnerHTML={this.createMarkup(res.content)}/>

                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-10 col-sm-10 col-12 align-self-center'>
                          <br/>
                            <center>
                              <img className="tab-img img-fluid" src={res.image} alt={res.title} />
                            </center>
                        </div>

                      </div>
                    }
                  </section>
                )
              })}
                <section className='how_it_works py-5 py-s-4'>
                  <br/>
                  <div className="container text-center mt-3 mb-3">
                    <h1 className='title'>Lot more to explore!</h1>
                    <h1 className='title'>Lot more to shop!</h1>
                    <h1 className='title'>Lot more to enjoy!</h1>
                    <h1 className='title'>Lot more to save!</h1>
                  </div>
                  <br/>
                </section>
            </div>
        );
    }
}
