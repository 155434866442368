import React,{Component} from 'react';
import HomeComponent from "./container/homeContainer";

export default class Home extends Component {

  componentDidMount(){
    setTimeout(()=>{
      window.scrollTo(0, 0);
    },1)
  }

    render(){
        return(
            <React.Fragment>
                <HomeComponent />                
            </React.Fragment>
        );
    }
}
