import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";
import {PageTitle} from 'gg-react-utilities';

export default class Credits extends Component {

    constructor(props){
        super(props);
        this.state = {
          data:[
            {
                name: "FRESCO",
                desc: "Fresco is a powerful system for displaying images in Android applications. Fresco takes care of image loading and display, so you don't have to. It will load images from the network, local storage, or local resources, and display a placeholder until the image has arrived. It has two levels of cache; one in memory and another in internal storage.",
                link: "https://github.com/facebook/fresco/blob/master/LICENSE",
            },
            {
                name: "EVENT BUS",
                desc: "simplifies the communication between components decouples event senders and receivers performs well with Activities, Fragments, and background threads avoids complex and error-prone dependencies and life cycle issues.",
                link: 'https://github.com/greenrobot/EventBus/blob/master/LICENSE',
            },
            {
                name: "VOLLEY",
                desc: "Contribute to volley development by creating an account on GitHub.",
                link: "https://github.com/google/volley/blob/master/LICENSE",
            },
            {
                name: "GSON",
                desc: "gson - A Java serialization/deserialization library to convert Java Objects into JSON and back.",
                link: 'https://github.com/google/gson/blob/master/LICENSE',
            },
            {
                name: "FIREBASE",
                desc: "quickstart-android - Firebase Quickstart Samples for Android.",
                link: "https://github.com/firebase/quickstart-android/blob/master/LICENSE",
            },
            {
                name: "PICASSO",
                desc: "picasso - A powerful image downloading and caching library for Android.",
                link: 'https://github.com/square/picasso/blob/master/LICENSE.txt',
            },
            {
                name: "ZXING",
                desc: 'zxing - Official ZXing ("Zebra Crossing") project home. Barcode scanning library for Android, using ZXing for decoding.',
                link: "https://github.com/zxing/zxing/blob/master/LICENSE",
            },
            {
                name: "RETROFIT",
                desc: "Retrofit is a REST Client for Java and Android. It makes it relatively easy to retrieve and upload JSON (or other structured data) via a REST based webservice. In Retrofit you configure which converter is used for the data serialization. Typically for JSON you use GSon, but you can add custom converters to process XML or other protocols. Retrofit uses the OkHttp library for HTTP requests.",
                link: 'http://www.apache.org/licenses/LICENSE-2.0',
            },
          ]
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    render(){
        return(
            <React.Fragment>
      				<PageTitle title='Credits | Global Garner' />
            <div className="container-fluid header-below">
                  <div className="container p-s-0">
                    <div className="career header-below-content">
                      <div className="row text-center mb-5">
                        <div className="col-12 col-xl-12">
                          <h1 className="bp3-text-xlarge heading">Open source Credit</h1>
                          <span className="heading-desc">
                            This Application Uses Open Source Components.
                            You Can Find The Source Code Of Their Open Source Projects Along With License Information Below.
                            We Acknowledge And Are Grateful To These Developers For Their Contributions To Open Source.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='container-fluid'>
                  <div className='container credit-list-container'>
                    <div className='row my-5'>
                        {this.state.data.map((res,key)=>{
                          return(
                            <div className="col-xl-12 col-md-12 col-sm-12 col-12 mb-4 credit-list px-s-0" key={key}>
                              <Card className="bp3-elevation-3" key={key}>
                                <span className="project-title mt-5">{res.name}</span><br/>
                                <div className='project-link mt-3'>
                                  <a href={res.link}>{res.link}</a>
                                </div>
                                <p className="p-text mt-3">{res.desc}</p>
                              </Card>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
            </React.Fragment>
        );
    }
}
