import {connect} from 'react-redux';

import Bsw from '../../components/RetailStrip/Bsw';
import { getBSWBrandList } from '../../../core/actions/stripAction';

const mapStateToProps = state => {

    return{
        bswBrandList:state.StripReducer.bswBrandList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getBSWBrandList :(name) => {
            return getBSWBrandList(name)
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bsw);
