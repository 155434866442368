import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";

export default class PDF extends Component{
    constructor(props){
        super(props);

        this.state = {

        };
    }


    render(){
        const {pdf} = this.props;
        return(
    			<div className="cb-tab-data">
    				<div className="row text-center pdf-list">
    					{pdf.map((res) => {
                return(
                  <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 pdf-detail' key={res.id}>
                    <Card className='bp3-elevation-1'>
                      <center>
                        <img src={require('../../images/svg/pdf.svg')} alt='pdf'
                           className="img-fluid mt-4"/>
                      </center>
                      <p className='name'>{res.title}</p>
                      <a href={process.env.REACT_APP_IMAGE_URL+res.document} target='_blank' rel="noopener noreferrer" className='text-primary'>Click to Download</a>
                    </Card>
                  </div>
                );
              })
    					}
    				</div>
    			</div>
        );
    }
}
