import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {PageTitle, Auth, Helper} from 'gg-react-utilities';

import CashbackTypeTabs from "./components/CashbackTypeTabs";
import Config from '../Config';
let activeTab = 'scb';

export default class Aboutus extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false,
            activeTab:'scb'
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    render(){
      let query = new URLSearchParams(this.props.location.search ? this.props.location.search : "");
      activeTab = query.get('c');

        return(
            <React.Fragment>
              <PageTitle title="Cashback/Reward | Global Garner" />
                <div className="container-fluid header-below">
        					<div className="container p-s-0">
        						<div className="career header-below-content">
        							<div className="row text-center">
        								<div className="col-12 col-xl-12 text-center py-5 pt-s-0 pt-sm-0 pb-s-0">
        									<h1 className="bp3-text-xlarge heading">Various cashback/reward options</h1>
                        <p className="heading-desc mb-5" style={{color:'#B1D3FF'}}>
                            Cashback/reward we process is a copyrighted unique combination of revenues we generate.
                            You can choose from any of the three Cashback options and can change it anytime or during any purchase.
        									</p>
                          <div className="text-center">
                            {!Auth.isLoggedIn()?
                              <a className="spl-btn bp3-large bp3-button mr-5" href={Config.ACCOUNT_URL+'register'}>Register</a>
                            :null}
                            <Link className="btn-bg-blur with-icon bp3-button bp3-large bp3-intent-primary mt-s-3 ml-2" to="/video">
                              Watch Video &nbsp;&nbsp;&nbsp;<span className="bp3-icon bp3-icon-arrow-right"></span>
                            </Link>
                          </div>
        								</div>
        							</div>
        						</div>
        					</div>
                </div>

				<div className="">
				  <CashbackTypeTabs activeTab={activeTab}/>
				</div>

				<div className='container-fluid'>
					<div className='container important-notes py-5 mb-5 my-s-0'>
						<h3 className='title text-center'>Important Note</h3>
						<h4>All UPVs/FPVs are generated and arranged automatically under users UPV/FPV as per SCB FIFO process and user has nothing to do for other UPV/FPVs
						  which are getting tagged under his UPV/FPV, just for instance one user's UPV/FPV might be from Mumbai and other can be from Kolkata and next can be from
						  bangalore etc. Every time any user spends {Helper.formatMoney(10000)} Anywhere In The World Through Global Garner(GG) a new UPV/FPV is added to the
						  Cashback/Reward system i.e. SCB FIFO. All UPV/FPVs will get eligible for 100% Cashback/Reward on First Come First Serve basis only through SCB Process And UPV/FPV's Tagged Under His UPV/FPV. All Payments Are Excluding Of Our Services Charge And Govt Taxes. Therefore Final Payment Would Be Done After Deducting Applicable Taxes And Service Charge.

						</h4>
					   <h5>*Gg Coins Will Not Be Converted Into GG Money In Case Of SCB Random Pick Process(For Any Slab),
						 Though GG Coins Can Be Used For Shopping.</h5>
					</div>
				</div>
            </React.Fragment>
        );
    }
}
