import React, {Component} from 'react';

export default class Gallery extends Component{

    constructor(props){
        super(props);

        this.state = {

        };
    }

    render(){
        return(
    			<div className="cb-tab-data">
                    <div className="text-center media">
                        <div className="row">
                            {this.props.image.map((res) => {
                                return(
                                  <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 media-detail'  key={res.id}>
                                      <img src={process.env.REACT_APP_IMAGE_URL+res.image} alt='gallery' className="img-fluid mt-4 mt-s-0 mt-sm-0"/>
                                      <div className="overlay">
                                        <p className='text'>
                                          {res.caption}
                                        <br/>
                                        <a className='text-primary bp3-text-small' href={process.env.REACT_APP_IMAGE_URL+res.image} target='_blank' rel="noopener noreferrer">
                                          Click to download
                                        </a>
                                        </p>
                                    </div>
                                </div>
                                );
                              })
                            }
                        </div>
                    </div>
    			</div>
        );
    }
}
