import React from 'react';
import {Button, Intent, FormGroup} from "@blueprintjs/core";
import {Async} from "react-select";
import { ssoInstance } from 'gg-react-utilities';
import Config from '../../../Config';


export default class Megabrand extends React.Component{
    constructor(props){
        super(props);
        this.state={
            megaBrand:'',
            slug:'',
            hasBrandError:false,
            hasBrandErrorMsg:''
        }
    }



    componentDidMount() {

    }

    goToMegaBrandModule(){
        if(!this.state.hasBrandError){
            this.setState({hasBrandErrorMsg:"Mega Brand cannot be empty"})
        }else{
            window.location.href = this.props.url+"brand/"+this.state.slug
        }
    }

    async getOptions(inputValue) {

        if (!inputValue) {
            return [];
        }

        return ssoInstance.post(Config.MEGABRAND_API_URL + 'all-brands',{"brand_name":inputValue})
                        .then(
                            (res)=> {
                                const response = res.data;
                                if (response.status === true) {
                                  response.data.data.map((resp) => {
                                        resp.value = resp.slug;
                                        resp.label = resp.brand_name ;
                                        return resp;
                                  })
                                }
                                return response.data.data;
                              }

                        );

    }


    render(){
        return(
            <React.Fragment>
                <div className="strip-form">
                    <div className="row mt-3">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <FormGroup
                                helperText={this.state.hasBrandErrorMsg}>
                                <Async
                                  className='strip-select'
                                  classNamePrefix="strip-select"
                                  autoFocus={true}
                                            isClearable
                                            multi={false}
                                            value={this.state.megaBrand}
                                            isLoading={true}
                                            onChange={(value)=>
                                              {
                                                this.setState({megaBrand:value,slug:value.slug, hasBrandError:true})
                                              }
                                            }
                                            valueKey="slug"
                                            labelKey="brand_name"
                                            Key="id"
                                            loadOptions={this.getOptions}
                                            placeholder={'Search your favourite brands'}
                                            searchPromptText={"Please enter 3 or More characters"}
                                            backspaceRemoves={true}
                                            defaultOptions
                                        />


                            </FormGroup>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right mt-s-4 mt-sm-0 mt-xs-4 text-center-s">
                            <Button text='Search' intent={Intent.PRIMARY} onClick={this.goToMegaBrandModule.bind(this)}  className="search-btn" />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
