import React, {Component} from 'react';

import MediaComponent from "./container/mediaListContainer";
import {PageTitle} from 'gg-react-utilities';

export default class Media extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    render(){
      let metaData = document.getElementsByTagName("meta");
      metaData['description'].content = "Global Garner is unique platform which gives 100% cashback through 3 cashback options which your can transferred to your bank Account, So now enjoy our every shopping with Global Garner and get your 100% Cashback";
      metaData['keywords'].content = "PDF Documemts, Videos, Gallary";
        return(
            <React.Fragment>
              <PageTitle title='Media | Global Garner' />
                <div className="container-fluid header-below">
        					<div className="container p-s-0">
        						<div className="career header-below-content">
        							<div className="row text-center mb-5 pb-4 pb-s-0">
        								<div className="col-12 col-xl-12">
        									<h1 className="bp3-text-xlarge heading">Let’s Show the world What a Salesman can do</h1>
        									<span className="heading-desc">
        									  Global Garner is a bunch of wunderkinds. People on board have proved their mettle in their individual fields and have now come together to
        									  enrich this unique platform of empowering people.
        									</span>
        								</div>
        							</div>
        						</div>
        					</div>
                </div>
                <div className="container-fluid">
                    <MediaComponent />
                </div>
            </React.Fragment>
        );
    }
}
