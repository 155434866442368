const buttonData = {
    buttonText:[
        {id:"gg-vendor",text:"GG vendor"},
        {id:"bsw",text:"Best shopping websites"},
        {id:"recharge",text:"Bill Pay & Recharge"},
        {id:"booking",text:"Online Booking"},
        {id:"mega-brands",text:"Mega Brands"},
        // {id:"regional-brands",text:"Regional Brands"},
        {id:"resale",text:"Resale"},
        {id:"relation",text:"Relation"},
        {id:"matrimonial",text:"Matrimonial"},
        {id:"news",text:"News"},
        {id:"gg-services",text:"GG Services"},
        {id:"real-estate",text:"Real Estate"},
        {id:"professional",text:"Professional"},
        {id:"donate",text:"Donate"},
        {id:"crowd-funding",text:"Crowd Funding"},
        /*{id:"railbhoj",text:"Railbhoj"},*/
        {id:"holidayPackage",text:"Holiday Packages"},
        {id:"upos",text:"UPOS - The Digital Mall"},
        {id:"loan",text:"Loan"},
        {id:"inquiry-services",text:"Inquiry Services"},
        {id:"hotel",text:"Hotel"},
        {id:"upv",text:"UPV"},
    ]
};

export default buttonData;
