import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";
import Wallpaper from '../components/Wallpaper';
import {getRingtoneList} from '../../core/actions/action';
import {ringtoneListAction} from '../../core/actions';

const mapStateToProps = state => {

    return{
        ringtoneList:state.RetailReducer.ringtoneList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getRingtoneList:() => {
            return getRingtoneList()
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(ringtoneListAction(sources.data));
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    dispatch(ringtoneListAction([]));
                }
            }).catch((error)=>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wallpaper);
