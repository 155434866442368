import {connect} from 'react-redux';

import Holidays from '../../components/RetailStrip/Holidays';
import { getHolidaysCityList } from '../../../core/actions/stripAction';

const mapStateToProps = state => {
    return{
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getHolidaysCityList :(obj) => {
            return getHolidaysCityList(obj)
                // .then((res)=>{
                //     const sources = res.data;
                //     if(sources.status===true){
                //         dispatch(holidayCityListAction(sources.data));
                //     }else{
                //         Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                //         dispatch(holidayCityListAction([]));
                //     }
                // }).catch((error)=>{
                //     Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                // })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
