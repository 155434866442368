import React, {Component, Fragment} from 'react';
import {PageTitle} from 'gg-react-utilities';
import {Card} from "@blueprintjs/core";
export default class HolidayTerms extends Component {
    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }
    
    render(){
        return(
            <Fragment>

                <PageTitle title='Holiday Package - Terms & Condition | Global Garner'/>
                <div className="container-fluid header-below">
                    <div className="container p-s-0">
                        <div className="career header-below-content">
                            <div className="row text-center mb-5">
                                <div className="col-12 col-xl-12 text-center py-5">
                                    <h1 className="bp3-text-xlarge heading">Holidays Package - Terms &
                                        Conditions</h1>
                                    <span className="heading-desc">
                                Last Updated On 1st July, 2019
                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container p-s-0 mb-5'>
                    <Card className='privacy-content py-5 bp3-elevation-1'>
                        <div className="row">
                            <div
                                className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 offset-xl-1 offset-lg-1 offset-md-1 my-3">
                                <p><strong>Terms and conditions for holiday packages :</strong></p>
                                <ul>
                                    <li><p>Price mentioned is on per person basis for two persons travelling
                                        together and sharing same room.</p></li>
                                    <li><p>Bookings are subject to availability of rooms with the hotels.</p>
                                    </li>
                                    <li><p>The package rates will vary during national holidays,
                                        festivals &amp; long weekends and special discount prices
                                        will not be applicable during these occasions. Surcharges
                                        applicable.</p></li>
                                    <li><p>In case of unavailability in the listed hotels, alternate
                                        accommodation will be made in a hotel of similar
                                        standard.</p></li>
                                    <li><p>Rates are valid for Indian Nationals only.</p></li>
                                    <ul>
                                        <li><p>No refund will be made for any unused accommodation, missed
                                            meals, transportation segments,
                                            sightseeing tours or any other service. Such unused items are
                                            neither refundable nor exchangeable in
                                            any form.</p></li>
                                        <li><p>No refund shall be claimed, if the services &amp; amenities of
                                            hotel were not up to your expectations, it will
                                            be considered on case to case basis.</p></li>
                                        <li><p>Final vouchers can only be issued once we have received full
                                            payment</p></li>
                                        <li><p>It is the responsibility of the tourists to check and hold valid
                                            travel documents like passport and Visa till
                                            the end of the tour. In case any travel document like visa is
                                            refused, expires or is cancelled by the
                                            authorities, the tour participant will have to meet all the
                                            contingencies arising therefrom and to meet all
                                            expenses consequential thereto. The Company will have no
                                            responsibility whatsoever in respect of the
                                            above matters</p></li>
                                        <li><p>Room Allocation is usually done by the hotel at the time of check
                                            in. We cannot guarantee any specific
                                            view room; also the rooms offered are the base category rooms in the
                                            package unless otherwise
                                            confirmed in inclusions</p></li>
                                        <li><p>We or Bigbreaks will not be liable to any passenger for refund,
                                            compensation or claim for shortage of tour days or for cancellation,
                                            postponement or re-routing of any particular scheduled transport
                                            service due to any reasons including fog. The rules in respect of
                                            cancellation charges or refund will be applicable</p></li>
                                        <li><p><strong>Visa Facilitation: The company provides visa facilitation
                                            and under no circumstances be held
                                            responsible for visa delay or rejection.</strong></p></li>
                                        <li><p>Although the company tries to ensure good service to all its
                                            guests, still we request you to notify us during the tour only if
                                            you are facing any difficulty to help us resolve it immediately. Due
                                            to any situation if you are advised by our team to pay locally and
                                            later get refund, please do keep the receipt of the payment made
                                            which would have to be sent to us for refund claim.</p></li>
                                        <li><p><strong>Early check-in or late check-out is subject to
                                            availability and the hotel may charge extra for the
                                            same and we don't guarantee it</strong></p></li>
                                        <li><p>Extra bed rates would be charged after 2 full paying persons in a
                                            room. However, in case of an extra
                                            bed request in a single occupancy room, double room rates would be
                                            charged</p></li>
                                        <li><p>The package does not include charges for optional services and
                                            facilities (such as room service, mini
                                            bar, snacks, telephone calls etc.). These will be charged directly
                                            from the guest at the time of check-out
                                            from the respective hotel</p></li>
                                        <li><p>Global Garner cashback/reward will be applicable as per cashback
                                            terms and conditions depending
                                            upon cashback option chosen by the user and other terms as
                                            applicable.</p></li>
                                        <li><p>Purchase will be sent to UPV cycle after 7-15 days of trip
                                            completion</p></li>
                                    </ul>
                                </ul>
                                <p><strong>Cancellation Policy :</strong></p>
                                <ul>
                                    <li><p>60 days or more to departure = 50 % of holiday cost</p></li>
                                    <li><p>59 - 21 days to departure = 80 % of holiday cost</p></li>
                                    <li><p>D – 20 days to departure = 100% of holiday cost</p></li>
                                </ul>
                                <p><strong>Booking Conditions :</strong></p>
                                <ul>
                                    <li><p>30 days or more to departure = 50% of holiday cost</p></li>
                                    <li><p>30 - 21 days to departure = 80% of holiday cost</p></li>
                                    <li><p>D – 20 days to departure = 100% of holiday cost</p></li>
                                    <li><p>Photocopies of the passport with validity of minimum 6 months from
                                        date of travel</p></li>
                                </ul>
                                <p><b>If any booking related query bigbreaks.com Support Number: 9266092663</b></p>
                            </div>
                        </div>
                    </Card>
                </div>
            </Fragment>

        );
    }
}
