import React, {Component, Fragment} from 'react';

export default class Trolley extends Component{

    render(){
        return(
            <Fragment>
                <div className="trolleyWrapper">
                    <div className="container">
                        <div className="trolleyHeader">
                            <h3>GG Water Trolley</h3>
                            <p>Global Garner water trolley is an electronic rickshaw equipped with advanced technology.</p>
                            <p>It has a <strong>Deep freezer</strong> and <strong>Packaged Drinking water bottles</strong> are sold on these trolleys with <strong>3 cashback options</strong>.</p>
                        </div>
                        <div className="trolleyContainer">
                            <div className="trolleyImage">
                                <img src={require('../../images/png/Trolley.png')} className="img-fluid" alt=""/>
                            </div>
                            <div className="trolleyDesc">
                                <p>These trolleys have UPOS - The Digital Mall and wifi in them. It is sold by Global Garner through Franchise model.</p>
                                <p><strong>This is the best tool used for user acquisition</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
