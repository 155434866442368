import React, {Component} from 'react';

export default class MoneyCoin extends Component{

    render(){
        return(
            <React.Fragment>
                <div className="moneyWrapper">
                    <div className="container">
                        <div className="moneyHeader">
                            <h3>Difference between GG Money & GG Coin</h3>
                        </div>
                        <div className="moneyContainer">
                            <div className="moneyDesc">
                                <div className="row">
                                    <div className='col-12 mb-4'>
                                      <center>
                                        <img src={require('../../images/png/Summary-of-user-beneifts.png')} className="img-fluid" alt=""/>
                                      </center>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="moneyGrid">
                                            <div className="moneyGridDesc">
                                                <h4>GG Money</h4>
                                                <p>GG Money can be transferred to bank account.</p>
                                            </div>
                                        </div>
                                    </div>
                                   <div className="col-md-6 col-sm-6 col-12 mt-s-3 mt-sm-0">
                                        <div className="moneyGrid">
                                            <div className="moneyGridDesc">
                                                <h4>GG Coin</h4>
                                                <p>GG Coins can be used up to the extent of 5% of the bill
                                                    amount on selected vendors & up to 80% on jewellery
                                                    vouchers. GG Coins are also converted to GG Money as an
                                                    additional 4th pay out of SCB FIFO and upto ₹ 50 lakhs in case of My Circle cashback.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
