import React, {Component} from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import Mobile from "./Type/Mobile";
import Desktop from "./Type/Desktop";
import Ringtone from "./Type/Ringtone";

export default class WallpaperComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeTab:'tab3',
            page:1
        };
    }


    componentDidMount(){
      this.props.getRingtoneList();
    }

    componentWillReceiveProps(nextprops){

    }

    render(){
        return(
            <div className="container px-s-0 pb-5">
                <div className="wallpaper">
                    <Tabs id="wallpaper-type" animate={false}>
                        <Tab id="tab1" title="Desktop" panel={<Desktop />}/>
                        <Tab id="tab2" title="Mobile" panel={<Mobile />}/>
                        <Tab id="tab3" title="Ringtone" panel={<Ringtone data={this.props.ringtoneList}/>}/>
                    </Tabs>
                </div>
            </div>
        );
    }
}
