import React from 'react';
import { Popover, Menu, MenuItem, Position} from "@blueprintjs/core";
import {ssoInstance} from 'gg-react-utilities';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import Recharge from "../container/RetailStrip/RechargeContainer";
import Flight from "../container/RetailStrip/FlightContainer";
import Bus from "../container/RetailStrip/BusContainer";
import Hotel from "../container/RetailStrip/HotelContainer";
import Holidays from "../container/RetailStrip/HolidaysContainer";
import BSW from "../container/RetailStrip/BswContainer";
import Megabrand from "../container/RetailStrip/MegaBrandContainer";
import Tripxoxo from "./RetailStrip/Tripxoxo";
import Railbhoj from "../container/RetailStrip/RailbhojContainer";
import Upos from "./RetailStrip/Upos";
import Config from '../../Config';

export default class RetailStrip extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            active: "recharge",
            content:[],
            services:[],
            url:process.env.REACT_APP_BASE_URL+'recharge.globalgarner'+process.env.REACT_APP_Extension+'/'
        };
    }

    componentWillMount(){

      ssoInstance.get(Config.ADMIN_GG_API_URL + 'slider?module=retail').then((res)=>{

          const sources = res.data;
          if (sources.status === true) {
            this.setState({content:sources.data });
          }

      });

        ssoInstance.get(Config.RECHARGE_API_URL + 'services').then((res)=>{

            const sources = res.data;
            if (sources.status === true) {
                this.setState({services:sources.data });
            }

        });
    }

    renderStripBody(module){
        switch (module){
            case "recharge":
                return <Recharge url={this.state.url}/>;
            case "flight":
                return <Flight url={this.state.url}/>;
            case "bus":
                return <Bus url={this.state.url}/>;
            case "hotel":
                return <Hotel url={this.state.url}/>;
            case "holidays":
                return <Holidays url={this.state.url}/>;
            case "bsw":
                return <BSW url={this.state.url}/>;
            case "megabrand":
                return <Megabrand url={this.state.url}/>;
            case "tripxoxo":
                return <Tripxoxo url={this.state.url}/>;
            /*case "railbhoj":
                return <Railbhoj url={this.state.url}/>;*/
            case "upos":
                return <Upos url={this.state.url}/>;
            default :
                return "";
        }
    }

    render(){
        const optionsLeft = [
            {
                label: "Recharge",
                url:process.env.REACT_APP_BASE_URL+'recharge.globalgarner'+process.env.REACT_APP_Extension+'/',
                id: "recharge"
            },
            {
                label: "Flight",
                url:process.env.REACT_APP_BASE_URL+'flight.globalgarner'+process.env.REACT_APP_Extension+'/',
                id: "flight"
            },
            {
                label: "Bus",
                url:process.env.REACT_APP_BASE_URL+'bus.globalgarner'+process.env.REACT_APP_Extension+'/',
                id: "bus"
            },

            {
                label: "BSW",
                url:process.env.REACT_APP_BASE_URL+'best-shopping-websites.globalgarner'+process.env.REACT_APP_Extension+'/',
                id: "bsw"
            },
            /*{
              label: "Hotel",
              url:process.env.REACT_APP_BASE_URL+'hotel.globalgarner'+process.env.REACT_APP_Extension+'/',
              id: "hotel"
            },*/
            {
                label: "Mega Brands",
                url:process.env.REACT_APP_BASE_URL+'mega-brands.globalgarner'+process.env.REACT_APP_Extension+'/',
                id: "megabrand"
            },
            {
                label: "Holidays",
                url:process.env.REACT_APP_BASE_URL+'holidays.globalgarner'+process.env.REACT_APP_Extension+'/',
                id: "holidays"
            },
            // {
            //     label: "Trip xoxo",
            //     url:process.env.REACT_APP_BASE_URL+'x-sitement.globalgarner'+process.env.REACT_APP_Extension+'/',
            //     id: "tripxoxo"
            // },
            /*{
                label: "Railbhoj",
                url:process.env.REACT_APP_BASE_URL+'railbhoj.globalgarner'+process.env.REACT_APP_Extension+'/',
                id: "railbhoj"
            },*/
            {
                label: "UPOS - The Digital Mall",
                url:process.env.REACT_APP_BASE_URL+'store.globalgarner'+process.env.REACT_APP_Extension+'/nearByUpos',
                id: "upos"
            }
        ];

        return(
            <div className="container-fluid p-0 home-page-slider" >
              {this.state.content.length>0?
                <Slider className="slider-wrapper" autoplay={1000}>
                    {this.state.content.map((item, index) => (
                        <div
                            key={index}

                            autoPlay='1000'
                            className="slider-content"
                            style={{ background: `url('${Config.IMAGE_URL+item.image}') no-repeat center center` }}
                            onClick={()=>{window.location.href=item.slider_link}}
                        >

                            <div className="inner">
                                <h1>{item.slider_title}</h1>
                                <p>{item.slider_description}</p>
                            </div>
                        </div>
                    ))
                  }
                </Slider>
                :
              <div className='' style={{height: '53em',backgroundColor: '#E3F2FD'}}>
                <center style={{paddingTop: '25em'}}>
                  <div className="py-5">
                    <img src={require('../../core/images/loader.gif')} alt={""} style={{width:'100px',height:'100px'}}/>
                  </div>
                </center>
              </div>
            }
                <div className="retail-strips text-center">
      					<div className="container retail-strip-box">
      						<div className="strip-head">
      							{
      								optionsLeft.map((option, key) => {
      									return <span
											className={this.state.active === option.id ? "left strip-option active" : "left strip-option text-nowrap"}
											key={key}
											onClick={() => this.setState({active: option.id,url:option.url})}
										>
											{option.label}
										</span>
      								})
      							}
								<Popover className='pull-right' content={
									<Menu className="dropdown_menu">
                                        {
                                            this.state.services && this.state.services.length ?
                                                this.state.services.map((data,key)=>{
                                                    if(!(data.service_slug == 'mobile-postpaid' || data.service_slug == 'mobile-prepaid')) {
                                                        return (
                                                            <MenuItem text={data.service_type}
                                                                      href={Config.RECHARGE_URL + data.service_id}/>
                                                        )
                                                    }
                                                })
                                            :''
                                        }
									</Menu>
								} position={Position.BOTTOM}>
									<span className="strip-option">More</span>
								</Popover>
      						</div>
      						<div className="strip-body text-left">
      							{
      								this.renderStripBody(this.state.active)
      							}
      						</div>
      					</div>
      				</div>
            </div>
        );
    }
}
