import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ArrowForwardRounded, PlayArrowRounded } from "@material-ui/icons";

export default class Introduction extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="introWrapper">
          <div className="container">
            <div className="introHeader">
              <h3>What is Global Garner ?</h3>
            </div>
            <div className="introContainer">
              <div className="col-12 py-4">
                <div className="col-12 text-center px-s-0">
                  <h4>All Products & Services Packed In One App And Website</h4>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3 align-self-center">
                    <div className="introImage">
                      <img
                        src={require("../../images/png/What-is-GG.png")}
                        alt=""
                        className={"img-fluid"}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                    <div className="introDesc">
                      <div className="introDescPara">
                        <p>
                          Global Garner Is World’s 1st & Only Postpaid Sales
                          Service Provider. We help the vendors to sell their
                          products & services with our Advanced Digital platform
                          & On-ground Sales Support with customized sales
                          solution for each vendor along with the best offer of
                          guaranteed 2% to 100% cashback/ reward to their
                          customers through the choice of 4 copyrighted cashback
                          options, which makes us the first choice for any
                          vendor as we perform first & then seek for our
                          commission only, Nothing in advance. Also, our
                          ecosystem helps each vendor to cross-sell each other’s
                          products & services, without actually investing in any
                          type of inventory through UPOS - The Digital Mall.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 py-4 pb-s-0">
                <div className="col-12 text-center px-s-0">
                  <h4>
                    Global Garner Is World’s 1st & Only Postpaid Sales Service
                    Provider
                  </h4>
                </div>
                <div className="row d-flex flex-column-reverse flex-lg-row">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="introDesc">
                      <div className="introDescPara">
                        <p>
                          Global Garner helps the vendor to sell their products
                          & services with its Advanced Digital platform &
                          On-ground Sales Support with customized sales solution
                          for each vendor along with the best offer of
                          guaranteed 2% to 100% cashback/ reward to their
                          customers through the choice of 4 copyrighted cashback
                          options, which makes us the first choice for any
                          vendor as we perform first & then seek for our
                          commission only, Nothing in advance. Also, our
                          ecosystem helps each vendor to cross-sell each other’s
                          products & services, without actually investing in any
                          type of inventory through UPOS - The Digital Mall.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 align-self-center">
                    <div className="introImage">
                      <img
                        src={require("../../images/png/world-first.png")}
                        alt=""
                        className={"img-fluid"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 py-4">
                <div className="col-12 text-center px-s-0">
                  <h4>Creating Sustainable Jobs / Earning Opportunities</h4>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-3 align-self-center">
                    <div className="introImage">
                      <img
                        src={require("../../images/png/Sustainable-Job.png")}
                        alt=""
                        className={"img-fluid"}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-md-12 col-sm-12 col-xl-6 d-flex align-items-center mt-3 mt-s-0">
                    <div className="introDesc">
                      <div className="introDescPara">
                        <p>
                          Now anyone can use their sales skills to promote /
                          sell any variety of product / services given in the
                          eco system and create sustainable earning for
                          themselves.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 py-4 align-self-center  text-xl-center text-lg-center text-sm-center text-center">
                <a
                  href="/pitch_video_d272Yiz"
                  className="bp3-button bp3-intent-primary bp3-button bp3-small mt-s-3 mt-sm-0"
                >
                  Pitch Video
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
