import React, {Component, Fragment} from 'react';

import Config from '../../Config';


export default class Megabrand extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    render(){
      const {data} = this.props;
        return(
        <Fragment>
          <div className="row">
            <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
              <h2 className="product-title">Megabrands</h2>
            </div>
            <div className="col-xl-5 col-xl-5 col-sm-5 col-4">
              <p className='mt-2 pull-right link text-primary'>{data.length} result</p>
            </div>
          </div>
          <div className="mt-4 mega-brand row">
            {data.map((brand, key) => {
              let ggCoin = "";
              brand = brand._source;

              if (brand.coin_applicable){

                ggCoin =  <img src={process.env.REACT_APP_GG_COIN_IMAGE} alt="GG Coins Applicable"
                     className="img-fluid coin-img mt-2 mr-2"/>;;
              }


              let colorCode = brand.color_code ? brand.color_code.split(',') : [];


              let tat = "";
              if( brand.tat === 0) {
                tat = "Instant";
              }
              else {
                tat = brand.tat + " Days"
              }

              return(

                <div className={" col-12 col-xl-3 col-sm-6 col-md-3 px-0  mb-3"} data-brandid={brand.brand_id}>
                  <a href={Config.MEGABRAND_URL+'brand/'+brand.slug}>

                    <div className="mega-brand-card mr-3">
                    <div className="brand-card d-flex justify-content-between item-align-center"
                         style={{'backgroundImage': 'linear-gradient(-135deg, ' + colorCode[0] + ' 0%, ' + colorCode[1] + ' 100%)'}}
                    >
                        <p className="brand-name">{brand.brand_name}</p>

                  </div>
                    <div className="text-center">
                        <img
                            src={brand.image_url}
                            className="img-fluid brand-img"
                            alt={brand.brand_name}
                            onError={(ev)=>{
                              ev.target.src = process.env.REACT_APP_MEGABRAND_DEFAULT_IMAGE;
                            }}

                        />
                        {ggCoin}
                    </div>
                    <div className="d-flex justify-content-between item-align-center pl-3 pr-3 pt-3 pb-2">
                        <p className="brand-delivery text-center m-auto">Delivery: {tat}</p>
                      <p className="brand-product-type text-center m-auto">{brand.product_type} Voucher</p>
                    </div>
                    </div>
                      </a>
                </div>
              );
            })
          }
        </div>
        </Fragment>
        );

    }

}
