import React, {Component} from 'react';
import { Card, Button, Intent, Icon } from "@blueprintjs/core";
import {AddRounded, RemoveRounded, SearchRounded} from '@material-ui/icons';
import FAQ from './faq';

export default class FAQSegment extends Component{
    constructor(props){
        super(props);
        this.state = {
            isActiveButtonID:0,
            query:"",
            faqData:[],
            isSearch:false,
            show:false
        };
    }

    componentDidMount(){
      this.props.getFaqList(1);
      this.props.getFaqCategoryList();
    }

    componentWillReceiveProps(nextprops){

    }

    handleClick(state){
        this.setState({show:!state})
    }

    render(){
        return(
          <div className="container">
            <div className='row my-5'>
              <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-s-0'>
                  <div className="button-group faq">
                      {this.props.faqCategoryList.map((res,i) => {
                          return(
                              <React.Fragment key={i}>
                                  <div className={this.state.isActiveButtonID===res.id ? "faq_list bp3-active" : "faq_list"}
                                   onClick={() => {
                                       this.setState({
                                           isActiveButtonID: (this.state.isActiveButtonID==res.id) ? 0 : res.id,
                                           show: (this.state.isActiveButtonID==res.id) ? false : true,
                                       })
                                       this.props.getFaqList(res.id)
                                       // this.handleClick(this.state.show)
                                   }}>
                                      {this.state.show && this.state.isActiveButtonID===res.id ?
                                          <RemoveRounded style={{'color':'#236AEE'}} onClick={() => {this.handleClick(this.state.show)}}/>
                                          :                                          <AddRounded style={{'color':'#236AEE'}} onClick={() => {this.handleClick(this.state.show)}}/>
                                      }
                                      <Button
                                          text={res.category_name}
                                          className={this.state.isActiveButtonID===res.id ? 'bp3-fill bp3-active faq-type' : 'bp3-fill faq-type'}
                                      >
                                      </Button>
                                  </div>

                                  {this.state.show && this.state.isActiveButtonID===res.id ?
                                      <div className="bg-basic pl-3 pl-sm-5">
                                          {this.state.isSearch ?
                                              this.props.searchFaqQuery.map((res,i) => {
                                                  return (
                                                      <FAQ faq={res} key={res.id} />
                                                  )
                                              })
                                              :
                                              this.props.faqList.map((res,i) => {
                                                  return(
                                                      <FAQ faq={res} key={res.id}/>
                                                  )
                                              })
                                          }
                                      </div>
                                  :null}
                              </React.Fragment>
                          );
                      })}
                  </div>

              </div>
            </div>
          </div>
        );
    }
}