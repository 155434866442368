import React from 'react';
import {Button, Intent} from "@blueprintjs/core";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { LocationOnRounded } from '@material-ui/icons';

export default class Upos extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        address: '',
        isOpen: false,
        lat:0,
        lng:0
      };
    }

    handleChange = address => {
      this.setState({ address });
    };

    handleSelect = address => {

      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {

          this.setState({lat:latLng.lat,lng:latLng.lng,address:address})

        })
        .catch(error => console.error('Error', error));


    };

    searchVendor(){
      window.location.href = this.props.url+'?lat='+this.state.lat+'&lng='+this.state.lng;
    }

    render(){
        return(
            <React.Fragment>
              <div className="strip-form">
              <p>Search UPOS - The Digital Mall vendor through location</p>
                <div className="row py-sm-2 pt-ms-0 pt-lg-0 pt-xl-0">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 text-center">
                      <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: 'Enter Location',
                              className: 'bp3-input',
                            })}
                            onKeyUp={(e)=>{
                              if(e.which===13){
                                this.searchVendor()
                              }
                            }}
                            />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                              // inline style for demonstration purpose

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,

                                  })}
                                  >
                                    <p className='d-flex align-items-center p-0 m-0'>
                                        <LocationOnRounded/><span
                                        className='ml-2'>{suggestion.description}</span></p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-3 col-sm-3 col-12 text-right mt-s-4 mt-xs-4 text-center-s mt-sm-0">
                        <Button text='Search' intent={Intent.PRIMARY} className='bp3-small pull-left'
                          onClick={()=>{
                            this.searchVendor()
                          }}/>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12 text-right mt-s-4 mt-xs-4 text-center-s pl-sm-0 pr-sm-2 mt-sm-0">
                        <a href={process.env.REACT_APP_BASE_URL+'upos.globalgarner'+process.env.REACT_APP_Extension} target='_blank'
                          rel="noopener noreferrer"  className='bp3-small bp3-button bp3-intent-primary pull-left'
                  >UPOS - The Digital Mall - Book Now</a>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12 text-right mt-s-4 mt-xs-4 text-center-s pl-sm-0 pr-sm-2 mt-sm-0">
                        <a href={process.env.REACT_APP_BASE_URL+'upos-app.globalgarner'+process.env.REACT_APP_Extension} target='_blank'
                          rel="noopener noreferrer"  className='bp3-small text-primary bp3-button pull-left' style={{border:'1px solid #236AEE',borderRadius: '3px'}}
                  >UPOS - The Digital Mall - Login</a>
                    </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
