import React, {Component, Fragment} from 'react';
import Config from '../../Config';
import {Auth} from 'gg-react-utilities';
import emptyImage from '../../core/images/large-product-placeholder.png';

export default class GRANGE extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    gotoGrange = (code,sku) =>{
        if(!Auth.isLoggedIn()){
            Auth.login(true);
            return false;
        }else{
            window.location.href= Config.GRANGE_URL + "/central_search/"+code+"/"+sku;
        }
    }

    render(){
      const colorCodes = ['#368FFE,#27DFC5','#6188ED,#7E4AAD','#D1FCAB,#1AEAEB','#F42E78,#C17AFC','#F4E5FA,#61A2E7','#FF8993,#FEC180'];
      const {data} = this.props;
        return(
        <Fragment>
          <div className="row">
            <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
              <h2 className="product-title">GG Grange</h2>
            </div>
            <div className="col-xl-5 col-xl-5 col-sm-5 col-4">
              <p className='mt-2 pull-right link text-primary'>{data.length} result</p>
            </div>
          </div>
          <div className="mt-4 mega-brand row">
            {data.map((brand, key) => {
              //let ggCoin = "";
              brand = brand._source;

              /*if (brand.coin_applicable){

                ggCoin =  <img src={process.env.REACT_APP_GG_COIN_IMAGE} alt="GG Coins Applicable"
                     className="img-fluid coin-img mt-2 mr-2"/>;;
              }*/


                let color_code =  colorCodes[(key % 6)];
                let colorCode = color_code ? color_code.split(',') : [];

              return(

                <div className={" col-12 col-xl-3 col-sm-6 col-md-3 px-0  mb-3"} data-brandid={brand.brand_id}>
                  <a onClick={()=>{ this.gotoGrange(brand.code,brand.sku);}}>

                    <div className="mega-brand-card mr-3">
                    <div className="brand-card d-flex justify-content-between item-align-center"
                         style={{'backgroundImage': 'linear-gradient(-135deg, ' + colorCode[0] + ' 0%, ' + colorCode[1] + ' 100%)'}}
                    >
                        <p className="brand-name">{brand.product_name}</p>

                  </div>
                    <div className="text-center">
                        <img
                            src={brand.logo}
                            className="img-fluid product-img"
                            alt={brand.product_name}
                            onError={(ev)=>{
                              ev.target.src = emptyImage;
                            }}

                        />
                        {/*{ggCoin}*/}
                        {
                            brand.is_special ?
                                <p className="brand-product-type text-center m-auto" style={{fontSize:"15px"}}><strike>{brand.price}</strike>  <span style={{color:"black"}}>{brand.special_price}</span></p>
                            :
                                <p className="brand-product-type text-center m-auto" style={{fontSize:"15px"}}>{brand.price}</p>
                        }
                    </div>
                    <div className="d-flex justify-content-between item-align-center pl-1 pr-1 pt-3 pb-2">
                        <p className="brand-delivery text-center m-auto">Store: {brand.store_name}</p>
                        <p className="brand-product-type text-center m-auto">{brand.city}, {brand.state}</p>
                    </div>
                    </div>
                      </a>
                </div>
              );
            })
          }
        </div>
        </Fragment>
        );

    }

}
