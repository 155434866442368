import React from 'react';
import {Button, Intent, Position, FormGroup} from "@blueprintjs/core";
import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';
import Select from 'react-select';

export default class Bus extends React.Component{
      constructor(props){
          super(props);
          this.state = {
              from:'',
              to:'',
              journeyDate:null,
              hasFromError:false,
              hasFromErrorMsg:'',
              hasToError:false,
              hasToErrorMsg:'',
              selectedOption:null,
              toSelectedOption:null,
              swipeClass:'transitionImg'
          };
      }

    componentDidMount() {
        this.props.getFromList();
    }

    fromHandleChange = (selectedOption) => {
        this.setState({ selectedOption, hasFromError:true  });

    }

    toHandleChange = (toSelectedOption) => {
        this.setState({ toSelectedOption, hasToError:true });

    }

    goToBusModule(){
        let d;
        if(!this.state.hasFromError){
            this.setState({ hasFromErrorMsg:"From cannot be empty",hasToErrorMsg:''})
        }else if(!this.state.hasToError){
            this.setState({ hasToErrorMsg:"To cannot be empty",hasFromErrorMsg:''})
        }else{
            d = moment(this.state.journeyDate).format("YYYY-MM-DD");
            window.location.href = this.props.url+"trip-list?source_id="+
                this.state.selectedOption.id+"" +
                "&destination_id="+this.state.toSelectedOption.id+"" +
                "&source_name="+this.state.selectedOption.name+"" +
                "&destination_name="+this.state.toSelectedOption.name+"" +
                "&doj="+d;
        }
    }

    inverseSearch(){
      let fromText = this.state.selectedOption;
      let toText = this.state.toSelectedOption;
      let swipeStyle = this.state.swipeClass;
      if(swipeStyle==='transitionImg'){
        swipeStyle = 'transitionImg-active'
      }else{
        swipeStyle = 'transitionImg'
      }

      this.setState({
          selectedOption: toText,
          toSelectedOption: fromText,
          swipeClass:swipeStyle
      });
    }

    render(){

        let maxDate = new Date();
        let year = maxDate.getFullYear();
        maxDate.setFullYear(year + 10);

        return(
          <div className="strip-form">
                <div className="row mt-3 mt-s-0">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-5 col-12">
                      <FormGroup
                          helperText={this.state.hasFromErrorMsg}>
                          <Select
                              value={this.state.selectedOption}
                              onChange={this.fromHandleChange.bind(this)}
                              className='strip-select'
                              classNamePrefix="strip-select"
                              onKeyUp ={(event) => {
                                  // if (event.which === 13){
                                  //     this.goToBusModule()
                                  // }
                              }}
                              options={this.props.fromList}
                              placeholder="From"
                              valueKey={'id'}
                              key='id'
                              labelKey='name'
                          />
                      </FormGroup>

                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-12 px-sm-0 d-flex justify-content-center align-item-center">
                    <img src={require('../../../core/images/png/swipe.png')} className={"img-fluid "+this.state.swipeClass} alt='swipe'
                    onClick={this.inverseSearch.bind(this)}/>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-5 col-12">
                      <FormGroup
                          helperText={this.state.hasToErrorMsg}>
                          <Select
                              value={this.state.toSelectedOption}
                              onChange={this.toHandleChange.bind(this)}
                              className='strip-select'
                              classNamePrefix="strip-select"
                              onKeyUp ={(event) => {
                                  if (event.which === 13){
                                      this.goToBusModule()
                                  }
                              }}
                              options={this.props.fromList}
                              valueKey={'id'}
                              placeholder="To"
                              key='id'
                              labelKey='name'
                          />
                      </FormGroup>
                  </div>
                  <div className="bp3-form-group col-xl-3 col-lg-3 col-md-3 col-sm-5 col-12">
                    <DateInput placeholder="Journey Date"
                        closeOnSelection={true}
                        minDate={new Date()}
                        maxDate={maxDate}
                        formatDate={date => date.toLocaleDateString()}
                        popoverProps={{ position: Position.BOTTOM }}
                        parseDate={str => new Date(str)}
                        value={this.state.journeyDate}
                        onChange={(date)=>{
                            this.setState({journeyDate:date})
                        }}
                        onKeyUp ={(event) => {
                           if (event.which === 13){
                               this.goToBusModule()
                           }
                        }}
                    />
					              <span className="bp3-icon bp3-icon-calendar inputaddon-icon"></span>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-7 col-12 text-right text-center-xs mt-s-4 mt-sm-1 mt-xs-4 pl-md-0 pl-lg-3">
                      <Button text='Search' intent={Intent.PRIMARY} onClick={this.goToBusModule.bind(this)} className="search-btn" />
                  </div>
                </div>
          </div>
      );
  }
}
