import React from 'react';
import {Button, Intent, InputGroup} from "@blueprintjs/core";

export default class Tripxoxo extends React.Component{
    render(){
        return(
            <React.Fragment>
              <div className="strip-form">
                  <div className="row">
                      <div className="col-xl-10 col-lg-10 col-md-9 col-sm-8 col-12">
                          <InputGroup placeholder="Where are you going ?" />
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 text-right mt-s-4 mt-sm-0 mt-xs-4 text-center-s">
                          <Button text='Search' intent={Intent.PRIMARY}
                            onClick={()=>{
                              window.location.href = this.props.url
                            }}/>
                      </div>
                  </div>
              </div>
            </React.Fragment>
        );
    }
}
