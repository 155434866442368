import React, {Component} from 'react';
import { Button } from "@blueprintjs/core";
import VideoModal from '../../../core/components/videoModal';

export default class FPV extends Component{
    constructor(props){
      super(props);

      this.state = {
        isModalOpen:false,
      };
    }

    componentDidMount(){

    }

    render(){
        return(
          <div className="p-s-0 fpvCashback-container mt-5">
            <VideoModal
    					size="lg"
    					isOpen={this.state.isModalOpen}
              data={'rQf9vKEv8X0'}
    					className="bp3-dialog p-0 rounded"
    				/>
            <div className='important-note py-5 px-s-0 py-s-0 pt-s-1'>
			           <h2 className='title text-center'>What is a FPV?</h2>
              <div className='container row d-flex flex-column-reverse flex-md-row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center'>
                  <h3 className='text-muted'>
                    FPV is termed as fuel purchase value. Global garner provides its users 100% cashback/reward equivalent to fuel amount on
                    the purchase of GG water or GG co-branded water bottle. The cashback/reward will be given as per cashback option chosen by
                    the user.
                    <br/>
                    <br/>
                    2 necessities of life clubbed together to reward on purchase of GG water by giving cashback/reward on fuel.<br/><br/>
                    <Button text='Watch FPV cashback video' className='btn-link w-100 text-left'
                      onClick={()=>{
                        this.setState({isModalOpen:true})
                      }}>
                      &nbsp;&nbsp;&nbsp;
                      <span className="bp3-icon bp3-icon-arrow-right"></span>
                  </Button>
                  </h3>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center'>
                  <img src={require('../../images/png/What-is-FPV.png')} alt="Fuel Cashback" className="img-fluid py-5 py-s-4" />
                </div>
              </div>
            </div>
            <div className='important-note py-5 px-s-0'>
              <div className='container'>
                <center>
                  <h2 className='title'>How to get your FPV Cashback ?</h2>
                  <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center'>
                    <img src={require('../../images/png/How-to-get-your-FPV-Cashback.png')} alt="Fuel Cashback" className="img-fluid" />
                  </div>
                </center>
              </div>
            </div>
            <div className='important-note py-5 p-s-0 pt-s-3'>
              <h1 className='text-primary text-center'>
                <b>
                  A user can choose any one of the desired options. Also, get Instant<br/> 100% GG Coins On Generation Of
                  Each And Every FPV
                </b>
                </h1>
                <br/>
			         <h2 className='title text-center'>FPV Instant Cashback</h2>
              <div className='container row d-flex flex-column-reverse flex-md-row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-5 py-s-4 px-s-0'>

                  <h3 className='text-muted'>
                    Buy a packaged drinking water and A user can choose any one of the desired options.
                  </h3>
                  <ul style={{'list-style-type': 'disc'}}>
                    <li className='mb-2'>Get instant cashback of &#8377; 5</li>
                    <li className='mb-2'>Shopping discount vouchers worth &#8377; 100</li>
                    {/* <li className='mb-2'>BOGO - Buy one Bottle and Get one bottle Free</li> */}
                  </ul>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-s-0 align-self-center'>
                  <img src={require('../../images/png/FPV-Instant.png')} alt="Fuel Cashback" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className='important-note py-5 py-s-4'>
			 <h2 className='title text-center'>FPV Random pick proccess</h2>
              <div className='container row d-flex flex-md-row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                  <img src={require('../../images/png/FPV-RPP.png')} alt="Fuel Cashback" className="img-fluid py-5 py-s-4" />
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center'>
                  <h3 className='text-muted'>
                    System will randomly select the user and cashback percentage generated in a month. Qualifiers are arranged in a pool and
                    system will randomly select the FPV and cashback percentage, unlike the above process RPP users will not have to wait for
                    their turn they are randomly selected. Users will get 2% to 100% cashback /reward.
                  </h3>
                </div>
              </div>
            </div>
            <div className='important-note py-5 px-s-0 pt-s-4'>
			  <h2 className='title text-center'>FPV First in first out</h2>
			  <div className='container row d-flex flex-column-reverse flex-md-row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-5 py-s-0 d-flex align-items-center'>
                  <h3 className='text-muted'>
                    User who generates the FPV first will get the cashback first when FPV's are tagged as per SCB tagging system.
                  </h3>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-5 py-s-0 align-self-center'>
                  <img src={require('../../images/png/FPV-FIFO.png')} alt="Fuel Cashback" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className='important-note py-5 px-s-0 py-s-4'>
			  <h2 className='title text-center'>How will you get 100% fuel cashback (FIFO) ?</h2>
              <div className='container row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center'>
                  <img src={require('../../images/png/How-will-you-get-Fuel-Cashback.png')} alt="Fuel Cashback" className="img-fluid py-5 py-s-4" />
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column align-items-center'>
                  <p className='other-text'>E.g.: Your cashback will follow as given below (assuming petrol price: &#8377; 80)</p>
                  <h3 className='text-muted'>
                    Note: User is only concerned with generating his own FPV. Other FPV's will be generated by the other users and arranged by
                    the company as per SCB. FPV generation might take 24hrs to 48hrs.<br/>
                    Cashback/reward we provide is a unique combination of vendor commission and advertisements revenue we source from various
                    partners.
                  </h3>
                </div>
              </div>
            </div>
            <div className='important-note py-5 px-s-0 py-s-4'>
			  <h2 className='title text-center'>Grab your water franchise</h2>
              <div className='container row d-flex flex-column-reverse flex-md-row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center'>
                  <h3 className='text-muted'>
                    Open the new avenues of earning for yourself with advanced GG water trolley. Have your own electric water trolley with UPOS , wifi,
                    advertisement banner etc. and earn for the lifetime. Contact us for State, city and Trolley franchise and be a part of  new generation business.
                  </h3>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center'>
                  <img src={require('../../images/png/water.png')} alt="Fuel Cashback" className="img-fluid py-5 py-s-4" />
                </div>
              </div>
            </div>

			<div className="px-s-0 py-5 py-s-3 important-note">
				<h2 className='title text-center'>GG - Money</h2>
				<div className='container py-4 row d-flex flex-column-reverse flex-lg-row'>
					<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center'>
						<h3 className='text-muted' style={{'textAlign':'justify'}}>
								This Can Be Immediately Transferred To Your Bank Account After deduction of Applicable Services Charges And Government Taxes.
                Cashback/Reward Through FPV FIFO, FPV RPP, IC And Referral Cashback Is Given In The Form Of GG Money.
							</h3>
					</div>
					<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center'>
						<img src={require('../../images/png/GG-Money.png')} alt="Fuel Cashback" className="img-fluid tab-img" />
					</div>
				</div>
			</div>
			<div className="px-s-0 my-4 my-s-0 py-3 important-note">
				<h2 className='title text-center'>GG - Coins</h2>
				<div className='container py-4 row d-flex flex-lg-row'>
					<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center'>
						<img src={require('../../images/png/GG-Coins.png')} alt="Fuel Cashback" className="img-fluid tab-img" />
					</div>
					<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
						<h3 className=''>You Get Instant 100% GG Coins On Generation Of Each And Every FPV.</h3>
						<h3 className='text-muted' style={{'textAlign':'justify'}}>
              This Can Be Redeemed With New Purchases To The Extent Of 5% Of The Bill Amount And GG-Coins Which Remain In GG-Rewards Till 4th
              Cashback  Payout of FPV FIFO  Will Be Compulsorily Converted Into GG-Money maximum up to fuel price  And Then You Can Encash It
              to your Bank Account*
						</h3>
					</div>
				</div>
			</div>

    			</div>
        );
    }
}
