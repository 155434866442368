import React from 'react';
import {Button, Intent, RadioGroup, Radio, InputGroup, Position, FormGroup, Toaster} from "@blueprintjs/core";
import Select, { Async } from 'react-select';

import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';
import { ssoInstance } from 'gg-react-utilities';
import Config from '../../../Config';


export default class Railbhoj extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            type:1,
            date:null,
            trainViaName:'',
            selectedOption: null,
            pnr:'',
            code:'',
            stationName:'',
            stationCode:'',
            stationList:[],
            hasNumberError:false,
            hasNumberErrorMsg:'',
            hasDateError:false,
            hasDateErrorMsg:'',
            hasPNRError:false,
            hasPNRErrorMsg:'',
            hasStationError:false,
            hasStationErrorMsg:''
        };
    }

    changeType(e){
      this.setState({type:Number(e.target.value)})
    }

    goToRailbhojWithPNR(){
        if(!this.state.hasPNRError){
            this.setState({
                hasPNRErrorMsg:"PNR cannot be empty"
            });
            return false;
        }else if(this.state.pnr.length!==10){
          this.setState({
              hasPNRErrorMsg:"Enter valid PNR"
          })
          return false;
        }else{
            window.location.href = this.props.url+"restaurant?type=pnr&pnr="+this.state.pnr;
        }
    }

    goToRailbhojWithTrain(){
        let d;
        if(!this.state.hasNumberError){
            this.setState({
                hasNumberErrorMsg:"Train Number cannot be empty"
            });
            return false;
        }else{
            this.setState({
                hasNumberErrorMsg:""
            });
        }
        if(!this.state.hasDateError) {
            this.setState({
                hasDateErrorMsg: "Journey date cannot be empty"
            });
            return false;
        }else {
            this.setState({
                hasDateErrorMsg:""
            });
        }
        if(!this.state.hasStationError){
            this.setState({
                hasStationErrorMsg:"Boarding Station cannot be empty"
            });
            return false;
        }else{
            this.setState({
                hasStationErrorMsg:""
            });
        }
        if(this.state.hasNumberError &&
            this.state.hasDateError &&
            this.state.hasStationError){
            d = moment(this.state.date).format("YYYY-MM-DD");
            window.location.href = this.props.url+"restaurant?train="+this.state.trainViaName.code+"&date="+d+"&trainCode="+this.state.selectedOption.value+"&type=train";
            // window.location.href = this.props.url+"restaurant?train="+this.state.code+"&date="+d+"&trainCode="+this.state.stationCode+"&type=train";
        }
    }


    getStationList(trainCode){
        this.props.getStationList(trainCode)
            .then((res) => {
                const sources = res.data;
                if(sources.status===true){
                  let result = [];
                  sources.data.map((item) => {
                      if(this.state.selectedOption === '' || this.state.selectedOption === null) {
                          this.setState({
                              selectedOption:{
                                  label: item.station_name,
                                  value: item.station_code
                              }
                          });
                      }
                      return result.push({
                          label: item.station_name,
                          value: item.station_code
                      });
                  });
                  this.setState({route_list: result,hasStationError:true});
                    // let name = sources.data[0];
                    // sources.data.map((res)=>{
                    //   res.value = res.id;
                    //   res.label= res.station_name;
                    // })
                    // console.log(sources.data);
                    // this.setState({
                    //     stationName:name,
                    //     stationList:sources.data,
                    //     hasStationError:true
                    // })
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                }
            })
            .catch((error)=> {
                Toaster.create({position: Position.TOP}).show({message:'Something went wrong',intent: Intent.DANGER});
            })
    }

    async getOptions(inputValue) {

        if (!inputValue) {
            return [];
        }

        return ssoInstance.get(Config.RAILBHOJ_API_URL + 'train/search/'+inputValue)
                        .then(
                            (res)=> {
                                const response = res.data;
                                if (response.status === true) {
                                  response.data.map((resp) => {
                                        resp.value = resp.name;
                                        resp.label = resp.code +' / '+resp.name ;
                                  })
                                }
                                return response.data;
                              }

                        );

    }

    handleChangeRoute = (selectedOption) => {
        this.setState({ selectedOption });
    }

    render(){
      const { selectedOption } = this.state;
      return(
          <div className="strip-form">
              <RadioGroup
                  className="d-inline option-type"
                  onChange={(e) => this.changeType(e)}
                  name="type"
                  selectedValue={this.state.type}>
                <Radio label="Search by train number" value={1} />
                <Radio label="Search by PNR" value={2} />
              </RadioGroup>
              {this.state.type===1 ?
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="flight-input">
                        <FormGroup
                            helperText={this.state.hasNumberErrorMsg}>
                            <Async
                              autoFocus={true}
                                        multi={false}
                                        className='strip-select'
                                        classNamePrefix="strip-select"
                                        value={this.state.trainViaName}
                                        isLoading={true}
                                        onChange={(value)=>
                                          {
                                            setTimeout(()=>{
                                              this.setState({
                                                  code:value.code,
                                                  trainViaName: value,
                                                  hasNumberError:true
                                              },()=>{
                                                  this.getStationList(this.state.code)
                                              })
                                            },10)
                                          }
                                        }
                                        valueKey="name"
                                        labelKey="value"
                                        Key="code"
                                        loadOptions={this.getOptions}
                                        placeholder={'Search by Train Number/Name'}
                                        searchPromptText={"Please enter 3 or More characters"}
                                        backspaceRemoves={true}
                                        defaultOptions
                                    />

                        </FormGroup>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-sm-0">
                    <div className="flight-input">
                        <FormGroup
                            helperText={this.state.hasDateErrorMsg}>
                          <DateInput placeholder="Select Date"
                              closeOnSelection={true}
                                     minDate={new Date()}
                              formatDate={date => date.toLocaleDateString()}
                              popoverProps={{ position: Position.BOTTOM }}
                              parseDate={str => new Date(str)}
                              value={this.state.date}
                              onChange={(date)=>{
                                this.setState({date:date, hasDateError:true})
                              }}/>
							                  <span className="bp3-icon bp3-icon-calendar inputaddon-icon"></span>
                        </FormGroup>
                    </div>
                  </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-sm-0">
                        <div className="flight-input">
                          <Select
                                value={selectedOption}
                                className='strip-select text-left'
                                classNamePrefix="strip-select"
                                valueKey="value"
                                labelKey="label"
                                Key="station_code"
                                onChange={this.handleChangeRoute}
                                options={this.state.route_list}
                                // placeholder="Select boarding station"
                            />
                        </div>
                    </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-s-4 mt-xs-4 mt-sm-0">
                      <Button text='Search' intent={Intent.PRIMARY} className='pull-right search-btn' onClick={this.goToRailbhojWithTrain.bind(this)}/>
                  </div>
                </div>
              :
              <div className="row">
                  <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                      <FormGroup
                        helperText={this.state.hasPNRErrorMsg}>
                        <InputGroup placeholder="Enter PNR number"
                          value={this.state.pnr.trim()}
                          onChange={(e) =>{
                            this.setState({
                                pnr:e.target.value,
                                hasPNRError:true
                            })
                        }}
                        onKeyUp={(e)=>{
                          if(e.which===13){
                            this.goToRailbhojWithPNR.bind(this)
                          }
                        }}/>
                      </FormGroup>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 mt-s-4 mt-xs-4 ">
                      <Button text='Search' intent={Intent.PRIMARY}  className="search-btn pull-right" onClick={this.goToRailbhojWithPNR.bind(this)}/>
                  </div>
              </div>
            }
          </div>
      );
  }
}
