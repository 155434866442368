import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";
import {Auth} from 'gg-react-utilities';
import Favorite from "../../core/components/favorite";
import Config from '../../Config'

export default class UposVendorDeals extends Component{
    constructor(props){
        super(props);
        this.state = {
            loadingData: true,
          cardColor: [],
            data: []
        };
    }

    componentDidMount(){
      if(this.props.locationData!==null){
        this.props.getUposVendorList(this.props.locationData.lat,this.props.locationData.lng)
      }else{
        this.props.getUposVendorList(0,0)
      };
      const Color = [
        1, 2, 3, 4, 5, 6
      ];

      this.setState({
        cardColor: Color
      })
    }

    render(){
      const {uposVendorList} = this.props;
        return(
            <section className="py-5">
              <div className="container">
                  <div className="awesome-deals">
                      <div className="row mb-3">
                          <div className="col-xl-8 col-8 pr-s-0">
                              <h2 className="custom-heading left"> Nearby UPOS Vendors</h2>
                          </div>
                            <div className="col-xl-4 col-4 text-right">
                                      <a className="view-all-link mt-2" href={Config.STORE_URL + 'nearByUpos'}>View All</a>
                  </div>
                  
                      </div>
                  </div>
                  {uposVendorList===null?
                      <div className="row mt-4">
                          <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                              <Card className='bp3-elevation-1'>
                                  <center>
                                      <div className="bp3-skeleton" style={{height: "200px"}} />
                                      <br/>
                                      <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                      <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                      <br/>
                                  </center>

                              </Card>
                          </div>
                          <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                              <Card className='bp3-elevation-1'>
                                  <center>
                                      <div className="bp3-skeleton" style={{height: "200px"}} />
                                      <br/>
                                      <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                      <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                      <br/>
                                  </center>

                              </Card>
                          </div>
                          <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                              <Card className='bp3-elevation-1'>
                                  <center>
                                      <div className="bp3-skeleton" style={{height: "200px"}} />
                                      <br/>
                                      <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                      <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                      <br/>
                                  </center>

                              </Card>
                          </div>
                          <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                              <Card className='bp3-elevation-1'>
                                  <center>
                                      <div className="bp3-skeleton" style={{height: "200px"}} />
                                      <br/>
                                      <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                      <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                      <br/>
                                  </center>

                              </Card>
                          </div>
                      </div>
                      :
                      <div className='row product-list'>

                          {uposVendorList.data.length===0?
                              <Card className='bp3--3 col-12 col-xl-12 bp3-elevation-1'>
                                  <center>
                                      <img src={require("../images/svg/NoVendor.svg")} alt=''/>
                                      <p className='mt-3 mb-2'>No UPOS vendor found!</p>
                                  </center>
                              </Card>
                              :uposVendorList.data.map((res,i) => {
                                  return(
                                      <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4' key={res.agent_id}>
                                          <div style={{ display: 'none' }}>
                                              {this.state.cardColor[i] == undefined ? i = 0 : ""}
                                          </div>
                                          <div className="product-card">
                                              <div className={this.state.cardColor[i] == 1 ? "bg-pink position-relative" : this.state.cardColor[i] == 2 ? "bg-green position-relative" : this.state.cardColor[i] == 3 ? "bg-orange position-relative" : this.state.cardColor[i] == 4 ? "bg-blue position-relative" : this.state.cardColor[i] == 5 ? "bg-darkgreen position-relative" : this.state.cardColor[i] === 6 ? "bg-darkblue position-relative" : "bg-pink position-relative"}>


                                                  <h1> {res.fname.charAt(0) + res.lname.charAt(0)}</h1>
                                              </div>
                                              <div className="product-sticker">
                                                  <div className="icon-area">
                                                      {Auth.isLoggedIn() ?
                                                          <Favorite show={res.is_fav === 0 ? false : true} vendor_slug={{ 'agent_id': res.agent_id }} {...this.props}/>
                                                          :
                                                          <Favorite show={false} vendor_slug={res.vendor_slug} {...this.props} />
                                                      }
                                                  </div>
                                              </div>
                                              <div class="product-details">
                                                  <div class="row">
                                                      <div class="col-xl-7 col-7 text-left">
                                                          <h3 class="product-name">{res.display_name}</h3>
                                                      </div>
                                                      <div class="col-xl-5 col-5 text-right">
                                                          <h4 class="product-distance">({res.distance !== undefined ? res.distance.toFixed(2) : ''} km)</h4>
                                                      </div>
                                                      <div style={{ display: 'none' }}>
                                                          {i++
                                                          }
                                                      </div>
                                                  </div>

                                              </div>
                                          </div>
                                      </div>
                                  )
                              })}
                      </div>
                  }
              </div>
        			<div className="clearfix"></div>
            </section>
        );
    }
}
