import React, {Component} from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import PDF from "./mediaType/PDF";
import Video from "./mediaType/Video";
import Gallary from "./mediaType/Gallary";
import Loading from './loading';


export default class Media extends Component{
    constructor(props){
        super(props);

        this.state = {
            activeTab: "pdf"
        };
    }
    handleTabChange = (TabId) => {
        this.setState({activeTab: TabId});
    };

    componentDidMount(){
        this.props.getImageList()
        this.props.getVideoList('page=media')
        this.props.getPdfList()
    }


    render(){
        return(
    			<div className="container p-s-0">
            {this.props.pdfList===null || this.props.videoList===null || this.props.imageList===null ?
              <Loading />
            :
            <div className="media">
              <Tabs id="media-type" animate={false}>
                <Tab id="glr" title="Gallery" panel={<Gallary image={this.props.imageList} />} />
                <Tab id="vd" title="Video" panel={<Video video={this.props.videoList} />} />
                <Tab id="pdf" title="PDF" panel={<PDF pdf={this.props.pdfList}/>} />
              </Tabs>
            </div>
          }
    			</div>
        );
    }
}
