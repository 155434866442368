import React, {Component} from 'react';
import {Button, FormGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import {Textbox, Textarea} from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import {postResumeAction} from "../../core/actions";
import LoaderImage from "../../core/images/loader.gif";

export default class ResumePost extends Component {
    constructor(props){
        super(props);
        this.state = {
            name:"",
            exp:"",
            email:"",
            mobile:"",
            address:"",
            ctc:"",
            desc:"",
            resume:null,
            hasNameError:true,
            hasExpError:true,
            hasEmailError:true,
            hasMobileError:true,
            hasAddressError:true,
            hasCtcError:true,
            hasDescError:true,
            hasFileError:false,
            validate:false,
            hasFileMsg:null,
            loader:false
        };

        this.onSubmit = this.onSubmit.bind(this);

    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    onSubmit(){ 
        this.toggleValidating(true);
        if(this.state.hasFileError===true) {
            if(!this.state.hasNameError ||
                !this.state.hasExpError ||
                !this.state.hasEmailError ||
                !this.state.hasMobileError ||
                !this.state.hasAddressError ||
                !this.state.hasCtcError ||
                !this.state.hasDescError) {

                let obj = {
                    name: this.state.name,
                    exp: this.state.exp,
                    email: this.state.email,
                    mobile: this.state.mobile,
                    address: this.state.address,
                    ctc: this.state.ctc,
                    desc: this.state.desc,
                    resume: this.state.resume
                }
                this.setState({loader:true});
                this.props.postResume(obj)
                    .then((res)=>{
                        const sources = res.data;
                        if(sources.status===true){
                            this.setState({
                                name:'',
                                exp:'',
                                email:'',
                                mobile:'',
                                address:'',
                                ctc:'',
                                desc:'',
                                resume:null
                            });
                            Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                        }else{
                            Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                        }

                        this.setState({loader:false})
                    }).catch((error)=>{
                    const sources = error.response;
                    Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    this.setState({loader:false})
                })
            }
        }else{
            this.setState({
                hasFileMsg :"Resume File is required"
            });
            //console.error("Resolve all errors before submit the form");
        }
    }
    render(){
        return(

        	<div className="container">
                <div className="row form">

                    <div className="col-lg-6 col-sm-12 mb-3">
                        <Textbox
                                    tabIndex="1"
                                    type="text"
                                    id="text-input"
                                    value={this.state.name}
                                    placeholder="Name"
                                    onChange={(name) => {
                                        this.setState({
                                            name:name
                                        });
                                    }}
                                    onBlur={e => {}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({ hasNameError:res, validate: false })
                                    }
                                    validationOption={{
                                        name: "Name",
                                        check: true,
                                        required: true
                                    }}/>
                        
                    </div>

                    <div className="col-lg-6 col-sm-12 mb-3">
                        <Textbox
                                    tabIndex="2"
                                    type="text"
                                    id="text-input"
                                    value={this.state.exp}
                                    placeholder="Experience"
                                    onChange={(exp) => {
                                        this.setState({
                                            exp:exp
                                        });
                                    }}
                                    onBlur={e => {}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({ hasExpError:res, validate: false })
                                    }
                                    validationOption={{
                                        name: "Experience",
                                        check: true,
                                        required: true
                                    }}/>

                            
                    </div>

                    <div className="col-lg-6 col-sm-12 mb-3">
                        <Textbox    

                                    tabIndex="3"
                                    type="text"
                                    id="text-input"
                                    value={this.state.email}
                                    placeholder="Email ID"
                                    onChange={(email) => {
                                        this.setState({
                                            email:email
                                        });
                                    }}
                                    onBlur={e => {}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({ hasEmailError:res, validate: false })
                                    }
                                    validationOption={{
                                        name: "Email ID",
                                        check: true,
                                        required: true
                                    }}/>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                        <Textbox
                                    tabIndex="4"
                                    type="text"
                                    id="text-input"
                                    value={this.state.mobile}
                                    placeholder="Mobile Number"
                                    onChange={(mobile) => {
                                        this.setState({
                                            mobile:mobile
                                        });
                                    }}
                                    onBlur={e => {}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({ hasMobileError:res, validate: false })
                                    }
                                    validationOption={{
                                        name: "Mobile Number",
                                        check: true,
                                        required: true
                                    }}/>
                    </div>

                    <div className="col-lg-6 col-sm-12 mb-3">
                        <Textbox
                                    tabIndex="5"
                                    type="text"
                                    id="text-input"
                                    value={this.state.address}
                                    placeholder="Address"
                                    onChange={(address) => {
                                        this.setState({
                                            address:address
                                        });
                                    }}
                                    onBlur={e => {}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({ hasAddressError:res, validate: false })
                                    }
                                    validationOption={{
                                        name: "Address",
                                        check: true,
                                        required: true
                                    }}/>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                        <Textbox
                                    tabIndex="6"
                                    type="text"
                                    id="text-input"
                                    value={this.state.ctc}
                                    placeholder="Current CTC"
                                    onChange={(ctc) => {
                                        this.setState({
                                            ctc:ctc
                                        });
                                    }}
                                    onBlur={e => {}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({ hasCtcError:res, validate: false })
                                    }
                                    validationOption={{
                                        name: "CTC",
                                        check: true,
                                        required: true
                                    }}/>
                    </div>

                    <div className="col-lg-12">
                       
                        <FormGroup
                                        helperText={this.state.hasFileMsg}>
                                        <label className="bp3-file-input bp3-fill mb-2">
                                            <input type="file" accept="application/*" className="bp3-input file-input" placeholder="Choose Attatchment" onChange={(event) => {
                                                this.setState({
                                                    hasFileError:true,
                                                    resume:event.target.files[0]
                                                })
                                            }}/>
                                            <span className="bp3-file-upload-input">Choose file...</span>
                                        </label>
                                    </FormGroup>   


                    </div>



                    <div className="col-lg-12 mb-3">
                        <Textarea
                                    tabIndex="7"
                                    id="description"
                                    name="description"
                                    value={this.state.desc}
                                    rows="7"
                                    placeholder="Place your description here..."
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({
                                            hasDescError: res,
                                            validate: false
                                        })}
                                    onChange={(desc) => {
                                        this.setState({ desc:desc });
                                    }}
                                    onBlur={(e) => {}}
                                    validationOption={{
                                        name: 'Description',
                                        check: true,
                                        required: true,
                                        type: 'string',
                                    }}
                                />
                    </div>

                    <div className="col-lg-3 mx-auto">
                        {
                            !this.state.loader ?
                                <Button onClick={this.onSubmit}>Apply For This Job</Button>
                            :
                                <center>
                                    <div className="my-3">
                                        <img src={LoaderImage} alt={""} style={{width:'50px',height:'50px'}}/>
                                    </div>
                                </center>
                        }
                    </div>

                </div>
            </div>
        	
        );
    }
}
