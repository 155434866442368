import React, { Component, Fragment } from "react";
import { Switch } from "react-router-dom";
import "normalize.css/normalize.css";
// import 'react-select/dist/react-select.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
import { Notfound, Maintance, GgUtils } from "gg-react-utilities";
import "react-inputs-validation/lib/react-inputs-validation.min.css";

import Home from "../home";
import HomeRoute from "./HomeRoute";
import Aboutus from "../aboutus";
import TermsConditions from "../terms_conditions";
import Support from "../support";
import FAQ from "../faq";
import Cashback from "../cashback";
import How_it_work from "../how_it_work";
import Video from "../video";
import Team from "../team";
import Privacy_policy from "../privacy_policy";
import Career from "../career";
import CareerNew from "../career/career";

import JobDetail from "../career/career/components/JobDetail";
import JobDescription from "../job_description";

import PostForm from "../postResume";
import Contest from "../contest";
import Wallpaper from "../Wallpaper";
import HolidayTerms from "../HolidayTerms";
import KnowUs from "../KnowUs";
import Credit from "../credits";
import CreditIOS from "../credits_IOS";
import Media from "../media";
import GlobalSearch from "../search";
import GetAppLink from "../getAppLink";
import Acheivers from "../Acheivers";
import sitemap from "../Sitemap";
import UserBenefits from "../userBenefits";
import Travel from "../travel";
import UposPartner from "../UposPartner";
import GloablWebGuru from "../GloablWebGuru";
import Brands from "../brands";
import UpgrageStore from "../UpgrageStore";

import Pitch from "../pitch_video_d272Yiz";

export default class Root extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_ENV === "production") {
      GgUtils.googleAnalyticalScripts("UA-92068611-1");
      GgUtils.sentryScripts(process.env.REACT_APP_SENTRY_CODE);
    }
  }

  render() {
    return (
      <Fragment>
        {process.env.REACT_APP_IS_MAINTENANCE_MODE === "true" ? (
          <Switch>
            <HomeRoute path="*" component={Maintance} />
          </Switch>
        ) : (
          <Switch>
            <HomeRoute exact path="/" component={Home} />
            <HomeRoute
              exact
              path="/open-source-credits-ios"
              component={CreditIOS}
            />
            <HomeRoute exact path="/open-source-credits" component={Credit} />
            <HomeRoute exact path="/know-us" component={KnowUs} />
            <HomeRoute exact path="/home-wallpaper" component={Wallpaper} />
            <HomeRoute exact path="/get-app-link" component={GetAppLink} />
            <HomeRoute
              exact
              path="/terms-and-conditions"
              component={TermsConditions}
            />
            <HomeRoute
              exact
              path="/privacy-policy"
              component={Privacy_policy}
            />
            <HomeRoute exact path="/support" component={Support} />
            <HomeRoute exact path="/faq" component={FAQ} />
            <HomeRoute exact path="/team" component={Team} />
            <HomeRoute exact path="/pitch_video_d272Yiz" component={Pitch} />
            <HomeRoute exact path="/career" component={Career} />
            <HomeRoute exact path="/career/career" component={CareerNew} />
            <HomeRoute
              exact
              path="/career/career/career-detail"
              component={JobDetail}
            />
            <HomeRoute exact path="/video" component={Video} />
            <HomeRoute exact path="/systematic-cashback" component={Cashback} />
            <HomeRoute exact path="/about-us" component={Aboutus} />
            <HomeRoute exact path="/how-to-begin" component={How_it_work} />

            <HomeRoute
              exact
              path="/job-description"
              component={JobDescription}
            />

            <HomeRoute exact path="/post-resume" component={PostForm} />
            <HomeRoute exact path="/contest" component={Contest} />
            <HomeRoute exact path="/contest-achievers" component={Acheivers} />
            <HomeRoute exact path="/media" component={Media} />
            <HomeRoute exact path="/search" component={GlobalSearch} />
            <HomeRoute exact path="/sitemap" component={sitemap} />
            <HomeRoute exact path="/travel" component={Travel} />
            <HomeRoute exact path="/user-benefits" component={UserBenefits} />
            <HomeRoute exact path="/holidays-terms" component={HolidayTerms} />
            <HomeRoute exact path="/it-services" component={GloablWebGuru} />
            <HomeRoute exact path="/my-upos-partner" component={UposPartner} />
            <HomeRoute exact path="/brands" component={Brands} />
            <HomeRoute exact path="/upgrade-store" component={UpgrageStore} />
            <HomeRoute path="*" component={Notfound} />
          </Switch>
        )}
      </Fragment>
    );
  }
}
