import React, {Component} from 'react';

export default class Vendor extends Component{

    render(){
        return(
            <React.Fragment>
                <div className="vendorWrapper">
                    <div className="container">
                        <div className="vendorHeader">
                            <h3>Many Vendor Benefits</h3>
                        </div>
                        <div className="vendorContainer">
                            <div className="vendorImage">
                                <img src={require('../../images/png/vendor_benefits.png')} alt="" className="img-fluid"/>
                            </div>
                            <div className="vendorDesc">
                                <p>Best part of our ecosystem is we promote vendor's business both online and offline through our on-ground sale support,
                                  our assisted sales partners & UPOS - The Digital Mall.</p>
                                <p>Unlike other platforms who seek high up-front fee before delivering anything and even after paying their fee, Vendors never get the
                                assurance of sales. We charge agreed-on commission only, once we deliver what we promised i.e. absolute & real sales for business
                                otherwise these vendors need not to pay anything for listing, marketing, etc. that we have done for their business.</p>
                            </div>
                            <div className="vendorLinks row">
                                <div className={"col-xl-6 col-12"}>
                                <a href="#!">Learn More about Vendor Benefits </a>
                                </div>
                                <div className={"col-xl-6 col-12  text-xl-right text-lg-right text-sm-right text-center"}>
                                    <a href="/video" className="bp3-button bp3-intent-primary bp3-button bp3-small mt-s-3 mt-sm-0">Watch Video</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
