import React, { Component } from "react";
import { Card } from "@blueprintjs/core";
import { Link } from "react-router-dom";

export default class CashbackTypeTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="container">
        <div className="contest-area py-5">
          <h2 className="title text-center">My Circle Contest</h2>
          {/* <h3 className="text-center title mb-5">Contest ends on</h3> */}
          <Card className="p-4 bp3-elevation-1">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <center>
                  <img
                    src="https://gg-statics.s3.ap-south-1.amazonaws.com/contests/current_contest.png"
                    alt="current_contest"
                    className="img-fluid"
                  />
                </center>
              </div>
            </div>
          </Card>
          {/*<Link to="/contest-achievers" className='btn-link pull-right pt-4'
                  style={{'marginRight':'0'}}>
                Past Contest and Results &nbsp;&nbsp; <span className="bp3-icon bp3-icon-arrow-right"></span>
              </Link>*/}

          <div className="clearfix"></div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}
