import {connect} from 'react-redux';

import ResumePost from '../components/resumePost';
import { postResume } from '../../core/actions/action';
import { postResumeAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        postResume:state.RetailReducer.postResume,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        postResume:(obj) => {
            return postResume(obj);

        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumePost);
