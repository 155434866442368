import React, {Component, Fragment} from 'react';
import { Card, FormGroup, Button } from "@blueprintjs/core";
import {PersonRounded, CallRounded, EmailRounded} from '@material-ui/icons';
import {Async} from 'react-select';
import _ from "lodash";

import GoogleMap from './teamCPGoogleMap';

export default class CP extends Component{
    constructor(props){
        super(props);
        this.state = {
          modalVisible:false,
          selectedOption:''
        };
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextprops){

    }

    getPincode = (input, callback) => {
        if (input.length <= 2) {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        }

        this.props.getPincodeList(input).then((res) => {
            if (res.status === 200) {
                let pincodeList = [];
                res.data.map((resp,i) => {
                    pincodeList.push({
                        pincode:resp.pincode
                    })
                    return res;
                });
                return pincodeList;
            }

            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });

        }).then((json) => {
            callback(null, {
                options: json,
                complete: true
            })
        }).catch((error) => {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({ options: [] });
        });
    }

    render(){
      let pincodeSearch = _.debounce((input, callback) => { this.getPincode(input, callback) }, 100);
      const {partner} = this.props;

        return(
    			<div className="row cb-tab-data cp-member-list px-s-3">
            <GoogleMap
    					size="lg"
              title={<div>Our PAN INDIA Presence - MCP: <img src={'https://www.globalgarner.com/gg-assets/img/mcp.png'} alt='' className='img-fluid'/>
              ACP: <img src={'https://www.globalgarner.com/gg-assets/img/acp.png'} alt='' className='img-fluid'/>
              CP: <img src={'https://www.globalgarner.com/gg-assets/img/cp.png'} alt='' className='img-fluid'/></div>}
    					isOpen={this.state.modalVisible}
              acpLatLngListData={this.props.acpLatLngListData}
              cpLatLngListData = {this.props.cpLatLngListData}
              mcpLatLngListData={this.props.mcpLatLngListData}
    					className="bp3-dialog bp3-dialog-large p-0 rounded"
              {...this.props}
    				/>
          <div className='col-xl-10 col-12 py-3 px-s-0'>
              <FormGroup
                  helperText={this.state.hasFromErrorMsg}>
                  <Async
                      autoFocus={true}
                      clearable={false}
                      multi={false}
                      value={this.state.selectedOption}
                      isLoading={true}
                      onChange={(value) => {
                          this.setState({selectedOption:value, hasPincodeError:true},()=>{
                            this.props.getPartnerList(value.pincode);
                          });

                      }}
                      valueKey="pincode"
                      labelKey="pincode"
                      Key="pincode"
                      loadOptions={(input, callback) => pincodeSearch(input, callback)}
                      placeholder="Search Pincode"
                      searchPromptText={"Please enter 3 or More characters"}
                      backspaceRemoves={true}
                  />
              </FormGroup>
    				</div>
            <div className='col-xl-2 col-12 py-3 pt-s-0 pb-s-5 px-s-0'>
              <Button text='View Map' className='bp3-button bp3-intent-primary bp3-fill' onClick={()=>{
                  this.setState({modalVisible:true})
                  this.props.getMCPLatLng();
                  this.props.getACPLatLng();
                  this.props.getCPLatLng();
                }}/>
            </div>
    				<div className="clearfix"></div>
            {partner===null || this.state.selectedOption===''?
              <Card className='col-xl-12 col-12 col-sm-12 col-md-12 bp3-elevation-1'>
                <div className="error-block mt-4 text-center ">
                  <center>
                    <img src={require('../../images/svg/Co_ACP_ATP_Found.svg')} alt='call-error' className="img-fluid" />
                    <h2 className='error-title'>No MCP, ACP and CP found in your area</h2>
                    <p className='error-muted'>For More Help Please Contact <a href={'mailto:info@globalgarner.com'} data-rel="external">info@globalgarner.com</a></p>
                  </center>
                </div>
              </Card>
              :
              <Fragment>
                {partner.mcp_id!==null?
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 cp-member-detail'>
          					<div className="pull-right">
          						<div className="MCP">
          							{/*<p className="text">MCP</p>*/}
                                    <img src={require('../../../core/images/png/MCP.png')} alt="" className="img-fluid" />
          						</div>
          					</div>
          					<Card className='bp3-elevation-1'>
                      <div className='row'>
          							<div className='col-xl-1 col-lg-1 col-md-1 col-sm-3 col-2'>
          								<span className='user-icon'>
          									<PersonRounded className='icon'/>
          								</span>
          							</div>
          							<div className='col-xl-11 col-lg-5 col-md-9 col-sm-9 col-10'>
          								<p className='name'>{partner.mcp_full_name}</p>
          							</div>
          						</div>
          						<p className='team-address'>Address : {partner.mcp_full_address}</p>
          						<div className='row no-gutters pt-2'>
          							<div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'>
          								<a href={'tel:'+partner.mcp_mobile} data-rel="external" className="align-items-center d-flex"><span style={{verticalAlign: 'sub'}}><CallRounded /></span> &nbsp;&nbsp;+(91) {partner.mcp_mobile}</a>
          							</div>
          							<div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'>
          								<a href={'mailto:'+partner.mcp_email} data-rel="external" className="align-items-center d-flex"><span style={{verticalAlign: 'sub'}}><EmailRounded/></span> &nbsp;&nbsp;{partner.mcp_email}</a>
          							</div>
          						</div>
          					</Card>
          				</div>
                :null}
                {partner.acp_id!==null?
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 cp-member-detail'>
          					<div className="pull-right">
          						<div className="ACP">
          							{/*<p className="text">ACP</p>*/}
                                    <img src={require('../../../core/images/png/ACP.png')} alt="" className="img-fluid" />
          						</div>
          					</div>
          					<Card className='bp3-elevation-1'>
          						<div className='row'>
          							<div className='col-xl-1 col-lg-1 col-md-1 col-sm-3 col-2'>
          								<span className='user-icon'>
          									<PersonRounded className='icon'/>
          								</span>
          							</div>
          							<div className='col-xl-11 col-lg-5 col-md-9 col-sm-9 col-10'>
          								<p className='name'>{partner.acp_full_name}</p>
          							</div>
          						</div>
          						<p className='team-address'>Address : {partner.acp_full_address}</p>
          						<div className='row no-gutters pt-2'>
          							<div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'>
                            <a href={'tel:'+partner.acp_mobile} data-rel="external" className="align-items-center d-flex"><span style={{verticalAlign: 'sub'}}> </span><CallRounded /> &nbsp;&nbsp;+(91) {partner.acp_mobile}</a>
          							</div>
          							<div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'>
          								<a href={'mailto:'+partner.acp_email} data-rel="external" className="align-items-center d-flex"><span style={{verticalAlign: 'sub'}}><EmailRounded/></span> &nbsp;&nbsp;{partner.acp_email}</a>
          							</div>
          						</div>
          					</Card>
          				</div>
                :null}
                {partner.cp_id!==null?
          				<div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 cp-member-detail'>
          					<div className="pull-right">
          						<div className="CP">
          							{/*<p className="text">CP</p>*/}
                                    <img src={require('../../../core/images/png/CP.png')} alt="" className="img-fluid" />
          						</div>
          					</div>
          					<Card className='bp3-elevation-1'>
                      <div className='row'>
          							<div className='col-xl-1 col-lg-1 col-md-1 col-sm-3 col-2'>
          								<span className='user-icon'>
          									<PersonRounded className='icon'/>
          								</span>
          							</div>
          							<div className='col-xl-11 col-lg-5 col-md-9 col-sm-9 col-10'>
          								<p className='name'>{partner.cp_full_name}</p>
          							</div>
          						</div>
          						<p className='team-address'>Address : {partner.cp_full_address}</p>
          						<div className='row no-gutters pt-2'>
          							<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
          								<a href={'tel:'+partner.cp_mobile} data-rel="external" className="align-items-center d-flex"><span style={{verticalAlign: 'sub'}}><CallRounded /></span> &nbsp;&nbsp;+(91) {partner.cp_mobile}</a>
          							</div>
          							<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
          								<a href={'tel:'+partner.cp_email} data-rel="external" className="align-items-center d-flex"><span style={{verticalAlign: 'sub'}}><EmailRounded/></span> &nbsp;&nbsp;{partner.cp_email}</a>
          							</div>
          						</div>
          					</Card>
          				</div>
                :null}
              </Fragment>
            }
    			</div>
        );
    }
}
