import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import CareerList from '../components/career';
import { getCareerList } from '../../core/actions/action';
import { careerListAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        careerList:state.RetailReducer.careerList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getCareerList:(query) => {
          dispatch(careerListAction([]));
            return getCareerList(query)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(careerListAction(sources.data));
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    dispatch(careerListAction([]));
                }
            }).catch((error)=>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CareerList);
