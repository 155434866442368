import React,{Component} from 'react';
import Service from './components/Service';
import Report from './components/Report';
import Price from './components/Price';

export default class Web extends Component{
    constructor(props){
        super(props);
        // document.title = "Digital Marketing Certification Course in Ahmadabad | Global Garner";
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }

    render(){
        // let metaData = document.getElementsByTagName("meta");
        // metaData['description'].content = 'Global Garner is a well-known SEO company in India, specializing in SEO, content marketing, PPC, email marketing, social media marketing, lead generation, online reputation management and more. We offer reliable, sustainable and affordable online marketing service';
        // metaData['keywords'].content = 'digital Marketing institute in India, digital marketing certificate programs in Ahmedabad, best institute for digital marketing, social media marketing courses, learn Digital Marketing, Social Media Marketing, SEO, YouTube, Email, PPC, digital marketing online training, digital marketing training in Ahmedabad, IT Services – Web Development, Mobile Application Development';
        return(
            <React.Fragment>
              <div className="container-fluid header-below">
      					<div className="container py-md-5 py-sm-4 py-s-0">
      						<div className="header-below-content about-us">
      							<div className="row">
      								<div className="col-xl-6 col-lg-6 col-md-7 col-sm-12 col-12 p-s-0">
      									<h1 className="bp3-text-xlarge heading">Create your Website & Online Ecommerce Store to sell products instantly.</h1>
      									<span className="heading-desc">
                          Sell on your Website & Online Ecommerce Store within minutes with integrated payment gateway, logistics, domain, hosting and your own mobile application.
      									</span>
      									<div className="clearfix"></div>
      									<div className="row btn-area pt-5 text-center-xs">
        									<div className="col-xl-6 col-lg-6 col-md-6 col-sm-5 col-12 mb-s-3">
        										<a className="spl-btn" href='#store-inquiry'
                              >Start Selling Now</a>
        									</div>
      									</div>
      								</div>
      								<div className="col-xl-6 col-lg-6 col-md-5 col-sm-12 col-12 p-s-0 mt-s-4">
                        <img src={require('./images/upgrade_store.png')} alt="" className="img-fluid" />
      								</div>
      							</div>
      						</div>
      					</div>
      				</div>
              <div className="upgrade-store-app-wrapper">
                <div className="store_web">
                    <div className="container">
                        <div className="col-12 py-5">
                            <div className="row d-sm-flex align-items-center inquiry_part py-sm-4">
                                <div className="col-md-6 col-12">
                                    <img src={require('./images/single_click_payment_gateway.png')} alt="" className="img-fluid" />
                                </div>
                                <div className="col-md-6 col-12 mt-s-4">
                                    <h2>A Single click Payment Gateway  & Logistics integration on your Website & Online Ecommerce Store</h2>
                                    <h4 className="mb-2">
                                        Sell products & get payments instantly with our integrated Payment Gateway and Logistics. Provide the best payment options
                                        to your customers. Collect online payments on your store using variety of payment options such as Net Banking, Credit Card,
                                        Debit Card, ATM Card. With Latest payment gateway integration boost your Website & Online Ecommerce Store perfectly.
                                    </h4>
                                    <h4 className="mb-2">
                                        We provide best in industry logistics integration with competitive rates. You have the ability to deliver to over 10,000
                                        Indian Postal Pincodes at the click of a button. By arranging pick up right through your online store via the admin panel.
                                        You have the control to enable or disable the area of delivery by selecting pin codes, grouped by states and cities.
                                    </h4>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-lightgrey">
                        <div className="container">
                            <div className="col-12 py-5 inq_service px-0">
                                <Service/>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="col-12 py-5">
                            <div className="row d-sm-flex align-items-center flex-column-reverse flex-md-row inquiry_part py-sm-4">
                                <div className="col-md-6 col-12 mt-s-4">
                                    <h2>Create a good looking Website & Online Ecommerce Store with Mobile Applications</h2>
                                    <br/>
                                    <h4 className="m-0">
                                      To Create a beautiful Website & Online Ecommerce Store has never been so simple. All you have to do is select the components
                                      you like and drag-and-drop them on the editor and Save. Your online store and website will be live instantly.

                                      <br/>  <br/>
                                      Preconfigured website & online store themes that you can use for your responsive website will be added as to your store as
                                      soon as you register. This will help you increase your website loading speed, and help you improve your search engine rankings.
                                    </h4>
                                </div>
                                <div className="col-md-6 col-12">
                                    <img src={require('./images/create_good_looking_website.png')} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="col-12 py-5">
                            <div className="row d-sm-flex align-items-center inquiry_part py-sm-4">
                                <div className="col-md-6 col-12">
                                    <img src={require('./images/how_to_create_your_own_website.png')} alt="" className="img-fluid" />
                                </div>
                                <div className="col-md-6 col-12 mt-s-4">
                                    <h2>How to Create your own Website & Online Ecommerce Store Instantly with Global Garner ?</h2>

                                    <h4 className="">
                                        <b>Fill your detail and click below button to get inquiry. Our team will contact you soon!</b>
                                    </h4>
                                    <a className='bp3-button bp3-intent-primary' href='#store-inquiry'>
                                      Inquiry Now
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-lightgrey py-5">
                        <div className="container">
                            <div className="col-12 py-5 inq_service px-0">
                                <Price/>
                            </div>
                        </div>
                    </div>
                    <div className="bg-report">
                        <div className="container">
                            <div className="col-12 py-5 inq_report px-0 px-s-3" id='store-inquiry'>
                                <Report/>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </React.Fragment>
        );
    }
}
