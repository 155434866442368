import React from 'react'
import { Route } from 'react-router-dom';
import {Header, Footer, ScrollTop} from 'gg-react-utilities';
// import Header from './components/Header';
// import Footer from './components/FooterBottom';
// import GGDetailFooter from './components/ggDetailFooter';

const HomeRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => (
            <div className="retail">
              <Header headerType='dark' locationRequired/>
              <Component {...props} />
              <ScrollTop/>
              <Footer />
            </div>
    )}/>
);

export default HomeRoute;
