import React, {Component, Fragment} from 'react';

export default class LoadingComponent extends Component{

    render(){
        return(
          <div className="row mt-4" >
            {this.props.loadingType==='module'?
            <Fragment>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 module-link mt-2 mb-2" key={module.id}>
                  <div className="bp3-skeleton rounded-circle" style={{height: "8em",width: '8em'}}>&nbsp;</div>
              </div>

            </Fragment>
            :
            <Fragment>
              <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
              </div>

              <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
              </div>

              <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
              </div>

              <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
              </div>

              <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
              </div>

              <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
              </div>

              <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
              </div>

              <div className="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12 mb-5">
                <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
              </div>
            </Fragment>
            }
          </div>
        );

    }

}
