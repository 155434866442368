import React, {Component} from 'react';
import InfiniteCarousel from 'react-leaf-carousel';

export default class AcheiversContent extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }

    render(){
        return(
            <div className="container mb-5">
                <div className="achievers">
                    <InfiniteCarousel
                        breakpoints={[
                            {
                                breakpoint: 500,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                },
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                },
                            },
                        ]}
                        dots={false}
                        showSides={true}
                        autoCycle={true}
                        cycleInterval={1500}
                        sidesOpacity={.5}
                        sideSize={.0}
                        slidesToScroll={1}
                        slidesToShow={1}
                        scrollOnDevice={true}>
                        <div className="home-slide">
                            <h2 className='text-center title'></h2>
                            <img alt={""} src={require('../images/new_upv_winner.png')} className="img-fluid" />
                        </div>
                        <div className="home-slide">
                            <img alt={""} src={require('../images/new_user_winner2.png')} className="img-fluid" />
                        </div>
                    </InfiniteCarousel>
                    <div className='container'>
                      <h2 className='title'>Contest Winners</h2>
                      <div className="table-responsive">
                          <table className="bp3-html-table bp3-html-table-bordered">
                            <thead className="thead-light">
                              <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>City</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                  <td><b>1</b></td>
                                  <td>Arun Bajaniya</td>
                                  <td>arunbajani****11@gmail.com</td>
                                  <td>Chandkheda, Gandhinagar</td>
                              </tr>
                              <tr>
                                  <td><b>2</b></td>
                                  <td>Aakanksha bhagwat</td>
                                  <td>aakankshasb****at@gmail.com</td>
                                  <td>Thane, Maharashtra</td>
                              </tr>
                              <tr>
                                  <td><b>3</b></td>
                                  <td>Anil Pal</td>
                                  <td>dhangar.a****al@gmail.com</td>
                                  <td>Dehradun, Uttrakhand</td>
                              </tr>
                              <tr>
                                  <td><b>4</b></td>
                                  <td>M Meenakshi</td>
                                  <td>prashantk*****ik@rediffmail.com</td>
                                  <td>Jaipur, Rajasthan</td>
                              </tr>
                              <tr>
                                  <td><b>5</b></td>
                                  <td>Pooja</td>
                                  <td>-</td>
                                  <td>Mumbai, Maharashtra</td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
                      <h3 className='title'>
                          SHOPPING IS NECESSARY TO ENTER OR WIN THIS CONTEST. THIS CONTEST IS FOR BOTH, NEW AND EXISTING USERS. TO ENTER THIS
                          CONTEST, YOU JUST NEED TO GENERATE AT LEAST ONE UPV DURING THE CONTEST PERIOD. MORE THE NUMBER OF UPVS MORE THE
                          CHANCES OF WINNING!!!!
                      </h3>
                      <p>
                        Please read these terms and conditions and official rules before entering this contest ("Contest"). You agree that,
                        by submitting an entry, you will be bound by these terms and conditions and you acknowledge that you satisfy all
                        contest eligibility requirements.
                      </p>
                      <p>
                        This Global garner contest ("Contest") is brought to you by Global Garner sales Services Private Limited(Global Garner).
                        Please read these terms and conditions carefully ("T&Cs") before entering the Contest. There is no entry fee to enter
                        the contest.<b>UPV GENERATION IS MANDATORY TO ENTER THE CONTEST FROM THE PURCHASES MADE DURING THE CONTEST PERIOD.</b>
                        By entering this competition, an entrant is indicating his/her agreement to be bound by these terms and conditions in
                        addition to our Terms and Conditions of Use and privacy policy to which you agree by using the www.globalgarner.com
                        website or mobile application or mobile site thereof (collectively hereinafter referred to as "Global Garner") and you
                        acknowledge that you satisfy all Contest eligibility requirements as provided herein below.
                      </p>
                      <p>
                        For the purposes of these T&Cs, wherever the context so requires "You" or "Your" shall mean any natural person who
                        is a participant in the Contest.
                      </p>
                      <p className='title mt-4'>ELIGIBILITY:</p>
                      <p>You need to fulfil the following eligibility criteria to enter the Contest and Eligible entries are ones that satisfy these eligibility criteria.</p>
                      <ul>
                        <li>You should be an individual legal resident of the Republic of India.</li>
                        <li>You have a residential address within the territory of India with proper address proof.</li>
                        <li>You should be of an age 18 years or above at the time of entry into the contest.</li>
                        <li>User can generate any number of UPVs. More the number of UPVs, more the probability of winning the contest.</li>
                        <li>You can be a new user or an existing user on the platform of Global Garner. All USERS are eligible to participate in this contest.</li>
                        <li>
                          <b>Purchases from all modules will be considered except for Best shopping websites as it takes around 90 days for the generation of UPV from this module.</b>
                        <br/>Our employees, their immediate family members (spouses, domestic partners, parents, grandparents, siblings, children
                            and grandchildren), and our affiliates, advisors, advertising/Contest agencies are not eligible to enter the contest.
                        </li>
                      </ul>
                      <p className='title mt-4'>DETAILS OF THE CONTEST AND HOW TO ENTER:</p>
                        <ol>
                          <li>This Contest will commence from 1st January, 2018 at 12:00:01 a.m. (IST) and end on 31st January 2018 at 11:59:59 p.m. (IST) ("Contest Period").</li>
                          <li>In order to be eligible for the Contest, during the Contest Period you must generate UPV on Global Garner platform from the purchases made between the contest period. All new and existing users are eligible for this contest.</li>
                          <li>Participant will be selected by a random draw of lots at the end of the Contest Period, after UPVs are generated from the purchases made during the contest period , amongst all participants who participated in the contest.</li>
                          <li>Contest will be on the UPVs generated from the purchases made between 1st Jan 2018 to 31st Jan 2018. UPVs generated from old purchases will not be considered even if they are generated during the contest period.</li>
                          <li>Purchases made from Best shopping websites will not be considered for this contest.</li>
                        </ol>
                      <p className='title mt-4'>OTHER CONTESTS:</p>
                      <p>
                        Please note that we may be running similar contests or promotions at the same time as this Contest. By entering this
                        contest, you will not be eligible to receive any benefit awarded in any other promotion. You can participate in other
                        contest too but you are eligible to receive only one reward.
                      </p>
                      <p className='title mt-4'>PRIZES:</p>
                      <p>
                        There are only Two prizes and the first winner will be awarded Samsung J7 and the second winner will be awarded Big
                        Bazaar voucher worth  &#8377; 5000/- by random lucky draw.
                      </p>
                      <p className='title mt-4'>PRIZE DRAW:</p>
                      <p>
                        Winner will be selected at random by software from all the new users signed up during contest period. The drawing for
                        selection of the winners of the Contest will be executed within a month from the end of the Contest Period. Winner will
                        be notified by an email within five (5) days following selection of Winner and they will be required to share a valid
                        proof of identity and age in the form of a copy of PAN Card / Driving License / Voter ID / Indian passport. A PAN card
                        copy is mandatory for prizes worth INR 10,000 or more. The selected participants will automatically forfeit their claim
                        to the Prize if they do not meet the eligibility criteria or do not comply with these Terms & Conditions . All USERS
                        are eligible to participate in this contest by generating UPVs from the purchases done within the contest period. Each
                        Prize will be awarded "AS IS" and WITHOUT WARRANTY OF ANY KIND, express or implied (including, without limitation, any
                        implied warranty of merchantability or fitness for a particular purpose). The prize is as stated and cannot be exchanged for cash or any other item. The prizes are not transferable. Prizes are subject to availability and we reserve the right to substitute any prize with another of equivalent value without giving notice. Global Garner shall have no liability for Winner’s failure to receive notices due to spam, junk e-mail or other security settings or for winner’s provision of incorrect or otherwise non-functioning contact information. If winner cannot be contacted, is ineligible, fails to claim the prize within 15 days from the time award notification was sent, or fails to timely return a completed and executed declaration and release as required, the prize may be forfeited and an alternate Winner selected. ANY VIOLATION OF THESE OFFICIAL RULES BY WINNER (AT GLOBAL GARNER’S SOLE DISCRETION) WILL RESULT IN WINNER’s DISQUALIFICATION AS WINNER OF THE CAMPAIGN, AND ALL PRIVILEGES AS WINNER WILL BE IMMEDIATELY TERMINATED.
                      </p>
                      <p className='title mt-4'>ADDITIONAL TERMS:</p>
                      <p>
                        We may, to the maximum extent permitted by applicable law and in our sole discretion, change the T&Cs or cancel the
                        contest at any time; or modify, terminate, or suspend the Contest should viruses, worms, bugs, unauthorized human
                        intervention or other causes beyond our control corrupt or impair the administration, security, fairness or proper
                        play of the Contest or submission of entries.
                      </p>
                      <p>
                        We are not responsible for any fake or incorrect entry or information, whether caused by you or by any of the equipment
                        or programming associated with or utilized in the Contest, or by any technical or human error that may occur in the
                        processing of entries or any error in the operation or transmission, theft, destruction, unauthorized access to, or
                        alteration of, entries, or for technical, network, telephone, computer, hardware or software, malfunctions of any kind,
                        or inaccurate transmission of, or failure to receive any entry information on account of technical problems or traffic
                        congestion on the Internet or at any website; or injury or damage to your or any other computer or mobile resulting
                        from downloading any materials in connection with the Contest.
                      </p>
                      <p>
                        We may, in our sole discretion, disqualify any individual found to be: (a) tampering with the entry process or the
                        operation of the Contest or website or mobile application; (b) acting in violation of these T&Cs or (c) acting in an
                        unsportsmanlike or disruptive manner or with intent to annoy, abuse, threaten or harass any other person.
                      </p>
                      <p>
                        If your entry is fake, incorrect or manipulated or if you use robotic, automatic, programmed or similar entry methods,
                        your entry will be void. The authorized subscriber of the email account used to enter the Contest at the actual time
                        of entry will be deemed to be the participant and must comply with these T&Cs in the event of a dispute as to entries
                        submitted by multiple users having the same email account. The authorized account subscriber is the natural person who
                        is assigned an email address by an internet access provider, online service provider, or other organization responsible
                        for assigning email addresses for the domains associated with the submitted e-mail addresses. No benefits greater than
                        those described in the "Description of the Contest and How to Enter" section above in these T&Cs will be awarded.
                      </p>
                      <p className='title mt-4'>MISCELLANEOUS:</p>
                      <p>
                        The Contest is governed by the laws of the Republic of India. All rights reserved by Global Garner Sales Service Pvt. Ltd.(Global Garner)
                        You agree that all our decisions related to the Contest are final and binding on you. There shall not be any claim in case any failure
                        happens by Global Garner to enforce any of these T&Cs in any instance. You agree that the decision of Global Garner shall at all times be
                        binding and final to you. These T&Cs are subject to Indian laws and the courts at Ahmedabad shall have the exclusive jurisdiction in
                        respect of any disputes or any matter arising here from.
                      </p>
                      <p className='title mt-4'>FORCE MAJEURE:</p>
                      <p>
                        This contest is subject to force majeure circumstances including without limitation, floods, natural disasters, war,
                        act of terror, political unrests, technical snags, act of God or any circumstance beyond the reasonable control of
                        Global Garner ("Force Majeure Event"). Global Garner shall not be liable for any delay or adverse effect caused to the
                        participants in the Contest including the winners as a result of a Force Majeure Event.
                      </p>
                      <p className='title mt-4'>PUBLICITY RELEASE:</p>
                      <p>
                        By participating in the Contest, you give Global Garner Sales Service Pvt. Ltd. and/ or its affiliates permission to use
                        your name, likeness, image, voice, and/or appearance as such may be embodied in any pictures, photos, video recordings,
                        audio tapes, digital images, and the like, taken or made in relation to the Contest and any promotions, events, or
                        contests to follow. You agree that Global Garner Sales Service Pvt. Ltd and/ or its affiliates have the right to
                        publish your saved item details for any communication, promotions, events, or contests that follow. You agree that
                        Global Garner Sales Service Pvt. Ltd and/ or its affiliates have complete ownership of such pictures, etc., including
                        the entire copyright, and may use them for any purpose. These uses include, but are not limited to illustrations,
                        bulletins, exhibitions, videotapes, reprints, reproductions, publications, advertisements, and any promotional or
                        educational materials in any medium now known or later developed, including the internet. You acknowledge that you
                        will not receive any compensation, etc. for the use of such pictures, etc. You give your consent to Global Garner Sales
                        Service Pvt. Ltd and/or its affiliates, agents and assigns to use your name and likeness to promote the Contest and any
                        promotions, events, or contests to follow.
                      </p>
                      <p className='title mt-4'>RELEASE FROM LIABILITY:</p>
                      <p>
                        This Contest is being made purely on a "best effort" basis and participating in this Contest is voluntary. By
                        participating in the Contest, you will be legally bound hereby, to release from liability, and hold harmless Global
                        Garner Sales Service Pvt. Ltd, and any of its affiliates, employees or agents representing or related to Global Garner
                        Sales Service Pvt. Ltd and its services/products. This release is for any and all liability for personal injuries
                        (including death), property loss or damage, and misuse of the benefits/ Prizes offered under this Contest, in
                        connection with any activity or directly or indirectly, by reason of the acceptance, possession, or participation in
                        the Contest, even if caused or contributed to by our negligence.
                      </p>
                      <p className='title mt-4'>ANNOUNCEMENT OF WINNER:</p>
                      <p>
                        After we confirm the winner, we will post the winner's names on this page as per the contest schedule.
                      </p>
                      <p className='title mt-4'>CONTEST SPONSOR BY:</p>
                      <p>
                        The sponsors of this Contest are Global Garner Sales Service Pvt. Ltd., B-401, Safal Pegasus, Aanand Nagar Road,Prahlad Nagar, Ahmedabad, Gujarat 380015. Please contact for any questions, comments or complaints related to
                        the Contest to the Customer Care of Global Garner Sales Service Pvt. Ltd.
                      </p>
                      <p className='title mt-4'>PRIVACY NOTICE:</p>
                      <p>
                        All information submitted in connection with this Contest will be treated in accordance with these T&Cs and the Privacy
                        Policy available at <a href='https://www.globalgarner.com'>www.globalgarner.com</a>.
                      </p>
                    </div>

                </div>
            </div>
        );
    }
}
