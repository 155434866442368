import React, {Component, Fragment} from 'react';
import {Button, Card, Intent, Position, Toaster} from "@blueprintjs/core";
import InfiniteScroll from "react-infinite-scroll-component";
import {EmailRounded, LocationOnRounded, PhoneAndroidRounded} from '@material-ui/icons';
import {Auth, Loader, NoLogin, ssoInstance} from 'gg-react-utilities';
import Loading from './loading';
import Config from '../../Config';

export default class UposPartner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            limit: 1,
            search: '',
            partnerData: [],
            isLoggedIn: true,
            lastPage:0,
            mainData:[],
            loadMoreLoader:false,
            loader:false
        };
    }

    async checkAuth() {
        setTimeout(() => {
            Auth.isLoggedInAsync().then((res) => {
                this.setState({isLoggedIn: res}, () => {
                    if (this.state.isLoggedIn === true) {
                        this.setState({
                            loader:true
                        })
                        this.props.getUposPartnerList(this.state.limit, this.state.search)
                    }
                })
            });
        }, 1)
    }

    componentDidMount() {
        this.checkAuth();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.uposPartnerData !== null) {
            //if (nextProps.uposPartnerData.agentList.last_page > this.state.limit) {
                this.setState({
                    partnerData: this.state.partnerData.concat(nextProps.uposPartnerData.agentList.data),
                    lastPage: nextProps.uposPartnerData.agentList.last_page-1,
                    mainData:nextProps.uposPartnerData,
                    loadMoreLoader:false,
                    loader:false,
                    //partnerData: nextProps.uposPartnerData.agentList.data
                })
            //}
        }
    }


    fetchMoreData() {
        if (this.props.uposPartnerData !== null) {
            if (this.state.limit > this.props.uposPartnerData.agentList.last_page) {
                this.setState({limit: this.state.limit + 1}, () => {
                    this.props.getUposPartnerList(this.state.limit, this.state.search);
                })
            }
        }
    }

    setDefaultAgent(id) {
        if(id===this.props.uposPartnerData.defaultAgentId){
          id = 0;
        };
        let obj = {
            'agent_id': id.toString(),
        }
        ssoInstance.post(Config.UPOS_API_URL + 'update-customer-default-agent', obj).then((res) => {

            const sources = res.data;
            if (sources.status === true) {
                Toaster.create({position: Position.TOP}).show({message: sources.message, intent: Intent.SUCCESS});
                setTimeout(() => {
                    this.props.getUposPartnerList(this.state.limit, this.state.search);
                }, 100);
            } else {
                Toaster.create({position: Position.TOP}).show({message: sources.message, intent: Intent.DANGER});
            }

        }).then(function (json) {

        }).catch(function (error) {
          Toaster.create({position: Position.TOP}).show({message: error.response.data.message, intent: Intent.DANGER});

        });
    }

    render() {
        const {uposPartnerData} = this.props;
        const {partnerData, isLoggedIn} = this.state;
        return (
            <div className="container p-s-0 pb-5">
                <div className="upos-partner-area">
                    {
                        isLoggedIn === true ?
                            <div className='row'>
                                <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-2">
                                    <input type="text" className="bp3-input" placeholder="Search partner"
                                           value={this.state.search}
                                           onChange={(e) => {
                                               this.setState({search: e.target.value}, () => {
                                                       this.setState({
                                                           loader: true,
                                                           limit: 1,
                                                           partnerData: []
                                                       })
                                                       this.props.getUposPartnerList(this.state.limit, this.state.search);
                                                   })
                                           }}/>
                                </div>
                            </div>
                        :''
                    }
                    {!this.state.loader ?
                        this.state.partnerData !== null && isLoggedIn === true ?
                            this.state.partnerData.length === 0 ?
                                <Card className='bp3-elevation-1 border mt-5 mb-5'>
                                    <div className='mt-5 mb-5 py-5'>
                                        <center>
                                            <img src={require('../images/category.svg')} alt='no partner'
                                                 className='img-fluid'/>
                                            <h3 className='py-3'>No UPOS partner found...!</h3>
                                        </center>
                                    </div>
                                </Card>
                                :
                                <Fragment>
                                    <InfiniteScroll
                                        dataLength={this.state.partnerData.length}
                                        next={this.fetchMoreData.bind(this)}
                                        hasMore={true}
                                        /*loader={uposPartnerData.agentList.current_page > uposPartnerData.agentList.last_page ?
                                            <div className='mt-1'><Loader/></div> : null}*/
                                    >
                                        <div className="row mt-5 mb-5 upos-partner-list">
                                            {this.state.partnerData.map((res) => {
                                                res.avatar = this.state.mainData.avatarPath + res.avatar;
                                                return (
                                                    <div className="col-md-6 col-xl-6 col-lg-6 col-sm-6 col-12 mb-4"
                                                         key={res.agent_id}>
                                                        <Card className='border shadow-none'>
                                                            <div className="row">
                                                                <div
                                                                    className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-s-2 text-center-xs">
                                                                    <img src={res.avatar} alt='partner avatar'
                                                                         className='img-fluid border partner-img img-thumbnail'
                                                                         onError={(ev) => {
                                                                             ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                                                         }}/>
                                                                </div>
                                                                <div
                                                                    className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 pl-xl-4 pl-lg-3 pl-md-1 pl-sm-3 pl-3 mt-s-3 py-3 pt-s-0">
                                                                    <Button
                                                                        text={this.state.mainData.defaultAgentId === res.agent_id ? 'Default' : 'Set as Default'}
                                                                        onClick={() => {
                                                                            this.setDefaultAgent(res.agent_id)
                                                                        }}
                                                                        className={this.state.mainData.defaultAgentId === res.agent_id ? 'bp3-button bp3-small bp3-intent-primary pull-right' : 'pull-right bp3-button bp3-small bp3-outline'}/>
                                                                    <p>{res.display_name}</p>
                                                                    <div className="upos-partner-text mt-3">
                                                                        <a href={'mailto:' + res.email} data-rel="external"
                                                                           className="d-flex mb-1 align-items-center"><EmailRounded
                                                                            className="mr-2"/>{res.email}</a>
                                                                    </div>
                                                                    <div className="upos-partner-text">
                                                                        <a href={'tel:' + res.mobile} data-rel="external"
                                                                           className="d-flex mb-1 align-items-center"><PhoneAndroidRounded
                                                                            className="mr-2"/>{res.mobile}</a>
                                                                    </div>
                                                                    <div className="upos-partner-text mb-s-3">
                                                                        <LocationOnRounded className="mr-2"/>
                                                                        <a href={'#!'}
                                                                           className="d-flex mb-1">{res.landmark + ', ' + res.city + ', ' + res.state + ' - ' + res.pincode}</a>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </Card>
                                                    </div>
                                                )
                                            })}
                                            <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mt-4">
                                                <center>
                                                    <Button
                                                        text={'Load More'}
                                                        onClick={() => {
                                                            this.setState({
                                                                limit: this.state.limit + 1,
                                                                loadMoreLoader:true
                                                            },()=>{
                                                                this.props.getUposPartnerList(this.state.limit, this.state.search)
                                                            })
                                                        }}
                                                        disabled={(this.state.lastPage > this.state.limit && this.state.partnerData.length > 9) ? false : true}
                                                        loading={this.state.loadMoreLoader}
                                                        className={'bp3-button bp3-small bp3-intent-primary center'}/>
                                                </center>
                                            </div>
                                        </div>
                                    </InfiniteScroll>
                                </Fragment>
                            :
                            isLoggedIn === false ?
                                <Card className='bp3-elevation-1 border mt-5 mb-5'>
                                    <div className='mt-5 mb-5 py-5'>
                                        <NoLogin imageShow={true} homeLink={false}/>
                                    </div>
                                </Card>
                                :''
                    :
                        <Loading/>
                    }
                </div>
            </div>
        );
    }
}
