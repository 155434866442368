import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import VideoList from '../components/videoList';
import { getVideoList } from '../../core/actions/action';
import { videoListAction } from '../../core/actions';


const mapStateToProps = state => {

    return{
        videoList:state.RetailReducer.videoList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getVideoList:() => {
            return getVideoList()
                .then((res)=>{
                    const sources = res.data;

                    if(sources.status===true){
                        dispatch(videoListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(videoListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoList);
