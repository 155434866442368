import React, {Component} from 'react';

import Terms from "./components/terms";
import {PageTitle} from 'gg-react-utilities';

export default class Aboutus extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }
    
    render(){

        return(
            <React.Fragment>
              <PageTitle title='Terms & Conditions | Global Garner' />
                <div className="container-fluid header-below">
                    <div className="container p-s-0">
						<div className="career header-below-content">
							<div className="row text-center">
								<div className="col-12 col-xl-12 text-center py-5 pt-s-0 pt-sm-0 mb-s-0 pb-s-0">
									<h1 className="bp3-text-xlarge heading">Terms and Conditions</h1>
									<span className="heading-desc">By accepting these Terms and Condition, you are deemed to accept our Privacy Policy.</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid terms-condtion-container">
					<Terms/>
				</div>

            </React.Fragment>
        );
    }
}
