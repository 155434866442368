import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import FAQ from '../components/faqSegment';
import { getFaqList, getFaqCategoryList, searchFaq} from '../../core/actions/action';
import {faqListAction, faqCategoryListAction, searchFaqAction} from '../../core/actions';

const mapStateToProps = state => {

    return{
        faqList:state.RetailReducer.faqList,
        faqCategoryList:state.RetailReducer.faqCategoryList,
        searchFaqQuery:state.RetailReducer.searchFaqQuery
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getFaqList:(cid) => {
            return getFaqList(cid)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(faqListAction(sources.data));
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    dispatch(faqListAction([]));
                }
            }).catch((error)=>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
            })
        },
        getFaqCategoryList:() => {
            return getFaqCategoryList()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(faqCategoryListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(faqCategoryListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        searchFaq:(query) => {
            return searchFaq(query)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(searchFaqAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(searchFaqAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
