import React, {Component} from 'react';
import {DateRangeRounded} from '@material-ui/icons';
import {Position, Popover, Button, RadioGroup, Radio,Toaster, Intent} from '@blueprintjs/core';
import {DateInput} from '@blueprintjs/datetime';
import { Async } from 'react-select';
import moment from 'moment';
import { ssoInstance } from 'gg-react-utilities';
import Config from '../../../Config';

export default class TripSearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tripType: 0,
            onwardDate: new Date(),
            returnDate: new Date(),
            Infant: 0,
            Teen: 0,
            Adult: 1,
            passengerInputWrapper: false,
            classType: '0',
            fromText: 'ah',
            toText: null,
            swipeClass:'transitionImg'
        }
        this.inverseSearch = this.inverseSearch.bind(this)
    }


    componentDidMount() {

        if (this.props.searchDetails && this.props.searchDetails !== null) {
            let returnDate = new Date(this.props.searchDetails.returnDate);
            if (this.props.searchDetails.returnDate === null) {
                returnDate = new Date(this.props.searchDetails.beginDate);
            }

            this.setState({
                fromText: {
                    id: this.props.searchDetails.from,
                    name: this.props.searchDetails.fromCity,
                    country_code: this.props.searchDetails.fromCountry
                },
                toText: {
                    id: this.props.searchDetails.to,
                    name: this.props.searchDetails.toCity,
                    country_code: this.props.searchDetails.toCountry
                },
                onwardDate: new Date(this.props.searchDetails.beginDate),
                returnDate: returnDate,
                Adult: this.props.searchDetails.adults,
                Teen: this.props.searchDetails.childs,
                Infant: this.props.searchDetails.infants,
                classType: this.props.searchDetails.cabin,
                tripType: this.props.searchDetails.tripType,
            })
        }

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.searchDetails && nextProps.searchDetails !== null) {

            let returnDate = new Date(nextProps.searchDetails.returnDate);
            if (nextProps.searchDetails.returnDate === null) {
                returnDate = new Date(nextProps.searchDetails.beginDate);
            }

            this.setState({
                fromText: {
                    id: nextProps.searchDetails.from,
                    name: nextProps.searchDetails.fromCity,
                    country_code: nextProps.searchDetails.fromCountry
                },
                toText: {
                    id: nextProps.searchDetails.to,
                    name: nextProps.searchDetails.toCity,
                    country_code: nextProps.searchDetails.toCountry
                },
                onwardDate: new Date(nextProps.searchDetails.beginDate),
                returnDate: returnDate,
                Adult: nextProps.searchDetails.adults,
                Teen: nextProps.searchDetails.childs,
                Infant: nextProps.searchDetails.infants,
                classType: nextProps.searchDetails.cabin,
                tripType: nextProps.searchDetails.tripType,
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, false);
    }


    /* START HERE - FUNCTION FOR INCREASE PASSENGER(ADULT , TEEN , INFANT) COUNT */
    increase = (name, value) => {

        let totalPassengers = this.state.Adult + this.state.Teen + 1;

        if (name === 'Infant') {

            let adultCount = this.state.Adult;

            if (value + 1 <= adultCount) {

                value++;
                this.setState({
                    [name]: value
                })

            }


        } else {

            if ((totalPassengers < 10)) {


                value++;
                this.setState({
                    [name]: value
                })

            }

        }


    };
    /* END HERE - FUNCTION FOR INCREASE PASSENGER(ADULT , TEEN , INFANT) COUNT */

    /* START HERE - FUNCTION FOR DECREASE PASSENGER(ADULT , TEEN , INFANT) COUNT */
    decrease = (name, value) => {
        if (name === 'Adult' && value <= 1) {
             value = 1;
        } else {
          value = value - 1;
          if (value <= 0) {
              value = 0;

          }
        }


        this.setState({
            [name]: value
        })

        setTimeout(() => {
            let infrantsCount = this.state.Infant;
            if (infrantsCount > this.state.Adult) {
                this.setState({
                    Infant: value
                })
            }
        }, 100)

    }
    /* END HERE - FUNCTION FOR DECREASE PASSENGER(ADULT , TEEN , INFANT) COUNT */

    handleFocus = () => {
        this.setState({
            passengerInputWrapper: !this.state.passengerInputWrapper
        })
    }

    handleClickOutside = (event) => {

        if (this.node.contains(event.target)) {
            return;
        }

        this.setState({
            passengerInputWrapper: false
        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        // const name = target.name;

        let trip_text = "Round Trip";
        if (value === true) {
            trip_text = "Round Trip";
        }
        this.setState({
            tripTypeText: trip_text,
            trip_status: value
        });

    }

    onChangeTripe = (value) => {
        this.setState({
            classType: this.state.classType,
            tripType: value
        })
    }

    handleChange = (keys, newDate) => {
        this.setState({
            [keys]: newDate
        })
    }

    async getOptions(inputValue) {

        if (!inputValue) {
            return [];
        }

        return ssoInstance.post(Config.FLIGHT_API_URL + 'getAirport',{"searchAirportData" : inputValue})
                        .then(
                            (res)=> {
                                const response = res.data;
                                if (response.status === true) {
                                  response.data.map((resp) => {
                                        resp.value = resp.city_code;
                                        resp.label = resp.city_name + ',' + resp.country_name + ' (' + resp.city_code + ')';
                                        resp.id = resp.city_code;
                                        resp.name = resp.city_name + ',' + resp.country_name + ' (' + resp.city_code + ')';
                                  })
                                }
                                return response.data;
                              }

                        );


    }


    inverseSearch = () => {

        let fromText = this.state.fromText;
        let toText = this.state.toText;
        let swipeStyle = 'transitionImg';

        if(swipeStyle==='transitionImg'){
          swipeStyle = 'transitionImg-active'
        }else{
          swipeStyle = 'transitionImg'
        }

        this.setState({
            fromText: toText,
            toText: fromText,
            swipeClass:swipeStyle
        });
    }

    onSearch = () => {

        let searchFlightData = {};

        if (this.state.fromText != null) {
            searchFlightData.from = this.state.fromText.id;
            searchFlightData.fromCity = this.state.fromText.name;
            searchFlightData.fromCountry = this.state.fromText.country_code;
        } else {
            Toaster.create({position: Position.TOP}).show({message:"Please Select From !",intent: Intent.DANGER});
            return false;
        }

        if (this.state.toText != null) {
            searchFlightData.to = this.state.toText.id;
            searchFlightData.toCity = this.state.toText.name;
            searchFlightData.toCountry = this.state.toText.country_code;
        } else {
            Toaster.create({position: Position.TOP}).show({message:"Please Select To !",intent: Intent.DANGER});
            return false;
        }

        if (this.state.fromText.id === this.state.toText.id) {
          Toaster.create({position: Position.TOP}).show({message:"From and To Place Can not be Same",intent: Intent.DANGER});

            return false;
        } else {

        }
        let tripType = this.state.tripType;
        if (this.state.tripType === 1) {

            searchFlightData.returnDate = moment(this.state.returnDate).format('MM/DD/YYYY');
        }

        searchFlightData.beginDate = moment(this.state.onwardDate).format('MM/DD/YYYY');

        searchFlightData.cabin = this.state.classType;
        searchFlightData.tripType = tripType;
        searchFlightData.adults = this.state.Adult;
        searchFlightData.childs = this.state.Teen;
        searchFlightData.infants = this.state.Infant;


        let recentSearch = [];
        if (localStorage.getItem('flightRecentSearch') === undefined || localStorage.getItem('flightRecentSearch') === null) {
            searchFlightData.id = 0;
            recentSearch.push(searchFlightData);
        } else {
            recentSearch = JSON.parse(localStorage.getItem('flightRecentSearch'));
            searchFlightData.id = recentSearch[recentSearch.length - 1].id + 1
            recentSearch.push(searchFlightData);
        }
        localStorage.setItem('flightRecentSearch', JSON.stringify(recentSearch));

        // this.props.filterSearchFlight(searchFlightData);
        var str = Object.keys(searchFlightData).map((key) => {
            return key + '=' + searchFlightData[key];
        }).join('&');

        const rand = 1 + Math.random() * (100 - 1);

        str += '&tripId=' + rand;

        window.location.href=this.props.url+'search-flights?' + str;
    }

    render() {

        let maxDate = new Date();
        let year = maxDate.getFullYear();
        maxDate.setFullYear(year + 1);

        return (
			<div className="strip-form">
            <div className={this.props.searchType === true ? "search-place-trip" : "search-place-trip"}>
                <div className={"row"}>
                    <div className="pl-2 ml-2">
                        <label className="bp3-control bp3-radio .modifier">
                            <input type="radio" name="docs-radio-regular" value={0}
                                   checked={this.state.tripType === 0 ? true : false} onChange={() => {
                                this.onChangeTripe(0)
                            }}/>
                            <span className="bp3-control-indicator"></span>One Way
                        </label>
                    </div>
                    <div className="pl-2 ml-2">
                        <label className="bp3-control bp3-radio .modifier">
                            <input type="radio" name="docs-radio-regular" value={1}
                                   checked={this.state.tripType === 1 ? true : false} onChange={() => {
                                this.onChangeTripe(1)
                            }}/>
                            <span className="bp3-control-indicator"></span>Round Trip
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="bp3-input-group form-group col-xl-2 col-lg-2 col-md-4 col-sm-4">
                      <Async
                                  isClearable
                                  className='strip-select'
                                  classNamePrefix="strip-select"
                                  autoFocus={true}
                                  multi={false}
                                  value={this.state.fromText}
                                  isLoading={true}
                                  onChange={(value)=>
                                    {
                                      this.setState({
                                        fromText:value
                                      })
                                    }
                                  }
                                  valueKey="CityID"
                                  labelKey="ItemName"
                                  Key="CountryID"
                                  loadOptions={this.getOptions}
                                  placeholder={'To'}
                                  searchPromptText={"Please enter 3 or More characters"}
                                  backspaceRemoves={true}
                                  defaultOptions
                              />

                      <img src={require('../../../core/images/png/swipe.png')} className={"flight-swipearrow "+this.state.swipeClass} alt='swipe'
                        onClick={this.inverseSearch}/>
                    </div>
                    {/*<SwapHorizRounded onClick={this.inverseSearch}/>*/}

                    <div className="bp3-input-group col-xl-2 col-lg-2 col-md-4 col-sm-4 mt-s-4 pt-s-3">
                        <Async
                            multi={false}
                            clearable={false}
                            value={this.state.toText}
                            className='strip-select'
                            classNamePrefix="strip-select"
                            onChange={(value) => {
                                this.setState({
                                    toText: value
                                })
                            }}
                            valueKey="id"
                            labelKey="name"
                            Key="country_code"
                            loadOptions={this.getOptions}
                            placeholder={'From'}
                            searchPromptText={"Please enter  3 or More characters"}
                            backspaceRemoves={true}
                        />
                    </div>

                    <div className="bp3-input-group col-xl-2 col-lg-2 col-md-4 mt-s-2 mt-sm-2 mt-md-0 mt-xl-0 mt-lg-0 p-lg-0 p-xl-0 col-sm-4">
                        <DateInput
                            closeOnSelection={true}
                            icon={<DateRangeRounded />}
                            name="onwardDate"
                            minDate={new Date()}
                            maxDate={maxDate}
                            formatDate={date => date.toLocaleDateString()}
                            parseDate={str => new Date(str)}
                            onChange={(date) => {
                                let returnDate = moment(new Date(this.state.returnDate)).format('YYYY-MM-DD');

                                if (returnDate <= moment(date).format('YYYY-MM-DD')) {
                                    this.setState({
                                        // journeyDate: moment(date).format('YYYY-MM-DD'),
                                        returnDate: date

                                    });
                                }
                                this.setState({
                                    onwardDate: new Date(moment(date))
                                })
                            }}
                            value={this.state.onwardDate}
                            placeholder={"DD/MM/YYYY"}
                        />
						               <span className="bp3-icon bp3-icon-calendar inputaddon-icon"></span>
                    </div>
                    <div className="bp3-input-group col-xl-2 col-lg-2 col-md-4 mt-s-2 mt-xl-0 mt-lg-0 col-sm-4">
                        <div className="bp3-form-group">
                            <DateInput
                                name="returnDate"
                                disabled={this.state.tripType === 0 ? true : false}
                                closeOnSelection={true}
                                formatDate={date => date.toLocaleDateString()}
                                parseDate={str => new Date(str)}
                                onChange={(newDate) => this.handleChange("returnDate", newDate)}
                                minDate={this.state.onwardDate}
                                maxDate={maxDate}
                                value={this.state.returnDate}
                                placeholder={"DD/MM/YYYY"}
                            />
							<span className="bp3-icon bp3-icon-calendar inputaddon-icon"></span>
                        </div>
                    </div>

                    <div className="bp3-input-group col-xl-2 col-lg-2 col-md-4 mt-s-2 mt-sm-3 mt-xl-0 mt-lg-0 p-lg-0 p-xl-0 col-sm-4">
                        <div className="bp3-form-group pt-xl-2 pt-lg-2 pt-md-2" style={{borderBottom: '1px solid #CBCBCB', paddingBottom: '10px', cursor: 'pointer'}}>
                            <Popover content={
                                <div className="passengerInputWrapper">
                                    <ul className="dropdown-menu passenger-dropdown-content dropdown-cart">
                                        <li>
                                            <div className="row ml-0 mr-0 mt-2">
                                                <div className="col-12 p-0 border-bottom py-2 d-flex align-items-center justify-content-between">
                                                  <div className="col-6 float-left d-flex align-items-center justify-content-between">
                                                     <div className="pl-1 pr-0 w-25">
                                                       <img alt="" src={require('../../images/svg/adult-icon.svg')} className="" />
                                                     </div>
                                                     <div className="pl-0 pr-0 w-75">
                                                       <p className="mt-0 mb-0 bp3-text-medium">
                                                         <strong>Adults</strong></p>
                                                       <span className="bp3-text-small">12+ years</span>
                                                     </div>
                                                   </div>

                                                    <div className="col-6 float-right">
                                                        <div className="bp3-control-group d-flex justify-content-end">
                                                            <Button type={"button"} className="bp3-button bp3-decrease bp3-decrease-small"
                                                              icon={'minus'}
                                                                    onClick={() => this.decrease('Adult', this.state.Adult)} />
                                                            <div className="bp3-input-group bp3-input-counter-group">
                                                                <input type="text"
                                                                    className="bp3-input bp3-input-counter text-center"
                                                                    value={this.state.Adult} />
                                                            </div>
                                                            <Button type={"button"} className="bp3-button bp3-increase bp3-increase-small"
                                                                icon='plus'    onClick={() => this.increase('Adult', this.state.Adult)} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="row ml-0 mr-0">
                                                <div className="col-12 p-0 border-bottom py-2 d-flex align-items-center justify-content-between">
                                                  <div className="col-6 float-left d-flex align-items-center justify-content-between">
                                                     <div className="pl-1 pr-0 w-25">
                                                       <img alt="" src={require('../../images/svg/teen-icon.svg')} className="" />
                                                     </div>
                                                     <div className="pl-0 pr-0 w-75">
                                                       <p className="mt-0 mb-0 bp3-text-medium">
                                                         <strong>Teens</strong></p>
                                                       <span className="bp3-text-small">2-11 years</span>
                                                     </div>
                                                   </div>
                                                    <div className="col-6 float-right">
                                                        <div className="bp3-control-group d-flex justify-content-end">
                                                            <Button type={"button"} className="bp3-button bp3-decrease bp3-decrease-small"
                                                                    onClick={() => this.decrease('Teen', this.state.Teen)} icon={'minus'}/>
                                                            <div className="bp3-input-group bp3-input-counter-group">
                                                                <input type="text"
                                                                       className="bp3-input bp3-input-counter text-center"
                                                                       value={this.state.Teen} />
                                                            </div>
                                                            <Button type={"button"} className="bp3-button bp3-increase bp3-increase-small"
                                                              icon='plus'      onClick={() => this.increase('Teen', this.state.Teen)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="row ml-0 mr-0">
                                                <div className="col-12 p-0 border-bottom py-2 d-flex align-items-center justify-content-between">
                                                  <div className="col-6 float-left d-flex align-items-center justify-content-between">
                                                     <div className="pl-1 pr-0 w-25">
                                                       <img alt="" src={require('../../images/svg/infants-icon.svg')} className="" />
                                                     </div>
                                                     <div className="pl-0 pr-0 w-75">
                                                       <p className="mt-0 mb-0 bp3-text-medium">
                                                         <strong>Infants</strong></p>
                                                       <span className="bp3-text-small">under 2 years</span>
                                                     </div>
                                                   </div>
                                                    <div className="col-6 float-right">
                                                        <div className="bp3-control-group d-flex justify-content-end">
                                                            <Button type={"button"} className="bp3-button bp3-decrease bp3-decrease-small"
                                                              onClick={() => this.decrease('Infant', this.state.Infant)} icon={'minus'} />
                                                            <div className="bp3-input-group bp3-input-counter-group">
                                                                <input type="text"
                                                                   className="bp3-input bp3-input-counter text-center"
                                                                   value={this.state.Infant} />
                                                            </div>
                                                            <Button type={"button"} className="bp3-button bp3-increase bp3-increase-small"
                                                              icon='plus' onClick={() => this.increase('Infant', this.state.Infant)}/>
                                                        </div>
                                                        {/*<img className="align-self-center mr-3 removeIcon"*/}
                                                             {/*onClick={() => this.decrease('Infant', this.state.Infant)}*/}
                                                             {/*src={removeIcon}/>*/}
                                                        {/*<span className="passengerCounter">{this.state.Infant}</span>*/}
                                                        {/*<img className="align-self-center ml-3 addIcon"*/}
                                                             {/*onClick={() => this.increase('Infant', this.state.Infant)}*/}
                                                             {/*src={addIcon}/>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                    <ul className="dropdown-menu passenger-dropdown-footer dropdown-cart">
                                        <li>
                                            <div className="row ml-0 mr-0 mt-2">
                                                <div className="col-12">
                        													<div className="row">
                        														<div className="col-12">
                                                      <div className="pl-1">
                              														<RadioGroup
                              															onChange={(e)=>{
                              																this.setState({
                              																	tripType:this.state.tripType,
                              																	classType: e.target.value
                              																})
                              															}}
                                                            className="d-flex justify-content-between"
                              															selectedValue={this.state.classType}
                              														>
                                                            <Radio label="Economy" value={'0'} className="col-6 bp3-text-small mb-0" />
                                                            <Radio label="Bussiness" value={'2'} className="col-6 bp3-text-small mb-0" />

                              														</RadioGroup>
                                                          </div>
                        														</div>
                        													</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            } position={Position.BOTTOM}>
                                <span
                                    className="dropdown-toggle">{this.state.Adult + " Adults," + this.state.Teen + " Teen," + this.state.Infant + " Infant"}<span
                                    className="bp3-icon bp3-icon-caret-down font-12 ml-2"></span></span>
                            </Popover>
                        </div>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 text-right mt-s-2 mt-sm-2 mt-xl-0 mt-lg-0">
                        <button type="button" className="bp3-button bp3-intent-primary search-btn"
                                onClick={this.onSearch}>Search
                        </button>
                    </div>
                </div>
            </div>
			</div>
        );
    }
}
