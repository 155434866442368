import React, {Component} from 'react';
import { AddRounded, RemoveRounded } from '@material-ui/icons';

export default class FAQ extends Component{
    constructor(props){
        super(props);

        this.state = {
          show:true
        };
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextprops){

    }

    handleClick(state){
      this.setState({show:!state})
    }

    createMarkup(data) {
       return {__html: data};
    }

    render(){
        return(
    			<React.Fragment>
    				<a className='row no-gutters faq-detail' href='#!'>
    					<div className='col-sm-12 col-12 d-flex'>
    						<div className="mr-4">
                                {this.state.show ?
                                    <RemoveRounded style={{'color':'#236AEE'}} onClick={() => {this.handleClick(this.state.show)}}/>
                                    :
                                    <AddRounded style={{'color':'#236AEE'}} onClick={() => {this.handleClick(this.props.faq.show)}}/>
                                }
                            </div>
                            <div className="">
                                <p className='faq-title'>{this.props.faq.question}</p>
                                <p className='faq-deccription' dangerouslySetInnerHTML={this.createMarkup(this.props.faq.answer)} />
                            </div>
    					</div>
    				</a>
    			</React.Fragment>
        );
    }
}
