import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
// import { Card, Elevation, Button } from "@blueprintjs/core";
import {PageTitle,Error} from 'gg-react-utilities';
import { Button } from '@material-ui/core';
import ResumeComponent from "./container/resumePostContainer";


export default class JobDescription extends Component {

    constructor(props){
        super(props);
        this.state = {

        };
    }

	createMarkup(data) {
		return {__html: data};
	}

  componentDidMount(){
    setTimeout(()=>{
      window.scrollTo(0, 0);
    },1)
  }
  
	render(){
    if(this.props.location.state!==undefined){
      const {job} = this.props.location.state;

      return(
          <React.Fragment >
                <div className="jobdetail mt-5 mb-5">
                    <PageTitle title='Career | Global Garner' />
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8 col-md-12">
                                <div className="jobDetailContainer">

                                    <div className="jobtitle">
                                        <h1>{job.title}</h1>
                                        <h5>{job.experience}</h5>
                                        <p>{job.location}</p>
                                    </div>

                                    <div className="kd"></div>

                                    <div className="jb">
                                         <p className='job-title py-2' dangerouslySetInnerHTML={this.createMarkup(job.ck_description)}></p>

                                    </div>
                                </div>
                            </div>

                            <div className="offset-lg-1 col-lg-3 col-md-12">
                                <h2 className="oj">Other Jobs</h2>

                                  {this.props.location.state.careerList.map((item, i) => (

                                    <Link to={{ pathname: '/job-description', state: { job: item , careerList:this.props.location.state.careerList } }}>
                                      <div className="joblist-sidebar">
                                          
                                          <ul>
                                              <li>
                                                  <h5>{item.title}</h5>
                                                  <p>{item.experience}</p>
                                              </li>
                                          </ul>

                                      </div>
                                    </Link>
                                      
                                  ))}
                            </div>

                        </div>
                    </div>

                    <div className="jobApplication">
                     <ResumeComponent />
                        
                    </div>
                </div>
            </React.Fragment>
      );
    }else{
      return(
        <Error/>
      )
    }

    }
}
