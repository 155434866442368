import React, {Component} from 'react';
import {Classes, Dialog } from "@blueprintjs/core";
import Youtube from '../../core/components/Youtube';


export default class VideoModal extends Component{

    constructor(props){
        super(props);

        this.state={
          autoFocus: true,
          canEscapeKeyClose: true,
          canOutsideClickClose: true,
          enforceFocus: true,
          isOpen: props.isOpen,
          usePortal: true,
          selectedOption:null
        }
        this.handleClose =  this.handleClose.bind(this);
    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      })
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextprops){
      this.setState({
        isOpen: nextprops.isOpen
      });
    }

    render(){
        return(
              <Dialog
                isOpen={this.state.isOpen}
                title={this.props.title}
                className={this.props.className?this.props.className:''}
                onClose={this.handleClose}
                canOutsideClickClose={true}
                canEscapeKeyClose={true}
              >
                 <div className={Classes.DIALOG_BODY+ ' m-0'} style={{background: '#BED7F9'}}>
                   <Youtube videoId={this.props.data} onEnd={() => this.setState({videoPlayed: false})} className="w-100" style={{height: '430px'}} />
                 </div>
             </Dialog>
        );
    }

}
