import React,{ Component, Fragment } from 'react';
import {Dialog} from "@blueprintjs/core";
import {SlideshowRounded, GetAppRounded} from '@material-ui/icons';
import Config from "../../../Config";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app


export default class SliderComponent extends Component{

    constructor(props){
        super(props);

        this.state={
            leftScroll:false,
            activeSliderIndex:0,
            photoIndex: 0,
            isOpen: false,
        };
    }

    componentDidMount(){
        if(this.props.dataSource.length-1 === this.state.activeSliderIndex){
            this.setState({
                leftScroll:false,
                activeSliderIndex:0
            });
        }else{
            this.setState({
                leftScroll:false,
                activeSliderIndex:this.state.activeSliderIndex+1
            });
        }

    }

    currentSliderActive(index){
        this.setState({
            leftScroll:false,
            activeSliderIndex:index
        });
    }

    previousSlider(){

        if(this.state.activeSliderIndex === 0){
            this.setState({
                leftScroll:true,
                activeSliderIndex:this.props.dataSource.length-1
            });
        }else{
            this.setState({
                leftScroll:true,
                activeSliderIndex:this.state.activeSliderIndex-1
            });
        }
    }

    nextSlider(){
        if(this.props.dataSource.length-1 === this.state.activeSliderIndex){
            this.setState({
                leftScroll:false,
                activeSliderIndex:0
            });
        }else{
            this.setState({
                leftScroll:false,
                activeSliderIndex:this.state.activeSliderIndex+1
            });
        }
    }

    render(){
      const { activeSliderIndex, isOpen } = this.state;
      const {dataSource} = this.props;
        return(
            <Fragment>
                {this.props.dataSource.map((res,key)=>{
                  return(
                    <div className={this.state.activeSliderIndex===key?'col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 mb-3 active mt-2 slides':'col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 mb-3 mt-2 slides'} key={key} onClick={()=>{
                          this.setState({
                              activeSliderIndex:key,

                          })
                      }}>
                          <center>
                              <div className="wallpaper">
                                  <div className="overlay">
                                    <img src={res.src} className='img-fluid img-wallpaper' alt='desktop-wallpaper'
                                       onError={(ev)=>{
                                         ev.target.src = Config.DEFAULT_IMAGE_URL;
                                    }}/>
                                      <div className="wallpaper-btn bp3-button-group bp3-fill">
                                          <button type="button" className="bp3-button" onClick={()=>{
                                              this.setState({isOpen:true})
                                            }}>
                                            <p className='align-items-center justify-content-center p-0 m-0 d-flex'>
                                                <span className='mr-2 hidden-xs'>{'Show'}</span><SlideshowRounded/>
                                            </p>
                                          </button>
                                          <a href={Config.IMAGE_URL+res.image} rel="noopener noreferrer" className="bp3-button border-left" target='_blank'>
                                              <p className='align-items-center p-0 m-0 d-flex justify-content-center'>
                                             <span className='mr-2 hidden-xs'>{'Download'}</span><GetAppRounded/></p>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </center>
                      </div>
                  )
                })}

                  {isOpen && (
                    <Lightbox
                        mainSrc={dataSource[activeSliderIndex].src}
                        nextSrc={dataSource[(activeSliderIndex + 1) % dataSource.length]}
                        prevSrc={dataSource[(activeSliderIndex + dataSource.length - 1) % dataSource.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            activeSliderIndex: (activeSliderIndex + dataSource.length - 1) % dataSource.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            activeSliderIndex: (activeSliderIndex + 1) % dataSource.length
                          })
                        }
                    />
                  )}
            </Fragment>
        )
    }
}
