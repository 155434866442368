import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import Hotel from '../../components/RetailStrip/Hotel';
import { getFromList } from '../../../core/actions/stripAction';
import { fromListAction } from '../../../core/actions';

const mapStateToProps = state => {

    return{
        fromList:state.RetailReducer.fromList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getFromList :() => {
            return getFromList()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(fromListAction(sources.data.sourceList));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(fromListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Hotel);
