import React, {Component} from 'react';
import CareerComponent from "./container/careerListContainer";




export default class Career extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }


    render(){
        let metaData = document.getElementsByTagName("meta");
        metaData['description'].content = 'Search for job openings in India? get news updates and stay connected. Global Garner Sales and Services Private ltd- Make joy for all stakeholders to work with us';
        metaData['keywords'].content = 'Career, Job, Job Search, Job Vacancies';
        return(
            <React.Fragment>
              
                    
                  <CareerComponent />
                    
               
            </React.Fragment>
        );
    }
}
