import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import RetailReducer from './retailReducer';
import StripReducer from './stripReducer';


const Retail = combineReducers({
    RetailReducer,
    StripReducer
})

export default Retail;
