import React, { Component } from 'react';
import Config from '../../Config';
import { Intent, Position, Toaster } from "@blueprintjs/core";
import Carousel from 'react-grid-carousel';
import "react-multi-carousel/lib/styles.css";
import { NavigateNext, NavigateBefore, FavoriteBorderRounded, FavoriteRounded } from '@material-ui/icons';
import { getPopularBrandList, putBrands } from '../../core/actions/action';
import { setDefaultMegaBrandImage } from "../../core/utils/helper";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const MBD = ({ isActive }) => (
  <span
    style={{
      display: 'inline-block',
      borderRadius: '7px',
      height: isActive ? '10px' : '10px',
      width: isActive ? '19px' : '10px',
      background: isActive ? '#236AEE' : '#CBCBCB'
    }}
  ></span>
)

export default class AwesomeBswDeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slider: [1],
      brands: [],
      cardColor: [],
      previous: false,
      toggle: null
    };
  }

  componentDidMount() {
    this.props.getMegabrandDataList();
    getPopularBrandList()
      .then((res) => {

        this.setState({
          brands: res.data.data
        })

      }).catch((error) => {
        const sources = error.response;
      })


    const Color = [
      1, 2, 3, 4, 5, 6
    ];
    this.setState({
      cardColor: Color
    })
  }

  handleFavoriteToggle = (e, brand_id, key) => {
    e.preventDefault();
    putBrands(brand_id)
      .then((res) => {
        let sources = res.data;
        let message = res.data.message;
        let alertColor = Intent.DANGER;


        if (sources.status === true) {
          alertColor = Intent.SUCCESS;
          this.state.brands[key].is_favorite = sources.data.is_favorite
          this.setState({ toggle: sources.data.is_favorite });
        }

        Toaster.create({
          position: Position.TOP,
        }).show({ message: message, intent: alertColor })

      })
      .catch((errors) => {
        if (errors.response.status !== 401) {
          let message = "";
          if (errors.response) {
            message = errors.response.message ? errors.response.message : "Something went wrong!";
          } else {
            message = "Something went wrong!";
          }
          Toaster.create({
            position: Position.TOP,
          }).show({ message: message, intent: Intent.DANGER });
        }
      });
  };



  render() {
    let counter = 0;
    let brands = this.state.brands
    var i = 0
    let colorCard = this.state.cardColor;


    return (
      <section className="pb-5 pt-5 bg-home-basic">
        <div className="container awesome-deals">
          <div className="row py-3">
            <div className="col-xl-8 col-8 pr-s-0">
              <h2 className="custom-heading left">Amazing Deals in Mega Brands</h2>
            </div>
            <div className="col-xl-4 col-4 text-right">
              <a className="view-all-link mt-2" href={Config.MEGABRAND_URL + 'brand'}>View All</a>
            </div>
          </div>
          <div>

            <div className="row pt-4">
              {
                this.state.slider === 0 ?
                  <React.Fragment>
                    <div className="bp3-skeleton w-100" style={{ height: "335px" }}>&nbsp;</div>
                  </React.Fragment>
                  :

                  <div className="col-sm-12 col-md-12 col-xs-12 mt-3 awesome-dealsbsw-img mb-2">

                    <Carousel dot={MBD} cols={4} hideArrow={false} showDots rows={brands.length > 12 ? 3 : 2} gap={12} loop className="mb-5 position-relative" responsiveLayout={[
                      { breakpoint: 1200, cols: 4, rows: brands.length > 12 ? 3 : 2, gap: 5 },
                      { breakpoint: 768, cols: 3, rows: brands.length > 12 ? 3 : 2, gap: 2 },
                      { breakpoint: 468, cols: 1, rows: 1, gap: 1 }
                    ]}
                      mobileBreakpoint={300} arrows={false}>

                      {brands && brands.map((data, key) => {
                        return (
                          <Carousel.Item>
                            <div>
                              {brands.length != 0 ?
                                <div>
                                  <div style={{ display: 'none' }}>
                                    {colorCard[i] == undefined ? i = 0 : ""}
                                  </div>

                                  <div className="mb-3">
                                    <a href={Config.MEGABRAND_URL + 'brand/' + data.slug}>
                                      <div className="mega-brand-card st-2">

                                        <div className={colorCard[i] === 1 ? "pink-card brand-card d-flex justify-content-between item-align-center" : colorCard[i] === 2 ? "blue-card brand-card d-flex justify-content-between item-align-center" : colorCard[i] === 3 ? "orange-card brand-card d-flex justify-content-between item-align-center" : colorCard[i] === 4 ? "darkblue-card brand-card d-flex justify-content-between item-align-center" : colorCard[i] === 5
                                          ? "green-card brand-card d-flex justify-content-between item-align-center" : colorCard[i] === 6 ? "lightblue-card brand-card d-flex justify-content-between item-align-center" : "pink-card brand-card d-flex justify-content-between item-align-center"}>

                                          <p className="brand-name">{data.brand_name}</p>

                                          <p className="mt-minus">{data.is_favorite === 0 ? <FavoriteBorderRounded onClick={(e) => {
                                            this.handleFavoriteToggle(e, data.brand_id, key)
                                          }} /> : <FavoriteRounded onClick={(e) => {
                                            this.handleFavoriteToggle(e, data.brand_id, key)
                                          }} />}

                                          </p>

                                        </div>

                                        <div class="text-center">
                                          <img src={data.image} onError={setDefaultMegaBrandImage} alt="UBER Discount Coupon" />
                                        </div>
                                        <div class="d-flex justify-content-between item-align-center pl-3 pr-3 pt-3 pb-2">
                                          <p class="brand-delivery text-center m-auto">Delivery: {data.tat === 0 ? "Instant" : data.tat + " Days"}</p>
                                          <p class="brand-product-type text-center m-auto">{data.product_type} Voucher</p>
                                        </div>
                                        <div style={{ display: 'none' }}>

                                          {i++
                                          }</div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                : ""} </div>
                          </Carousel.Item>
                        )
                      })}

                    </Carousel>

                  </div>

              }
            </div>
            {brands.length != 0 ?
              <div className="border-bottom mb-4 mt-4"></div> : ""}
          </div>
          <div className="row">
            {
              this.props.megabrandDataList.length === 0 ?
                <React.Fragment>
                  <div className="bp3-skeleton w-100" style={{ height: "335px" }}>&nbsp;</div>
                </React.Fragment>
                :
                this.props.megabrandDataList.map((single, key) => {
                  counter++;
                  if (counter > 6) counter = 1;
                  return <a href={Config.MEGABRAND_URL + 'brand/' + single.slug} key={single.slider_id} className="col-sm-4 col-md-4 col-xs-12 mt-3 awesome-dealsbsw-img mb-2">
                    <div className="awesome-img"><img src={single.image} alt="Deals" onError={(ev) => {
                      ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                    }} /></div>
                  </a>
                })
            }
          </div>
        </div>
        <div className="clearfix"></div>
      </section>
    );
  }
}
