import React from 'react';
import {Intent, Position, Toaster} from "@blueprintjs/core";
import {Button} from "@blueprintjs/core";
import {ssoInstance} from "gg-react-utilities";
import Config from "../../Config";
import {Textbox, Select} from "react-inputs-validation";
import validator from "validator";
import constant from "../../core/utils/constant";

export default class Report extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoggedIn :false,
            companyName:"",
            hasCompanyNameError:true,
            hasCompanyNameErrorMsg:"",
            name:"",
            hasNameError:true,
            hasNameErrorMsg:"",
            mobile:"",
            hasMobileError:true,
            hasMobileErrorMsg:"",
            email:"",
            hasEmailError:true,
            hasEmailErrorMsg:"",
            validate:false,
            loader:false
        }
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    // async checkAuth(){
    //     setTimeout(()=>{
    //         Auth.isLoggedInAsync().then((res) => {
    //             this.setState({isLoggedIn:res},()=>{
    //                 if(res===false){
    //                     Auth.login(true);
    //                 }
    //             })
    //         });
    //     },1)
    // }

    onSubmitWebsite() {
        // Auth.isLoggedInAsync().then((res) => {
        //     this.setState({
        //         isLoggedIn: res,
        //     }, () => {
        this.toggleValidating(true);
        // if(this.state.isLoggedIn){
            if( !this.state.hasNameError &&
                !this.state.hasMobileError &&
                !this.state.hasEmailError &&
                !this.state.hasWebsiteError &&
                !this.state.hasServiceError
            ) {
                this.setState({
                    loader:true
                });
                let formData = new FormData();
                formData.append("company_name", this.state.companyName)
                formData.append("person_name", this.state.name)
                formData.append("email", this.state.email)
                formData.append("mobile_number", this.state.mobile)

                ssoInstance.post(Config.INQUIRY_API_URL + "store-inquiry", formData).then((response) => {
                    this.setState({
                        loader:false
                    });
                    if (response.data.status === true) {
                      Toaster.create({position: Position.TOP_RIGHT}).show({
                          message: response.data.message,
                          intent: Intent.SUCCESS
                      });

                    } else {
                      Toaster.create({position: Position.TOP_RIGHT}).show({
                          message: response.data.message,
                          intent: Intent.DANGER
                      });
                    }
                    this.setState({
                      name:"",
                      mobile:"",
                      email:"",
                      companyName:""
                    })
                }).catch((error) => {
                    this.setState({
                        loader:false
                    });
                    Toaster.create({position: Position.TOP_RIGHT}).show({
                        message: "Something went wrong!",
                        intent: Intent.DANGER
                    });
                });
            }else{
                // console.error("Please enter all fields.");
            }
        // }else{
        //     Auth.login(true);
        // }
    }
        // });


    render(){
        return(
            <React.Fragment>
                <div className="col-12 mt-4">
                    <div className="row">
                        <h2>Get Started With Your Online Store  Today By Choosing The Platform That Is Right For Your Business.</h2>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col mb-4">
                        <Textbox
                            tabIndex="1"
                            id={"Company Name"}
                            placeholder="Company Name"
                            value={this.state.companyName}
                            onBlur={e => {}}
                            onChange={(companyName, e) => {
                                this.setState({ companyName });
                            }}
                            validate={this.state.validate}
                            validationCallback={res =>
                                this.setState({ hasCompanyNameError:res, validate: false })
                            }
                            validationOption={{
                                name: 'Company Name',
                                check: true,
                                required: true
                            }}/>
                    </div>

                    <div className="col mb-4">
                        <Textbox
                            tabIndex="1"
                            id={"name"}
                            name="name"
                            type="text"
                            value={this.state.name}
                            placeholder="Contact Person Name"
                            onChange={(name) => {
                                this.setState({ name });
                            }}
                            onBlur={e => {}}
                            validate={this.state.validate}
                            validationCallback={res =>
                                this.setState({ hasNameError:res, validate: false })
                            }
                            validationOption={{
                                name: "Contact Person Name",
                                check: true,
                                required: true,

                            }}/>
                    </div>

                    <div className="col mb-4">
                        <Textbox
                            tabIndex="3"
                            id={"email"}
                            name="email"
                            type="text"
                            value={this.state.email}
                            placeholder="Email Address"
                            onChange={(email) => {
                                this.setState({ email });
                            }}
                            onBlur={e => {}}
                            validate={this.state.validate}
                            validationCallback={res =>
                                this.setState({ hasEmailError:res, validate: false })
                            }
                            validationOption={{
                                name: "Email Address",
                                check: true,
                                required: true,
                                customFunc: email => {
                                    if (Config.EMAIL_REGEX.test(String(email).toLowerCase())) {
                                        return true;
                                    } else {
                                        return "Is not a valid email address";
                                    }
                                }
                            }}/>
                    </div>

                    <div className="col mb-4">
                      <Textbox
                          tabIndex="1"
                          id={"phone"}
                          name="phone"
                          type="text"
                          value={this.state.mobile}
                          placeholder="Mobile Number"
                          onChange={(mobile) => {
                              this.setState({ mobile });
                          }}
                          onBlur={e => {}}
                          validate={this.state.validate}
                          validationCallback={res =>
                              this.setState({ hasMobileError:res, validate: false })
                          }
                          validationOption={{
                              name: "Mobile Number",
                              check: true,
                              required: true,
                              customFunc: mobile => {
                                  if (validator.isMobilePhone(mobile, "en-IN")) {
                                      return true;
                                  } else {
                                      return "Is not a valid mobile number.";
                                  }
                              }
                          }}/>
                    </div>

                    <div className="col-12 text-center">
                        <Button
                            className="btn_report mt-4"
                            text="SUBMIT"
                            intent={Intent.PRIMARY}
                            loading={this.state.loader}
                            onClick={() => {
                                this.onSubmitWebsite()
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
