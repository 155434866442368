import React, {Component} from 'react';
import {Classes, Dialog, InputGroup, Button, Toaster, Intent, Position } from "@blueprintjs/core";
import Select from 'react-select';
import { CloseRounded } from '@material-ui/icons';
import {ssoInstance} from 'gg-react-utilities';
import Config from '../../../Config';

const options = [
  { value: 'User', label: 'User' },
  { value: 'Assistant Channel Partner / Channel Partner', label: 'Assistant Channel Partner / Channel Partner' },
  { value: 'Cashback/Reward Query', label: 'Cashback/Reward Query' },
  { value: 'Vendor Query', label: 'Vendor Query' },
  { value: 'Water Franchise', label: 'Water Franchise' }
];

export default class CallUs extends Component{

    constructor(props){
        super(props);

        this.state={
          isOpen: props.isOpen,
          subject:'',
          name:'',
          email:'',
          mobile:'',
          city:'',
          state:'',
          pincode:'',
          formStatus:false

        }
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      })
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextprops){
      if(nextprops.isOpen!==undefined){
          this.setState({
              isOpen: nextprops.isOpen
          });
      }
    }

    submitForm(){
      this.setState({formStatus:true})
      let formData = {
        "subject" :this.state.subject,
        "name": this.state.name,
        "email": this.state.email,
        "contact": this.state.mobile,
        "state": this.state.state,
        "city": this.state.city,
        "pin": this.state.pincode
      }

      ssoInstance.post(Config.ADMIN_GG_API_URL + 'request-call',formData).then((res)=>{

          const sources = res.data;
          this.setState({formStatus:false})
          if (sources.status === true) {
            this.handleClose();
            Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
          }else{
            sources.message.map((res)=>{
                  Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                  return res;
            })
          }
      }).catch((error) => {
          this.setState({formStatus:false})

      });
    }

    render(){
        return(
            <React.Fragment>
              <Dialog
                isOpen={this.state.isOpen}
                title={this.props.title}
                className={this.props.className?this.props.className:''}
                canOutsideClickClose={false}
                onClose={this.handleClose}
                canEscapeKeyClose={true}
              >
                 <div className={Classes.DIALOG_BODY+' p-1 m-0'} >
                   <div className='d-flex'>
                       <div className='col-xl-4 col-lg-4 col-md-4 vector-background d-flex align-items-center justify-content-center'>
							<img src={require('../../images/support_page.png')} className='vector img-fluid' alt='vector'/>
						</div>
						<div className='col-xl-8 detail-area p-4'>
							<span className='pull-right' style={{cursor: 'pointer'}}>
								<CloseRounded onClick={this.handleClose}/>
							</span>
							<h3 className='title'>Request Call</h3>
							<h5 className='title'>Fill form of request call</h5>
							<div className='row'>
								<div className='col-xl-12 mt-4'>
									<Select
										value={this.state.subject}
										onChange={(value)=>{
											this.setState({subject:value.value})
										}}
										placeholder='Select subject'
										options={options}
									/>
								</div>
								<div className='col-xl-12 col-12 mt-4'>
									<InputGroup  placeholder="Name" onChange={(e)=>{this.setState({name:e.target.value})}} value={this.state.name}/>
								</div>

								<div className='col-xl-6 col-6 mt-4'>
									<InputGroup  placeholder="Email" onChange={(e)=>{this.setState({email:e.target.value})}} value={this.state.email}/>
								</div>

								<div className='col-xl-6 col-6 mt-4'>
									<InputGroup  placeholder="Contact number" onChange={(e)=>{this.setState({mobile:e.target.value})}} value={this.state.mobile}/>
								</div>

								<div className='col-xl-6 col-6 mt-4'>
									<InputGroup  placeholder="City" onChange={(e)=>{this.setState({city:e.target.value})}} value={this.state.city}/>
								</div>

								<div className='col-xl-6 col-6 mt-4'>
									<InputGroup  placeholder="State" onChange={(e)=>{this.setState({state:e.target.value})}} value={this.state.state}/>
								</div>

								<div className='col-xl-12 col-12 mt-4'>
									<InputGroup  placeholder="Pincode" onChange={(e)=>{this.setState({pincode:e.target.value})}} value={this.state.pincode}/>
								</div>
								<div className='col-xl-7 col-7' />
									<div className='col-xl-5 col-12 text-right' >
										<Button text='Submit Request' loading={this.state.formStatus}
											className='mt-4 callreq-submit bp3-button bp3-intent-primary bp3-button bp3-intent-primary'
										  onClick={()=>{
											this.submitForm()
										  }}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Dialog>
            </React.Fragment>
        );
    }

}
