import React, {Component, Fragment} from 'react';
import {Card} from '@blueprintjs/core';

export default class Price extends Component {
    constructor() {
        super();

        this.state = {
            data: [
                {
                    img: require('../images/custom.png'),
                    title: 'Customizable Designs',
                    desc: 'Easily build your Website & Online Ecommerce Store with drag & drop components. It takes less than five minutes to create your store with payment gateway & logistics.'
                },
                {
                    img: require('../images/mobile_friendly.png'),
                    title: 'Mobile Friendly',
                    desc: 'Your Website & Online Ecommerce Store is automatically optimized for mobile use. Connect with Customers anytime, anywhere, on any device.'
                },
                {
                    img: require('../images/discount.png'),
                    title: 'Coupons and Discounts',
                    desc: 'Offer coupons to your customers as incentives to buy your products. Boost sales on your online store.'
                }
            ],

        }
    }

    render() {
        const {data} = this.state
        return (
            <Fragment>
                <div className="col-12 mt-4 text-center">
                    <h2>Effective Pricing</h2>
                    <h3>Get started with your online store today choosing the package that is right for your
                        business.</h3>
                </div>

                <div className="row mt-5 price-segment pt-5 pt-s-0">
                    <div
                        className="col-lg-4 col-md-4 col-sm-6 col-12 mb-5 mb-s-3 pl-s-2 text-center price-segment-type">
                        <div className='header'>
                            <p>Plus</p>
                        </div>
                        <Card className='bp3-elevation-1'>
                            <div className="card-body">
                                <h4 className='title'>For small & medium enterprises, wanting silver sparkles on top of
                                    the icing.</h4>
                                <ul>
                                    <li>
                                        Fully Integrated E-commerce
                                    </li>
                                    <li>
                                        Unlimited Products
                                    </li>
                                    <li>
                                        Premium Themes
                                    </li>
                                    <li>
                                        Detailed analytics of last 7 days
                                    </li>
                                    <li>
                                        Android application for your store
                                    </li>
                                    <li>
                                        Many more Features are available
                                    </li>
                                </ul>
                            </div>

                        </Card>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-5 mb-s-3 pl-s-2 text-center price-segment-type">
                        <div className='header'>
                            <p>Pro</p>
                        </div>
                        <Card className='bp3-elevation-1'>
                            <div className="card-body">
                                <h4 className='title'>For all type of enterprises, wanting gold sparkles on top of the
                                    icing.</h4>
                                <ul>
                                    <li>
                                        All The Features Of Plus Plan
                                    </li>
                                    <li>
                                        Unlimited Products
                                    </li>
                                    <li>
                                        Pro Themes
                                    </li>
                                    <li>
                                        Discount Engine
                                    </li>
                                    <li>
                                        Android & iOS application for your store
                                    </li>
                                    <li>
                                        Many Features are available
                                    </li>
                                </ul>
                            </div>

                        </Card>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-5 mb-s-3 pl-s-2 text-center price-segment-type">
                        <div className='header'>
                            <p>Plus</p>
                        </div>
                        <Card className='bp3-elevation-1'>
                            <div className="card-body">
                                <h4 className='title'>Call <a href='tel:079 4890 1845' className='text-primary'>079 4890
                                    1845</a> for custom pricing lower than other
                                    providers and Shopify.<br/><br/>
                                    Power your team’s success with the best full-service open platform</h4>
                                <ul>
                                    <li>
                                        Customzation as per your Business Requirement
                                    </li>

                                </ul>
                            </div>

                        </Card>
                    </div>

                </div>
            </Fragment>
        )
    }
}
