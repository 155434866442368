import {connect} from 'react-redux';

import CP from '../components/teamMemberType/CP';
import {getPincodeList, getPartnerList, getMCPLatLng, getACPLatLng, getCPLatLng} from '../../core/actions/action';
import {partnerListAction, mcpLatLngListAction, acpLatLngListAction, cpLatLngListAction} from "../../core/actions";
import {Intent, Position, Toaster} from "@blueprintjs/core";

const mapStateToProps = state => {
    return{
        partner:state.RetailReducer.partner,
        mcpLatLngListData:state.RetailReducer.mcpLatLngListData,
        acpLatLngListData:state.RetailReducer.acpLatLngListData,
        cpLatLngListData:state.RetailReducer.cpLatLngListData,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getPincodeList:(input)=>{
            return getPincodeList(input)
        },
        getPartnerList:(pin) => {
          dispatch(partnerListAction(null));
            return getPartnerList(pin)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(partnerListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(partnerListAction(null));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        getMCPLatLng:() => {

            return getMCPLatLng()
                .then((res)=>{
                    const sources = res.data;

                    dispatch(mcpLatLngListAction(sources));

                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },

        getACPLatLng:() => {

            return getACPLatLng()
                .then((res)=>{
                    const sources = res.data;
                    dispatch(acpLatLngListAction(sources));

                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },

        getCPLatLng:() => {

            return getCPLatLng()
                .then((res)=>{
                    const sources = res.data;

                    dispatch(cpLatLngListAction(sources));
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CP);
