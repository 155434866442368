import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import Recharge from '../../components/RetailStrip/Recharge';
import { getOperatorList, getPlansList } from '../../../core/actions/stripAction';
import { operatorListAction, plansListAction } from '../../../core/actions';

const mapStateToProps = state => {

    return{
        operatorList:state.StripReducer.operatorList,
        planList:state.StripReducer.planList
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getOperatorList :(type) => {
            return getOperatorList(type)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        sources.data.map((res)=>{
                          res.label = res.service_provider_name;
                          res.value = res.id;
                        });
                        dispatch(operatorListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(operatorListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },

        getPlansList :(obj) => {
            dispatch(plansListAction([]));
            return getPlansList(obj)
                .then((res)=>{

                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(plansListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(plansListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recharge);
