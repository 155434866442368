
const buttonDetails={
    data:[
        {
            segID:"gg-vendor",
            title:"GG Vendors",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/vendor.svg',
            text:"GG Vendors",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        This\n" +
                "                                        segment consists mostly of classifieds and regional stores and\n" +
                "                                        brands available nearby your location, You have to search the type\n" +
                "                                        of service or product you wish to buy from your nearby location and\n" +
                "                                        visit the Vendor, who are registered with the Global Garner,\n" +
                "                                        directly make payment there or Use our payment gateway to make\n" +
                "                                        payments or other modes of payment available thereon and upload the\n" +
                "                                        bill on our app for approval, Then your bill is sent to the vendor\n" +
                "                                        for his approval.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Thereafter\n" +
                "                                        approval of the vendor and receiving the commission from vendor we\n" +
                "                                        will approve your cashback. Approximately, your UPV generated\n" +
                "                                        through purchase from online regional brands/local vendors for\n" +
                "                                        products and services will be approved within 15-90 days depending\n" +
                "                                        upon the vendor terms of payment of our commission. After receiving\n" +
                "                                        the commission on your purchase through the vendor (local\n" +
                "                                        vendors/online regional brands) GG will start processing the cash\n" +
                "                                        back /reward on your generated UPVs as per SCB FIFO process or\n" +
                "                                        through SCB RPP (RANDOM PICK PROCESS) and for IC (INSTANT CASHBACK)\n" +
                "                                        cashback/reward will be given thereafter on receipt of commission,\n" +
                "                                        without mandatorily generation of UPV. Others terms and conditions\n" +
                "                                        applicable to the respective regional brands/shop /local vendor will\n" +
                "                                        be in force when you visit the link through Global garner website.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        You\n" +
                "                                        need to purchase daily routine goods from the GG’s authorized\n" +
                "                                        vendors in your area and require to take <b>GG\n" +
                "\t                                      QR code (not compulsory) bill from the vendor.</b>\n" +
                "                                        Bill with GG QR (not compulsory) code from the vendor.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        After\n" +
                "                                        taking GG QR code bill, upload the same on the GLOBAL GARNER\n" +
                "                                        APP/WEBSITE and after verification, approval and receipt of\n" +
                "                                        commission from the captioned vendor , UPV will be processed and it\n" +
                "                                        will take 15-90 days for the approval of the same.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Once\n" +
                "                                        you register yourself as a GG user, you can select from the list of\n" +
                "                                        Local Vendors provided in the LOCAL/NEARBY Vendor option. You can\n" +
                "                                        visit the vendor personally or place the order through our APP and\n" +
                "                                        you can even use MAKE MY CART option as well.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Once\n" +
                "                                        you decide the mode you can select the product and services you want\n" +
                "                                        to purchase. Pay the money Digitally or in cash and take the Bill\n" +
                "                                        for the same and upload it.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        In\n" +
                "                                        case the vendor issues GG bill then you can scan the QR code on the\n" +
                "                                        bill and upload the details else you have to scan the QR code\n" +
                "                                        available at the vendor’s store</p></li>\n" +
                "                                </ul>\n" +
                "                                <p className=\"para-text\">\n" +
                "                                    Vendor\n" +
                "                                    is responsible solely for initiating the Buyer’s cash-back process\n" +
                "                                    as only after vendor approves the purchases made by the Buyer, user\n" +
                "                                    is eligible for Cash-back/reward through Systematic Cash Back\n" +
                "                                    FIFO(SCB FIFO) process or Random Pick Process or Instant Cashback\n" +
                "                                    Process as opted by you. The Buyer shall be given cash-back only when\n" +
                "                                    Vendor credits the Commission as described in the online Commission\n" +
                "                                    Grid that is due to the Company and as per the norms of the\n" +
                "                                    Systematic Cash-back(SCB) process or other cashback options as opted.</p>\n" +
                "                                <ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        As\n" +
                "                                        a User you have to purchase daily routine goods from Vendor\n" +
                "                                        registered with Global Garner, User has to follow the below\n" +
                "                                        mentioned guidelines and can purchase from the NEARBY Vendor option\n" +
                "                                        tab available :-</p></li>\n" +
                "                                </ul>\n" +
                "                                <p className=\"para-text\">\n" +
                "                                    <b>Mode\n" +
                "                                        1: For In-store purchase</b></p>\n" +
                "                                <ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        <b>Payment\n" +
                "                                            Type 1: Cash Payment</b></p></li>\n" +
                "                                    <ul>\n" +
                "                                        <li><p className=\"para-text\">\n" +
                "                                            User\n" +
                "                                            can make the cash payment to the vendor. To avail cashback, User\n" +
                "                                            has to scan QR Code on the received bill and upload it through the\n" +
                "                                            bill upload module along with required details through GG app or\n" +
                "                                            website. Once the bill is approved by the vendor then User’s\n" +
                "                                            purchase will be added to the UPV Cashback System by us, post\n" +
                "                                            receipt of vendor commission for cash back /reward through SCB FIFO\n" +
                "                                            (SYSTEMATIC CASHBACK PROCESS) or through SCB RPP (RANDOM PICK\n" +
                "                                            PROCESS) and for IC (INSTANT CASHBACK) cashback/reward will be\n" +
                "                                            given thereafter on receipt of commission. If User fails to upload\n" +
                "                                            the bill or the bill is rejected by Vendor or by Global Garner,\n" +
                "                                            User’s purchase will not be added to the UPV Cashback System. No\n" +
                "                                            refunds will be processed through Global Garner. User has to make\n" +
                "                                            the settlement directly with the vendor.</p></li>\n" +
                "                                    </ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                            <b>Payment\n" +
                "                                Type 2: QR Code Scan</b></p></li>\n" +
                "                                    <ul>\n" +
                "                                        <li><p className=\"para-text\">To\n" +
                "\t\tavail cashback User can make the payment through GG Mobile App by\n" +
                "\t\tscanning the QR Code of the vendor. User has to scan QR Code on the\n" +
                "\t\treceived bill and upload it through the bill upload module along\n" +
                "\t\twith the additional details.\n" +
                "                                            Once bill is approved by vendor then the User’s purchase will be\n" +
                "                                            added to the UPV Cashback System post receipt of vendor commission\n" +
                "                                            for cash back/reward through SCB FIFO (SYSTEMATIC CASHBACK PROCESS)\n" +
                "                                            or through SCB RPP (RANDOM PICK PROCESS) and for IC (INSTANT\n" +
                "                                            CASHBACK) cashback/reward will be given thereafter on receipt of\n" +
                "                                            commission If User fails to upload the bill or the bill is rejected\n" +
                "                                            by Vendor or by Global Garner, User’s purchase will not be added\n" +
                "                                            to the UPV Cashback System and not be eligible for any other kind\n" +
                "                                            of cashback or reward</p></li>\n" +
                "                                        <li><p className=\"para-text\">\n" +
                "                                            No\n" +
                "                                            refunds will be processed through Global Garner. You can make the\n" +
                "                                            settlement directly with the vendor.</p></li>\n" +
                "                                    </ul>\n" +
                "                                </ul>\n" +
                "                                <p className=\"para-text\">\n" +
                "                                    <b>Mode\n" +
                "                                        2: Online Purchase from vendor</b></p>\n" +
                "                                <ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Payment\n" +
                "                                        Type 1: Payment through payment gateway</p></li>\n" +
                "                                    <ul>\n" +
                "                                        <li><p className=\"para-text\">\n" +
                "                                            When\n" +
                "                                            User makes the payment through the payment gateway. Once User’s\n" +
                "                                            order is marked as delivered by vendor and the same has been\n" +
                "                                            approved by the vendor, User’s purchase will be added to the UPV\n" +
                "                                            Cashback System for cash back/reward through SCB FIFO(SYSTEMATIC\n" +
                "                                            CASHBACK PROCESS) or through SCB RPP (RANDOM PICK PROCESS) or for\n" +
                "                                            IC (INSTANT CASHBACK) cashback/reward will be given thereafter on\n" +
                "                                            receipt of commission. No cancellation option or refunds will be\n" +
                "                                            processed after the vendor accepts your order.</p></li>\n" +
                "                                    </ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Payment\n" +
                "                                        Type 2: Cash on Delivery</p></li>\n" +
                "                                    <ul>\n" +
                "                                        <li><p className=\"para-text\">\n" +
                "                                            User\n" +
                "                                            can make the cash payment to the vendor. Once User’s order is\n" +
                "                                            marked as delivered by vendor and the same has been approved by the\n" +
                "                                            vendor, User’s purchase will be added to the UPV Cashback System.\n" +
                "                                            No cancellation option or refunds will be available after the\n" +
                "                                            vendor accepts the order.</p></li>\n" +
                "                                    </ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Payment\n" +
                "                                        Type 3: QR Code scan</p></li>\n" +
                "                                    <ul>\n" +
                "                                        <li><p className=\"para-text\">\n" +
                "                                            User\n" +
                "                                            can make the payment through GG Mobile App by scanning the QR Code\n" +
                "                                            of the vendor. Once the order is marked as delivered by vendor,\n" +
                "                                            User’s purchase will be added to the UPV Cashback System for cash\n" +
                "                                            back /reward through SCB FIFO (SYSTEMATIC CASHBACK PROCESS) or\n" +
                "                                            through SCB RPP (RANDOM PICK PROCESS) or  for IC (INSTANT\n" +
                "                                            CASHBACK). Cashback/reward will be given thereafter on receipt of\n" +
                "                                            commission. No cancellation option or refunds will be available\n" +
                "                                            after the vendor accepts your order.</p></li>\n" +
                "                                        <li><p className=\"para-text\">\n" +
                "                                            All\n" +
                "                                            the purchases are done between User and vendor, Global garner is\n" +
                "                                            not liable for any discrepancy in quantity. quality , price etc of\n" +
                "                                            the products purchased by you. You hereby agree to take up any\n" +
                "                                            matter of dispute with the Vendor only.</p></li>\n" +
                "                                    </ul>\n" +
                "                                </ul>\n" +
                "                                <p className=\"para-text\">\n" +
                "                                    <b>Note:</b>\n" +
                "                                    We charge commission only from the vendor for the sales services.\n" +
                "                                    User has to buy for the    product/ services at their negotiated\n" +
                "                                    price with the vendor/service provider. It is also advisable not to\n" +
                "                                    pay any amount in excess for the cashback as Company never promotes\n" +
                "                                    such practises and never take anything from their users. Company will\n" +
                "                                    not be liable for any extra amount paid by the user to the vendor.</p>"
        },
        {
            segID:"bsw",
            title:"Best Shopping Website",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/best-shopping-website.svg',
            text:"Shop Online: From shopping websites",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        After\n" +
                "                                        shopping from third party website with whom the Global Garner has\n" +
                "                                        done agreement, the Payments will be done on their website only,\n" +
                "                                        through their respective payment gateways and other modes of\n" +
                "                                        payment. As a User, you will be redirected to the e commerce\n" +
                "                                        shopping website when you opt to buy from online shopping website\n" +
                "                                        through the BEST SHOPPING WEBSITES tab available on our app and\n" +
                "                                        website. When you select the particular\n" +
                "                                        website/product/vendor/service provider, you will be redirected on\n" +
                "                                        their website and there you have to do payment for your purchases as\n" +
                "                                        per your discretion through the available mode of payment opted by\n" +
                "                                        you. Approximately, your UPV generated through purchase from online\n" +
                "                                        websites will be approved within 90-120 days depending upon the\n" +
                "                                        website terms of payment of our commission. After receiving the\n" +
                "                                        commission on your purchase through the vendor (online shopping\n" +
                "                                        website/service provider/vendor) you will start getting the cash\n" +
                "                                        back/reward on your generated UPVs as per SCB process FIFO or SCB\n" +
                "                                        Random Pick Process or Instant Cashback Process. Your purchases will\n" +
                "                                        be auto tracked by the system except in case of few websites like\n" +
                "                                        Amazon, Jetradar, divya pharma, hotelook, in such cases you have to\n" +
                "                                        upload the bill. Others terms and conditions applicable to the\n" +
                "                                        respective websites will be in force when you visit the link through\n" +
                "                                        Global garner website.</p></li>\n" +
                "                                </ul>\n" +
                "                                <p className=\"para-text\">\n" +
                "                                    For\n" +
                "                                    detailed terms and condition, the same will be displayed on the\n" +
                "                                    screen as and when you visit the link through Global Garner website\n" +
                "                                    and after accepting the terms and condition only, you will be\n" +
                "                                    redirected to the concern website. Cash back /reward will not be\n" +
                "                                    applicable on Exclusive products and few other products, as mentioned\n" +
                "                                    in the terms when you enter the website, which may vary from time to\n" +
                "                                    time depending on our agreement with them. Cash back /reward as per\n" +
                "                                    SCB FIFO or SCB RPP will be the sole responsibility of the global\n" +
                "                                    garner, only after the receipt of the commission from these websites,\n" +
                "                                    post UPV generation. For IC Process also the terms will be same but\n" +
                "                                    UPV generation is not mandatory and it will also take 90 to 120  days.\n" +
                "                                    Sometimes, it can take more than 120 days if there is any delay from\n" +
                "                                    the respective website or due to any other reason.</p>\n" +
                "                                <p className=\"para-text\">\n" +
                "                                    Some\n" +
                "                                    orders may not be tracked by the system due to the technical glitch\n" +
                "                                    from affiliate side or any other technical issues, in those cases\n" +
                "                                    Company is not liable to pay cash back /reward. Such orders are not\n" +
                "                                    confirmed /approved by these affiliates or these third-party websites\n" +
                "                                    under our tracking system which does not bound us to approve such\n" +
                "                                    purchases and company is not liable to pay anything if it is declined\n" +
                "                                    /not approved under us. Global Garner has full discretion and right\n" +
                "                                    to reject the purchases which are not tracked by the system or for\n" +
                "                                    the purchases for which minimum required percentage of commission is\n" +
                "                                    not received by them. Company also has full right to reject those\n" +
                "                                    bills which are not correct in terms of products or amount.</p>"
        },
        {
            segID:"recharge",
            title:"Bill Pay & Recharge",
            icon:'https://s3.ap-south-1.amazonaws.com/gg-statics/v3/header/recharge.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        As\n" +
                "                                        a User, you can pay your daily utility bills through our website.\n" +
                "                                        All the payments made by the user will be processed through our\n" +
                "                                        payment gateway through the modes available therein via Nodal\n" +
                "                                        Account and it will be credited to the concerned operator/service\n" +
                "                                        provider/vendor. Sometimes this may take a longer time to reflect in\n" +
                "                                        the account of the biller or the transactions are sometimes under\n" +
                "                                        process for a longer period, Company is not liable in such cases as\n" +
                "                                        we entirely depends upon the service provider, though we try our\n" +
                "                                        best that no transaction gets failed. Approximately, your UPV\n" +
                "                                        generated through online bill pay and recharges will be approved\n" +
                "                                        within 15-60 days depending upon the website /vendor/service\n" +
                "                                        provider terms of payment of our commission. After receiving the\n" +
                "                                        commission on your purchase through the vendor (service\n" +
                "                                        provider/website/vendor ) you will start getting the cash\n" +
                "                                        back/reward on your generated UPVs as per SCB process FIFO and In\n" +
                "                                        case of SCB RPP cashback/reward will be given randomly. In case of\n" +
                "                                        IC, cashback/reward will be given instantly after the receipt of\n" +
                "                                        approval and commission from the concerned service provider, this\n" +
                "                                        may also take 10 to 15 working days after payment confirmation by\n" +
                "                                        the service provide or operator and UPV generation is also not\n" +
                "                                        mandatory. In case of any failed transactions, your refund will be\n" +
                "                                        processed within 5 to 10 working days and it will be credited in\n" +
                "                                        your respective account within T+5 working days, T being the date of\n" +
                "                                        refund transaction initiated. This depends upon the mode of payment\n" +
                "                                        chosen by the user. Sometimes it may take longer, Company will not\n" +
                "                                        be liable for delay in refund as it depends upon technical issues.</p></li>\n" +
                "                                </ul>"
        },
        {
            segID:"booking",
            title:"Online Booking",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/megabrand.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        As\n" +
                "                                        a user you can make online booking for your entertainment needs like\n" +
                "                                        Shopping /concert /play tickets and also for the services like booking\n" +
                "                                        hotel rooms, Bus tickets, flights etc from our website. Such\n" +
                "                                        bookings can be made through wide range of service\n" +
                "                                        provider/websites/local nearby and national vendors etc. Other terms\n" +
                "                                        and conditions will be applicable as per respective segment and\n" +
                "                                        website.</p>\n" +
                "                                    <p className=\"para-text\">\n" +
                "                                        The\n" +
                "                                        payment will be processed through our payment gateway/Split Pay via a\n" +
                "                                        Nodal Account to the concern operator/service provider/vendor or\n" +
                "                                        through direct payment to third party providing such facilities to us\n" +
                "                                        or through other modes of payment available. Approximately, your UPV\n" +
                "                                        generated through online booking will be approved within 15-90 days\n" +
                "                                        depending upon the website /vendor/service provider terms of payment\n" +
                "                                        of our commission and date of journey or event. After receiving the\n" +
                "                                        commission on your purchase through the vendor (service\n" +
                "                                        provider/website/vendor ) you will start getting the cash back on\n" +
                "                                        your generated UPVs as per SCB process FIFO and in case of SCB RPP\n" +
                "                                        cashback/reward will be given randomly.</p>\n" +
                "                                    </li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        In\n" +
                "                                        case of IC, cashback/reward will be given instantly after the\n" +
                "                                        receipt of approval and commission from the concerned service\n" +
                "                                        provider and after the completion of journey or attending event.\n" +
                "                                        Instant Cash back/reward may also take 15 to 90 working days\n" +
                "                                        depending upon payment confirmation and approval by the service\n" +
                "                                        provide /operator .UPV generation is not mandatory in this case</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Cash\n" +
                "                                        back will not be applicable on few products, as mentioned in the\n" +
                "                                        terms when you enter the website, which may vary from time to time\n" +
                "                                        depending on our agreement with them. Cash back/reward as per SCB\n" +
                "                                        FIFO or SCB RPP post UPV generation or IC will be the sole\n" +
                "                                        responsibility of the global garner, only after the receipt of the\n" +
                "                                        commission from these websites/service provider/third party\n" +
                "                                        mediator.</p></li>\n" +
                "                                </ul>\n" +
                "                                <p className=\"para-text\">\n" +
                "                                    GG\n" +
                "                                    BUS AND FLIGHT BOOKING: In case of Bus or flight tickets directly\n" +
                "                                    booked through us UPV will be generated within 15 to 45 days after\n" +
                "                                    the date of journey. Global Garner will not be liable in case of\n" +
                "                                    cancellation of bus or flights. Respective service provider will be\n" +
                "                                    responsible for any cancellation or delay in services. Global garner\n" +
                "                                    is just a mediator between User and service provider and will be\n" +
                "                                    responsible for cashback/reward after receipt of commission and other\n" +
                "                                    approval as the case may be. No airline or bus operator will be\n" +
                "                                    responsible for cashback/reward. Other terms and conditions of\n" +
                "                                    airlines will remain in force.</p>\n" +
                "                                <ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        In\n" +
                "                                        case of any failed transactions, your refund will be processed\n" +
                "                                        within 5 to 10 working days and it will be credited in your\n" +
                "                                        respective account within T+5 working days, T being the date of\n" +
                "                                        refund transaction initiated. Sometimes it may take longer , Company\n" +
                "                                        will not be liable for delay in refund as it depends upon technical\n" +
                "                                        issues.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        In\n" +
                "                                        case of cancellation of flight directly from airline side, user has\n" +
                "                                        to inform us and write to the airline for refund.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Cashback\n" +
                "                                        is not applicate on flight booking through Arewah travel.</p></li>\n" +
                "                                </ul>"
        },
        {
            segID:"mega-brands",
            title:"Mega Brands",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/megabrand.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Grab\n" +
                "                                        vouchers for the mega brand stores and enjoy the shopping at any of\n" +
                "                                        its outlets. GG gives you vouchers of top brand stores with\n" +
                "                                        Cashback/reward up to 100% from Global garner through SCB FIFO or\n" +
                "                                        SCB RPP. Cashback /reward is given by Global garner and not by the\n" +
                "                                        respective mega brand.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        User\n" +
                "                                        has to select the gift voucher of the respective mega brand from\n" +
                "                                        which he/she intends to purchase for the specified value as per\n" +
                "                                        his/her discretion. All terms related to gift card /voucher are\n" +
                "                                        given on the product page so please read all the terms and\n" +
                "                                        conditions carefully before purchasing the Gift Card. Payment will\n" +
                "                                        be processed through our payment gateway (Split Pay) via a Nodal\n" +
                "                                        Account  or any other available payment option to the concern\n" +
                "                                        operator/service provider/vendor or through any third-party vendor\n" +
                "                                        providing these services to us</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Sometimes\n" +
                "                                        there may be delay in product/ card delivery from the side of third\n" +
                "                                        party mediator /vendor/service provider/respective brand , in such\n" +
                "                                        cases, Global garner will not be liable for the delay caused. All\n" +
                "                                        the terms and conditions of the respective brand will be in force\n" +
                "                                        and if any issue occurs in acceptance of these vouchers by the given\n" +
                "                                        brand, company will take all the necessary and required steps to\n" +
                "                                        resolve the issues but will not be responsible if not accepted by\n" +
                "                                        the brand in case they change their policy or withdraw any such\n" +
                "                                        facility.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Approximately,\n" +
                "                                        UPV generated through Gift cards /voucher will be approved within\n" +
                "                                        15-90 days depending upon delivery of gift voucher/card and receipt\n" +
                "                                        for our commission. After receiving the commission/discount on your\n" +
                "                                        purchase from the service provider/vendor we will start processing\n" +
                "                                        the cash back/reward on your generated UPVs as per SCB process FIFO\n" +
                "                                        and in case of SCB RPP cashback/reward will be given randomly. In\n" +
                "                                        case of IC, cashback/reward will be given instantly after the\n" +
                "                                        receipt of approval and commission from the concerned service\n" +
                "                                        provider, this may also take 15 to 45 working days after payment\n" +
                "                                        confirmation and product delivery and UPV generation is also not\n" +
                "                                        mandatory.. Others terms and conditions applicable to the respective\n" +
                "                                        megabrand/vendor will be in force.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        The\n" +
                "                                        detail terms and conditions will be displayed on the screen whenever\n" +
                "                                        you visit any link outside the global garner website and you proceed\n" +
                "                                        further only after accepting the terms and condition of that\n" +
                "                                        particular website</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        In\n" +
                "                                        case of any failed transactions, your refund will be processed\n" +
                "                                        within 5 to 10 working days and it will be credited in your\n" +
                "                                        respective account within T+5 working days, T being the date of\n" +
                "                                        refund transaction initiated. Sometimes it may take longer , Company\n" +
                "                                        will not be liable for delay in refund as it depends upon technical\n" +
                "                                        issues</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Once\n" +
                "                                         the Gift Cards are delivered, all issues pertaining to the Cards need to be raised with the issuing brand directly and Global Garner will not be responsible for the same. Global Garner shall however reasonably assist the User towards the resolution of any issues." +
                "                                        </p></li>\n" +
                "                                </ul>"
        },
        // {
        //     segID:"regional-brands",
        //     title:"Regional Brands",
        //     icon:require('./images/svg/bill_payment.svg'),
        //     text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
        //     description:""
        // },
        {
            segID:"resale",
            title:"Resale",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/resale.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        This\n" +
                "                                        segment Would be a classified marketplace where used goods are up\n" +
                "                                        for sale from a registered seller ( Not Preferred Vendor). You have\n" +
                "                                        to search, type of product you wish to buy in your nearby location\n" +
                "                                        and visit the Vendor directly, make payment there and upload the\n" +
                "                                        bill on our app /website for approval, then your bill is sent to the\n" +
                "                                        vendor/seller for his approval. Your UPV will be added to the cash\n" +
                "                                        back system for cashback/reward through SCB FIFO or SCB RPP once\n" +
                "                                        goods are delivered to the buyer , payment is received by the seller\n" +
                "                                        and commission is received by us. For IC/reward within 15-45 days\n" +
                "                                        after receipt of commission. Also you can use our payment gateway to\n" +
                "                                        do payment transaction. User has to register on our website/app as\n" +
                "                                        seller or Buyer. If seller or buyer is not registered with us in\n" +
                "                                        that case you shall not be eligible for cash back. When you register\n" +
                "                                        as a seller under this segment you deem to be temporary vendor as\n" +
                "                                        per terms and conditions of the Global Garner.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        GG\n" +
                "                                        will facilitate the transaction and the buyer or seller can take our\n" +
                "                                        paid pickup and drop paid facility also. You can buy and sell your\n" +
                "                                        goods, Not only in nearby location but in different cities also\n" +
                "                                        through paid service of pick-up and drop.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Thereafter\n" +
                "                                        after approval of the vendor/seller and after receiving the\n" +
                "                                        commission from the seller for the same purchase, you will start\n" +
                "                                        getting the cash back/reward on your generated UPVs through SCB FIFO\n" +
                "                                        or SCB RPP. For IC within 15 to 45 days after receipt of commission.</p></li>\n" +
                "                                </ul>\n" +
                "                                <p className=\"para-text\">\n" +
                "                                    Approximately,\n" +
                "                                    your UPV generated through purchase of used goods will be approved\n" +
                "                                    within 15-90 days depending upon the payment of our commission from\n" +
                "                                    the seller. After receiving the commission on your purchase through\n" +
                "                                    the seller you will start getting the cash back/reward on your\n" +
                "                                    generated UPVs as per SCB process FIFO or SCB RPP or IC.</p>\n" +
                "                                <ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Global\n" +
                "                                        garner will not be liable for the quantity /quality /price of any\n" +
                "                                        product sold or purchased by any of its users. We at global garner\n" +
                "                                        are providing you (our Users) platform for purchase of old goods, we\n" +
                "                                        are not liable for any conflict occurring between its users for\n" +
                "                                        resale of goods. By accepting the terms and condition, you hereby\n" +
                "                                        confirm and agree that all the transactions are done between you and\n" +
                "                                        the other users and any conflict will be dealt between the users\n" +
                "                                        without any involvement of Global garner. As a seller of the old\n" +
                "                                        goods you are also liable to pay commission (as decided by the\n" +
                "                                        company) to the Global garner and you confirm that you will pay the\n" +
                "                                        commission within prescribed time limit.</p></li>\n" +
                "                                </ul>"
        },
        {
            segID:"relation",
            title:"Relation",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/relation.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Relations\n" +
                "                                        is an Advanced Social Networking Platform with a number of active\n" +
                "                                        users registered that are TRIPLE VERIFIED. It is a unique concept of\n" +
                "                                        ‘Earn while Socialize’ which is now a new trend in Social Media\n" +
                "                                        Platform. With Relations, you can connect to your family &amp;\n" +
                "                                        friends, besides, you earn on getting socialized with each other. We\n" +
                "                                        give you the platform to share , make friends, like post, etc.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Earn\n" +
                "                                        GG- Coins with all your social activity and Use GG- Coin with your\n" +
                "                                        next purchase.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        This\n" +
                "                                        can be redeemed with new purchases to the extent of 5% of the bill\n" +
                "                                        amount and GG Coins which remain in GG Rewards till 4th Cashback\n" +
                "                                        Payout will be compulsorily converted into GG-Money and then user\n" +
                "                                        can encash it to his Bank account. GG coins will be credited as per\n" +
                "                                        company’s payment structure, subject to change on company’s\n" +
                "                                        discretion time to time. All payments will be made after deducting\n" +
                "                                        the applicable taxes and charges as per company’s policy and\n" +
                "                                        government directive which may change from time to time.</p></li>\n" +
                "                                </ul>\n" +
                "                                <p className=\"para-text\">\n" +
                "                                    Global\n" +
                "                                    garner will not be responsible for any objectionable content written\n" +
                "                                    or shared by you or any other user. Global Garner is not liable for\n" +
                "                                    any obsessive and abusive material shared through the platform by any\n" +
                "                                    of its users to other users.</p>\n" +
                "                                <ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        You\n" +
                "                                        will not post messages, pictures or recordings or use the Website in\n" +
                "                                        any way which violates or infringes upon the rights of any third\n" +
                "                                        party, including but not limited to any copyright or trademark law,\n" +
                "                                        privacy or other personal or proprietary rights, or is fraudulent or\n" +
                "                                        otherwise unlawful or violates any law and also violates the rights\n" +
                "                                        of the others.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        You\n" +
                "                                        shall use the Website for your own personal use. You shall not\n" +
                "                                        authorize third party to use the Website and you may not transfer\n" +
                "                                        your accounts with other users.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        You\n" +
                "                                        will not use the Website to distribute, promote or otherwise publish\n" +
                "                                        any material containing any solicitation for funds, advertising or\n" +
                "                                        solicitation for goods or websites.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        It\n" +
                "                                        should be understood that when you register yourself with GG\n" +
                "                                        Relation, the contents which you post in your account of GG\n" +
                "                                        Relation, shall be public to all member of GG Relation and all\n" +
                "                                        members shall view the same.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Despite\n" +
                "                                        the above instructions if anything which is against the law from\n" +
                "                                        your GG Relation account has been posted, shall deemed to be posted\n" +
                "                                        by you and you shall be responsible for the same. GG shall not be\n" +
                "                                        held liable for anything posted against the law</p></li>\n" +
                "                                </ul>"
        },
        {
            segID:"matrimonial",
            title:"Matrimonial",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/matrimonial.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Matrimony\n" +
                "                                        is the platform providing services for the purpose of match making.\n" +
                "                                        In this segment you have to provide your personal details in the\n" +
                "                                        platform, which requires to be correct and accurate in every form.\n" +
                "                                        We will disclose your contact details to the third party , only\n" +
                "                                        after acceptance of your request by the other party. After\n" +
                "                                        acceptance of request we will give you contact numbers and other\n" +
                "                                        details as agreed by you and other user who are registered with the\n" +
                "                                        Global Garner, with whom you can contact and chat. We strongly\n" +
                "                                        recommend you to establish the authenticity and intent of members\n" +
                "                                        before giving out personally identifiable information. Global garner\n" +
                "                                        will not be responsible for it in any form.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Global\n" +
                "                                        Garner is not liable for any obsessive and abusive material shared\n" +
                "                                        through the platform by any of its users to other users.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        You\n" +
                "                                        will not post messages, pictures or recordings or use the Website in\n" +
                "                                        any way which violates or infringes upon the rights of any third\n" +
                "                                        party, including but not limited to any copyright or trademark law,\n" +
                "                                        privacy or other personal or proprietary rights, or is fraudulent or\n" +
                "                                        otherwise unlawful or violates any law and also violates the rights\n" +
                "                                        of the others.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        You\n" +
                "                                        shall use the Website for your own personal use. You shall not\n" +
                "                                        authorize third party to use the Website and you may not transfer\n" +
                "                                        your accounts with other users.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        You\n" +
                "                                        will not use the Website to distribute, promote or otherwise publish\n" +
                "                                        any material containing any solicitation for funds, advertising or\n" +
                "                                        solicitation for goods or websites.</p></li>\n" +
                "                                </ul>\n" +
                "                                <p className=\"para-text\">\n" +
                "                                    By\n" +
                "                                    accepting the terms and condition , you hereby confirm and agree that\n" +
                "                                    all the matrimonial relations are established between you and the\n" +
                "                                    other user and any conflict will be dealt between the users only\n" +
                "                                    without any involvement of global garner</p>"
        },
        {
            segID:"news",
            title:"News",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/news.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        This\n" +
                "                                        segment will provide you the place where you can get the latest news\n" +
                "                                        around the world and also gives you chance to post a news to the\n" +
                "                                        website under My city-My Reporter. The posting of news by you, is at\n" +
                "                                        your entire risk and consequences. Global Garner will not be liable\n" +
                "                                        for news posted by you through your registered account with the\n" +
                "                                        Global Garner website. Global garner will also not be liable for the\n" +
                "                                        authenticity of the contents of news available on the website\n" +
                "                                        through different news websites provided through the link.My\n" +
                "                                        City my reporter: Only for reporters with full enrolment form can\n" +
                "                                        post news using videos, links, pictures, and text . He can have\n" +
                "                                        followers, likes on his/her news post and his/her rankings in\n" +
                "                                        country, state, city and region also give him/her the platform to\n" +
                "                                        select desired audience, also give him/her a marketing platform on\n" +
                "                                        which he/she can source advertisements and other revenues. Global\n" +
                "                                        Garner will not be liable for the contents uploaded on GG website\n" +
                "                                        and application through registered users account. However Global\n" +
                "                                        garner has full right to remove any content as per its discretion\n" +
                "                                        and to change any feature from time to time.</p></li>\n" +
                "                                </ul>"
        },
        {
            segID:"gg-services",
            title:"GG Services",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/services.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        You\n" +
                "                                        can book services like car wash, plumber, laundry, personal care,\n" +
                "                                        beauty treatment, pet care and many more such services through the\n" +
                "                                        GG platform</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        You\n" +
                "                                        have to search, type of product you wish to buy in your nearby\n" +
                "                                        location and visit the Vendor directly make payment there and upload\n" +
                "                                        the bill through the bill upload module along with the additional\n" +
                "                                        details. Once the bill is approved by the vendor then User’s\n" +
                "                                        purchase will be added to the UPV Cashback System by us for\n" +
                "                                        cashback/reward through SCB FIFO or SCB RPP. For IC /reward within\n" +
                "                                        15 to 45 days after receipt of commission. If User fails to upload\n" +
                "                                        the bill or the bill is rejected by Vendor or by Global Garner,\n" +
                "                                        User’s purchase will not be added to the UPV Cashback System\n" +
                "                                        neither will be considered for instant cashback. Also you can use\n" +
                "                                        our payment gateway as a mode of payment (as per other guidelines\n" +
                "                                        mentioned under regional brands)&nbsp;<br></br>Approximately, your UPV\n" +
                "                                        generated through purchase of these services will be approved within\n" +
                "                                        15-90 days depending upon the payment of our commission from the\n" +
                "                                        service provider. After receiving the commission on your purchase\n" +
                "                                        through the seller you will start getting the cash back on your\n" +
                "                                        generated UPVs as per SCB FIFO process or through SCB RPP</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Global\n" +
                "                                        Garner is not liable for any product or service you buy from our\n" +
                "                                        platform, as Global Garner is providing third party support to its\n" +
                "                                        vendor to reach its customers. If there is any kind of dispute with\n" +
                "                                        the service provider you agree to take up the matter with captioned\n" +
                "                                        service provider. Other terms and conditions as applicable to the\n" +
                "                                        service provider will be in force. By accepting the terms and\n" +
                "                                        condition , you hereby confirm and agree that all the transactions\n" +
                "                                        are done between you and service provider and any conflict will be\n" +
                "                                        dealt between you and service provider without any involvement of\n" +
                "                                        Global garner</p></li>\n" +
                "                                </ul>"
        },
        {
            segID:"real-estate",
            title:"Real Estate",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/real-estate.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        This\n" +
                "                                        segment gives you vast range of real estate property for purchase ,\n" +
                "                                        sale and rent. Whether you are looking to sell your property or buy\n" +
                "                                        property or rent it out, we can help you to save your time and\n" +
                "                                        provides you digital platform to exhibit your choice. User has to\n" +
                "                                        register on our website/app as seller or Buyer. If seller or buyer\n" +
                "                                        is not registered with us in that case you shall not be eligible for\n" +
                "                                        cash back/reward</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        You\n" +
                "                                        can search any type of real estate property you wish to buy and you\n" +
                "                                        can post your property for selling through website. You can directly\n" +
                "                                        sell or purchase the property to the user or from the user\n" +
                "                                        respectively and make payment directly and upload the payment\n" +
                "                                        receipt and other required documents on our app for approval, then\n" +
                "                                        your bill is sent to the seller for approval. You can also use our\n" +
                "                                        payment gateway to do payment transaction.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Once\n" +
                "                                        the registration documents of property (as per state laws) is\n" +
                "                                        approved by the seller , the buyer’s purchase will be added to the\n" +
                "                                        UPV Cashback System by us for cashback/reward through SCB FIFO or\n" +
                "                                        SCB RPP. For IC/Reward within 15 to 45 days after receipt of\n" +
                "                                        commission. If User fails to upload the bill or the bill is rejected\n" +
                "                                        by seller or by Global Garner, buyer purchase will not be added to\n" +
                "                                        the UPV Cashback System neither it will be eligible for instant\n" +
                "                                        cashback /reward. Approximately, your UPV generated through purchase\n" +
                "                                        of real estate property will be approved within 15-90 days depending\n" +
                "                                        upon the payment of our commission from the seller. After receiving\n" +
                "                                        the commission on your purchase through the seller you will start\n" +
                "                                        getting the cash back on your generated UPVs as per SCB FIFO process\n" +
                "                                        or through SCB RPP.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Global\n" +
                "                                        Garner is not liable for real estate property/product you buy or\n" +
                "                                        sell through our platform, as Global Garner is providing third party\n" +
                "                                        support to its seller and buyer . If there is any kind of dispute\n" +
                "                                        between seller and buyer it should be solved between the two without\n" +
                "                                        any involvement of Global garner. By accepting the terms and\n" +
                "                                        condition , you hereby confirm that all the real estate transactions\n" +
                "                                        are done between you and the other users and any conflict will be\n" +
                "                                        dealt between the users. Global garner will not be liable for any\n" +
                "                                        dispute regarding title clearance of the property or any other type\n" +
                "                                        of legal matter related to the property. As a seller you are also\n" +
                "                                        liable to pay commission (as decided by the company) to the Global\n" +
                "                                        garner and you confirm that you/seller will pay the commission\n" +
                "                                        within prescribed time limit.</p></li>\n" +
                "                                </ul>"
        },
        {
            segID:"professional",
            title:"Professional",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/proffessional.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:" <ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Professional\n" +
                "                                        is all about posting jobs, and searching jobs . You can post your\n" +
                "                                        job requirements for hiring professionals as a recruiter and you can\n" +
                "                                        seek for new job opportunities as a job seeker through our platform.\n" +
                "                                        This service is also covered under cash back/reward process if any\n" +
                "                                        paid services are availed. After the approval of the professional\n" +
                "                                        and receiving the commission from professionals we will approve your\n" +
                "                                        cashback/reward through SCB FIFO or SCB RPP or IC. User has to\n" +
                "                                        register on our website/app as recruiter or job seeker. If recruiter\n" +
                "                                        or job seeker is not registered with us in that case you shall not\n" +
                "                                        be eligible for cash back/reward.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Approximately,\n" +
                "                                        your UPV generated through purchase of any paid services will be\n" +
                "                                        approved within a period of 15-90 days depending upon the payment of\n" +
                "                                        our commission from the Service Provider. After receiving the\n" +
                "                                        commission on your paid services from the Service Provider. you will\n" +
                "                                        start getting the cash back/reward on your generated UPVs as per the\n" +
                "                                        SCB FIFO(Systematic Cashback) process or through or SCB RPP. For\n" +
                "                                        IC/Reward within 15 to 45 days after receipt of commission.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Global\n" +
                "                                        Garner is not liable for any discrepancy or incorrect information or\n" +
                "                                        claim from either side , as Global Garner is providing third party\n" +
                "                                        support to professionals . If there is any kind of dispute between\n" +
                "                                        recruiter and job seeker it should be solved between the two without\n" +
                "                                        any involvement of Global garner. By accepting the terms and\n" +
                "                                        condition , you hereby confirm that all professional agreements and\n" +
                "                                        arrangements are done between you and the other users and any\n" +
                "                                        conflict will be dealt between the two without any involvement of\n" +
                "                                        Global garner. As a recruiter you are also liable to pay commission\n" +
                "                                        (as decided by the company)to the Global garner and you confirm that\n" +
                "                                        you will pay the commission within prescribed time limit.</p></li>\n" +
                "                                </ul>"
        },
        {
            segID:"donate",
            title:"Donate",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/donate.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Donate\n" +
                "                                        for a good cause. Users can seek or respond help via GG Relations\n" +
                "                                        for a noble cause of blood donation to anyone in an emergency.\n" +
                "                                        Whereas, users can also donate toys, education fund, food, etc. to\n" +
                "                                        various NGOs and Institutions via GG. Through our platform you can\n" +
                "                                        donate anything you wish to donate for a noble cause. Global Garner\n" +
                "                                        is not liable for any kind of donation made or information shared by\n" +
                "                                        users through its platform. If there is any kind of dispute it\n" +
                "                                        should be solved without any involvement of Global garner. By\n" +
                "                                        accepting the terms and condition, you hereby confirm and agree that\n" +
                "                                        you are solely responsible for any kind of donations made by you\n" +
                "                                        .You also confirm that you are solely responsible for the quantity\n" +
                "                                        and quality of the product donated by you</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        It\n" +
                "                                        is social help tabs like</p>\n" +
                "                                        <ul>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Blood\n" +
                "                                                group page:</p>\n" +
                "                                                <ul>\n" +
                "                                                    <li><p className=\"para-text\">\n" +
                "                                                        Volunteer\n" +
                "                                                        yourself for a cause. Donate Blood to those who really need them</p></li>\n" +
                "                                                    <li><p className=\"para-text\">\n" +
                "                                                        Seek\n" +
                "                                                        Blood donation when you need it from nearby GG Users.</p></li>\n" +
                "                                                </ul>\n" +
                "                                            </li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Organ\n" +
                "                                                Donation:</p>\n" +
                "                                                <ul>\n" +
                "                                                    <li><p className=\"para-text\">\n" +
                "                                                        Volunteer\n" +
                "                                                        yourself for a cause. Donate Organs to those who really need them</p></li>\n" +
                "                                                    <li><p className=\"para-text\">\n" +
                "                                                        Seek\n" +
                "                                                        Organ donation when you need it from nearby GG Users.</p></li>\n" +
                "                                                </ul>\n" +
                "                                            </li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                In\n" +
                "                                                Danger/SOS:</p>\n" +
                "                                                <ul>\n" +
                "                                                    <li><p className=\"para-text\">\n" +
                "                                                        Volunteer\n" +
                "                                                        to help Nearby People.</p></li>\n" +
                "                                                    <li><p className=\"para-text\">\n" +
                "                                                        Seek\n" +
                "                                                        help from your Friends and Relatives also form Nearby People.</p></li>\n" +
                "                                                </ul>\n" +
                "                                            </li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Lend\n" +
                "                                                your books:</p>\n" +
                "                                                <ul>\n" +
                "                                                    <li><p className=\"para-text\">\n" +
                "                                                        Lend\n" +
                "                                                        or Borrow books form Nearby People</p></li>\n" +
                "                                                </ul>\n" +
                "                                            </li>\n" +
                "                                        </ul>\n" +
                "                                    </li>\n" +
                "                                </ul>"
        },
        {
            segID:"crowd-funding",
            title:"Crowd Funding",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/crowd-funding.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<ul>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Share\n" +
                "                                        your Idea with Society and Seek funding form like-minded People.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Search\n" +
                "                                        best idea to invest</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        You\n" +
                "                                        can Plan your business idea, and the investors and make your dreams\n" +
                "                                        come true with GG crowdfunding portal. GG helps you to get the\n" +
                "                                        source &amp; support for your innovative idea to be alive. You can\n" +
                "                                        post your business ideas if you require funding as an entrepreneur\n" +
                "                                        and you can also provide funds to other startups as an investor\n" +
                "                                        through our platform. Investors can also get cash back /reward of\n" +
                "                                        the amount he/she has invested through our portal, which reduces the\n" +
                "                                        risk of losses. Once the investment is approved by the borrower, the\n" +
                "                                        investor’s investment will be added to the UPV Cashback System by\n" +
                "                                        us for cashback/reward through SCB FIFO or SCB RPP. For IC/Reward\n" +
                "                                        within 15 to 45 days after receipt of commission. If investor fails\n" +
                "                                        to upload the receipt or the receipt is rejected by the borrower or\n" +
                "                                        by Global Garner, invested amount will not be added to the UPV\n" +
                "                                        Cashback System.</p></li>\n" +
                "                                    <li><p className=\"para-text\">\n" +
                "                                        Approximately,\n" +
                "                                        your UPV generated through crowd funding will be approved within\n" +
                "                                        15-90 days depending upon the payment of our commission received\n" +
                "                                        from the borrower After receiving the commission on your investment\n" +
                "                                        from borrower you will start getting the cash back/reward on your\n" +
                "                                        generated UPVs as per SCB FIFO process or through SCB RPP. Instant\n" +
                "                                        cashback /reward instantly without UPV generation if IC option is\n" +
                "                                        opted. Global Garner is not liable for any discrepancy or incorrect\n" +
                "                                        information or claim from either side , as Global Garner is\n" +
                "                                        providing third party support to investors and\n" +
                "                                        borrowers/entrepreneurs . If there is any kind of dispute between\n" +
                "                                        the investor and borrower it should be solved between the two\n" +
                "                                        without any involvement of Global garner. By accepting the terms and\n" +
                "                                        condition , you hereby confirm and agree that all investment\n" +
                "                                        dealings between you and the other users and any conflict will be\n" +
                "                                        dealt between the two without any involvement of Global garner. As a\n" +
                "                                        borrower seeking investment you are also liable to pay commission\n" +
                "                                        (as decided by the company)to the Global garner and you confirm that\n" +
                "                                        you will pay the commission within prescribed time limit.</p></li>\n" +
                "                                </ul>"
        },
        /*{
            segID:"railbhoj",
            title:"Railbhoj",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/railbhoj.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<p className=\"para-text\">\n" +
                "                                        Railbhoj\n" +
                "                                        is an e-catering service provider located\n" +
                "                                        at&nbsp;<a href=\"https://railbhoj.globalgarner.com/\"><u>railbhoj.globalgarner.com</u></a>&nbsp;which\n" +
                "                                        enables its users to order food/ meals , while travelling in train\n" +
                "                                        by communicating your orders to Delivery Restaurants (&quot;Delivery\n" +
                "                                        Restaurants&quot;) displayed on this Website.</p>\n" +
                "                                        <ul>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                &quot;Participating\n" +
                "                                                Restaurant&quot; is a third party, which has agreed to co-operate\n" +
                "                                                with the Company to prepare and/or deliver the Goods or Services.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                &quot;Food\n" +
                "                                                Delivery in train&quot; is a reference to perishable goods and to\n" +
                "                                                any form of delivery service, which both are provided by our\n" +
                "                                                Participating Restaurants and for both of which our Participating\n" +
                "                                                Restaurants take full responsibility; and</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Any\n" +
                "                                                contract for the supply of Food Delivery from this Website/ app is\n" +
                "                                                between you and the Participating Restaurant; for the supply of\n" +
                "                                                Goods or Services from this Website any contact not between you and\n" +
                "                                                Railbhoj.\n" +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Please\n" +
                "                                                note it is important that you check the information that you enter\n" +
                "                                                and correct any errors before clicking on the &quot;Finish&quot;\n" +
                "                                                button .The fact that you receive an automatic confirmation does\n" +
                "                                                not necessarily mean that either we or the Delivery Restaurant will\n" +
                "                                                be able to fill your order. Sometimes due to unavoidable\n" +
                "                                                circumstances , we may not be able to process your order . We don’t\n" +
                "                                                accept cash on delivery option. All the orders must be pre-paid.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                All\n" +
                "                                                orders placed through Railbhoj will be deemed completed after the\n" +
                "                                                scheduled delivery time stated in the bill when the order is placed\n" +
                "                                                has passed, unless the customer notifies the customer service agent\n" +
                "                                                about the non-delivery of their order.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Please\n" +
                "                                                note that from time to time there may be delays with processing\n" +
                "                                                payments and transactions, on occasion this can take up to sixty\n" +
                "                                                (60) days to be deducted from your bank account or credit card.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                If\n" +
                "                                                delivery is done by the Delivery Restaurant or its delivery\n" +
                "                                                partners, it is the Delivery Restaurants sole responsibility to\n" +
                "                                                provide Food Delivery in a timely manner. Delivery Restaurants and\n" +
                "                                                Railbhoj shall not be liable to you for any losses, liabilities,\n" +
                "                                                costs, damages, charges or expenses arising out of late delivery.\n" +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                However,\n" +
                "                                                please note that the legal contract for the supply and purchase of\n" +
                "                                                food and beverages is between you and the Delivery Restaurants that\n" +
                "                                                you place your order with. Railbhoj cannot give any undertaking\n" +
                "                                                that the food and beverages ordered from the Delivery Restaurants\n" +
                "                                                through this Website will be of satisfactory quality and any such\n" +
                "                                                warranties are disclaimed by the Company. These disclaimers do not\n" +
                "                                                affect your statutory rights against the Delivery Restaurants.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                As\n" +
                "                                                per Company’s terms your purchase will be sent to UPV cycle after\n" +
                "                                                7-15 days of delivery of food or goods receipt for our commission.\n" +
                "                                                After this we will start processing the cash back/reward on your\n" +
                "                                                generated UPVs as per SCB process FIFO and in case of SCB RPP\n" +
                "                                                cashback/reward will be given randomly. In case of IC,\n" +
                "                                                cashback/reward will be given instantly after the receipt of\n" +
                "                                                approval and commission from the concerned service provider, this\n" +
                "                                                may also take 15 to 45 working days after payment confirmation and\n" +
                "                                                product delivery and UPV generation is also not mandatory.</p></li>\n" +
                "                                        </ul>"
        },*/
        {
            segID:"holidayPackage",
            title:"Holiday Package",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/holidays_packages.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"                                        <ul>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Price mentioned is on per person basis for two persons travelling together and sharing same room.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Bookings are subject to availability of rooms with the hotels.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                The package rates will vary during national holidays, festivals & long weekends and special discount prices"+
                "                                                will not be applicable during these occasions. Surcharges applicable." +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +"In case of unavailability in the listed hotels,"+
                "                                                alternate accommodation will be made in a hotel of similar standard.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Rates are valid for Indian Nationals only.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n " +
                "                                                No refund will be made for any unused accommodation, missed meals, transportation segments, sightseeing tours or any other service. Such unused items are neither refundable nor exchangeable in any form.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                No refund shall be claimed, if the services & amenities of hotel were not up to your expectations, it will be considered on case to case basis.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Final vouchers can only be issued once we have received full payment." +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +"It is the responsibility of the tourists to check and hold valid travel documents like passport and Visa till the end of the tour. In "+
                "                                                   case any travel document like visa is refused, expires or is cancelled by the authorities, the tour participant will have to meet all the contingencies arising therefrom and to meet all expenses consequential thereto. The Company will have no responsibility whatsoever in respect of the above matters.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Room Allocation is usually done by the hotel at the time of check in. We cannot guarantee any specific view room; also the rooms offered are the base category rooms in the package unless otherwise confirmed in inclusions.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                We or Bigbreaks will not be liable to any passenger for refund, compensation or claim for shortage of tour days or for cancellation, postponement or re-routing of any particular scheduled transport service due to any reasons including fog. The rules in respect of cancellation charges or refund will be applicable.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                <b>Visa Facilitation: The company provides visa facilitation and under no circumstances be held responsible for visa delay or rejection.</b>\n" +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Although the company tries to ensure good service to all its guests, still we request you to notify us during the tour only if you are facing any difficulty to help us resolve it immediately.  Due to any situation if you are advised by our team to pay locally and later get refund, please do keep the receipt of the payment made which would have to be sent to us for refund claim.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                <b>Early check-in or late check-out is subject to availability and the hotel may charge extra for the same and we don’t guarantee it.</b></p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                               Extra bed rates would be charged after 2 full paying persons in a room. However, in case of an extra bed request in a single occupancy room, double room rates would be charged.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                The package does not include charges for optional services and facilities (such as room service, mini bar, snacks, telephone calls etc.). These will be charged directly from the guest at the time of check-out from the respective hotel.\n" +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Global Garner cashback/reward will be applicable as per cashback terms and conditions depending upon cashback option chosen by the user and other terms as applicable.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Purchase will be sent to UPV cycle after 7-15 days of trip completion.</p></li>\n" +
                "                                        </ul>"+
                "<p className=\"para-text tex-center\">\n" +"<b>Cancellation Policy : </b></p>\n" +
                "<ul>\n" +
                "                                        <li><p className=\"para-text\">\n" +
                "                                                60 days or more to departure = 50 % of holiday cost.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                59 - 21 days to departure = 80 % of holiday cost.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                D – 20 days to departure = 100% of holiday cost .\n" +
                "                                            </p></li>\n" +
                "                                        </ul>"+
                "<p className=\"para-text tex-center\">\n" +"<b>Booking Conditions : </b></p>\n" +
                "<ul>\n" +
                "                                        <li><p className=\"para-text\">\n" +
                "                                                30 days or more to departure = 50% of holiday cost.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                30 - 21 days to departure = 80% of holiday cost.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                               D – 20 days to departure = 100% of holiday cost.\n" +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Photocopies of the passport with validity of minimum 6 months from date of travel." +
                "                                            </p></li>\n"+
                "                                        </ul>\n"+"<p className=\"para-text\">\nIf any booking related query bigbreaks.com Support Number: 9266092663</p>"

        },
        {
            segID:"upos",
            title: "UPOS - The Digital Mall",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/upos.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<p className=\"para-text\">\n" +
                "                                            Universal\n" +
                "                                            Point of Sale: All terms and conditions of the Company and its\n" +
                "                                            website will also be applicable on all transactions done through UPOS - The Digital Mall\n" +
                "                                            software as an extension of Global Garner. If you wish to purchase\n" +
                "                                            UPOS - The Digital Mall, all the terms on www. UPOS - The Digital Mall.globalgarner.com will be applicable\n" +
                "                                            .</p>"
        },
        {
            segID:"loan",
            title:"Loan",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/loan.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<p className=\"para-text\">\n" +
                "                                            Global\n" +
                "                                            Garner Sales Services Ltd acts as a mediator to fulfill your\n" +
                "                                            loan requirements from banking and non-banking  financial institutes.\n" +
                "                                            We direct all your loan  requirements to the RupeeBoss, which acts as\n" +
                "                                            an agent between banks and our Users. No cashback is applicable on\n" +
                "                                            any Loan segment. We does not guarantee any confirmation of loan.\n" +
                "                                            Loan approval entirely lies in the hands of financial institutes\n" +
                "                                            depending upon their eligibility criteria. Loan requirement leads are\n" +
                "                                            passed on to RupeeBoss and they are responsible for further process.\n" +
                "                                            Loan services are not available in few areas.</p>"
        },
        {
            segID:"inquiry-services",
            title:"Inquiry Services",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/inquiryforms.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:"<p className=\"para-text\">\n" +
                "                                            You\n" +
                "                                            can generate your enquiry for IT, HR, Vehicle purchase, insurance\n" +
                "                                            etc. We are not responsible for meeting all your requirements but we\n" +
                "                                            will try our best to do so. We can also share your leads  and data\n" +
                "                                            with third party in order to full fill your requirements.\n" +
                "                                        </p>\n" +
                "                                        <p className=\"para-text\">\n" +
                "                                            <i><b>Important\n" +
                "                                                Note:</b></i><i>&nbsp;User\n" +
                "                                            has to select product or services as per his discretion.<br></br>All\n" +
                "                                            Cashback/reward and other remuneration if any will attract Company’s\n" +
                "                                            Service Charge and Govt. Taxes as applicable from time to time Please\n" +
                "                                            insist on paying your vendor bill through our payment gateway which\n" +
                "                                            is the fastest and safest way of payment transaction through a nodal\n" +
                "                                            account and split pay.(if available) Through this, vendor also\n" +
                "                                            receives his payment instantly and simultaneously we also receive our\n" +
                "                                            commission in short span ,therefore it enable us to process your cash\n" +
                "                                            back (SCB FIFO/SCB RPP or IC) in minimum time. For detailed terms and\n" +
                "                                            condition please go through the entire document.</i></p>"
        },
        {
            segID:"hotel",
            title:"Hotel",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/v3/header/hotel.svg',
            text:"Bill Pay & Recharge: Mobile, DTH, Electricity etc.",
            description:
                "<p className=\"para-text\">\n"+"Bigbreaks Rules:</p>"+
                "<ul>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Guest must be over 18 years of age to check-in to this hotel.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                As per Government regulations, it is mandatory for all guests above 18 years of age to carry a valid photo identity card &amp; address proof at the time of check-in. Please note that failure to abide by this can result with the hotel denying a check-in. Hotels normally do not provide any refund for such cancellations." +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +"The standard check-in and check-out times are 12 noon. Early check-in or late check-out is subject to hotel availability and may also be chargeable by the hotel. Any early check-in or late check-out request must be directed to and reconfirmed with the hotel directly.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Failure to check-in to the hotel, will attract the full cost of stay or penalty as per the hotel cancellation policy.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n " +
                "                                                Hotels charge a compulsory Gala Dinner Supplement during Christmas, New Year's eve or other special events and festivals like Diwali or Dusshera. These additional  charge are not included in the booking amount and will be collected directly at the hotel.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                There might be seasonal variation in hotel tariff rates during Peak days, for example URS period in Ajmer or Lord Jagannath Rath Yatra in Puri, the room tariff differences if any will have to be borne and paid by the customer directly at the hotel, if the booking stay period falls during such dates.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                All additional charges other than the room charges and inclusions as mentioned in the booking voucher are to be borne and paid separately during check-out. Please make sure that you are aware of all such charges that may comes as extras. Some of them can be WiFi costs, Mini Bar, Laundry Expenses, Telephone calls, Room Service, Snacks etc." +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +"Some hotels may have policies that do not allow unmarried / unrelated couples or certain foreign nationalities to check-in without the correct documentation. No refund will be applicable in case the hotel denies check-in under such circumstances. If you have any doubts on this, do call us for any assistance.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Any changes or booking modifications are subject to availability and charges may apply as per the hotel policies..</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Global Garner cashback/reward will be applicable as per cashback terms and conditions depending upon cashback option chosen by the user and other terms as applicable.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                 Purchase will be sent to UPV cycle after 7-15 days of checkout completion.\n" +
                "                                            </p></li>\n" +
                "                                        </ul>"

        },

        {
            segID:"upv",
            title:"UPV",
            icon:'https://gg-statics.s3.ap-south-1.amazonaws.com/header/upv.svg',
            text:"UPV",
            description:
                "<p className=\"para-text\">\n"+"UPV TRANSFER Rules:</p>"+
                "<ol>\n" +
                "<p className=\"para-text\">\n" +
                "                                            <i><b>Under this option, the owner of UPV will change and the existing owner will transfer his UPV to the new User.</b></i></p>\n" +              
                "                                            <li><p className=\"para-text\">\n" +
                "                                                UPV Transfer works in My Circle, where My Circle UPVs can be transferred to any other user or individual.</p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                The transferor or User who wishes to transfer his/her UPV can generate a request for the same by using the Transfer UPV option and entering the details of the transferee or new User to whom he wants to assign or transfer his UPV." +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                If any UPV owner thinks he/she cannot complete his My Circle then he/she can propose another User to take the charge of his UPV at his old/existing position" +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                Thereafter, the new user or Transferee who gets the Transferor’s UPV will continue the My Circle from the point from where Transferor has left" +
                "                                            </p></li>\n" +
                "                                            <li><p className=\"para-text\">\n" +
                "                                                The cashback already gone to the Transferor would not be adjusted anywhere and the future cashback would be given to the Transferee or new User who has taken the place of Transferor, post the completion of the transfer" +
                "                                            </p></li>\n" +
                "                                        </ol>"

        },
    ]
}

export default buttonDetails;
