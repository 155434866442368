import React, {Component} from 'react';
import Audio from './Audio';

export default class Ringtone extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }

    render(){
      const {data} = this.props;
        return(
    			<div className="row cb-tab-data">
            {data.map((res)=>{
                return(
                  <Audio data={res} key={res.id}/>
                )
            })}
    			</div>
        );
    }
}
