import {connect} from 'react-redux';
import {Intent, Position, Toaster} from "@blueprintjs/core";

import TeamList from '../components/team';
import { getTeamList } from '../../core/actions/action';
import { teamListAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        teamList:state.RetailReducer.teamList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getTeamList:() => {
            return getTeamList()
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(teamListAction(sources.data));
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    dispatch(teamListAction(null));
                }
            }).catch((error)=>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamList);
