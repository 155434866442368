import React, {Component} from 'react';
import { Card, Button } from "@blueprintjs/core";
import VideoModal from '../../../core/components/videoModal';

export default class UPV extends Component{
  constructor(props){
    super(props);

    this.state = {
      isModalOpen:false,
      link:''
    };
  }
    render(){
        return(
          <div className="p-s-0 fpvCashback-container mt-5">
            <VideoModal
    					size="lg"
    					isOpen={this.state.isModalOpen}
              data={this.state.link}
    					className="bp3-dialog p-0 rounded"
    				/>
            <div className='important-note py-5 p-s-0 pt-s-3'>

               <h2 className='title text-center'>My Circle</h2>
              <div className='container row d-flex flex-md-row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-s-0 align-self-center'>
                  <img src={require('../../images/png/my_circle.png')} alt="my circle" className="img-fluid" />
                </div>

                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-3 py-s-4 px-s-0'>
                  <h3 className='text-muted'>
                    It is a cashback in which the User will be rewarded by referring new users to generate new UPVs under him from his own social circle. It is unlike other cashback options in which UPVs will be tagged globally. In this cashback, UPVs will be tagged within a circle formed by a user by generating his UPV at the beginning, thereafter referring 6 UPVs under his UPV , thus  creating his own circle. Further these 6 UPVs will refer other 6 UPVs under each UPV of their previous referral Circle and so on till it reaches the maximum level of 9 circles. Then this will keep continuing so on and so forth and other UPVs will keep referring new UPVs and further creating 9 referral Circles under them to get rewards. Therefore, several circles will keep forming in a single circle.

                  </h3>
                  <br/>
                  <Button text='Watch My Circle  Cashback Video' className='btn-link w-100 text-left'
                      onClick={()=>{
                        this.setState({isModalOpen:true,link:'zTxf1EePv5w'})
                      }}>
                      &nbsp;&nbsp;&nbsp;
                      <span className="bp3-icon bp3-icon-arrow-right"></span>
                  </Button>
                </div>

              </div>
            </div>
            <div className='important-note py-5 px-s-0 py-s-0 pt-s-1'>
			           <h2 className='title text-center'>Instant Cashback</h2>
              <div className='container row d-flex flex-column-reverse flex-md-row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center'>
                  <h3 className='text-muted'>
                    It is a cashback/ reward in which you can shop and get a guaranteed 2% to 10% instant cashback/ reward without waiting
                    to form an UPV or without new UPVs tagging under the purchases. Cashback/Reward will be processed instantly as soon as
                    you makes a purchase of any amount and this purchase will not be counted under SCB process.

                  </h3>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center'>
                  <img src={require('../../images/png/IC.png')} alt="Fuel Cashback" className="img-fluid py-5 py-s-4" />
                </div>
              </div>
            </div>

            <div className='important-note py-5 p-s-0 pt-s-3'>

			         <h2 className='title text-center'>Systematic Random Pick Process</h2>
              <div className='container row d-flex flex-md-row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-s-0 align-self-center'>
                  <img src={require('../../images/png/SCB-RPP.png')} alt="Fuel Cashback" className="img-fluid" />
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-5 py-s-4 px-s-0'>

                  <h3 className='text-muted'>
                  It is a Cashback/Reward process in which Cashback/Reward will be processed through random selection of UPVs generated in
                  a month where qualifiers (users whose UPV has been generated) are arranged in a pool and then number of qualifiers and
                  cashback percentage are decided as per SCB process but unlike SCB process of FIFO (first in and first out), where the
                  UPVs are given cashback as per tagging system as and when new UPVs are generated, in this process of SCB RPP, the system
                  will randomly pick the user for any given slab/amount of cashback but there will be a guarantee of everyone getting the
                  Cashback/Reward from minimum 2% to a maximum 100%. Amount of cashback /reward given to you will be randomly decided by
                  the system but the eligibility for the number of qualifiers under any given cashback/ reward slab will solely depends
                  upon SCB process only. Then these UPVs will be out of the system of SCB tagging.

                  </h3>
                  <br/>
                  <h4>*2% is in the form of GG Gift Vouchers.</h4>
                </div>

              </div>
            </div>
            <div className='important-note py-5 py-s-4'>
			         <h2 className='title text-center'>Systematic Cashback (FIFO)</h2>
              <div className='container row d-flex flex-md-row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                  <img src={require('../../images/png/SCB-FIFO.png')} alt="Fuel Cashback" className="img-fluid py-5 py-s-4" />
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-5 py-s-4 px-s-0'>
                  <h3 className='text-muted'>
                  SCB FIFO is a systematic Cashback/Reward process in which Cashback/Reward is given in 3 unequal installments of &#8377; 500,
                  &#8377; 1000, &#8377; 8500 each as per unique auto-tagging system, where you have to do nothing except for generating your own UPV's
                  by transacting through us for your daily needs. This money is real money which can be transferred into your bank account.
                  </h3>
                  <br/>
                    <Button text='Watch UPV cashback video' className='btn-link w-100 text-left'
                      onClick={()=>{
                        this.setState({isModalOpen:true,link:'3a3Twhjsavc'})
                      }}>
                      &nbsp;&nbsp;&nbsp;
                      <span className="bp3-icon bp3-icon-arrow-right"></span>
                  </Button>
                  <br/>
                  <h4>Note: This process may take time depending upon new UPVs generated in the system.</h4>
                </div>
              </div>
            </div>
            <div className='important-note py-5 px-s-0 pt-s-4 pb-s-0'>
      			  <h2 className='title text-center'>SCB cashback</h2>
      			  <div className='container row d-flex flex-column-reverse flex-md-row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-5 py-s-4 px-s-0'>
                  <h3 className='text-muted'>
                  SCB is a systematic Cashback/Reward process in which Cashback/Reward is given in 3 unequal installments of &#8377; 500,
                  &#8377; 1000, &#8377; 8500 each as per unique auto-tagging system, where you have to do nothing except for generating your own UPV's
                  by transacting through us for your daily needs. It is done through a specific algorithm depending solely upon the number
                  of new UPVs generated under your UPV. This money is real money which can be transferred into your bank account.
                </h3>
                <h5>Note: This process may take time depending upon new UPVs generated in the system.</h5>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-5 py-s-0 align-self-center'>
                  <img src={require('../../images/png/What-is-SCB.png')} alt="Fuel Cashback" className="img-fluid" />
                </div>
              </div>
            </div>


    					<div className='container refer-cashback pb-5'>
    						<h2 className='title text-center pt-4 pt-s-0'>Refer and get Cashback</h2>
      						<div className="row" style={{minHeight: '100%', display: 'flex'}}>
      							<div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
      								<Card className='bp3-elevation-5 cashback-cardfix'>
      									<center>
      										<img src={require('../../images/svg/vendor.svg')} alt="Fuel Cashback" className="img-fluid py-2" />
      										<p className='cashback-title'>Refer a Friend</p>
      									</center>
      									<p className='description'>Refer friends and get Get 10% of the Cashback/Reward Given To Them.</p>

      									<div className="clearfix"></div>
      								</Card>
      							</div>
      							<div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mt-s-4'>
      								<Card className='bp3-elevation-5 cashback-cardfix'>
      									<center>
      										<img src={require('../../images/svg/refer_friend.svg')} alt="Fuel Cashback" className="img-fluid py-2" />
      										<p className='cashback-title'>Buddy Referral</p>
      									</center>
                    <p className='description'>Get 2 GG Gift Vouchers worth &#8377; 100 each when your buddy generates a UPV.</p>
      								</Card>
      							</div>
      							<div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mt-s-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0'>
      								<Card className='bp3-elevation-5 cashback-cardfix'>
      									<center>
      										<img src={require('../../images/svg/vendor.svg')} alt="Fuel Cashback" className="img-fluid py-2" />
											<p className='cashback-title'>Refer a Vendor</p>
      									</center>
      									<p className='description'>Refer A Vendor And Get 2.75% Of The Commission Given To Us By The Referred Vendors.</p>
      								</Card>
      							</div>
							</div>
						</div>

						<div className='refer-cashback'>

								<div className="px-s-0 py-5 py-s-3 important-note">
									<h2 className='title text-center'>GG - Money</h2>
									<div className='container py-4 row d-flex flex-column-reverse flex-lg-row'>
										<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center'>
											<h3 className='text-muted' style={{'textAlign':'justify'}}>
                                                This Can Be Immediately Transferred To Your Bank Account After deduction of Applicable Services Charges And Government Taxes.
                                                Cashback/Reward Through SCB FIFO, SCB RPP, IC, MY CIRCLE and Referral Cashback Is Given In The Form Of GG Money.
												</h3>
										</div>
										<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center'>
											<img src={require('../../images/png/GG-Money.png')} alt="Fuel Cashback" className="img-fluid tab-img" />
										</div>
									</div>

							</div>


								<div className="px-s-0 my-4 my-s-0 py-3 important-note">
									<h2 className='title text-center'>GG - Coins</h2>
									<div className='container py-4 row d-flex flex-lg-row'>
										<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-self-center'>
											<img src={require('../../images/png/GG-Coins.png')} alt="Fuel Cashback" className="img-fluid tab-img" />
										</div>
										<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
											<h3 className=''>You Get Instant 100% GG Coins On Generation Of Each And Every UPV.</h3>
											<h3 className='text-muted' style={{'textAlign':'justify'}}>
                        This Can Be Redeemed With New Purchases To The Extent Of 5% Of The Bill Amount And GG-Coins Which Remain In GG-Rewards
                        Till 4th Cashback Payout of SCB FIFO Will Be Compulsorily Converted Into GG-Money maximum up to ₹ 10000/- And Then You
                        Can Encash It to your Bank Account*
											</h3>
										</div>
									</div>
								</div>

						</div>

    			</div>
        );
    }
}
