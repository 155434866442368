import React, {Component} from 'react';
import Youtube from '../../../core/components/Youtube';
import VideoModal from '../../../core/components/videoModal';


export default class Video extends Component{
	constructor(props){
		super(props);

		this.state = {
			isModalOpen:false,
			data:''
		};
	}


    render(){
			const {video} = this.props;
        return(
    			<div className="cb-tab-data">
						<VideoModal
    					size="lg"
    					isOpen={this.state.isModalOpen}
              data={this.state.data}
    					className="bp3-dialog p-0 rounded"
    				/>
    				<div className='row video-container py-5'>
						{ video.map((res) => {
							return(
								<div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 video' key={res.id}
									onClick={()=>{this.setState({isModalOpen:true,data:res.video_code})}}>
									<div className="bp3-elevation-3 text-center">
										<div className="embed-responsive embed-responsive-16by9">
											<Youtube videoId={res.video_code} onEnd={() => this.setState({videoPlayed: false})}/>
										</div>
										<p className=''>{res.title}</p>
										<div className="overlay">
											<p className='text '>
												{res.description}
											</p>
										</div>
									</div>
								</div>
							);
							})
						}
    				</div>
    			</div>
        );
    }
}
