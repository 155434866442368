import React, {Component,Fragment} from 'react';
import {Auth} from 'gg-react-utilities';

export default class AllModules extends Component {

      componentDidMount(){
        this.props.getModuleList()
      }

      render(){
        let counter = 0;
        const {moduleList} = this.props;
        return(
            <section className="pt-4 pb-5">
                <div className="container">
                    <div className='text-center'>
                      <h1 className="title">Myriad of Options</h1>
                      <h3 className="title"></h3>
                    </div>
                    <div className="row mb-4 mt-3">
                    { moduleList.length===0?
                        <Fragment >

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>

                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                        </Fragment>
                                    :
                        moduleList.map((module, key) => {
                        counter++;
                        if(counter > 8) counter = 1;
                          if(module.show_on_middle==='Yes'){
                            return (
                              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 text-center mt-5" key={key}>
                                  <a href={module.title!=='Loan'?module.url:'#!'} onClick={()=>{
                                      let url = module.url;
                                      if(module.title==='Loan'){
                                        if(Auth.isLoggedIn()){
                                          url = url + +Auth.user().user_id;
                                        }
                                        window.location.href = url;
                                      }
                                    }}>
                                    <div className={'text-center'}>
                                      <img src={module.header_icon} alt={module.title}
                                        style={{height: '60px',width: '60px'}}
                                        className='img-fluid '
                                        onError={(ev)=>{
                                          ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                        }}/>
                                    </div>
                                    <p className='py-2'>{module.title}</p>
                                  </a>
                                </div>
                            )
                          }
                        })
                    }
                    </div>
                </div>
                <div className="clearfix"></div>
            </section>
        );
    }
}
