import React, {Component,Fragment} from 'react';
import {Auth,ssoInstance} from 'gg-react-utilities';
import Config from "../../Config";

export default class AllModules extends Component {

      componentDidMount(){
        this.props.getModuleList()
      }
      magentoStore = (title,url) =>{
          ssoInstance.get(Config.STORE_API_URL + 'user/get-magento-store-url?module=' + title).then((res)=>{

              const sources = res.data;
              if (sources.status === true) {

                  if(sources.data.URL === ''){
                      window.location.href = url;
                  }else {
                      window.location.href = sources.data.URL;
                  }
              }

          }).then(function(json) {

          }).catch(function(error) {


          });
      }
      render(){
        let counter = 0;
        const {moduleList} = this.props;
        return(
            <section className="pt-4 pb-5">
                <div className="container">
                    <div className="row mb-4 mt-3">
                    { moduleList.length===0?
                        <Fragment >

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>

                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 mt-5 mb-4">
                              <div className="bp3-skeleton rounded-circle mx-auto" style={{width: "120px", height: "120px"}}/>
                          </div>

                        </Fragment>
                                    :
                        moduleList.map((module, key) => {
                        counter++;
                        if(counter > 8) counter = 1;
                          if(module.show_on_middle==='Yes'){
                            return (
                              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 all-module text-center mt-5" key={key}>
                                  <a href={module.is_login === 'No' ? module.url : '#!'}
                                     onClick={()=>{
                                         if(module.is_login === 'Yes'){
                                             if (!Auth.isLoggedIn()){
                                                 Auth.login(true);
                                             }else{
                                                 this.magentoStore(module.title,module.url);
                                             }
                                         }
                                     }}>
                                    <div className={'text-center'}>
                                      <img src={module.middle_icon} alt={module.title}
                                        style={{height: '100px',width: '100px'}}
                                        className='img-fluid rounded-circle'
                                        onError={(ev)=>{
                                          ev.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                                        }}/>
                                    </div>
                                    <p className='py-2'>{module.title}</p>
                                  </a>
                                </div>
                            )
                          }
                        })
                    }
                    </div>
                </div>
                <div className="clearfix"></div>
            </section>
        );
    }
}
