import React, { Component } from 'react';
// import Slider from 'react-animated-slider';
// import 'react-animated-slider/build/horizontal.css';
import "../slider-style.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NavigateNext, NavigateBefore, FavoriteBorder } from '@material-ui/icons';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const content = [
  {
    image: "https://gg-statics.s3.ap-south-1.amazonaws.com/gg-modules/GG_Games.png",
    url: "https://games.globalgarner.com"
  },
  {
    image: "https://gg-statics.s3.ap-south-1.amazonaws.com/gg-voucher-img.png",
    url: "https://mega-brands.globalgarner.com/ggvoucher"
  },
  {
    image: "https://gg-statics.s3.ap-south-1.amazonaws.com/upospgweb.png",
    url: "https://store.globalgarner.com/nearByUpos"
  },
  {
    image: "https://gg-statics.s3.ap-south-1.amazonaws.com/mega1web.png",
    url: "https://mega-brands.globalgarner.com/brand"
  },
  {
    image: "https://gg-statics.s3.ap-south-1.amazonaws.com/mega2web.png",
    url: "https://mega-brands.globalgarner.com/brand"
  },
  /*{
    image: "https://gg-statics.s3.ap-south-1.amazonaws.com/fast_tag.png",
    url: "https://best-shopping-websites.globalgarner.com/brands/fastag/1"
  },*/
  {
    image: "https://gg-statics.s3.ap-south-1.amazonaws.com/hobnobweb.png",
    url: "https://best-shopping-websites.globalgarner.com/brands/hobnob/1"
  },
  {
    image: "https://gg-statics.s3.ap-south-1.amazonaws.com/gg-statics/Shop_today_and_earn_Banner.png",
    url: "https://best-shopping-websites.globalgarner.com/brands/hobnob/1"
  }
];

const handleClick = (url) => {
  if (url != '') {
    window.open(url);
  }
}

const NewestItemSlider = () => (

  <section className="py-3">
    <div className="container">
      <div className="testimonial newestItemSlider">
        <Carousel responsive={responsive} arrows={false} showDots={true} autoPlaySpeed={4000} autoPlay={true} infinite={true}>
          {content.map((item, index) => (
            <div onClick={() => handleClick(item.url)}
              key={index}>
              <img className="w-100 pb-3" src={item.image} />
            </div>
          ))}
        </Carousel>
        { /*	<Slider className="slider-wrapper" duration={4000} autoplay={true}>
					{content.map((item, index) => (
						<div 
							onClick={() => handleClick(item.url)}
							key={index}
							className="slider-content"
							style={{ background: `url('${item.image}') no-repeat center center` }}
						>          
						</div>
					))}
          </Slider> */}
      </div>

    </div>
    <div className="clearfix"></div>
  </section>

);

export default NewestItemSlider;
