import React from 'react';
import { Card } from '@blueprintjs/core';
import Icon_1 from '../images/Icon_1.svg';
import Icon_2 from '../images/Icon_2.svg';
import Icon_3 from '../images/Icon_3.svg';
import Icon_4 from '../images/Icon_4.svg';
import Icon_5 from '../images/Icon_5.svg';
import Icon_6 from '../images/Icon_6.svg';
import Icon_7 from '../images/Icon_7.svg';
import Icon_8 from '../images/Icon_8.svg';

export default class Service extends React.Component{
    constructor(){
        super();

        this.state = {
            SEODescription: 'Global Garner is a leading search engine optimization company based in India that has been delivering top results and exceptional service for all types of business. ',
            SMMDescription: 'Social Media marketing has become a very important part of most digital marketing campaigns.',
            PPCDescription: 'PPC is that the abbreviation of pay per click which implies you have to pay some amount on for each click on your advertisement.',
            WEBDescription: 'Our professionals can design your Website with a unique look and feel. The professional quality of the design instils trust and recognition to your visitors.',
            LEADDescription: 'Strategic sales and marketing became one of the most powerful Lead Generation companies in the industry,',
            DevDescription: 'We transform your idea in to creative web designing in a way that it looks Good. Our team of experts leads you in every step of the way, from business model to implementation with smooth transition.',
            AppDescription: 'Mobile apps will enhance business productivity, sales, and engagement with our customers. It develops customer loyalty and customer connects with your business for the long-term.',
            GraphicDescription: 'Graphic designs are direct medium of promotion & can be put at different places. Banners are available in variety of sizes to accommodate any of your needs.'
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="col-12 mt-4">
                    <div className="row">
                        <h2>Our Services</h2>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-5 mb-s-3 pr-s-2">
                        <a href='#'   rel="noopener noreferrer">
                            <Card interactive={false} className="col-12 shadow-none">
                                <div className="card-body">
                                    <img src={Icon_1} alt="" className="img-fluid" />
                                    <h4>SEO Services</h4>
                                    <h5>
                                        {this.state.SEODescription}
                                    </h5>
                                </div>
                            </Card>
                        </a>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-5 mb-s-3 pl-s-2">
                        <a href='#'    rel="noopener noreferrer">
                            <Card interactive={false} className="col-12 shadow-none">
                                <div className="card-body">
                                    <img src={Icon_2} alt="" className="img-fluid" />
                                    <h4>Social Media Marketing</h4>
                                    <h5>
                                        {this.state.SMMDescription}
                                    </h5>
                                </div>
                            </Card>
                        </a>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-5 mb-s-3 pr-s-2">
                        <a href='#'   rel="noopener noreferrer">
                            <Card interactive={false} className="col-12 shadow-none">
                                <div className="card-body">
                                    <img src={Icon_3} alt="" className="img-fluid" />
                                    <h4>PPC (Pay per Click)</h4>
                                    <h5>
                                        {this.state.PPCDescription}
                                    </h5>
                                </div>
                            </Card>
                        </a>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-5 mb-s-3 pl-s-2">
                        <a href='#'   rel="noopener noreferrer">
                            <Card interactive={false} className="col-12 shadow-none">
                                <div className="card-body">
                                    <img src={Icon_4} alt="" className="img-fluid" />
                                    <h4>Web Design</h4>
                                    <h5>
                                        {this.state.WEBDescription}
                                    </h5>
                                </div>
                            </Card>
                        </a>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-5 mb-s-3 pr-s-2">
                        <a href='#'   rel="noopener noreferrer">
                            <Card interactive={false} className="col-12 shadow-none">
                                <div className="card-body">
                                    <img src={Icon_5} alt="" className="img-fluid" />
                                    <h4>Web Development</h4>
                                    <h5>
                                        {this.state.DevDescription}
                                    </h5>
                                </div>
                            </Card>
                        </a>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-5 mb-s-3 pl-s-2">
                        <a href='#'   rel="noopener noreferrer">
                            <Card interactive={false} className="col-12 shadow-none">
                                <div className="card-body">
                                    <img src={Icon_6} alt="" className="img-fluid" />
                                    <h4>Mobile Application Development</h4>
                                    <h5>
                                        {this.state.AppDescription}
                                    </h5>
                                </div>
                            </Card>
                        </a>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-5 mb-s-3 pr-s-2">
                        <a href='#'   rel="noopener noreferrer">
                            <Card interactive={false} className="col-12 shadow-none">
                                <div className="card-body">
                                    <img src={Icon_7} alt="" className="img-fluid" />
                                    <h4>Graphic Design</h4>
                                    <h5>
                                        {this.state.GraphicDescription}
                                    </h5>
                                </div>
                            </Card>
                        </a>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-5 mb-s-3 pl-s-2">
                        <a href='#'   rel="noopener noreferrer">
                            <Card interactive={false} className="col-12 shadow-none">
                                <div className="card-body">
                                    <img src={Icon_8} alt="" className="img-fluid" />
                                    <h4>Lead Generation</h4>
                                    <h5>
                                        {this.state.LEADDescription}
                                    </h5>
                                </div>
                            </Card>
                        </a>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
