import React, { Component } from 'react'
import { PageTitle } from 'gg-react-utilities';

import { SearchRounded } from '@material-ui/icons';
import { Button } from '@material-ui/core';

export default class JobDetail extends Component {

    joblistSidebar = [
        { jobtitle: "Relationship Manager", exp: "2-4" },
        { jobtitle: "Executive Assistant", exp: "0-2" },
        { jobtitle: "Manual and Automation Test Engineer", exp: "2-4" },
        { jobtitle: "UI/UX Designer", exp: "2-4" },
        { jobtitle: "Laravel Devloper", exp: "2-4" },
        { jobtitle: "Full Stack Developer", exp: "2-4" },
        { jobtitle: "React JS Developer", exp: "2-4" }
    ]

    render() {
        return (
            <React.Fragment >
                <div className="jobdetail mt-5 mb-5">
                    <PageTitle title='Career | Global Garner' />
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8 col-md-12">
                                <div className="jobDetailContainer">

                                    <div className="jobtitle">
                                        <h1>Sales Executive</h1>
                                        <h5>2 - 4 YEARS</h5>
                                        <p>Ahmedabad</p>
                                    </div>

                                    <div className="kd"></div>

                                    <div className="jb">
                                        <p>We are looking for an HR Executive to manage our company's recruiting, learning and development and employee performance programs.</p>
                                        <p>HR Executive responsibilities include creating referral programs, updating HR policies and  overseeing our hiring processes. To be successful in this role, you should have an extensive background in Human Resources departments.</p>
                                        <p>Ultimately, you will make strategic decisions for our company so that we hire, develop and retain qualified employees.</p>
                                    </div>

                                    <div className="rp">
                                        <h5>Responsibilities</h5>
                                        <ul>
                                            <li><p>Design compensation and benefits packages</p></li>
                                            <li><p>Implement performance review procedures (e.g. quarterly/annual and 360° evaluations)</p></li>
                                            <li><p>Develop fair HR policies and ensure employees understand and comply with them</p></li>
                                            <li><p>Implement effective sourcing, screening and interviewing techniques</p></li>
                                            <li><p>Assess training needs and coordinate learning and development initiatives for all employees</p></li>
                                            <li><p>Monitor HR department's budget</p></li>
                                            <li><p>Manage employees' grievances</p></li>
                                            <li><p>Review current HR technology and recommend more effective software</p></li>
                                            <li><p>Measure employee retention and turnover rates</p></li>
                                            <li><p>Oversee daily operations of the HR department</p></li>
                                        </ul>
                                    </div>

                                    <div className="rs">
                                        <h5>Requirements and skills</h5>

                                        <ul>
                                            <li><p>Proven work experience as an HR Executive, HR Manager or similar role</p></li>
                                            <li><p>Familiarity with Human Resources Management Systems and Applicant Tracking Systems</p></li>
                                            <li><p>Experience with full-cycle recruiting</p></li>
                                            <li><p>Good knowledge of labor legislation (particularly employment contracts, employee leaves and insurance)</p></li>
                                            <li><p>Demonstrable leadership abilities</p></li>
                                            <li><p>Solid communication skills</p></li>
                                        </ul>
                                    </div>

                                    <div className="jd"></div>

                                    <Button>Apply Now</Button>

                                </div>
                            </div>

                            <div className="offset-lg-1 col-lg-3 col-md-12">
                                <h2 className="oj">Other Jobs</h2>
                                {this.joblistSidebar.map((item, i) => (
                                    <div className="joblist-sidebar">
                                        <ul>
                                            <li>
                                                <h5>{item.jobtitle}</h5>
                                                <p>{item.exp}</p>
                                            </li>
                                        </ul>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>

                    <div className="jobApplication">
                        <div className="container">
                            <div className="row form">

                                <div className="col-lg-6 col-sm-12">
                                    <input type="text" className="bp3-input border-0 input-applyjob" placeholder="Full Name" />
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <input type="text" className="bp3-input border-0 input-applyjob" placeholder="Experience" />
                                </div>

                                <div className="col-lg-6 col-sm-12">
                                    <input type="text" className="bp3-input border-0 input-applyjob" placeholder="Email ID" />
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <input type="text" className="bp3-input border-0 input-applyjob" placeholder="Mobile Number" />
                                </div>

                                <div className="col-lg-6 col-sm-12">
                                    <input type="text" className="bp3-input border-0 input-applyjob" placeholder="Address" />
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <input type="text" className="bp3-input border-0 input-applyjob" placeholder="Current CTC" />
                                </div>

                                <div className="col-lg-12">
                                    <input type="file" className="bp3-input border-0 inputfile-applyjob" placeholder="Choose file" />
                                </div>
                                <div className="col-lg-12">
                                    <textarea type="text" className="bp3-input border-0 input-applyjob" placeholder="Place your description here..." />
                                </div>

                                <div className="col-lg-3 mx-auto">
                                    <Button>Apply For This Job</Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}