import React, {Component} from 'react';
import { Button, FormGroup } from "@blueprintjs/core";
import {Textbox, Textarea} from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';

export default class SubmitForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            name:"",
            exp:"",
            email:"",
            mobile:"",
            address:"",
            ctc:"",
            desc:"",
            resume:null,
            hasNameError:true,
            hasExpError:true,
            hasEmailError:true,
            hasMobileError:true,
            hasAddressError:true,
            hasCtcError:true,
            hasDescError:true,
            hasFileError:false,
            validate:false,
            hasFileMsg:null
        };

        this.onSubmit = this.onSubmit.bind(this);

    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    onSubmit(){
        this.toggleValidating(true);
        if(this.state.hasFileError===true) {
            if(!this.state.hasNameError ||
                !this.state.hasExpError ||
                !this.state.hasEmailError ||
                !this.state.hasMobileError ||
                !this.state.hasAddressError ||
                !this.state.hasCtcError ||
                !this.state.hasDescError) {

                let obj = {
                    name: this.state.name,
                    exp: this.state.exp,
                    email: this.state.email,
                    mobile: this.state.mobile,
                    address: this.state.address,
                    ctc: this.state.ctc,
                    desc: this.state.desc,
                    resume: this.state.resume
                }
                this.props.postResume(obj);
            }
        }else{
            this.setState({
                hasFileMsg :"Resume File is required"
            });
            //console.error("Resolve all errors before submit the form");
        }
    }
    render(){
        return(
			<div className='container mb-5'>
				<div className='row submit-career-form pt-5 p-s-2 justify-content-center'>
					<div className='col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12'>
						<div className="row">
							<div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 py-3'>
								<Textbox
									tabIndex="1"
									type="text"
									id="text-input"
									value={this.state.name}
									placeholder="Name"
									onChange={(name) => {
										this.setState({
											name:name
										});
									}}
									onBlur={e => {}}
									validate={this.state.validate}
									validationCallback={res =>
										this.setState({ hasNameError:res, validate: false })
									}
									validationOption={{
										name: "Name",
										check: true,
										required: true
									}}/>
							</div>
							<div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 py-3'>

								<Textbox
									tabIndex="2"
									type="text"
									id="text-input"
									value={this.state.exp}
									placeholder="Experience"
									onChange={(exp) => {
										this.setState({
											exp:exp
										});
									}}
									onBlur={e => {}}
									validate={this.state.validate}
									validationCallback={res =>
										this.setState({ hasExpError:res, validate: false })
									}
									validationOption={{
										name: "Experience",
										check: true,
										required: true
									}}/>
							</div>
							<div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 py-3'>
								<Textbox
									tabIndex="3"
									type="text"
									id="text-input"
									value={this.state.email}
									placeholder="Email ID"
									onChange={(email) => {
										this.setState({
											email:email
										});
									}}
									onBlur={e => {}}
									validate={this.state.validate}
									validationCallback={res =>
										this.setState({ hasEmailError:res, validate: false })
									}
									validationOption={{
										name: "Email ID",
										check: true,
										required: true
									}}/>
							</div>
							<div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 py-3'>
								<Textbox
									tabIndex="4"
									type="text"
									id="text-input"
									value={this.state.mobile}
									placeholder="Mobile Number"
									onChange={(mobile) => {
										this.setState({
											mobile:mobile
										});
									}}
									onBlur={e => {}}
									validate={this.state.validate}
									validationCallback={res =>
										this.setState({ hasMobileError:res, validate: false })
									}
									validationOption={{
										name: "Mobile Number",
										check: true,
										required: true
									}}/>
							</div>
							<div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 py-3'>
								<Textbox
									tabIndex="5"
									type="text"
									id="text-input"
									value={this.state.address}
									placeholder="Address"
									onChange={(address) => {
										this.setState({
											address:address
										});
									}}
									onBlur={e => {}}
									validate={this.state.validate}
									validationCallback={res =>
										this.setState({ hasAddressError:res, validate: false })
									}
									validationOption={{
										name: "Address",
										check: true,
										required: true
									}}/>
							</div>
							<div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 py-3'>
								<Textbox
									tabIndex="6"
									type="text"
									id="text-input"
									value={this.state.ctc}
									placeholder="Current CTC"
									onChange={(ctc) => {
										this.setState({
											ctc:ctc
										});
									}}
									onBlur={e => {}}
									validate={this.state.validate}
									validationCallback={res =>
										this.setState({ hasCtcError:res, validate: false })
									}
									validationOption={{
										name: "CTC",
										check: true,
										required: true
									}}/>
							</div>
							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3'>
								<Textarea
									tabIndex="7"
									id="description"
									name="description"
									value={this.state.desc}
									rows="7"
									placeholder="Place your description here..."
									validate={this.state.validate}
									validationCallback={res =>
										this.setState({
											hasDescError: res,
											validate: false
										})}
									onChange={(desc) => {
										this.setState({ desc:desc });
									}}
									onBlur={(e) => {}}
									validationOption={{
										name: 'Description',
										check: true,
										required: true,
										type: 'string',
									}}
								/>
							</div>
							<div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 col-12 mt-3'>
								<div className="bp3-input-group">
									<FormGroup
										helperText={this.state.hasFileMsg}>
										<label className="bp3-file-input bp3-fill mb-2">
											<input type="file" accept="application/*" className="bp3-input file-input" placeholder="Choose Attatchment" onChange={(event) => {
												this.setState({
													hasFileError:true,
													resume:event.target.files[0]
												})
											}}/>
											<span className="bp3-file-upload-input">Choose file...</span>
										</label>
									</FormGroup>
								</div>
							</div>

							<div className='col-xl-12 text-right mt-5 mb-5'>
								<Button text='Apply for this job' className='bp3-button bp3-intent-primary bp3-button bp3-intent-primary bp3-large'onClick={this.onSubmit}/>
							</div>
						</div>
					</div>
				</div>
			</div>
        );
    }
}
