import React from 'react';
import {Button} from '@blueprintjs/core';


const maxAdultsAllowed = 4;
const maxChildAllowed = 2;


export default class SingleRoomSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            adults: this.props.roomData.adults,
            children: this.props.roomData.children
        };
    }

    updateRoomGuests = () => {
        setTimeout(() => {
            this.props.updateRoomGuests(this.props.roomNumber, this.state)
        }, 10);
    }

    incrementAdult() {
        if (this.state.adults < maxAdultsAllowed) {
            this.setState({
                adults: this.state.adults + 1
            }, this.updateRoomGuests())
        }
    }

    incrementChild() {
        if (this.state.children < maxChildAllowed) {
            this.setState({
                children: this.state.children + 1
            }, this.updateRoomGuests())
        }

    }

    decrementAdult() {
        if (this.state.adults > 1) {
            this.setState({
                adults: this.state.adults - 1
            }, this.updateRoomGuests())
        }
    }

    decrementChild() {
        if (this.state.children > 0) {
            this.setState({
                children: this.state.children - 1
            }, this.updateRoomGuests())
        }
    }

    render() {
        return (
            <li className="border-bottom">
                <div className="row ml-0 mr-0 mt-3 mb-2 select-room">
                    <p className="text-uppercase font-12 font-weight-bold text-dark pl-3 mb-1">Room: {this.props.roomNumber}</p>
                    <div className="col-12 p-0 d-flex align-items-center justify-content-between mb-1">
                        <div
                            className="col-sm-8 col-6 float-left d-flex align-items-center justify-content-between pr-0">
                            <div
                                className="mt-0 mb-0 bp3-text-medium text-dark font-weight-normal d-flex align-items-center">
                                Adults
                                <span className="bp3-text-small"></span>
                            </div>
                        </div>

                        <div className="col-sm-4 col-6 float-right">
                            <div className="bp3-control-group d-flex justify-content-end">
                                <Button type={"button"}
                                        className="bp3-button bp3-increase bp3-increase-small"
                                        icon='minus' onClick={() => this.decrementAdult()}
                                        style={{borderRadius: "3px 0px 0px 3px"}}/>

                                <input type="text"
                                       className="bp3-input bp3-input-counter text-center font-weight-bold"
                                       value={this.state.adults} style={{width: '40px', height: '30px'}} readOnly/>
                                {(this.state.adults < maxAdultsAllowed) ?
                                    <Button type={"button"}
                                            className="bp3-button bp3-decrease bp3-decrease-small"
                                            icon={'plus'}
                                            onClick={() => this.incrementAdult()}
                                            style={{borderRadius: "0px 3px 3px 0px"}}/>
                                    :
                                    <Button type={"button"}
                                            className="bp3-button bp3-decrease bp3-decrease-small"
                                            icon={'plus'}
                                            disabled={true}
                                            style={{borderRadius: "0px 3px 3px 0px"}}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-0 d-flex align-items-center justify-content-between mt-2">
                        <div
                            className="col-sm-8 col-6 float-left d-flex align-items-center justify-content-between pr-0">
                            <div
                                className="mt-0 mb-0 bp3-text-medium text-dark font-weight-normal d-flex align-items-center">
                                Child &nbsp;
                                <span
                                    className="bp3-text-small"> (Below {parseInt(process.env.REACT_APP_HOTEL_MAX_CHILD_AGE) + 1} years)</span>
                            </div>
                        </div>

                        <div className="col-sm-4 col-6 float-right">
                            <div className="bp3-control-group d-flex justify-content-end">
                                <Button type={"button"}
                                        className="bp3-button bp3-increase bp3-increase-small"
                                        icon='minus' onClick={() => this.decrementChild()}
                                        style={{borderRadius: "3px 0px 0px 3px"}}/>
                                <input type="text"
                                       className="bp3-input bp3-input-counter text-center font-weight-bold"
                                       value={this.state.children} style={{width: '40px', height: '30px'}} readOnly/>

                                {(this.state.children < maxChildAllowed) ?
                                    <Button type={"button"}
                                            className="bp3-button bp3-decrease bp3-decrease-small"
                                            icon={'plus'}
                                            onClick={() => this.incrementChild()}
                                            style={{borderRadius: "0px 3px 3px 0px"}}/>
                                    :
                                    <Button type={"button"}
                                            className="bp3-button bp3-decrease bp3-decrease-small"
                                            icon={'plus'}
                                            disabled={true}
                                            style={{borderRadius: "0px 3px 3px 0px"}}
                                    />
                                }


                            </div>
                        </div>
                    </div>

                </div>
            </li>
        );
    }
}
