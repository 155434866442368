import React, {Component, Fragment} from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import Director from "./teamMemberType/Director";
import Management from "./teamMemberType/Management";
import OperationalTeam from "./teamMemberType/Operational_team";
import CP from "../container/channelPartner";
import Loading from './loadingTeam'

export default class Team extends Component{
    constructor(props){
        super(props);

        this.state = {
            activeTab: "dir"
        };
    }

    handleTabChange = (TabId) => {
        this.setState({activeTab: TabId});
    };

    componentDidMount(){
      this.props.getTeamList()
    }

    render(){
        return(
    			<div className="container p-s-0 pb-5">
					<div className="team">
						{this.props.teamList===null ?
						<Fragment>
							<Tabs id="team-type" onChange={this.handleTabChange} selectedTabId={this.state.activeTab} animate={false}>
							<Tab id="dir" title="Board of Directors" panel={''} />
							<Tab id="mgt" title="Management Team" panel={''} />
							<Tab id="ot" title="Operational Team" panel={''} />
							<Tab id="cp" title="Channel Partner" panel={''} />
							</Tabs>
							<Loading/>
						</Fragment>
						:
						<Tabs id="team-type" onChange={this.handleTabChange} selectedTabId={this.state.activeTab} animate={false}>
							<Tab id="dir" title="Board of Directors" panel={<Director data={this.props.teamList.Directors}/>} />
							<Tab id="mgt" title="Management Team" panel={<Management data={this.props.teamList.Management} />} />
							<Tab id="ot" title="Operational Team" panel={<OperationalTeam data={this.props.teamList.Operational} />} />
							<Tab id="cp" title="Channel Partner" panel={<CP />} />
						</Tabs>
						}
					</div>
    			</div>
        );
    }
}
