import React, {Component, Fragment} from 'react';
import {Card, Button, Dialog, Classes} from "@blueprintjs/core";

export default class Management extends Component{
    constructor(props){
        super(props);

        this.state = {
          isCallModal: false,
          modalData:null
        };
    }

    render(){
      const {data} = this.props;
        return(
            <React.Fragment>
    			<div className="row cb-tab-data text-center directer-member-list">
                    {/*<MemberDetailModal*/}
                      {/*title={''}*/}
                      {/*isOpen={this.state.isCallModal}*/}
                      {/*className="bp3-dialog p-0 rounded"*/}
                      {/*data={this.state.modalData}*/}
                      {/*/>*/}
                    {data.map((res)=>{
                      return(
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 directer-member-detail' key={res.team_id}>
                          <Card className='bp3-elevation-1 py-5'>
                            <center>
                              <img src={process.env.REACT_APP_IMAGE_URL+res.image} alt='team-member-img'
                                onError={(ev)=>{
                                  ev.target.src = process.env.REACT_APP_USER_DEFAULT_IMAGE;
                              }}/>
                            </center>
                            <p className='employee-name'>{res.name}</p>
                            <p className='employee-position'>{res.position}</p>
                            <Button text={'More about '+ res.name} className='bp3-button btn-link' onClick={()=>{
                              this.setState({isCallModal:true,modalData:res})
                              }}/>
                          </Card>
                        </div>
                      )
                    })}
    			</div>
                {this.state.modalData !== null ?
                    <Dialog
                        isOpen={this.state.isCallModal}
                        title={this.props.title}
                        canOutsideClickClose={false}
                        canEscapeKeyClose={true}
                        className={'bp3-dialog p-0 rounded memberDetail'}>
                        <div className="bp3-dialog-header">
                            <h4 className="bp3-heading"> </h4>
                            <button aria-label="Close" className="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross" onClick={() =>{
                                this.setState({
                                    isCallModal:false
                                })
                            }}> </button>
                        </div>

                        <div className={Classes.DIALOG_BODY+' p-1 m-0 member-detail'} >
                            <Card className='text-center bp3-elevation-1'>
                                <center>
                                    <img src={process.env.REACT_APP_IMAGE_URL+this.state.modalData.image} alt='team-member-img'
                                         onError={(ev)=>{
                                             ev.target.src = process.env.REACT_APP_USER_DEFAULT_IMAGE;
                                         }}/>
                                </center>
                                <h3 className='name mb-2'>{this.state.modalData.name}</h3>
                                <p className='position'>{this.state.modalData.position}</p>
                                <p className='description'>
                                    {this.state.modalData.description}
                                </p>
                                {this.state.socialIcon?
                                    <Fragment>
                                        <div className='border-bottom pt-3'/>
                                        <div className='social-icon row no-gutters'>
                                            <div className='col-xl-2 col-2'>
                                                <a href={'mailto:'+this.state.modalData.email} data-rel="external">
                                                    <img src={require('../../images/svg/mail.svg')} alt='team-member-img'/>
                                                </a>
                                            </div>
                                            <div className='col-xl-2 col-2'>
                                                <a href={this.state.modalData.facebook}>
                                                    <img src={require('../../images/svg/fb.svg')} alt='team-member-img'/>
                                                </a>
                                            </div>
                                            <div className='col-xl-2 col-2'>
                                                <a href={'tel:'+this.state.modalData.mobile} data-rel="external">
                                                    <img src={require('../../images/svg/call.svg')} alt='team-member-img'/>
                                                </a>
                                            </div>
                                            <div className='col-xl-2 col-2'>
                                                <a href={this.state.modalData.linkedin}>
                                                    <img src={require('../../images/svg/linkedIn.svg')} alt='team-member-img'/>
                                                </a>
                                            </div>
                                            <div className='col-xl-2 col-2'>
                                                <a href={this.state.modalData.skype}>
                                                    <img src={require('../../images/svg/skype.svg')} alt='team-member-img'/>
                                                </a>
                                            </div>
                                            <div className='col-xl-2 col-2'>
                                                <a href={this.state.modalData.relations}>
                                                    <img src={require('../../images/svg/relation.svg')} alt='team-member-img'/>
                                                </a>
                                            </div>
                                        </div>
                                    </Fragment>

                                    :null}
                            </Card>
                        </div>
                    </Dialog>
                    :null}
            </React.Fragment>
        );
    }
}
