import React, {Component, Fragment} from 'react';
import Config from '../../Config';
import {Helper} from 'gg-react-utilities';

export default class Service extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    render(){
      const {data} = this.props;
        return(
        <Fragment>
          <div className="row">
            <div className="col-xl-7 col-xl-7 col-sm-7 col-8">
              <h2 className="product-title">GG Services</h2>
            </div>
            <div className="col-xl-5 col-xl-5 col-sm-5 col-4">
              <p className='mt-2 pull-right link text-primary'>{data.length} result</p>
            </div>
          </div>
          <div className='product-list row'>
            {data.map((service) => {
              service = service._source
              return(
                <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2' key={service.id}>
                  <div className="product-card">
                    <div className="image">
                      <a href={Config.STORE_URL+'service/'+service.slug}>
                        <img src={process.env.REACT_APP_SERVICE_IMAGE} alt="5 Terre" className="img-fluid" />
                      </a>
                    </div>
                    <div className="product-sticker">
                      <img src={process.env.REACT_APP_INFO_IMAGE} className='gg-coin-img' alt='info'/>
                    </div>
                    <div className="products">
                      <div className='row'>
                        <div className="col-xl-12 col-12 text-left">
                          <a href={Config.STORE_URL+'service/'+service.slug}>
                            <h3 className="product-name">{service.title}</h3>
                          </a>
                          <p className="product-description">{service.description}</p>
                          <p className="product-service-price"> {Helper.formatMoney(service.price)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Fragment>
        );

    }

}
