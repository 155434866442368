import React, {Component} from 'react';

import Privacypolicy from "./components/privacy_policy";
import {PageTitle} from 'gg-react-utilities';

export default class PrivacyPolicy extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoPlayed: false
        };
    }

    componentDidMount(){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },1)
    }
    
    render(){
        return(
            <React.Fragment>
              <PageTitle title='Privacy Policy | Global Garner' />
                <div className="container-fluid header-below">
        					<div className="container p-s-0">
        						<div className="career header-below-content">
        							<div className="row text-center mb-5">
        								<div className="col-12 col-xl-12 text-center py-5">
        									<h1 className="bp3-text-xlarge heading">Privacy Policy</h1>
        									<span className="heading-desc">
        										Last Updated On 1st March, 2019
        									</span>
        								</div>
        							</div>
        						</div>
                    </div>
                </div>
                <div className="container-fluid">
                    <Privacypolicy />
                </div>
            </React.Fragment>
        );
    }
}
